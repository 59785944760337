import { Typography } from '@mui/material'
import { IconCalendar } from '@tabler/icons-react'
import React, { ReactNode, useState } from 'react'
import DatePickerPopover from '../../../../components/dashboard/DatePickerPopover'
import RestaurantsPageLayout from '../../../../components/restaurants/RestaurantsPageLayout'
import useAppUtilities from '../../../../hooks/useAppUtilities'
import useCurrentWeek from '../../../../hooks/useCurrentWeek'
import {
  DashboardHeaderMainBox,
  DateMainBox,
  HeaderCalendarButton,
} from '../styled/DashboardHeader.styled'

interface DashboardHeaderProps {
  title: string
  body: ReactNode
}

const DashboardHeader: React.FC<DashboardHeaderProps> = ({ title, body }) => {
  const { theme } = useAppUtilities()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (): void => setAnchorEl(null)

  return (
    <>
      <RestaurantsPageLayout
        title={title}
        headerShow={true}
        HeaderRight={
          <DashboardHeaderMainBox>
            {useCurrentWeek().map((item, i) => (
              <DateBox key={i} {...item} />
            ))}
            <HeaderCalendarButton
              aria-describedby={open ? 'simple-popover' : undefined}
              onClick={handleClick}
            >
              <IconCalendar stroke={2} color={theme.palette.grey[700]} />
            </HeaderCalendarButton>
          </DashboardHeaderMainBox>
        }
        body={body}
      />
      <DatePickerPopover
        open={open}
        anchorEl={anchorEl}
        handleClose={handleClose}
      />
    </>
  )
}

export default DashboardHeader

interface DateBoxProps {
  active?: boolean
  date: number
  day: string
}

const DateBox: React.FC<DateBoxProps> = ({ date, day, active = false }) => {
  const { theme } = useAppUtilities()
  const activeColor = active
    ? theme.palette.secondary.main
    : theme.palette.grey[300]

  return (
    <DateMainBox activeColor={activeColor}>
      <Typography
        variant="subtitle1"
        color={active ? theme.palette.secondary.main : theme.palette.grey[800]}
      >
        {date}
      </Typography>
      <Typography
        variant="caption"
        color={active ? theme.palette.secondary.main : theme.palette.grey[500]}
      >
        {day}
      </Typography>
    </DateMainBox>
  )
}
