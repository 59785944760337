import { Box, Typography } from '@mui/material'
import React from 'react'
import useAppUtilities from '../../hooks/useAppUtilities'

interface DietaryTagsProps {
  dietaryData: string
  suitable?: boolean
}

const DietaryTags: React.FC<DietaryTagsProps> = ({
  dietaryData,
  suitable = true,
}) => {
  const { theme } = useAppUtilities()

  const dietaryColors = suitable
    ? [
        theme.palette.success.light,
        theme.palette.error.main,
        theme.palette.warning.dark,
        theme.palette.primary.dark,
        theme.palette.warning.light,
      ]
    : [
        theme.palette.success[600],
        theme.palette.primary.dark,
        theme.palette.grey[800],
        theme.palette.warning.light,
        theme.palette.error.light,
        theme.palette.secondary.light,
        theme.palette.info.light,
      ]

  const dietaryItems = (
    dietaryData: string,
    bgColors: string[],
  ): JSX.Element[] =>
    dietaryData
      .split(',')
      .filter((item) => item.trim().length > 0)
      .map((item, index) => (
        <Box
          key={index}
          sx={{
            padding: '6px 10px',
            backgroundColor: bgColors[index % bgColors.length],
            borderRadius: '7px',
          }}
        >
          <Typography
            variant="subtitle2"
            sx={{ color: theme.palette.background.paper }}
          >
            {item}
          </Typography>
        </Box>
      ))

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: '8px',
      }}
    >
      {dietaryItems(dietaryData, dietaryColors)}
    </Box>
  )
}

export default DietaryTags
