import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useAppSelector } from '../../../../store'
import { selectAdminReview } from '../../../../store/restaurant/review'
import {
  ReviewsSummaryDescriptionTypography,
  ReviewsSummaryDivider,
  ReviewsSummaryListContainer,
  ReviewsSummaryMainContainer,
  ReviewsSummaryTitleTypography,
  ReviewsSummaryUserPreferenceTypography,
} from '../styled/ReviewsSummary.styled'
import SummaryChip from './SummaryChip'
import SummaryList from './SummaryList'

interface WordCount {
  label: string
  count?: number | null
}

const ReviewsSummary: React.FC = () => {
  const adminReview = useAppSelector(selectAdminReview)
  const [usersLike, setUsersLike] = useState<WordCount[]>([])
  const [usersDislike, setUsersDislike] = useState<WordCount[]>([])
  const [userLikesSelectedChip, setUserLikesSelectedChip] = useState<string[]>(
    [],
  )
  const [userDislikesSelectedChip, setUserDislikesSelectedChip] = useState<
    string[]
  >([])

  useEffect(() => {
    const likeWords = adminReview?.positiveTags?.map((item) => {
      return { label: item, count: null }
    })
    const dislikeWords = adminReview?.negativeTags?.map((item) => {
      return { label: item, count: null }
    })

    if (likeWords) setUsersLike(likeWords)
    if (dislikeWords) setUsersDislike(dislikeWords)
  }, [adminReview?.positiveTags, adminReview?.negativeTags])

  const handleSelectedWord = (word: Record<string, number | null>): void => {
    const updatedUsersLike = usersLike.map((item) => {
      const count = word?.[item.label.toLowerCase()]
      return {
        ...item,
        count: count && count > 0 ? count : null,
      }
    })
    const updatedUsersDislike = usersDislike.map((item) => {
      const count = word?.[item.label.toLowerCase()]
      return {
        ...item,
        count: count && count > 0 ? count : null,
      }
    })

    setUsersLike(updatedUsersLike)
    setUsersDislike(updatedUsersDislike)
  }

  return (
    <>
      <ReviewsSummaryMainContainer>
        {adminReview?.summary && (
          <>
            <ReviewsSummaryTitleTypography>
              Summary:
            </ReviewsSummaryTitleTypography>
            <ReviewsSummaryDescriptionTypography>
              {adminReview?.summary ?? ''}
            </ReviewsSummaryDescriptionTypography>
            <ReviewsSummaryDivider />
          </>
        )}
        {usersLike && (
          <>
            <ReviewsSummaryUserPreferenceTypography>
              What users like
            </ReviewsSummaryUserPreferenceTypography>
            <Box sx={{ marginTop: '8px', marginBottom: '20px' }}>
              <SummaryChip
                highlightColor={'green'}
                data={usersLike}
                selectedChip={userLikesSelectedChip}
                setSelectedChip={setUserLikesSelectedChip}
              />
            </Box>
          </>
        )}
        {usersDislike && (
          <>
            <ReviewsSummaryUserPreferenceTypography>
              What users didn’t like
            </ReviewsSummaryUserPreferenceTypography>
            <Box sx={{ marginTop: '8px', marginBottom: '30px' }}>
              <SummaryChip
                highlightColor={'red'}
                data={usersDislike}
                selectedChip={userDislikesSelectedChip}
                setSelectedChip={setUserDislikesSelectedChip}
              />
            </Box>
          </>
        )}
      </ReviewsSummaryMainContainer>
      <ReviewsSummaryListContainer>
        <SummaryList
          likeSelectedWord={userLikesSelectedChip}
          dislikeSelectedWord={userDislikesSelectedChip}
          onSelectedWord={handleSelectedWord}
        />
      </ReviewsSummaryListContainer>
    </>
  )
}

export default ReviewsSummary
