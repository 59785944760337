import React, { useState } from 'react'
import BasicModal from '../../../../components/modal/BasicModal'
import useAppUtilities from '../../../../hooks/useAppUtilities'
import { useAuthHelper } from '../../../../hooks/useAuthHelper'
import { useAppSelector } from '../../../../store'
import {
  deleteUserProfile,
  selectIsLoading,
  selectMessage,
  selectSeverity,
  selectShouldOpen,
  setOpen,
} from '../../../../store/authSlice'
import useRudderStackAnalytics from '../../../../useRudderAnalytics'
import { MuiAlert, MuiSnackbar } from '../../styled/global.styled'
import {
  ProfileMoreButtonBasicModalTextField,
  ProfileMoreButtonBasicModalTypography,
  ProfileMoreButtonPopover,
  ProfileMoreButtonPopoverMenuContainer,
  ProfileMoreButtonPopoverMenuTypography,
} from '../styled/MoreButton.styled'

interface ProfilePopupProps {
  anchorEl: HTMLButtonElement | null
  openPop: boolean
  id: string | undefined
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>
  setEditProfile: (isEditing: boolean) => void
}

const ProfilePopup: React.FC<ProfilePopupProps> = ({
  anchorEl,
  openPop,
  id,
  setAnchorEl,
  setEditProfile,
}) => {
  const { dispatch, isMediumScreen } = useAppUtilities()
  const { logoutAuthUser } = useAuthHelper()
  const [analytics, isAnalyticsReady] = useRudderStackAnalytics()
  const message = useAppSelector(selectMessage)
  const severity = useAppSelector(selectSeverity)
  const isLoading = useAppSelector(selectIsLoading)
  const openServer = useAppSelector(selectShouldOpen)
  const [modalOpen, setModalOpen] = React.useState(false)
  const [deleteAccountReason, setDeleteAccountReason] = useState('')

  const handleClose = (): void => {
    setAnchorEl(null)
  }

  const onHandleModalOpen = (): void => setModalOpen(true)
  const onHandleModalCancel = (): void => setModalOpen(false)

  const onHandleDeleteProfile = async (): Promise<void> => {
    const params = {
      deleteAccountReason,
    }

    const result = await dispatch(deleteUserProfile(params))

    if (deleteUserProfile.fulfilled.match(result)) {
      if (isAnalyticsReady && analytics) {
        analytics?.identify('', { isLoggedIn: false })
        analytics?.reset()
      }
      logoutAuthUser()
      setModalOpen(false)
    }
  }

  const setOpenServer = (): void => {
    dispatch(setOpen(false))
  }
  const onEditProfile = (): void => {
    setEditProfile(true)
    setAnchorEl(null)
  }

  return (
    <>
      <ProfileMoreButtonPopover
        id={id}
        open={openPop}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        {isMediumScreen && (
          <ProfileMoreButtonPopoverMenuContainer>
            <ProfileMoreButtonPopoverMenuTypography onClick={onEditProfile}>
              Edit Profile
            </ProfileMoreButtonPopoverMenuTypography>
          </ProfileMoreButtonPopoverMenuContainer>
        )}
        <ProfileMoreButtonPopoverMenuContainer onClick={onHandleModalOpen}>
          <ProfileMoreButtonPopoverMenuTypography>
            Delete Profile
          </ProfileMoreButtonPopoverMenuTypography>
        </ProfileMoreButtonPopoverMenuContainer>
      </ProfileMoreButtonPopover>

      <BasicModal
        open={modalOpen}
        isLoader={isLoading}
        onCancel={onHandleModalCancel}
        onConfirm={() => {
          void onHandleDeleteProfile()
        }}
        title="Delete Profile?"
        buttonName="Delete Profile"
        handleClose={() => setModalOpen(false)}
      >
        <div>
          <ProfileMoreButtonBasicModalTypography>
            Please explain the reason for deleting your profile.
          </ProfileMoreButtonBasicModalTypography>
          <ProfileMoreButtonBasicModalTextField
            id="reason-description"
            placeholder="Enter a description..."
            value={deleteAccountReason}
            onChange={(event) => setDeleteAccountReason(event.target.value)}
          />
        </div>
      </BasicModal>
      <MuiSnackbar open={openServer} onClose={setOpenServer}>
        <MuiAlert onClose={setOpenServer} severity={severity ?? 'success'}>
          {message}
        </MuiAlert>
      </MuiSnackbar>
    </>
  )
}

export default ProfilePopup
