import { Menu, useTheme } from '@mui/material'
import styled from 'styled-components'

export const StyledMenuPopup = styled(Menu).attrs(() => {
  const theme = useTheme()
  return {
    PaperProps: {
      style: {
        borderRadius: '8px',
        boxShadow: theme.shadows[3],
      },
    },
    transformOrigin: { horizontal: 'right', vertical: 'top' },
    anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
  }
})(() => ({}))
