import { useAuth0 } from '@auth0/auth0-react'
import { Browser } from '@capacitor/browser'
import { useCallback } from 'react'
import { clearLocalStorage } from '../helpers/auth-helper'
import { useAppDispatch } from '../store'
import { clearData as authClearData } from '../store/authSlice'
import { clearData as eaterClearData } from '../store/eaterSlice'
import { clearData as landingClearData } from '../store/landingSlice'
import { clearData as onboardClearData } from '../store/onboardSlice'
import { clearData as profileClearData } from '../store/profileSlice'
import { clearData as restaurantClearData } from '../store/restaurantSlice'
import { clearData as searchClearData } from '../store/searchSlice'
import { clearData as socialClearData } from '../store/socialSlice'
import { clearData as menuClearData } from '../store/restaurant/menu'
import { clearData as settingClearData } from '../store/restaurant/setting'
import { clearData as dashboardClearData } from '../store/restaurant/dashboard'
import { clearData as reviewClearData } from '../store/restaurant/review'
import { clearData as messageClearData } from '../store/restaurant/messages'
import { useDevice } from './useDevice'
import { errorLog } from '../utils/log-helper'

interface AuthHelper {
  logoutAuthUser: () => void
  loginAuthUser: () => void
}

export const useAuthHelper = (): AuthHelper => {
  const { logout, loginWithRedirect } = useAuth0()
  const { isHybrid, isAndroid } = useDevice()
  const dispatch = useAppDispatch()

  // Combined login function for web and mobile
  const loginAuthUser = useCallback(() => {
    if (isHybrid) {
      void loginWithRedirect({
        openUrl(url: string) {
          // Use Capacitor Browser plugin for mobile
          Browser.open({
            url,
            windowName: '_self',
          }).catch((err) => {
            errorLog('Failed to open browser:', err)
          })
        },
      })
    } else {
      // Web login
      loginWithRedirect().catch((err) => {
        errorLog('Login failed:', err)
      })
    }
  }, [isHybrid, loginWithRedirect])

  const logoutAuthUser = useCallback(() => {
    clearLocalStorage()
    dispatch(restaurantClearData())
    dispatch(authClearData())
    dispatch(eaterClearData())
    dispatch(landingClearData())
    dispatch(onboardClearData())
    dispatch(profileClearData())
    dispatch(searchClearData())
    dispatch(socialClearData())
    dispatch(menuClearData())
    dispatch(settingClearData())
    dispatch(dashboardClearData())
    dispatch(reviewClearData())
    dispatch(messageClearData())

    const packageId =
      (isAndroid
        ? process.env.REACT_APP_ANDROID_APP_ID
        : process.env.REACT_APP_IOS_APP_ID) ?? ''

    if (isHybrid) {
      void logout({
        logoutParams: {
          returnTo: `${packageId}://login.resactly.com/capacitor/${packageId}/callback`,
        },
        openUrl(url: string) {
          // Redirect using Capacitor's Browser plugin
          Browser.open({
            url,
            windowName: '_self',
          }).catch((err) => {
            errorLog('Failed to open browser during logout:', err)
          })
        },
      })
    } else {
      logout({ logoutParams: { returnTo: window.location.origin } }).catch(
        (err) => {
          errorLog('Logout failed:', err)
        },
      )
    }
  }, [isHybrid, isAndroid])

  return { loginAuthUser, logoutAuthUser }
}
