import { Box, RadioGroup, Tooltip, Typography } from '@mui/material'
import React, { useRef, useState } from 'react'
import DeleteDialog from '../../../../components/restaurants/DeleteDialog'
import DishDetailCommon from '../../../../components/restaurants/DishDetailCommon'
import { DEFAULT_CARD_IMAGE } from '../../../../helpers/static-constants'
import useAppUtilities from '../../../../hooks/useAppUtilities'
import {
  CopyMenuRadio,
  CopyMenuRadioFormControlLabel,
  DishDetailBackButton,
  DishDetailBox,
  DishDetailCopyMenuButton,
  DishDetailDeleteButton,
  DishDetailEditButton,
  DishDetailMainBox,
  DishDetailSecondScrollBox,
} from '../styled/DishDetail.styled'
import {
  addCopyMenuDish,
  deleteMenuDish,
  publishMenuById,
  selectDish,
  selectedMenu,
  selectMenus,
  setOpenStaticMessage,
  setSelectedDish,
} from '../../../../store/restaurant/menu'
import BasicModal from '../../../../components/modal/BasicModal'
import AddEditDishDialog, { dishDialogMethods } from './AddEditDishDialog'
import { useAppSelector } from '../../../../store'
import { ICopyDishRequest, IMenuStatus } from '../../../../types/menuTypes'
import { MuiBackdrop } from '../../../eater/styled/global.styled'
import { ProfileCircularProgress } from '../../../eater/profile/styled/ProfilePage.styled'

interface DishDetailProps {
  handleDrawerToggle?: (open: boolean) => () => void
  onSelect: React.Dispatch<React.SetStateAction<boolean>>
}

const DishDetail: React.FC<DishDetailProps> = ({
  handleDrawerToggle,
  onSelect,
}) => {
  const { theme, dispatch } = useAppUtilities()
  const [modalOpenDelete, setModalOpenDelete] = useState(false)
  const selectedDish = useAppSelector(selectDish)
  const selectMenu = useAppSelector(selectedMenu)
  const selectAllMenus = useAppSelector(selectMenus)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isLoadingCopyDish, setIsLoadingCopyDish] = useState<boolean>(false)
  const [isValid, setIsValid] = useState(false)
  const [addDialog, setAddDialog] = useState<boolean>(false)
  const [openCopyMenuDialog, setOpenCopyMenuDialog] = useState<boolean>(false)
  const [copyMenuId, setCopyMenuId] = useState<string>('')
  const dishDialogRef = useRef<dishDialogMethods>(null)

  const openAddEditDishDialog = (): void => {
    setAddDialog(true)
  }

  const onHandleDelete = async (): Promise<void> => {
    if (selectedDish?.dishID) {
      const res = await dispatch(
        deleteMenuDish({ dishId: selectedDish?.dishID }),
      )
      if (deleteMenuDish.fulfilled.match(res)) {
        onSelect(false)
        dispatch(setSelectedDish(undefined))
        dispatch(
          setOpenStaticMessage({
            message: `${selectedDish?.name ?? ''} dish updated successfully.`,
            severity: 'success',
            shouldOpen: true,
            status: 'idle',
          }),
        )
      }
    }
    setModalOpenDelete(false)
  }

  const onHandleModalDeleteCancel = (): void => {
    setModalOpenDelete(false)
  }

  const onHandleEditDish = async (): Promise<void> => {
    if (dishDialogRef.current) {
      await dishDialogRef.current.getPayload()
      setAddDialog(false)
    }
  }

  const onCancelCopyMenu = (): void => {
    setCopyMenuId('')
    setOpenCopyMenuDialog(false)
  }

  const onHandleCopyMenu = async (): Promise<void> => {
    if (selectedDish && copyMenuId && copyMenuId.length > 0) {
      setIsLoadingCopyDish(true)

      const findMenu = selectAllMenus?.find(
        (menu) => menu?.menuID === copyMenuId,
      )

      if (!findMenu) {
        setIsLoadingCopyDish(false)
        return
      }
      if (findMenu.status === IMenuStatus.NEW) {
        const menuRes = await dispatch(
          publishMenuById({ menuId: findMenu.menuID }),
        )
        if (publishMenuById.rejected.match(menuRes)) {
          return
        }
      }

      const payload: ICopyDishRequest = {
        menuId: copyMenuId,
        dishId: selectedDish.dishID,
      }
      const res = await dispatch(addCopyMenuDish(payload))
      if (addCopyMenuDish.fulfilled.match(res)) {
        dispatch(
          setOpenStaticMessage({
            message: `${selectedDish?.name ?? ''} dish copied to ${
              findMenu?.name ?? ''
            } menu successfully.`,
            severity: 'success',
            shouldOpen: true,
            status: 'idle',
          }),
        )
      }
      setIsLoadingCopyDish(false)
    }
    setCopyMenuId('')
    setOpenCopyMenuDialog(false)
  }

  return (
    <DishDetailMainBox>
      <DishDetailSecondScrollBox>
        <Box sx={{ position: 'relative', width: '100%' }}>
          <img
            src={
              selectedDish?.imageurl && selectedDish.imageurl.length > 0
                ? selectedDish.imageurl
                : DEFAULT_CARD_IMAGE
            }
            alt="restaurant-dish"
            style={{
              borderRadius: '8px',
              width: '107px',
              height: '107px',
              objectFit: 'cover',
            }}
          />
          {handleDrawerToggle && (
            <DishDetailBackButton onClick={handleDrawerToggle(false)} />
          )}
        </Box>
        <Box sx={{ marginTop: '20px' }}>
          <Typography
            variant="body1"
            textTransform="capitalize"
            color={theme.palette.grey[800]}
          >
            {selectedDish?.name}
          </Typography>
          {selectedDish?.description && (
            <Typography
              variant="subtitle1"
              fontWeight={400}
              color={theme.palette.grey[600]}
              sx={{ marginTop: '2px' }}
            >
              {selectedDish.description}
            </Typography>
          )}
        </Box>
        {selectedDish?.price && (
          <Typography
            variant="body1"
            color={theme.palette.grey[800]}
            sx={{ marginTop: '20px' }}
          >
            ${selectedDish.price}
          </Typography>
        )}
        <DishDetailCommon
          Ingredients={selectedDish?.ingredients}
          Preparation={selectedDish?.preparation}
          suitableDietary={selectedDish?.dietary}
          unsuitableDietary={selectedDish?.unsuitabledietary}
        />
      </DishDetailSecondScrollBox>
      <DishDetailBox>
        <Tooltip title="Edit">
          <div>
            <DishDetailEditButton onClick={openAddEditDishDialog} />
          </div>
        </Tooltip>
        <Tooltip title="Copy to menu">
          <Box sx={{ width: '100%' }}>
            <DishDetailCopyMenuButton
              onClick={() => setOpenCopyMenuDialog(true)}
            />
          </Box>
        </Tooltip>
        <Tooltip title="Delete">
          <div>
            <DishDetailDeleteButton onClick={() => setModalOpenDelete(true)} />
          </div>
        </Tooltip>
      </DishDetailBox>

      <BasicModal
        open={addDialog}
        onCancel={() => setAddDialog(false)}
        onConfirm={() => {
          void onHandleEditDish()
        }}
        title="Edit Dish"
        buttonName="Update"
        modelWidth="500px"
        isLoader={isLoading}
        saveBtnDisabled={!isValid}
        handleClose={() => setAddDialog(false)}
      >
        <AddEditDishDialog
          ref={dishDialogRef}
          dish={selectedDish}
          setLoading={(loading) => setIsLoading(loading)}
          setIsValid={(valid) => setIsValid(valid)}
        />
      </BasicModal>
      <BasicModal
        open={openCopyMenuDialog}
        onCancel={onCancelCopyMenu}
        onConfirm={() => {
          void onHandleCopyMenu()
        }}
        title="Copy to menu"
        buttonName="Copy"
        modelWidth="500px"
        isLoader={isLoadingCopyDish}
        saveBtnDisabled={copyMenuId.length <= 0}
        handleClose={onCancelCopyMenu}
      >
        <Box
          sx={{
            my: 2,
          }}
        >
          <RadioGroup
            value={copyMenuId}
            onChange={(_, value) => setCopyMenuId(value)}
            sx={{
              px: 2,
              maxHeight: '200px',
              overflowY: 'scroll',
            }}
          >
            <Box>
              {selectAllMenus
                .filter(
                  (item) =>
                    item?.menuID !== selectMenu?.menuID &&
                    (item?.status === IMenuStatus.NEW ||
                      item?.status === IMenuStatus.COMPLETED),
                )
                .map((item, index) => (
                  <CopyMenuRadioFormControlLabel
                    color={theme.palette.grey[300]}
                    key={index}
                    value={item.menuID}
                    control={<CopyMenuRadio />}
                    label={item.name}
                  />
                ))}
            </Box>
          </RadioGroup>
        </Box>
      </BasicModal>

      <DeleteDialog
        open={modalOpenDelete}
        onCancel={onHandleModalDeleteCancel}
        onConfirm={() => {
          void onHandleDelete()
        }}
        title="Delete Dish?"
        handleClose={() => setModalOpenDelete(false)}
      />

      <MuiBackdrop open={isLoadingCopyDish}>
        <ProfileCircularProgress />
      </MuiBackdrop>
    </DishDetailMainBox>
  )
}

export default DishDetail
