import {
  Box,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Popover,
} from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'
import { IconX } from '@tabler/icons-react'
import dayjs, { Dayjs } from 'dayjs'
import React, { useState } from 'react'
import { DATE_FORMAT, DATE_RANGES } from '../../helpers/static-constants'
import theme from '../../theme'
import { useDispatch } from 'react-redux'
import { setEndDate, setStartDate } from '../../store/restaurant/dashboard'

interface DatePickerPopoverProps {
  open: boolean
  anchorEl: HTMLElement | null
  handleClose: () => void
}

const DatePickerPopover: React.FC<DatePickerPopoverProps> = ({
  anchorEl,
  handleClose,
  open,
}) => {
  const dispatch = useDispatch()

  const [selectedRange, setSelectedRange] = React.useState<number | undefined>()
  const [start, setStart] = useState<Dayjs>(dayjs().subtract(1, 'day'))
  const [end, setEnd] = useState<Dayjs>(dayjs())

  const handleSelect = (range: number | undefined): void => {
    setSelectedRange(range)
    if (range) {
      setStart(dayjs().subtract(range, 'day'))
      setEnd(dayjs())
      dispatch(setStartDate(dayjs().subtract(range, 'day').format(DATE_FORMAT)))
      dispatch(setEndDate(dayjs().format(DATE_FORMAT)))
    } else {
      setStart(dayjs().subtract(1, 'day'))
      setEnd(dayjs())
      dispatch(setStartDate(dayjs().subtract(1, 'day').format(DATE_FORMAT)))
      dispatch(setEndDate(dayjs().format(DATE_FORMAT)))
    }
    handleClose()
  }

  const handleDateChange = (
    date: Dayjs | null,
    type: 'start' | 'end',
  ): void => {
    if (!date) return

    if (type === 'start') {
      if (date.isAfter(dayjs())) return

      setStart(date)
      dispatch(setStartDate(date.format(DATE_FORMAT)))

      if (end.isBefore(date)) {
        setEnd(date)
        dispatch(setEndDate(date.format(DATE_FORMAT)))
      }
    } else {
      if (date.isBefore(start) || date.isAfter(dayjs())) return

      setEnd(date)
      dispatch(setEndDate(date.format(DATE_FORMAT)))
    }
  }

  const onStartDate = (date: Dayjs | null): void => {
    handleDateChange(date, 'start')
    handleClose()
  }

  return (
    <Popover
      id={open ? 'simple-popover' : undefined}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      sx={{
        '& .MuiPaper-root': {
          marginTop: 1,
          width: 285,
          borderRadius: '8px',
        },
      }}
    >
      <Box sx={{ p: 1 }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <MobileDatePicker
                value={start}
                format="DD-MM-YYYY"
                minDate={dayjs().subtract(100, 'year')} // Prevent very old dates
                maxDate={dayjs().subtract(1, 'day')} // Prevent future dates
                onAccept={(date) => onStartDate(date)}
                slotProps={{ textField: { fullWidth: true } }}
                sx={{
                  '& .MuiInputBase-root': { borderRadius: '8px' },
                  '& .MuiInputBase-input': {
                    padding: '6px 10px',
                    fontSize: '14px',
                    lineHeight: '20px',
                    fontWeight: 400,
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <MobileDatePicker
                value={end}
                onAccept={(date) => handleDateChange(date, 'end')}
                format="DD-MM-YYYY"
                minDate={start} // Prevent selecting a date before start date
                maxDate={dayjs()} // Prevent future dates
                slotProps={{
                  textField: { fullWidth: true, placeholder: 'To' },
                }}
                sx={{
                  '& .MuiInputBase-root': { borderRadius: '8px' },
                  '& .MuiInputBase-input': {
                    padding: '6px 10px',
                    fontSize: '14px',
                    lineHeight: '20px',
                    fontWeight: 400,
                  },
                }}
              />
            </Grid>
          </Grid>
        </LocalizationProvider>
        <Divider sx={{ mt: 1.5 }} />
        <List sx={{ width: '100%', p: 0 }}>
          {DATE_RANGES.map((range, index) => (
            <ListItem
              value={selectedRange}
              key={index}
              onClick={() => handleSelect(range.value)}
              sx={{
                borderRadius: '6px',
                p: '8px',
                '&:hover': { bgcolor: 'action.selected' },
                '&.Mui-selected': {
                  bgcolor: 'transparent',
                  color: 'primary.main',
                  fontWeight: 'bold',
                  '&:hover': { bgcolor: 'action.selected' },
                },
              }}
            >
              <ListItemText
                primary={range.title}
                sx={{
                  my: '1px',
                  '& .MuiTypography-root': {
                    fontSize: '14px',
                    lineHeight: '20px',
                    fontWeight: 500,
                    color:
                      range.value === selectedRange
                        ? theme.palette.secondary.main
                        : theme.palette.grey[800],
                  },
                }}
              />
              {range.value === selectedRange && (
                <IconButton
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation()
                    handleSelect(undefined)
                  }}
                  sx={{ p: 0, ml: 1, '&:hover': { bgcolor: 'transparent' } }}
                >
                  <IconX stroke={2} size={20} />
                </IconButton>
              )}
            </ListItem>
          ))}
        </List>
      </Box>
    </Popover>
  )
}

export default DatePickerPopover
