import {
  IconBuildingStore,
  IconCurrencyDollar,
  IconMapPin,
} from '@tabler/icons-react'
import React from 'react'
import { DEFAULT_CARD_IMAGE } from '../../../../../../helpers/static-constants'
import useAppUtilities from '../../../../../../hooks/useAppUtilities'
import {
  DashboardDishCardInfoMainBox,
  DashboardDishCardInfoTypography,
  DashboardDishCardInnerBox,
  DashboardDishCardMainBox,
  DashboardDishCardTypography,
} from '../../../styled/competitorInsights/dish/DashboardDishCard.styled'
import { Dish } from '../../../../../../types/menuTypes'
import { Box } from '@mui/material'
import { useAppSelector } from '../../../../../../store'
import { selectRestaurant } from '../../../../../../store/restaurant/setting'

interface DashboardDishCardProps {
  similarSelectedDish: Dish
  setSimilarSelectedDish?: React.Dispatch<Dish>
}

const DashboardDishCard: React.FC<DashboardDishCardProps> = ({
  similarSelectedDish,
  setSimilarSelectedDish,
}) => {
  const restaurant = useAppSelector(selectRestaurant)

  const handleClick = (dish: Dish): void => {
    if (setSimilarSelectedDish) {
      setSimilarSelectedDish(dish)
    }
  }

  const restaurantAddress = (): string => {
    const address = `${restaurant?.address1 ?? ''}, ${
      restaurant?.city ?? ''
    }, ${restaurant?.state ?? ''}, ${restaurant?.zip_code ?? ''}`

    return address
  }

  return (
    <DashboardDishCardMainBox onClick={() => handleClick(similarSelectedDish)}>
      <Box
        component="img"
        height="96px"
        width="96px"
        sx={{
          borderRadius: '8px',
          aspectRatio: 1,
          objectFit: 'cover',
        }}
        src={similarSelectedDish?.imageurl ?? DEFAULT_CARD_IMAGE}
        alt="restaurant-dish"
      />
      <DashboardDishCardInnerBox>
        <DashboardDishCardTypography>
          {similarSelectedDish?.name}
        </DashboardDishCardTypography>
        <Info title={restaurant?.name ?? ''} Icon={IconBuildingStore} />
        <Info title={restaurantAddress()} Icon={IconMapPin} />
        {similarSelectedDish?.price && (
          <Info
            title={String(similarSelectedDish?.price.toFixed(1))}
            Icon={IconCurrencyDollar}
          />
        )}
      </DashboardDishCardInnerBox>
    </DashboardDishCardMainBox>
  )
}

export default DashboardDishCard

interface infoProps {
  title: string
  Icon: any
}

const Info: React.FC<infoProps> = ({ Icon, title }): JSX.Element => {
  const { theme } = useAppUtilities()

  return (
    <DashboardDishCardInfoMainBox>
      <Icon
        stroke={2}
        color={theme.palette.grey[500]}
        fontSize="12px"
        size={12}
      />
      <DashboardDishCardInfoTypography>{title}</DashboardDishCardInfoTypography>
    </DashboardDishCardInfoMainBox>
  )
}
