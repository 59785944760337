import { Chip, useTheme } from '@mui/material'
import { IconX } from '@tabler/icons-react'
import styled from 'styled-components'
import React from 'react'

export const AddEditDialogAutoCompleteChip = styled(Chip).attrs(() => {
  const theme = useTheme()
  return {
    sx: {
      alignSelf: 'start',
      height: '24px',
      borderRadius: '6px',
      color: theme.palette.grey[500],
      backgroundColor: theme.palette.grey[50],
      border: `1px solid ${theme.palette.grey[300]}`,
      fontSize: '12px',
    },
    deleteIcon: <IconX stroke={2} size={14} />,
    style: { color: theme.palette.grey[500] },
  }
})(() => ({}))
