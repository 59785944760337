import { Box, Container, Typography } from '@mui/material'
import { IconBuildingStore } from '@tabler/icons-react'
import React, { useEffect } from 'react'
import useAppUtilities from '../../../hooks/useAppUtilities'
import OnboardTitleTypography from './OnboardTitleTypography'

interface Props {
  title?: string
  subTitle?: string
  validRole: string
  setValidRole: (role: string) => void
  setNextButtonDisable: (disable: boolean) => void
}

interface IRole {
  title: string
  roleName: string
  subTitle: string
  icon: JSX.Element
}

const OnboardChooseRole: React.FC<Props> = ({
  title,
  subTitle,
  validRole,
  setValidRole,
  setNextButtonDisable,
}: Props) => {
  const { theme, isSmallScreen } = useAppUtilities()
  const [selectRole, setSelectRole] = React.useState(validRole)

  useEffect(() => {
    setSelectRole(validRole)
    setNextButtonDisable(validRole?.length > 0)
  }, [validRole])

  const onSetRoleValid = (role: IRole): void => {
    setSelectRole(role.roleName)
    setValidRole(role.roleName)
  }

  return (
    <>
      <OnboardTitleTypography title={title ?? ''} subTitle={subTitle ?? ''} />

      <div
        style={{
          paddingTop: '28px',
        }}
      >
        {roles.map((role: IRole, index) => (
          <Box
            data-testid={`${role.roleName}_${index}`}
            key={index}
            marginTop={'12px'}
            marginBottom={2}
            onClick={() => onSetRoleValid(role)}
          >
            <Container
              maxWidth="sm"
              sx={{
                border:
                  selectRole === role.roleName
                    ? `1px solid ${theme.palette.primary.light}`
                    : `1px solid ${theme.palette.grey[200]}`,
                borderRadius: '12px',
                boxShadow:
                  selectRole === role.roleName
                    ? '0px 0px 8px 0px #00000040'
                    : 'none',
                padding: isSmallScreen ? '14px' : '20px',
                cursor: 'pointer',
              }}
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Box
                padding={2}
                sx={{
                  backgroundColor: theme.palette.grey[200],
                  borderRadius: '50%',
                  marginRight: '16px',
                }}
                height={56}
                width={56}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {role.icon}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'start',
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    mb: '4px',
                    fontWeight: 500,
                    fontSize: isSmallScreen ? '16px' : '18px',
                    lineHeight: '24px',
                    color: theme.palette.secondary[800],
                  }}
                >
                  {role.title}
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: isSmallScreen ? '12px' : '14px',
                  }}
                  variant="body1"
                  color={theme.palette.grey[500]}
                >
                  {role.subTitle}
                </Typography>
              </Box>
            </Container>
          </Box>
        ))}
      </div>
    </>
  )
}

export default OnboardChooseRole

const roles: IRole[] = [
  {
    title: 'Restaurant Owner',
    roleName: 'restaurant',
    subTitle:
      'Showcase your establishments and attract diners who appreciate your culinary offerings.',
    icon: <IconBuildingStore stroke={2} height={24} width={24} />,
  },
  {
    title: 'Eater',
    roleName: 'eater',
    subTitle:
      'Find tailored restaurant recommendations crafted for your unique taste preferences.',
    icon: <IconBuildingStore stroke={2} height={24} width={24} />,
  },
]
