export const PRIVACY_POLICY_URL =
  'https://www.iubenda.com/privacy-policy/90550491'
export const COOKIE_POLICY_URL =
  'https://www.iubenda.com/privacy-policy/90550491/cookie-policy'
export const TERMS_AND_CONDITION_URL =
  'https://www.iubenda.com/terms-and-conditions/90550491'

export const FACEBOOK_PAGE_URL =
  'https://www.facebook.com/people/Resactly/100087837874001/'
export const INSTAGRAM_PAGE_URL = 'https://www.instagram.com/resactly/'

export const COPY_RIGHT_TEXT = `&copy; ${new Date().getFullYear()} OKAO Inc. All right reserved.`

export const DEFAULT_CARD_IMAGE = '/logoLetter-resize.svg'
export const DEFAULT_AVATAR_IMAGE =
  'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'

export const WEB_NAV_HEIGHT = 91
export const MOBILE_NAV_HEIGHT = 72
export const MOBILE_SEARCH_HEIGHT = 48
export const MOBILE_APP_HEIGHT = 74

export const price = ['$', '$$', '$$$', '$$$$']

export const headingName = [
  {
    title: 'Wallet Friendly Restaurants',
    key: 'WALLET',
  },
  {
    title: 'Local Flavors To Explore',
    key: 'LOCAL',
  },
  {
    title: 'Top Rated Restaurants',
    key: 'TOPRATED',
  },
  {
    title: 'Must Try Cuisine Hotspots',
    key: 'MUSTTRY',
  },
]

export const DATE_FORMAT = 'YYYY-MM-DD'

export const DATE_RANGES = [
  { title: 'Last 2 days', value: 2 },
  { title: 'Last 7 days', value: 7 },
  { title: 'Last 10 days', value: 10 },
  { title: 'Last 30 days', value: 30 },
]

export const RESTAURANT_HEADER_WEB = 150
export const RESTAURANT_HEADER_MOBILE = 102
export const RESTAURANT_PAGE_LAYOUT_HEADER = 78

// TODO: temp for mock data

export const SUITABLE_DIETARY_DATA =
  'Vegan, Vegetarian, Pescatarian, Flexitarian'
export const UNSUITABLE_DIETARY_DATA =
  'Omnivore,Carnivore,Vegetarian,Kosher,Gluten-Free'

export const CHART_COLORS = [
  '#9C640C',
  '#FF0000',
  '#00C49F',
  '#FFD700',
  '#FFA500',
  '#8000FF',
  '#3399FF',
  '#66CC00',
  '#008000',
  '#FF0090',
  '#FFC0CB',
]
