import { Box } from '@mui/material'
import React, { memo, useState } from 'react'
import BasicModal from '../../../../components/modal/BasicModal'

import {
  IMenuAddRequest,
  IMenuUpdateRequest,
  Menu,
} from '../../../../types/menuTypes'
import useAppUtilities from '../../../../hooks/useAppUtilities'
import {
  addMenu,
  selectIsLoading,
  setOpenStaticMessage,
  updateMenuName,
} from '../../../../store/restaurant/menu'
import { useAppSelector } from '../../../../store'
import { selectRestaurant } from '../../../../store/restaurant/setting'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import InputComponent from '../../../../components/ui/InputComponent'

interface IMenuCardProps {
  openEditModel: boolean
  setOpenEditModel: React.Dispatch<React.SetStateAction<boolean>>
  menu?: Menu
  menuType?: string
}

const formSchema = z.object({
  name: z.string().min(1, { message: 'Menu name is required' }),
})

const AddEditMenuDialog: React.FC<IMenuCardProps> = ({
  openEditModel,
  setOpenEditModel,
  menu,
}) => {
  const { dispatch } = useAppUtilities()
  const restaurant = useAppSelector(selectRestaurant)
  const isLoading = useAppSelector(selectIsLoading)
  const [menuName, setMenuName] = useState<string>(menu?.name ?? '')

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    defaultValues: {
      name: menuName,
    },
  })

  const onHandleModalEditCancel = (): void => {
    setMenuName('')
    reset()
    setOpenEditModel(false)
  }

  const onHandleEdit = async (): Promise<void> => {
    await handleSubmit(
      async (data: z.infer<typeof formSchema>): Promise<void> => {
        if (!menu?.menuID) {
          const payload: IMenuAddRequest = {
            name: data.name,
            restaurantId: restaurant?.restaurantID ?? '',
          }
          const addMenuRes = await dispatch(addMenu(payload))
          if (addMenu.fulfilled.match(addMenuRes)) {
            dispatch(
              setOpenStaticMessage({
                message: `${data.name} menu created successfully.`,
                severity: 'success',
                shouldOpen: true,
                status: 'idle',
              }),
            )
          }
        } else {
          const payload: IMenuUpdateRequest = {
            menuId: menu.menuID,
            name: data.name,
          }
          const res = await dispatch(updateMenuName(payload))
          if (updateMenuName.fulfilled.match(res)) {
            dispatch(
              setOpenStaticMessage({
                message: `${data.name} menu updated successfully.`,
                severity: 'success',
                shouldOpen: true,
                status: 'idle',
              }),
            )
          }
        }
        reset()
      },
    )()
    setMenuName('')
    setOpenEditModel(false)
  }

  return (
    <BasicModal
      open={openEditModel}
      onCancel={onHandleModalEditCancel}
      onConfirm={() => {
        void onHandleEdit()
      }}
      isLoader={isLoading}
      title={menu?.menuID ? 'Edit Menu' : 'Add Menu'}
      buttonName={menu?.menuID ? 'Update' : 'Add'}
      saveBtnDisabled={!isValid}
      handleClose={() => setOpenEditModel(false)}
    >
      <Box my={2}>
        <InputComponent
          label="Menu Name"
          id="edit-menu-input"
          disabled={isLoading}
          {...register('name')}
          error={!!errors.name}
          helperText={errors.name ? errors.name.message : ' '}
          isFieldRequired
          fullWidth
          placeholder="Eg: Lunch Menu"
          sx={{
            mt: '6px',
          }}
        />
      </Box>
    </BasicModal>
  )
}

export default memo(AddEditMenuDialog)
