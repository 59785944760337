import { Autocomplete, Box, Paper } from '@mui/material'
import { IconChevronDown } from '@tabler/icons-react'
import React from 'react'
import { AddEditDialogAutoCompleteChip } from '../../dish/styled/AddEditDishDialog.styled'
import InputComponent from '../../../../components/ui/InputComponent'

interface ITagInputProps {
  label: string
  id: string
  onChange: React.Dispatch<React.SetStateAction<string[]>>
  value: string[]
  isEdit: boolean
  freeSolo?: boolean
  inputRows?: number
}

const TagInput: React.FC<ITagInputProps> = ({
  label,
  id,
  onChange,
  value,
  isEdit,
  freeSolo = true,
  inputRows = 2,
}) => {
  return (
    <Box>
      <Autocomplete
        id={id}
        readOnly={!isEdit}
        options={[]}
        value={value}
        onChange={(event: any, valueData: string[]) => onChange(valueData)}
        freeSolo={freeSolo}
        renderTags={(value: readonly string[], getTagProps) =>
          value.map((option: string, index: number) => {
            const { key, ...tagProps } = getTagProps({ index })
            return (
              <AddEditDialogAutoCompleteChip
                key={key}
                label={option}
                {...tagProps}
              />
            )
          })
        }
        multiple
        popupIcon={<IconChevronDown stroke={2} size={18} />}
        PaperComponent={(props) => (
          <Paper
            {...props}
            sx={{
              fontSize: '14px !important',
              fontWeight: `400 !important`,
              borderRadius: '12px',
              mt: '4px',
            }}
          />
        )}
        renderInput={(params) => (
          <InputComponent
            {...params}
            label={label}
            id={id}
            rows={inputRows}
            isEditBackgroundMode
            disabled={!isEdit}
            placeholder={value.length > 0 ? '' : 'Press enter to add tag'}
            sx={{
              mt: '6px',
              '& .MuiInputBase-root': {
                flexWrap: 'wrap',
                minHeight:
                  inputRows === 1 ? '56px' : `${56 + (inputRows - 1) * 24}px`,
                alignItems: 'flex-start',
                paddingTop: '8px',
              },
              '& .MuiInputBase-input': {
                flexGrow: 1,
                width: '0 !important',
                minWidth: '30px',
              },
            }}
          />
        )}
      />
    </Box>
  )
}

export default TagInput
