import { Box, Typography } from '@mui/material'
import React, { useState } from 'react'
import { DEFAULT_CARD_IMAGE } from '../../../../helpers/static-constants'
import useAppUtilities from '../../../../hooks/useAppUtilities'
import { useAppSelector } from '../../../../store'
import { selectDish, setSelectedDish } from '../../../../store/restaurant/menu'
import { Dish } from '../../../../types/menuTypes'
import { DishCardDrawer, DishCardMainBox } from '../styled/DishCard.styled'
import DishDetail from './DishDetail'

interface DishCardProps {
  dish: Dish
  onSelect: React.Dispatch<React.SetStateAction<boolean>>
}

const DishCard: React.FC<DishCardProps> = ({ dish, onSelect }) => {
  const { theme, isMediumScreen, dispatch } = useAppUtilities()
  const selectedDish = useAppSelector(selectDish)
  const [isDrawerOpen, setDrawerOpen] = useState<boolean>(false)
  const [imageLoaded, setImageLoaded] = useState<boolean>(false)

  const handleDrawerToggle = (open: boolean) => (): void => {
    setDrawerOpen(open)
    if (!open) {
      dispatch(setSelectedDish(undefined))
      onSelect(false)
    }
  }

  const handleDishClick = (): void => {
    if (selectedDish?.dishID === dish.dishID) {
      dispatch(setSelectedDish(undefined))
      onSelect(false)
    } else {
      onSelect(true)
      dispatch(setSelectedDish(dish))
    }
    setDrawerOpen(true)
  }

  return (
    <>
      <DishCardMainBox
        onClick={handleDishClick}
        isSelected={dish.dishID === selectedDish?.dishID}
      >
        <Box
          sx={{
            borderRadius: '8px',
            width: '100%',
            height: 'calc(100% - 45px)',
            position: 'relative',
            overflow: 'hidden',
          }}
        >
          <img
            src={
              dish?.imageurl && dish.imageurl.length > 0
                ? dish.imageurl
                : DEFAULT_CARD_IMAGE
            }
            alt={dish?.name}
            onLoad={() => setImageLoaded(true)}
            style={{
              borderRadius: '8px',
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              filter: imageLoaded ? 'blur(0px)' : 'blur(10px)',
              transition: 'filter 0.5s ease-in-out',
            }}
          />
        </Box>
        <Box
          sx={{
            mx: '8px',
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Typography
            noWrap
            variant="subtitle1"
            color={theme.palette.grey[800]}
            fontWeight={600}
            textTransform="capitalize"
            sx={{ maxWidth: '100%' }}
          >
            {dish?.name}
          </Typography>
          {dish?.description && (
            <Typography
              noWrap
              variant="subtitle2"
              color={theme.palette.grey[500]}
              sx={{ maxWidth: '100%', marginTop: '2px' }}
            >
              {dish?.description}
            </Typography>
          )}
        </Box>
      </DishCardMainBox>

      {isMediumScreen && (
        <DishCardDrawer open={isDrawerOpen} onClose={handleDrawerToggle(false)}>
          <DishDetail
            handleDrawerToggle={handleDrawerToggle}
            onSelect={onSelect}
          />
        </DishCardDrawer>
      )}
    </>
  )
}

export default DishCard
