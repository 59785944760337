import { AppBar, Box, Toolbar, Typography } from '@mui/material'
import { IconMenu2 } from '@tabler/icons-react'
import React from 'react'
import ImgIcon from '../../components/ui/ImgIcon'
import { ROUTES } from '../../helpers/routes-helper'
import useAppUtilities from '../../hooks/useAppUtilities'

interface RestaurantLayoutHeaderMobileProps {
  isDrawerOpen: boolean
  setIsDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>
  activeItem: string
}

const RestaurantLayoutHeaderMobile: React.FC<
  RestaurantLayoutHeaderMobileProps
> = ({ isDrawerOpen, setIsDrawerOpen, activeItem }) => {
  const { theme, navigate } = useAppUtilities()

  const handleDrawerOpen = (): void => {
    setIsDrawerOpen(true)
  }

  return (
    <AppBar
      position="sticky"
      elevation={0}
      sx={{
        zIndex: isDrawerOpen ? 6000 : 1000,
        px: '16px',
        pb: '16px',
        pt: '15px',
      }}
    >
      <Toolbar disableGutters>
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
          width="100%"
        >
          <Box display="flex" alignItems="center">
            <img
              srcSet={`${'/logo.svg'}?w=20&h=20&fit=crop&auto=format&dpr=2 2x`}
              src={`${'/logo.svg'}?w=30&h=30&fit=crop&auto=format`}
              style={{
                height: '40px',
                marginRight: '16px',
                cursor: 'pointer',
              }}
              loading="lazy"
              onClick={() => navigate(ROUTES.RESTAURANT_ADMIN_DASHBOARD)}
              alt="Resactly-Logo"
            />
            <Typography
              color={theme.palette.grey[200]}
              fontWeight={700}
              fontSize="20px"
              lineHeight="28px"
            >
              {activeItem}
            </Typography>
          </Box>
          <Box display={'flex'}>
            <ImgIcon
              iconElement={
                <IconMenu2
                  size={16}
                  style={{ color: theme.palette.common.white }}
                />
              }
              alt="menu-icon"
              wrapperStyle={{
                backgroundColor: theme.palette.primary.light,
              }}
              onClick={handleDrawerOpen}
            />
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  )
}

export default RestaurantLayoutHeaderMobile
