import React from 'react'
import RestaurantCard from '../../../../components/restaurant/RestaurantCard'
import RestaurantCardSkeleton from '../../../../components/skeleton/RestaurantCardSkeleton'
import { ROUTES } from '../../../../helpers/routes-helper'
import { DEFAULT_CARD_IMAGE } from '../../../../helpers/static-constants'
import useAppUtilities from '../../../../hooks/useAppUtilities'
import { useAppSelector } from '../../../../store'
import { selectPageSize } from '../../../../store/landingSlice'
import {
  selectMessage,
  selectSeverity,
  selectShouldOpen,
  setOpen,
} from '../../../../store/restaurantSlice'
import { RestaurantAndPredictedRestaurant } from '../../../../types'
import { MuiAlert, MuiSnackbar } from '../../styled/global.styled'
import {
  RestaurantListInnerGrid,
  RestaurantListMainContainer,
  RestaurantListMainGrid,
  RestaurantListWrapper,
} from '../styled/RestaurantList.styled'

interface RestaurantListProps {
  restaurants: RestaurantAndPredictedRestaurant[]
  loading: boolean
}

const RestaurantList: React.FC<RestaurantListProps> = ({
  restaurants,
  loading = false,
}) => {
  const { navigate, dispatch } = useAppUtilities()
  const openServer = useAppSelector(selectShouldOpen)
  const message = useAppSelector(selectMessage)
  const severity = useAppSelector(selectSeverity)
  const pageSize = useAppSelector(selectPageSize)

  const setOpenServer = (): void => {
    dispatch(setOpen(false))
  }

  return (
    <RestaurantListMainContainer>
      <RestaurantListMainGrid>
        {restaurants?.map((card, index: number) => {
          return (
            <RestaurantListInnerGrid key={index}>
              <RestaurantListWrapper
                onClick={() =>
                  navigate(
                    `${ROUTES.RESTAURANT_DETAILS}/${card?.restaurantDTO?.alias}/details`,
                  )
                }
              >
                <RestaurantCard
                  id={card?.restaurantDTO?.restaurantID}
                  alias={card?.restaurantDTO?.alias ?? ''}
                  imgList={[
                    card?.restaurantDTO?.imageurl ??
                    card?.restaurantDTO?.imageurl !== ''
                      ? card?.restaurantDTO?.imageurl ?? DEFAULT_CARD_IMAGE
                      : DEFAULT_CARD_IMAGE,
                  ]}
                  title={card?.restaurantDTO?.name ?? ''}
                  isVerified={card?.restaurantDTO?.verified ?? false}
                  subTitle={[
                    card?.restaurantDTO?.address1,
                    card?.restaurantDTO?.city,
                    card?.restaurantDTO?.state,
                  ]
                    .filter(Boolean)
                    .join(', ')}
                  distance={`${card?.restaurantDTO?.distance ?? ''}`}
                  tagChipData={[
                    {
                      percentage: `${card.value?.toLocaleString() ?? ''}`,
                      title: 'Match',
                    },
                  ]}
                />
              </RestaurantListWrapper>
            </RestaurantListInnerGrid>
          )
        })}
        {loading &&
          Array.from({ length: pageSize }).map((_, index) => {
            return (
              <RestaurantListInnerGrid key={index}>
                <RestaurantCardSkeleton />
              </RestaurantListInnerGrid>
            )
          })}
      </RestaurantListMainGrid>

      <MuiSnackbar open={openServer} onClose={setOpenServer}>
        <MuiAlert onClose={setOpenServer} severity={severity ?? 'success'}>
          {message}
        </MuiAlert>
      </MuiSnackbar>
    </RestaurantListMainContainer>
  )
}
export default RestaurantList
