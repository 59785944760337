import { Typography } from '@mui/material'
import React from 'react'
import useAppUtilities from '../../hooks/useAppUtilities'
import BasicModal from '../modal/BasicModal'
import { selectIsLoading } from '../../store/restaurant/menu'
import { useAppSelector } from '../../store'

interface DeleteDialogProps {
  onCancel: () => void
  onConfirm: () => void
  open: boolean
  title?: string
  handleClose: () => void
}

const DeleteDialog: React.FC<DeleteDialogProps> = ({
  title,
  open,
  handleClose,
  onCancel,
  onConfirm,
}) => {
  const { theme } = useAppUtilities()
  const isLoading = useAppSelector(selectIsLoading)

  return (
    <BasicModal
      open={open}
      onCancel={onCancel}
      onConfirm={onConfirm}
      title={title}
      buttonName="Delete"
      isLoader={isLoading}
      handleClose={handleClose}
    >
      <Typography variant="subtitle1" color={theme.palette.grey[800]} my={2}>
        Are you sure you want to delete the menu? This action cannot be undone.
      </Typography>
    </BasicModal>
  )
}

export default DeleteDialog
