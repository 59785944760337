import { Grid, Typography } from '@mui/material'
import { IconInfoSquareRounded, IconPencil } from '@tabler/icons-react'
import React, { useEffect, useState } from 'react'
import RestaurantsPageLayout from '../../../components/restaurants/RestaurantsPageLayout'
import { FabButtonStyled } from '../../../components/styled/FabButton.styled'
import useAppUtilities from '../../../hooks/useAppUtilities'
import {
  ProfileHeaderCustomButtonCancel,
  ProfileHeaderCustomButtonUpdate,
} from '../../eater/profile/styled/ProfileHeader.styled'
import EditModeButtons from '../settings/components/EditModeButtons'
import ViewModeButton from '../settings/components/ViewModeButton'
import RecommendationInput from './components/RecommendationInput'
import TagInput from './components/TagInput'
import {
  RecommendationsMobileCancelUpdateButtonBox,
  RecommendationsStyleGridMainBox,
} from './styled/Recommendations.styled'
import { useAppSelector } from '../../../store'
import {
  selectIsLoading,
  selectMessage,
  selectRestaurant,
  selectSeverity,
  selectShouldOpen,
  setOpen,
  updateRestaurant,
} from '../../../store/restaurant/setting'
import { Restaurant } from '../../../types'
import {
  MuiAlert,
  MuiBackdrop,
  MuiSnackbar,
} from '../../eater/styled/global.styled'
import { ProfileCircularProgress } from '../../eater/profile/styled/ProfilePage.styled'

const Recommendations: React.FC = () => {
  const { theme, isMediumScreen, dispatch } = useAppUtilities()
  const restaurant = useAppSelector(selectRestaurant)
  const isLoading = useAppSelector(selectIsLoading)
  const message = useAppSelector(selectMessage)
  const severity = useAppSelector(selectSeverity)
  const openServer = useAppSelector(selectShouldOpen)
  const [isEdit, setIsEdit] = useState(false)
  const [tags, setTags] = useState<string[]>([])
  const [awards, setAwards] = useState<string>('')
  const [knownFor, setKnownFor] = useState<string>('')
  const [cuisineTags, setCuisineTags] = useState<string[]>([])
  const [categoryTags, setCategoryTags] = useState<string[]>([])
  const [occasionTags, setOccasionTags] = useState<string[]>([])
  const [atmosphereTags, setAtmosphereTags] = useState<string[]>([])
  const [specialFeaturesTags, setSpecialFeaturesTags] = useState<string[]>([])

  useEffect(() => {
    onUpdataValue(restaurant)
  }, [restaurant])

  const onUpdate = async (): Promise<void> => {
    if (restaurant) {
      const payload = {
        ...restaurant,
        awards,
        knownFor,
        cuisine: cuisineTags.join(','),
        category: categoryTags.join(','),
        occasion: occasionTags.join(','),
        atmosphere: atmosphereTags.join(','),
        specialFeatures: specialFeaturesTags.join(','),
        tags: tags.join(','),
      }
      await dispatch(updateRestaurant(payload))
    }

    setIsEdit(false)
  }
  const onCancel = (): void => {
    onUpdataValue(restaurant)
    setIsEdit(false)
  }
  const onEdit = (): void => {
    setIsEdit(true)
  }

  const onUpdataValue = (restaurant: Restaurant | undefined): void => {
    if (restaurant) {
      const splitAndClean = (value?: string): string[] =>
        value?.length ? value.split(',').map((item) => item.trim()) : []

      setAwards(restaurant.awards ?? '')
      setKnownFor(restaurant.knownFor ?? '')
      setCuisineTags(splitAndClean(restaurant.cuisine))
      setCategoryTags(splitAndClean(restaurant.category))
      setOccasionTags(splitAndClean(restaurant.occasion))
      setAtmosphereTags(splitAndClean(restaurant.atmosphere))
      setSpecialFeaturesTags(splitAndClean(restaurant.specialFeatures))
      setTags(splitAndClean(restaurant.tags))
    }
  }

  const setOpenServer = (): void => {
    dispatch(setOpen(false))
  }
  return (
    <>
      <MuiBackdrop open={isLoading}>
        <ProfileCircularProgress />
      </MuiBackdrop>
      <MuiSnackbar open={openServer} onClose={setOpenServer}>
        <MuiAlert onClose={setOpenServer} severity={severity ?? 'success'}>
          {message}
        </MuiAlert>
      </MuiSnackbar>
      <RestaurantsPageLayout
        title={'Recommendations'}
        HeaderRight={
          isEdit ? (
            <EditModeButtons
              onCancel={onCancel}
              onUpdate={() => {
                void onUpdate()
              }}
            />
          ) : (
            <ViewModeButton onEdit={onEdit} />
          )
        }
        body={
          <>
            {!isEdit ? (
              <>
                {isMediumScreen && (
                  <FabButtonStyled id="edit-button" onClick={onEdit}>
                    <IconPencil stroke={2} size={16} />
                  </FabButtonStyled>
                )}
              </>
            ) : (
              <>
                {isMediumScreen && (
                  <RecommendationsMobileCancelUpdateButtonBox>
                    <ProfileHeaderCustomButtonCancel
                      title="Cancel"
                      onClick={onCancel}
                      fullWidth
                    />
                    <ProfileHeaderCustomButtonUpdate
                      title="Update"
                      disabled={false}
                      onClick={() => {
                        void onUpdate()
                      }}
                      fullWidth
                    />
                  </RecommendationsMobileCancelUpdateButtonBox>
                )}
              </>
            )}
            <Typography
              variant="body2"
              fontWeight={500}
              sx={{
                color: theme.palette.grey[800],
                mb: '4px',
              }}
            >
              Your restaurant will be suggested to users based on these tags
            </Typography>
            {isEdit && (
              <Typography
                variant="subtitle2"
                fontWeight={500}
                sx={{
                  color: theme.palette.grey[400],
                  mb: 2,
                }}
              >
                <IconInfoSquareRounded
                  stroke={2}
                  size={12}
                  style={{
                    marginRight: '5px',
                  }}
                />
                Add attributes by typing them into the field and pressing Enter.
                Don’t forget to click the ‘Update’ button at the top right to
                save your changes.
              </Typography>
            )}
            <RecommendationsStyleGridMainBox isEdit={isEdit}>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  md={6}
                  display="flex"
                  flexDirection="column"
                  gap={2}
                >
                  {/* <Stack spacing={2}> */}
                  <TagInput
                    label="Atmosphere"
                    id="atmosphere"
                    value={atmosphereTags}
                    onChange={setAtmosphereTags}
                    isEdit={isEdit}
                  />
                  <TagInput
                    label="Category"
                    id="category"
                    value={categoryTags}
                    onChange={setCategoryTags}
                    isEdit={isEdit}
                  />
                  <TagInput
                    label="Cuisine"
                    id="cuisine"
                    value={cuisineTags}
                    onChange={setCuisineTags}
                    isEdit={isEdit}
                  />
                  <TagInput
                    label="Occasion"
                    id="occasion"
                    value={occasionTags}
                    onChange={setOccasionTags}
                    isEdit={isEdit}
                  />
                  <TagInput
                    label="Special Features"
                    id="specialFeatures"
                    value={specialFeaturesTags}
                    onChange={setSpecialFeaturesTags}
                    isEdit={isEdit}
                  />
                  {/* </Stack> */}
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  display="flex"
                  flexDirection="column"
                  gap={2}
                >
                  <TagInput
                    label="Tags"
                    id="tags"
                    value={tags}
                    onChange={setTags}
                    isEdit={isEdit}
                  />

                  <RecommendationInput
                    label="Awards"
                    id="awards"
                    value={awards}
                    onChange={setAwards}
                    isEdit={isEdit}
                  />
                  <RecommendationInput
                    label="Known for"
                    id="knownFor"
                    value={knownFor}
                    onChange={setKnownFor}
                    isEdit={isEdit}
                  />
                </Grid>
              </Grid>
            </RecommendationsStyleGridMainBox>
          </>
        }
      />
    </>
  )
}

export default Recommendations
