import { Box, CSSObject, styled, Theme } from '@mui/material'
import MuiDrawer from '@mui/material/Drawer'
import {
  // IconBell,
  IconBook,
  IconId,
  IconLayoutDashboard,
  IconMessageDots,
  IconSettings,
  IconStar,
} from '@tabler/icons-react'
import React, { useEffect, useMemo, useState } from 'react'
import { ROUTES } from '../../helpers/routes-helper'
import { MOBILE_NAV_HEIGHT } from '../../helpers/static-constants'
import useAppUtilities from '../../hooks/useAppUtilities'
import RestaurantLayoutHeaderMobile from './RestaurantLayoutHeaderMobile'
import RestaurantLayoutHeaderWeb from './RestaurantLayoutHeaderWeb'
import RestaurantPageWrapper from './RestaurantPageWrapper'
import RestaurantSaidBarMenuList from './RestaurantSaidBarMenuList'
import UserAccountSection from './UserAccountSection'
import { setSideDrawer } from '../../store/restaurant/dashboard'

const DRAWER_WIDTH_OPEN = '285px'
const DRAWER_WIDTH_CLOSED = '100px'
const HEADER_MARGIN_TOP = `${MOBILE_NAV_HEIGHT}px`

const Layout: React.FC = () => {
  const { theme, location, navigate, isMediumScreen, dispatch } =
    useAppUtilities()
  const [isDrawerOpen, setDrawerOpen] = useState(false)
  const [activeItem, setActiveItem] = useState('Dashboard')

  useEffect(() => {
    const currentPath = location.pathname
    const activeMenuItem = RESTAURANT_MENU_LIST.find((menu) =>
      currentPath.startsWith(menu.path),
    )
    if (activeMenuItem) {
      setActiveItem(activeMenuItem.label)
    }
  }, [location.pathname])

  useEffect(() => {
    dispatch(setSideDrawer(isDrawerOpen))
  }, [isDrawerOpen, dispatch])

  const handleMenuItemClick = (label: string, path: string): void => {
    setActiveItem(label)
    navigate(path)
    if (isMediumScreen) setDrawerOpen(false)
  }

  const drawerContent = useMemo(
    () => (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height="100%"
      >
        <Box>
          <RestaurantLayoutHeaderWeb
            isDrawerOpen={isDrawerOpen}
            setIsDrawerOpen={setDrawerOpen}
          />
          <RestaurantSaidBarMenuList
            isDrawerOpen={isDrawerOpen}
            activeItem={activeItem}
            handleMenuItemClick={handleMenuItemClick}
            RESTAURANT_MENU_LIST={RESTAURANT_MENU_LIST}
          />
        </Box>
        <UserAccountSection
          isDrawerOpen={isDrawerOpen}
          setIsDrawerOpen={setDrawerOpen}
        />
      </Box>
    ),
    [isDrawerOpen, activeItem, handleMenuItemClick],
  )

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        backgroundColor: theme.palette.primary.main,
      }}
    >
      {isMediumScreen && (
        <RestaurantLayoutHeaderMobile
          isDrawerOpen={isDrawerOpen}
          setIsDrawerOpen={setDrawerOpen}
          activeItem={activeItem}
        />
      )}
      {isMediumScreen ? (
        <MuiDrawer
          transitionDuration={300}
          translate="yes"
          open={isDrawerOpen}
          anchor="right"
          sx={{
            width: 224,
            '& .MuiPaper-root': {
              backgroundColor: theme.palette.primary.main,
            },
            '& .MuiDrawer-paper': {
              width: 250,
              marginTop: `${MOBILE_NAV_HEIGHT}px` || '96px',
              border: 'none',
              boxShadow:
                '0px 8px 10px -5px rgba(0,0,0,0.2),-1px 26px 10px 2px rgba(0,0,0,0.14),0px 11px 11px 5px rgba(0,0,0,0.12)',
              zIndex: 10,
            },

            '& .MuiModal-backdrop': {
              backgroundColor: 'transparent',
            },
          }}
          onClose={() => setDrawerOpen(false)}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            height={`calc(100% - ${HEADER_MARGIN_TOP ?? '96px'})`}
            alignItems="stretch"
            flexDirection="column"
            py={3}
            px={2}
          >
            <Box>
              <RestaurantSaidBarMenuList
                isDrawerOpen={isDrawerOpen}
                activeItem={activeItem}
                handleMenuItemClick={handleMenuItemClick}
                RESTAURANT_MENU_LIST={RESTAURANT_MENU_LIST}
              />
            </Box>
            <UserAccountSection
              isDrawerOpen={isDrawerOpen}
              setIsDrawerOpen={setDrawerOpen}
            />
          </Box>
        </MuiDrawer>
      ) : (
        <Drawer
          variant="permanent"
          open={isDrawerOpen}
          transitionDuration={1000}
          translate="yes"
          sx={{
            '& .MuiPaper-root': {
              backgroundColor: theme.palette.primary.main,
              border: 'none',
              padding: '24px',
              transition: theme.transitions.create(
                ['background-color', 'transform'],
                {
                  duration: 300,
                },
              ),
            },
          }}
        >
          {drawerContent}
        </Drawer>
      )}
      <RestaurantPageWrapper />
    </Box>
  )
}

export default Layout

const drawerStyles = (theme: Theme, open: boolean | undefined): CSSObject => ({
  width: open ? DRAWER_WIDTH_OPEN : DRAWER_WIDTH_CLOSED,
  transition: theme.transitions.create(['width', 'background-color'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  flexShrink: 0,
  whiteSpace: 'nowrap',
  transition: 'all',
  boxSizing: 'border-box',
  ...drawerStyles(theme, open),
  '& .MuiDrawer-paper': drawerStyles(theme, open),
}))

export interface IRestaurantMenuItem {
  icon: JSX.Element
  label: string
  path: string
}

const RESTAURANT_MENU_LIST: IRestaurantMenuItem[] = [
  {
    icon: <IconLayoutDashboard stroke={2} />,
    label: 'Dashboard',
    path: ROUTES.RESTAURANT_ADMIN_DASHBOARD,
  },
  {
    icon: <IconBook stroke={2} />,
    label: 'Menu',
    path: ROUTES.RESTAURANT_MENUS,
  },
  {
    icon: <IconStar stroke={2} />,
    label: 'Reviews',
    path: ROUTES.RESTAURANT_REVIEWS,
  },
  {
    icon: <IconId stroke={2} />,
    label: 'Recommendations',
    path: ROUTES.RESTAURANT_RECOMMENDATIONS,
  },
  {
    icon: <IconMessageDots stroke={2} />,
    label: 'Messages',
    path: ROUTES.RESTAURANT_MESSAGES,
  },
  // TODO: Notifications side drawer option

  // {
  //   icon: <IconBell stroke={2} />,
  //   label: 'Notifications',
  //   path: ROUTES.RESTAURANT_NOTIFICATIONS,
  // },
  {
    icon: <IconSettings stroke={2} />,
    label: 'Settings',
    path: ROUTES.RESTAURANT_SETTINGS,
  },
]
