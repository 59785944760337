import { Box, Typography } from '@mui/material'
import React from 'react'
import { DEFAULT_CARD_IMAGE } from '../../helpers/static-constants'
import { SubOneTypography } from '../../pages/restaurant/dashboard/styled/competitorInsights/PricingTabTable.styled'
import { SquareAvatar } from '../styled/Avatar.styled'
import { TableBodyCell } from '../styled/Table.styled'

interface AvatarTableCellProps {
  imageUrl: string | undefined
  primaryText: string
  secondaryText?: string
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify'
  maxWidth?: string
  minWidth?: string
}

const AvatarTableCell: React.FC<AvatarTableCellProps> = ({
  imageUrl,
  primaryText,
  secondaryText,
  align = 'left',
  maxWidth = '280px',
  minWidth,
}) => {
  return (
    <TableBodyCell align={align} style={{ maxWidth, minWidth }}>
      <Box display="flex" alignItems="center" style={{ width: '100%' }}>
        <SquareAvatar
          src={imageUrl && imageUrl?.length > 0 ? imageUrl : DEFAULT_CARD_IMAGE}
          size="32px"
          name={primaryText}
        />
        <Box pl="14px" flexGrow={1} style={{ maxWidth: 'calc(100% - 32px)' }}>
          <SubOneTypography noWrap>
            {primaryText?.toLocaleLowerCase()}
          </SubOneTypography>

          {secondaryText && secondaryText.length > 0 && (
            <Typography
              noWrap
              variant="subtitle2"
              color={'grey.500'}
              fontWeight={500}
            >
              {secondaryText}
            </Typography>
          )}
        </Box>
      </Box>
    </TableBodyCell>
  )
}

export default AvatarTableCell
