import { Box, Drawer, useTheme } from '@mui/material'
import styled from 'styled-components'
import React from 'react'

interface DishCardMainProps {
  isSelected: string
}

export const DishCardMainBox = styled(({ isSelected, ...rest }) => (
  <Box {...rest} />
)).attrs<DishCardMainProps>(({ isSelected }) => {
  const theme = useTheme()
  return {
    sx: {
      padding: '6px',
      border: 1.5,
      borderRadius: '16px',
      display: 'flex',
      flexDirection: 'column',
      borderColor: isSelected
        ? theme.palette.secondary[300]
        : theme.palette.grey[200],
      backgroundColor: isSelected
        ? theme.palette.secondary[50]
        : theme.palette.background.paper,
      height: { xs: '41vw', sm: '32vw', md: '18vw' },
      maxHeight: { xs: '160px', md: '190px' },
      cursor: 'pointer',
      '&:hover': {
        borderColor: theme.palette.secondary[300],
      },
      animation: 'fadeIn 0.1s ease-in-out',
      '@keyframes fadeIn': {
        '0%': { opacity: 0, transform: 'scale(0.8)' },
        '100%': { opacity: 1, transform: 'scale(1)' },
      },
    },
  }
})<DishCardMainProps>(() => ({}))

export const DishCardDrawer = styled(Drawer).attrs(() => {
  return {
    anchor: 'right',
    transitionDuration: 300,
    sx: {
      '& .MuiPaper-root': {
        height: { xs: `calc(100% - 87px)`, sm: `calc(100% - 96px)` },
        bottom: 0,
        top: 'auto',
        width: { xs: '80vw', sm: '50vw' },
      },
    },
  }
})(() => ({}))
