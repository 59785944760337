import { Box, Typography } from '@mui/material'
import React, { useMemo } from 'react'
import useAppUtilities from '../../../../../hooks/useAppUtilities'
import RatingBar from './RatingBar'
import { RatingBox } from '../../styled/Rating.styled'
import { selectDashboardDetail } from '../../../../../store/restaurant/dashboard'
import { useAppSelector } from '../../../../../store'

const Rating: React.FC = () => {
  const { theme } = useAppUtilities()
  const dashboardDetail = useAppSelector(selectDashboardDetail)
  const ratings = useMemo(() => {
    const defaultRatings = Array.from({ length: 5 }, (_, i) => ({
      starCount: i + 1,
      count: 0,
    }))

    const actualRatings = Object.entries(dashboardDetail?.ratings ?? {}).map(
      ([key, count]) => ({
        starCount: Number(key),
        count,
      }),
    )

    const mergedRatings = defaultRatings.map((defaultItem) => {
      const actualItem = actualRatings.find(
        (item) => item.starCount === defaultItem.starCount,
      )
      return actualItem ?? defaultItem
    })

    return mergedRatings.sort((a, b) => b.starCount - a.starCount)
  }, [dashboardDetail?.ratings])

  const maxValue = useMemo(
    () => ratings.reduce((sum, rating) => sum + rating.count, 0) || 10,
    [ratings],
  )
  return (
    <RatingBox>
      <Typography
        variant="body2"
        color={theme.palette.grey[800]}
        fontWeight={700}
        sx={{ pb: { xs: '12px', md: '16px' } }}
      >
        Rating
      </Typography>
      <Box
        display="flex"
        flexDirection="column"
        gap={['4px', '8px']}
        sx={{
          height: '100%',
        }}
      >
        {ratings.map(({ starCount, count }) => (
          <RatingBar
            key={starCount}
            starCount={starCount}
            maxValue={maxValue}
            count={count}
          />
        ))}
      </Box>
    </RatingBox>
  )
}

export default Rating
