import { Box, Typography, useTheme } from '@mui/material'
import styled from 'styled-components'

export const SubOneTypography = styled(Typography).attrs(() => {
  const theme = useTheme()
  return {
    variant: 'subtitle1',
    color: theme.palette.grey[800],
    fontWeight: 500,
    textTransform: 'capitalize',
  }
})(() => ({}))

export const HighestPriceBox = styled(Box).attrs(() => {
  return {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
  }
})(() => ({}))
