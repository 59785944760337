import { IconPlus } from '@tabler/icons-react'
import React, { useState } from 'react'
import useAppUtilities from '../../../../hooks/useAppUtilities'
import {
  AddMenuTypography,
  StyledAddMenuBox,
} from '../styled/AddMenuCard.styled'
import AddEditMenuDialog from './AddEditMenuDialog'

const AddMenuCard: React.FC = () => {
  const { theme } = useAppUtilities()
  const [modalOpenEdit, setModalOpenEdit] = useState(false)

  return (
    <>
      <StyledAddMenuBox onClick={() => setModalOpenEdit(true)}>
        <IconPlus
          color={theme.palette.grey[800]}
          height={'16px'}
          width={'16px'}
          stroke={2}
        />
        <AddMenuTypography>Add Menu</AddMenuTypography>
      </StyledAddMenuBox>
      <AddEditMenuDialog
        openEditModel={modalOpenEdit}
        setOpenEditModel={setModalOpenEdit}
      />
    </>
  )
}

export default AddMenuCard
