import { Box } from '@mui/material'
import React from 'react'
import BasicModal from '../../../../components/modal/BasicModal'
import useAppUtilities from '../../../../hooks/useAppUtilities'
import {
  fetchLoadMenu,
  selectIsLoading,
  setOpenStaticMessage,
} from '../../../../store/restaurant/menu'
import { useAppSelector } from '../../../../store'
import { selectRestaurant } from '../../../../store/restaurant/setting'
import InputComponent from '../../../../components/ui/InputComponent'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'

interface IMenuCardProps {
  openEditModel: boolean
  setOpenEditModel: React.Dispatch<React.SetStateAction<boolean>>
  setShowToaster: React.Dispatch<React.SetStateAction<boolean>>
}

const formSchema = z.object({
  name: z.string().min(1, { message: 'Menu name is required' }),
  url: z.string().optional(),
})

const LoadMenuDialog: React.FC<IMenuCardProps> = ({
  openEditModel,
  setOpenEditModel,
  setShowToaster,
}) => {
  const { dispatch } = useAppUtilities()
  const restaurant = useAppSelector(selectRestaurant)
  const isLoading = useAppSelector(selectIsLoading)

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    defaultValues: {
      name: '',
      url: '',
    },
  })

  const onHandleModalEditCancel = (): void => {
    reset()
    setOpenEditModel(false)
  }

  const onHandleLoadMenu = async (): Promise<void> => {
    await handleSubmit(
      async (data: z.infer<typeof formSchema>): Promise<void> => {
        const loadMenuPayload = {
          id: restaurant?.restaurantID ?? '',
          name: data.name,
          url: data.url ?? '',
        }
        const res = await dispatch(fetchLoadMenu(loadMenuPayload))
        if (fetchLoadMenu.fulfilled.match(res)) {
          dispatch(
            setOpenStaticMessage({
              message: `Load menu request has been sent, This might take few days to
                update.`,
              severity: 'success',
              shouldOpen: true,
              status: 'idle',
            }),
          )
          reset()
          setShowToaster(true)
          setOpenEditModel(false)
        }
      },
    )()
  }

  return (
    <BasicModal
      open={openEditModel}
      onCancel={onHandleModalEditCancel}
      onConfirm={() => {
        void onHandleLoadMenu()
      }}
      isLoader={isLoading}
      title={'Load Menu'}
      buttonName={'Load menu'}
      saveBtnDisabled={!isValid}
      handleClose={onHandleModalEditCancel}
    >
      <Box mt={2}>
        <InputComponent
          id="load-menu-name"
          label="Menu Name"
          disabled={isLoading}
          {...register('name')}
          error={!!errors.name}
          helperText={errors.name ? errors.name.message : ' '}
          isFieldRequired
          fullWidth
          placeholder="Enter menu name"
          sx={{
            mt: '6px',
          }}
        />
      </Box>
      <Box mb={2}>
        <InputComponent
          id="load-menu-url"
          label="Url"
          disabled={isLoading}
          {...register('url')}
          fullWidth
          placeholder="Enter URL"
          sx={{
            mt: '6px',
          }}
        />
      </Box>
    </BasicModal>
  )
}

export default LoadMenuDialog
