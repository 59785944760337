import {
  Button,
  Container,
  InputLabel,
  Skeleton,
  Typography,
  styled as muiStyled,
  useTheme,
} from '@mui/material'
import { IconPlus } from '@tabler/icons-react'
import styled from 'styled-components'

export const SocialMainContainer = styled(Container).attrs(() => ({
  component: 'main',
  sx: {
    flex: 1,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
}))``

export const SocialSkeleton = muiStyled(Skeleton)({
  marginBottom: '16px',
  marginTop: '16px',
})

export const SocialEmptyScreenContainer = muiStyled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

export const SocialEmptyScreenButton = muiStyled(Button)(() => {
  const theme = useTheme()

  return {
    textTransform: 'capitalize',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '10px',
    color: theme.palette.primary.main,
  }
})

export const SocialEmptyScreenIcon = muiStyled(IconPlus)(() => {
  return {
    marginRight: '8px',
  }
})

export const SocialUserEmailSearchContainer = muiStyled('div')(() => {
  return {
    marginBottom: '16px',
  }
})

export const SocialBasicModalContainer = muiStyled('div')(() => {
  return {
    paddingTop: '16px',
  }
})

export const SocialInputLabel = styled(InputLabel).attrs(() => ({
  sx: {
    fontWeight: 500,
    fontSize: '14px',
  },
  shrink: true,
}))(() => {
  const theme = useTheme()
  return {
    color: theme.palette.secondary[800],
  }
})

export const SocialNewGroupTypography = styled(Typography).attrs(() => {
  const theme = useTheme()
  return {
    component: 'p',
    variant: 'subtitle1',
    color: theme.palette.primary.main,
    fontWeight: 600,
  }
})(() => ({}))
