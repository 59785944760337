import {
  Box,
  Card,
  CardContent,
  Divider,
  Fade,
  Grid,
  Skeleton,
  Typography,
} from '@mui/material'
import React, { useEffect, useMemo } from 'react'
import EmptyScreen from '../../../../../components/EmptyScreen'
import useAppUtilities from '../../../../../hooks/useAppUtilities'
import { useAppSelector } from '../../../../../store'
import {
  fetchRegionInsights,
  selectIsFIPS,
  selectIsLoading,
  selectRegionInsights,
  selectRestaurantFIPS,
  setFIPS,
  setIsFIPS,
  setRegionInsightSearch,
} from '../../../../../store/restaurant/dashboard'
import GetInsightsAnswers from './GetInsightsAnswers'

const RegionalInsights: React.FC = () => {
  const regionInsight = useAppSelector(selectRegionInsights)
  const isLoading = useAppSelector(selectIsLoading)
  const isFIPS = useAppSelector(selectIsFIPS)
  const adminFIPS = useAppSelector(selectRestaurantFIPS)
  const { dispatch } = useAppUtilities()

  useEffect(() => {
    if (adminFIPS) {
      dispatch(setRegionInsightSearch(''))
      dispatch(setIsFIPS(false))
      dispatch(setFIPS(null))
      void dispatch(
        fetchRegionInsights({
          fips: adminFIPS,
        }),
      )
    }
  }, [adminFIPS])

  const insightsData = useMemo(
    () =>
      Object.entries(regionInsight?.insight ?? {}).map(([title, content]) => ({
        title,
        content,
      })),
    [regionInsight?.insight],
  )

  const suggestions = useMemo(
    () => regionInsight?.suggestion ?? [],
    [regionInsight?.suggestion],
  )

  return (
    <Box>
      <Grid container spacing={2} p={2} alignItems="stretch">
        {!isFIPS ? (
          <>
            <Grid item xs={12} md={4}>
              {isLoading ? (
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height={330}
                  sx={{
                    borderRadius: { xs: '12px', md: '16px' },
                  }}
                />
              ) : (
                <Fade in={!isLoading} timeout={1000}>
                  <Card
                    sx={{
                      border: '1px solid #99CAFF',
                      backgroundColor: '#F5FAFF',
                      borderRadius: '12px',
                    }}
                  >
                    <CardContent>
                      <Box display="flex" alignItems="center" mb={2}>
                        <Box
                          component="img"
                          src="/images/regional-insight-icon.svg"
                          pr={1}
                        />
                        <Typography
                          variant="body2"
                          fontWeight={700}
                          lineHeight="24px"
                          color="#007AFF"
                        >
                          Insights About this Region
                        </Typography>
                      </Box>
                      <Box sx={{ maxHeight: 250, overflow: 'auto' }}>
                        {insightsData.length > 0 ? (
                          insightsData.map((item, index) => (
                            <React.Fragment key={index}>
                              <Typography
                                variant="subtitle1"
                                textTransform="capitalize"
                                fontWeight={700}
                              >
                                {item.title}
                              </Typography>
                              <Typography variant="subtitle1">
                                {item.content}
                              </Typography>
                              {index < insightsData.length - 1 && (
                                <Divider sx={{ my: 2 }} />
                              )}
                            </React.Fragment>
                          ))
                        ) : (
                          <EmptyScreen
                            mainText="No suggestions available at this time."
                            secondaryText="Please select another location."
                            imageUrl="/images/empty-screen.svg"
                            containerHeight={'250px'}
                          />
                        )}
                      </Box>
                    </CardContent>
                  </Card>
                </Fade>
              )}
            </Grid>

            <Grid item xs={12} md={4}>
              {isLoading ? (
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height={330}
                  sx={{
                    borderRadius: { xs: '12px', md: '16px' },
                  }}
                />
              ) : (
                <Fade in={!isLoading} timeout={1000}>
                  <Card
                    sx={{
                      border: '1px solid #6CE9A6',
                      borderRadius: '12px',
                      backgroundColor: '#F6FEF9',
                      height: '100%',
                    }}
                  >
                    <CardContent>
                      <Box display="flex" alignItems="center" mb={2}>
                        <Box
                          component="img"
                          src="/images/regional-insight-icon-1.svg"
                          pr={1}
                        />
                        <Typography
                          variant="body2"
                          color="success.main"
                          fontWeight={700}
                          lineHeight="24px"
                        >
                          {`Here's What We Suggest`}
                        </Typography>
                      </Box>
                      <Box sx={{ maxHeight: 250, overflow: 'auto' }}>
                        {suggestions.length > 0 ? (
                          suggestions.map((suggestion, index) => (
                            <React.Fragment key={index}>
                              <Typography
                                variant="subtitle1"
                                textTransform="capitalize"
                                fontWeight={700}
                              >
                                {suggestion.type}
                              </Typography>
                              <Typography variant="subtitle1">
                                {suggestion.justification}
                              </Typography>
                              {index < suggestions.length - 1 && (
                                <Divider sx={{ my: 2 }} />
                              )}
                            </React.Fragment>
                          ))
                        ) : (
                          <EmptyScreen
                            mainText="No suggestions available at this time."
                            secondaryText="Please select another location."
                            imageUrl="/images/empty-screen.svg"
                            containerHeight={'250px'}
                          />
                        )}
                      </Box>
                    </CardContent>
                  </Card>
                </Fade>
              )}
            </Grid>
            <Grid item xs={12} md={4}>
              <GetInsightsAnswers />
            </Grid>
          </>
        ) : (
          <Grid item xs={12}>
            <EmptyScreen
              mainText="Please select another location."
              secondaryText="No regional insights available."
              imageUrl="/images/empty-screen.svg"
              containerHeight={'400px'}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  )
}

export default RegionalInsights
