import {
  Select,
  MenuItem,
  SelectProps,
  Box,
  SelectChangeEvent,
  Typography,
} from '@mui/material'
import React from 'react'
import { IconChevronDown } from '@tabler/icons-react'
import useAppUtilities from '../../hooks/useAppUtilities'
import { ResactlyInputLabel } from './InputComponent'

interface Option {
  label: string
  value: string
}

type SelectBoxProps = {
  id: string
  options: Option[]
  value: string
  isEdit?: boolean
  onChange: (value: SelectChangeEvent<unknown>) => void
  label?: string
  // Label Props
  isFieldRequired?: boolean
  labelWeight?: number
  labelFontSize?: string & {}
  labelColor?: string
  labelShrink?: boolean
} & SelectProps

const SelectBox: React.FC<SelectBoxProps> = ({
  id,
  options,
  value,
  isEdit = false,
  onChange,
  label,
  // Label Props
  isFieldRequired = false,
  labelWeight,
  labelFontSize,
  labelColor,
  labelShrink,
  error,
  ...props
}) => {
  const { theme } = useAppUtilities()
  return (
    <Box width="100%">
      {label && (
        <ResactlyInputLabel
          isFieldRequired={isFieldRequired}
          htmlFor={`dynamic-select-label-${id}`}
          label={label}
          labelColor={labelColor}
          isValid={error}
          labelFontSize={labelFontSize}
          labelWeight={labelWeight}
          labelShrink={labelShrink}
        />
      )}
      <Select
        labelId={`dynamic-select-label-${id}`}
        value={value}
        onChange={(e) => onChange(e)}
        variant="outlined"
        size="small"
        IconComponent={IconChevronDown}
        MenuProps={{
          PaperProps: {
            sx: {
              mt: 1,
              boxShadow: 3,
              borderRadius: '12px',
              overflow: 'hidden',
            },
          },
        }}
        sx={{
          width: '100%',
          '&.MuiOutlinedInput-root': {
            '& .MuiSelect-icon': {
              top: 'calc(50% - .8em)',
            },
            fontSize: '16px',
            '& fieldset': {
              borderColor: theme.palette.grey[300],
              borderRadius: '8px',
            },
            '&:hover fieldset': {
              borderColor: theme.palette.grey[300],
            },
            '&.Mui-focused fieldset': {
              borderColor: theme.palette.grey[300],
            },
            backgroundColor: `${
              isEdit ? theme.palette.grey[50] : theme.palette.background.paper
            } !important`,
          },
        }}
        {...props}
      >
        {options.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            sx={{
              px: 2,
              py: 1.2,
              transition: 'background 0.2s',
              '&:hover': {
                backgroundColor: 'grey.100',
              },
            }}
          >
            <Typography
              noWrap
              component="p"
              variant="body2"
              fontWeight={500}
              color="grey.800"
            >
              {option.label}
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </Box>
  )
}

export default SelectBox
