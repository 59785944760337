import { Typography } from '@mui/material'
import { IconDotsVertical } from '@tabler/icons-react'
import React, { useState } from 'react'
import { ROUTES } from '../../../../helpers/routes-helper'
import { DEFAULT_CARD_IMAGE } from '../../../../helpers/static-constants'
import useAppUtilities from '../../../../hooks/useAppUtilities'
import {
  InnerBox,
  StyledBox,
  StyledIconButton,
  StyledImage,
} from '../styled/MenuCard.styled'
import EditDeleteMenu from './EditDeleteMenu'
import { Menu } from '../../../../types/menuTypes'

interface IMenuProps {
  menu: Menu
}

const MenuCard: React.FC<IMenuProps> = ({ menu }) => {
  const { theme, navigate } = useAppUtilities()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = (): void => {
    setAnchorEl(null)
  }

  const handleClick = (): void => {
    navigate(`${ROUTES.RESTAURANT_MENUS}/${menu.menuID}`)
  }

  return (
    <>
      <StyledBox onClick={handleClick}>
        <StyledImage
          src={menu.imageurl ?? DEFAULT_CARD_IMAGE}
          alt="restaurant-profile"
        />
        <InnerBox>
          <Typography
            noWrap
            variant="subtitle1"
            color={theme.palette.grey[800]}
            textTransform="capitalize"
            sx={{ maxWidth: '80%', paddingY: '5px' }}
          >
            {menu.name}
          </Typography>
          <StyledIconButton
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            onClick={handleMenuOpen}
          >
            <IconDotsVertical
              color={theme.palette.grey[800]}
              height={'16px'}
              width={'16px'}
              stroke={2}
            />
          </StyledIconButton>
        </InnerBox>
      </StyledBox>
      <EditDeleteMenu
        anchorEl={anchorEl}
        handleMenuClose={handleMenuClose}
        open={open}
        menu={menu}
      />
    </>
  )
}

export default MenuCard
