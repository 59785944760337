import { Box, Typography } from '@mui/material'
import React from 'react'
import {
  TableBodyCell,
  TableBodyRow,
} from '../../../../components/styled/Table.styled'
import useAppUtilities from '../../../../hooks/useAppUtilities'
import { useAppSelector } from '../../../../store'
import { selectDashboardDetail } from '../../../../store/restaurant/dashboard'
import RestaurantTable from '../../../../components/table/RestaurantTable'
import AvatarTableCell from '../../../../components/table/AvatarTableCell'
import TextTableCell from '../../../../components/table/TextTableCell'

interface Column {
  id: string
  label: string
  minWidth?: string
  maxWidth?: string
  width?: string
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify'
  format?: (value: number) => string
  type?: string
}

const PopularDishesTable: React.FC = () => {
  const dashboardDetail = useAppSelector(selectDashboardDetail)
  const { theme } = useAppUtilities()

  const columns: readonly Column[] = [
    { id: 'name', label: '', minWidth: '50px' },
    {
      id: 'popularDishes',
      label: 'Popular Dishes',
    },
    {
      id: 'views',
      label: 'no. of views',
      minWidth: '110px',
    },
    { id: 'price', label: 'Price' },
  ]

  return (
    <RestaurantTable
      columns={columns}
      data={dashboardDetail?.popularDishes ?? []}
      renderRow={(row, index) => (
        <TableBodyRow key={index}>
          <TableBodyCell
            align="left"
            style={{ maxWidth: '60px', paddingRight: '0px' }}
          >
            <Box display="flex" alignItems="center">
              <Typography
                variant="subtitle1"
                color={theme.palette.grey[800]}
                fontWeight={500}
              >
                {index + 1}
              </Typography>
              {row.raising && (
                <Typography
                  color={theme.palette.success.main}
                  fontSize="10px"
                  fontWeight={500}
                  pl="4px"
                >
                  ▲
                </Typography>
              )}
            </Box>
          </TableBodyCell>

          <AvatarTableCell
            imageUrl={row.dish?.imageurl}
            primaryText={row?.dish?.name}
          />

          <TextTableCell
            align="center"
            text={row?.views ? row?.views : '-'}
            minWidth={columns?.[2].minWidth}
          />
          <TextTableCell
            text={row?.dish?.price ? `$ ${row?.dish?.price}` : '-'}
            minWidth="90px"
          />
        </TableBodyRow>
      )}
    />
  )
}

export default PopularDishesTable
