import { Box, Typography, useTheme } from '@mui/material'
import styled from 'styled-components'
import ImageUpload from '../components/ImageUpload'
import { IconPointFilled } from '@tabler/icons-react'

export const ImageTabTitleTypography = styled(Typography).attrs(() => {
  const theme = useTheme()
  return {
    variant: 'subtitle1',
    color: theme.palette.grey[800],
    sx: { marginBottom: '6px' },
  }
})(() => ({}))

export const ImageTabImages = styled(ImageUpload).attrs(() => {
  return {
    style: { width: '100%', height: '100%' },
  }
})(() => ({}))

export const ImageTagBottomBox = styled(Box).attrs(() => {
  return {
    sx: { marginTop: '16px' },
  }
})(() => ({}))

export const ImageTagFirstTitleBox = styled(Box).attrs(() => {
  return {
    sx: {
      display: 'flex',
      justifyContent: 'start',
      alignItems: 'center',
    },
  }
})(() => ({}))
export const ImageTagSecondTitleBox = styled(Box).attrs(() => {
  return {
    sx: {
      display: 'flex',
      justifyContent: 'start',
      alignItems: 'center',
      marginTop: '4px',
    },
  }
})(() => ({}))

export const StyledIconPointFilled = styled(IconPointFilled).attrs(() => {
  const theme = useTheme()
  return {
    stroke: 2,
    height: '15px',
    width: '15px',
    color: theme.palette.grey[500],
  }
})(() => ({}))

export const StyledTypography = styled(Typography).attrs(() => {
  const theme = useTheme()
  return {
    variant: 'caption',
    color: theme.palette.grey[500],
  }
})(() => ({}))
