import React from 'react'
import EmptyScreen from '../../../../components/EmptyScreen'
import RestaurantList from '../../../../components/restaurant/RestaurantList'
import {
  MOBILE_APP_HEIGHT,
  MOBILE_NAV_HEIGHT,
  MOBILE_SEARCH_HEIGHT,
} from '../../../../helpers/static-constants'
import useAppUtilities from '../../../../hooks/useAppUtilities'
import { RestaurantAndPredictedRestaurant } from '../../../../types'

interface ProfileSavedRestaurantsProps {
  cardList: RestaurantAndPredictedRestaurant[]
}

const ProfileSavedRestaurants: React.FC<ProfileSavedRestaurantsProps> = ({
  cardList,
}) => {
  const { isMediumScreen } = useAppUtilities()

  return (
    <div>
      {cardList.length && cardList.length !== 0 ? (
        <RestaurantList
          cardList={cardList}
          mobileSearchHight={`${
             `(${MOBILE_SEARCH_HEIGHT + MOBILE_APP_HEIGHT + 213}px + env(safe-area-inset-bottom) + env(safe-area-inset-top))`
          }`}
          noneMobileSearchHight={`${MOBILE_NAV_HEIGHT + 265}px`}
        />
      ) : (
        <EmptyScreen
          mainText="No data available"
          secondaryText="No restaurants found"
          imageUrl="/images/empty-screen.svg"
          containerHeight={isMediumScreen ? '40vh' : '400px'}
        />
      )}
    </div>
  )
}
export default ProfileSavedRestaurants
