import { Box, TextField, useTheme } from '@mui/material'
import styled from 'styled-components'

export const NotificationsUpdateCardMainContainer = styled(Box).attrs(() => {
  const theme = useTheme()

  return {
    sx: {
      backgroundColor: theme.palette.background.paper,
      padding: 2,
      borderRadius: '12px',
      border: 1.5,
      borderColor: theme.palette.grey[200],
      width: '100%',
      maxWidth: { xs: '100%', md: '327px' },
      textAlign: 'center',
    },
  }
})(({ theme }) => ({}))

export const NotificationsUpdateCardTextField = styled(TextField).attrs(() => {
  return {
    variant: 'outlined',
    placeholder: 'Eg: 9704978423',
    fullWidth: true,
    type: 'number',
    sx: {
      marginTop: '16px',
      '& .MuiInputBase-root': { borderRadius: '8px' },
      '& .MuiInputBase-input': {
        padding: '10px 10px 8px 10px',
        fontSize: '14px',
        lineHeight: '20px',
        fontWeight: 400,
      },
    },
  }
})(({ theme }) => ({}))
