import React from 'react'
import { Box, Skeleton } from '@mui/material'

const ChartSkeleton: React.FC = () => {
  return (
    <Box display="flex" flexWrap="wrap" gap={2}>
      {[...Array(3)].map((_, index) => (
        <Skeleton
          key={index}
          variant="rectangular"
          height={300}
          sx={{
            flex: { xs: '1 1 100%', lg: '1 1 calc(33.33% - 16px)' },
            borderRadius: 4,
          }}
        />
      ))}
    </Box>
  )
}

export default ChartSkeleton
