import { Typography } from '@mui/material'
import React, { useState } from 'react'
import CustomButton from '../../../../components/ui/CustomButton'
import useAppUtilities from '../../../../hooks/useAppUtilities'
import {
  NotificationsUpdateCardMainContainer,
  NotificationsUpdateCardTextField,
} from '../styled/NotificationsUpdateCard.styled'

const NotificationsUpdateCard: React.FC = () => {
  const { theme } = useAppUtilities()
  const [phoneNumber, setPhoneNumber] = useState('')
  const [error, setError] = useState(false)

  const handlePhoneNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const value = event.target.value
    setPhoneNumber(value)

    const phoneNumberPattern = /^[0-9]{10,15}$/
    setError(!phoneNumberPattern.test(value))
  }

  return (
    <NotificationsUpdateCardMainContainer>
      <Typography
        variant="body2"
        sx={{
          color: theme.palette.grey[800],
        }}
      >
        Stay Updated with SMS
      </Typography>

      <Typography
        component="span"
        variant="subtitle2"
        sx={{
          color: theme.palette.grey[500],
          marginTop: '8px',
          display: 'block',
        }}
      >
        To receive SMS updates, please provide your phone number below.
      </Typography>

      <NotificationsUpdateCardTextField
        value={phoneNumber}
        onChange={handlePhoneNumberChange}
        error={error}
        helperText={error ? 'Please enter a valid phone number' : ''}
      />

      <CustomButton
        variant="contained"
        title="Activate"
        textColor={theme.palette.grey[400]}
        sx={{
          textTransform: 'none',
          marginTop: '8px',
          width: '100%',
          border: 1,
          borderRadius: '10px',
          backgroundColor:
            error || !phoneNumber
              ? theme.palette.grey[300]
              : theme.palette.primary.main,
          borderColor:
            error || !phoneNumber
              ? theme.palette.primary.dark
              : theme.palette.grey[400],
          '&:disabled': {
            backgroundColor: theme.palette.grey[100],
            borderColor: theme.palette.grey[300],
            color: theme.palette.grey[400],
          },
        }}
        disabled={error || !phoneNumber}
      />
    </NotificationsUpdateCardMainContainer>
  )
}

export default NotificationsUpdateCard
