import { Box } from '@mui/material'
import styled from 'styled-components'
import { RESTAURANT_HEADER_WEB } from '../../../../helpers/static-constants'

export const NotificationsMainContainer = styled(Box).attrs(() => ({
  sx: {
    height: {
      xs: 'calc(100vh - 120px)',
      md: `calc(100vh - ${RESTAURANT_HEADER_WEB}px)`,
    },
    overflowY: 'scroll',
  },
}))(({ theme }) => ({}))

export const NotificationsUpdateCardContainer = styled(Box).attrs(() => ({
  sx: {
    display: 'flex',
    justifyContent: 'center',
    position: 'sticky',
    top: '0px',
  },
}))(({ theme }) => ({}))

export const NotificationsCardContainer = styled(Box).attrs(() => ({
  sx: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
}))(({ theme }) => ({}))
