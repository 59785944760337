import {
  AutocompleteResponseState,
  AutocompleteResult,
} from '@elastic/search-ui'
import ElasticsearchAPIConnector from '@elastic/search-ui-elasticsearch-connector'
import { debounce } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'

interface SearchQuery {
  searchTerm: string
}

const elasticConfig = {
  cloud: {
    id: process.env.REACT_APP_ELASTIC_CLOUD_ID ?? '',
  },
  apiKey: process.env.REACT_APP_ELASTIC_API_KEY ?? '',
}

const autocompleteQueryConfig = {
  results: {
    search_fields: {},
    result_fields: {},
  },
}

const restaurantConnector = new ElasticsearchAPIConnector({
  ...elasticConfig,
  index: process.env.REACT_APP_RESTAURANT_INDEX ?? '',
})

const defaultAutocompleteResult: AutocompleteResponseState = {
  autocompletedResults: [],
  autocompletedResultsRequestId: '',
  autocompletedSuggestions: {},
  autocompletedSuggestionsRequestId: '',
}

const useElasticRestaurantSearch = (searchInputValue: string): any => {
  const [results, setResults] = useState<AutocompleteResult[]>([])
  const [loading, setLoading] = useState(false)

  const handleAutocompleteError = (): AutocompleteResponseState => {
    return defaultAutocompleteResult
  }

  const onAutocomplete = async (
    query: SearchQuery,
    queryConfig,
  ): Promise<AutocompleteResponseState> => {
    if (!query.searchTerm) return defaultAutocompleteResult

    try {
      const results = await Promise.all([
        restaurantConnector.onAutocomplete(query, queryConfig),
      ])

      const [firstResult] = results

      return {
        autocompletedResults: results.flatMap(
          (result) => result.autocompletedResults,
        ),
        autocompletedResultsRequestId:
          firstResult.autocompletedResultsRequestId,
        autocompletedSuggestions: {},
        autocompletedSuggestionsRequestId: '',
      }
    } catch (error) {
      return handleAutocompleteError()
    }
  }

  const fetchAutocompleteResults = useMemo(
    () =>
      debounce(
        async (
          state: { searchTerm: string },
          callback: (results?: AutocompleteResponseState) => void,
        ) => {
          setLoading(true)
          const result = await onAutocomplete(state, autocompleteQueryConfig)
          callback(result)
        },
        1000,
      ),
    [],
  )

  useEffect(() => {
    if (!searchInputValue) return

    void fetchAutocompleteResults(
      { searchTerm: searchInputValue },
      (result?: AutocompleteResponseState) => {
        if (result) {
          setResults(result.autocompletedResults)
        }
        setLoading(false)
      },
    )
  }, [searchInputValue, fetchAutocompleteResults])

  return { results, loading }
}

export default useElasticRestaurantSearch
