import styled from 'styled-components'
import InputComponent from '../../../../components/ui/InputComponent'
import { Box, Button, Grid, Typography, useTheme } from '@mui/material'

export const DishesTabMainContainer = styled('div').attrs(() => ({}))(() => ({
  paddingBottom: '44px',
}))

export const DishesTabInnerContainer = styled(Box).attrs(() => ({
  className: 'hide-scrollbar',
  pt: { xs: '16px', md: '46px' },
  pb: '10px',
  overflow: 'auto',
  sx: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '12px',
  },
}))(() => ({}))

export const DishesTabSearchWrapper = styled(Box).attrs(() => ({
  sx: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'stretch',
    gap: '12px',
  },
}))(() => ({}))

export const DishesTabFilterWrapper = styled(Box).attrs(() => ({
  sx: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
  },
}))(() => ({}))

export const DishesTabInputComponent = styled(InputComponent).attrs(() => ({
  sx: {
    width: '280px',
    '& .MuiInputBase-root': {
      py: {
        xs: '0px',
        sm: '3px',
      },
    },
  },
}))(() => ({}))

export const DishesTabMainGrid = styled(Grid).attrs(() => ({
  container: true,
  columnSpacing: '20px',
  sx: {
    mt: '14px',
  },
}))(() => ({}))

export const DishesTabInnerGridSearchDish = styled(Grid).attrs(() => ({
  item: true,
  xs: 12,
  md: 6,
}))(() => ({}))

export const DishesTabInnerGrid = styled(Grid).attrs(() => ({
  item: true,
  xs: 12,
}))(() => ({}))

export const DishesTabTypography = styled(Typography).attrs(() => {
  const theme = useTheme()
  return {
    variant: 'subtitle1',
    color: theme.palette.grey[800],
  }
})(() => ({}))

export const DishesTabFilterButton = styled(Button).attrs(() => {
  return {
    variant: 'outlined',
    color: 'primary',
    size: 'large',
    sx: {
      borderRadius: '10px',
      textTransform: 'none',
    },
  }
})(() => ({}))
