import { Eater } from '../types'
import { Restaurant } from './restaurantTypes'

export interface MenuRequest {
  menuId: string
  name: string
  url?: string
  itemParser?: string
  titleParser?: string
  descriptionParser?: string
  priceParser?: string
  ingredientParser?: string
  dishes?: Dish[]
}

export interface Menu {
  menuID: string
  name: string
  placeId: string
  url?: string
  nextRefreshDate: Date
  itemParser: string
  titleParser: string
  ingredientParser: string
  descriptionParser: string
  priceParser: string
  description: string
  created: Date
  updated: Date
  dishes: Dish[]
  predictedDishes: PredictedDish[]
  rating: number
  status: string
  imageurl: string
}

export interface PredictedDish {
  probability: Recommendation
  dish: Dish
  menuName?: string
  menuId?: string
}

export interface Dish {
  dishID: string
  name: string
  price: number
  ingredients: string
  description: string
  orderCount: any
  tags: string
  status: string
  created: string
  updated: string
  dietary: string
  unsuitabledietary: string
  preparation: string
  imageurl: string
  starCount: number
  reviews: DiningReviewDTO[]
}

export interface DishRequest {
  menuId: string
  dishId: string
  chefId?: string
  name?: string
  price?: number
  ingredients?: string
  description?: string
  orderCount?: number
  tags?: string
  dietary?: string
  unsuitabledietary?: string
  preparation?: string
}

export interface NewMenuRequest {
  name: string
  restaurantId: string
}

export interface Recommendation {
  name?: string
  yes: number
  no: number
}

export interface IMenuAddRequest {
  name: string
  restaurantId: string
}
export interface IMenuDeleteRequest {
  menuId: string
}

export interface IMenuUpdateRequest {
  menuId: string
  name: string
}

export interface IDishAddRequest {
  menuId: string
  dishId?: string
  chefId?: string
  name?: string
  price?: number
  ingredients?: string
  description?: string
  orderCount?: number
  tags?: string
  dietary?: string
  unsuitabledietary?: string
  preparation?: string
  imageurl?: string
}
export interface ICopyDishRequest {
  menuId: string
  dishId: string
}
export interface IDishDeleteRequest {
  dishId: string
}

export interface IDishAddImage {
  dishId: string
  file: Blob
}
export interface ILoadMenuRequest {
  id: string
  name: string
  url: string
}

export interface DiningReviewDTO {
  diningReviewId: string
  value: string
  restaurant: Restaurant
  eater: Eater
  dish: Dish
  sentimentLabel: string
  sentimentScore: number
  sentimentSummary: string
  sentimentTags: string
  created: string
  updated: string
}

export enum IMenuStatus {
  NEW = 'NEW',
  COMPLETED = 'COMPLETED',
  REQUESTED = 'REQUESTED',
  DELETED = 'DELETED',
}
export enum IDishStatus {
  NEW = 'NEW',
  COMPLETED = 'COMPLETED',
  DELETED = 'DELETED',
}
