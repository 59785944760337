import { Box, Typography } from '@mui/material'
import React from 'react'

type Height<TLength = (string & {}) | 0> =
  | TLength
  | '-moz-max-content'
  | '-moz-min-content'
  | '-webkit-fit-content'
  | 'auto'
  | 'fit-content'
  | 'max-content'
  | 'min-content'
  | (string & {})

type Width<TLength = (string & {}) | 0> =
  | TLength
  | '-moz-fit-content'
  | '-moz-max-content'
  | '-moz-min-content'
  | '-webkit-fit-content'
  | '-webkit-max-content'
  | 'auto'
  | 'fit-content'
  | 'intrinsic'
  | 'max-content'
  | 'min-content'
  | 'min-intrinsic'
  | (string & {})

interface NoDataFoundProps {
  height: Height
  width?: Width
}

const NoDataFound: React.FC<NoDataFoundProps> = ({
  height,
  width = '100%',
}) => {
  return (
    <Box
      display="flex"
      textAlign="center"
      justifyContent="center"
      alignItems="center"
      sx={{
        height,
        width,
      }}
    >
      <Typography variant="subtitle1" fontWeight={500}>
        No data found
      </Typography>
    </Box>
  )
}

export default NoDataFound
