import {
  Badge,
  Box,
  Button,
  Chip,
  Divider,
  Menu,
  Skeleton,
  Typography,
  useTheme,
} from '@mui/material'
import { IconPointFilled } from '@tabler/icons-react'
import styled from 'styled-components'
import CustomButton from '../../../../components/ui/CustomButton'

export const SocialGroupGroupRequestsContainer = styled('div').attrs(
  () => ({}),
)(() => ({ cursor: 'pointer' }))

export const SocialGroupInnerContainer = styled(Box).attrs(() => ({
  sx: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}))(() => ({}))

export const SocialGroupLeftContainer = styled(Box).attrs(() => ({
  sx: {
    paddingY: '24px',
    paddingLeft: '12px',
  },
}))(() => ({}))

export const SocialGroupTitleContainer = styled('div').attrs(() => ({}))(
  () => ({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  }),
)

export const SocialGroupTitleTypography = styled(Typography).attrs(() => {
  const theme = useTheme()
  return {
    variant: 'body1',
    maxWidth: { xs: '160px', sm: '350px', md: '400px' },
    noWrap: true,
    color: theme.palette.grey[800],
    textTransform: 'capitalize',
    sx: {
      paddingBottom: '2px',
      textTransform: 'capitalize',
    },
  }
})(() => ({}))

export const SocialGroupChip = styled(Chip).attrs(() => {
  const theme = useTheme()
  return {
    sx: {
      height: '24px',
      borderRadius: '6px',
      color: theme.palette.background.paper,
      background: `linear-gradient(324deg, #FF864C 9.14%, #FAAD6C 62.98%)`,
      border: `1px solid ${theme.palette.grey[200]}`,
      fontSize: '12px',
      ml: '10px',
    },
  }
})(() => ({}))

export const SocialGroupUserTitle = styled(Box).attrs(() => {
  const theme = useTheme()
  return {
    display: 'flex',
    justifyItems: 'center',
    alignItems: 'center',
    sx: { color: theme.palette.grey[400] },
  }
})(() => ({}))

export const SocialGroupUserCountTypography = styled(Typography).attrs(() => ({
  variant: 'body2',
  fontWeight: 500,
}))(() => ({
  padding: '0px 4px',
}))

export const SocialGroupIconPointFilled = styled(IconPointFilled).attrs(() => {
  const theme = useTheme()
  return {
    size: 12,
    color: theme.palette.grey[300],
  }
})(() => ({
  marginRight: '3px',
}))

export const SocialGroupMemberListTypography = styled(Typography).attrs(() => {
  const theme = useTheme()
  return {
    noWrap: true,
    variant: 'subtitle1',
    maxWidth: { xs: '160px', md: '100%' },
    sx: {
      display: 'flex',
      alignItems: 'center',
    },
    color: theme.palette.grey[400],
  }
})(() => ({}))

export const SocialGroupGroupRequestsRightContainer = styled(Box).attrs(() => ({
  sx: {
    display: 'flex',
    alignItems: 'center',
    width: '25rem',
    gap: '10px',
    marginRight: '10px',
  },
}))(() => ({}))

export const SocialGroupCustomButton = styled(CustomButton).attrs(() => {
  const theme = useTheme()
  return {
    fullWidth: true,
    textVariant: 'subtitle1',
    backgroundColor: theme.palette.background.paper,
    textColor: theme.palette.primary.light,
    hoverColor: theme.palette.primary.light,
    disabledTextColor: theme.palette.grey[400],
  }
})(() => {
  const theme = useTheme()
  return {
    borderColor: `${theme.palette.primary.light} !important`,
    paddingY: '8px',
  }
})

export const SocialGroupDivider = styled(Divider).attrs(() => ({}))(() => {
  const theme = useTheme()
  return {
    color: theme.palette.grey[200],
  }
})

export const SocialGroupBadge = styled(Badge).attrs(() => ({
  sx: {
    marginX: '20px',
  },
}))(() => {
  const theme = useTheme()
  return {
    '& .MuiBadge-badge': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.background.paper,
    },
  }
})

export const SocialGroupsRightContainer = styled(Box).attrs(() => ({
  sx: {
    display: 'flex',
    alignItems: 'center',
  },
}))(() => ({}))

export const SocialGroupsRightInnerContainer = styled(Box).attrs(() => ({
  sx: {
    paddingLeft: '40px',
    paddingRight: '8px',
  },
}))(() => {
  const theme = useTheme()
  return {
    color: theme.palette.grey[800],
  }
})

export const SocialGroupsIconDotsVerticalButton = styled(Button).attrs(() => ({
  sx: {
    minWidth: '40px',
    cursor: 'pointer',
  },
}))(() => ({}))

export const SocialGroupSkeleton = styled(Skeleton).attrs(() => ({
  variant: 'rounded',
  height: 98,
  sx: { my: '16px' },
}))(() => ({}))

export const SocialGroupMenu = styled(Menu).attrs(() => ({
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
  MenuListProps: {
    'aria-labelledby': 'basic-button',
  },
  sx: {
    '& .MuiPaper-root': {
      boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px;',
      borderRadius: '10px',
    },
    p: 1,
  },
}))(() => {
  const theme = useTheme()

  return {
    color: theme.palette.grey[800],
  }
})

export const SocialGroupMenuItemTypography = styled(Typography).attrs(() => ({
  variant: 'subtitle2',
  sx: { p: 1 },
}))(() => ({}))

export const SocialGroupBasicModalTitleTypography = styled(Typography).attrs(
  () => ({
    variant: 'subtitle1',
    mb: '6px',
    mt: '16px',
  }),
)(() => ({}))


