import { Restaurant } from '../types'
import { Dish } from '../types/menuTypes'

export const getAllRestaurantDishes = (restaurant: Restaurant): Dish[] => {
  return (
    restaurant?.menus
      ?.filter(
        (menu) => menu.status !== 'DELETED' && menu.status !== 'REQUESTED',
      )
      .flatMap((menu) =>
        menu.dishes.filter(
          (dish) => dish.status !== 'DELETED' && dish.status !== 'REQUESTED',
        ),
      ) ?? []
  )
}
