import { Chip, CircularProgress } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import {
  OnboardPage,
  onboardStepTypes,
} from '../../../../helpers/onboard-helper'
import useAppUtilities from '../../../../hooks/useAppUtilities'
import { RootState, useAppSelector } from '../../../../store'
import {
  selectIsLoading,
  selectMessage,
  selectOnboardPayload,
  selectSeverity,
  selectShouldOpen,
  setDiningFrequency,
  setOpen,
  setSocialLinks,
  updateEaterProfile,
} from '../../../../store/onboardSlice'
import {
  selectEater,
  selectIsEditCancelledData,
  selectIsProfileEdit,
  selectIsUpdateData,
  setIsEditCancelledData,
  setIsProfileEdit,
  setIsUpdateData,
} from '../../../../store/profileSlice'
import { selectIsMobileSearch } from '../../../../store/searchSlice'
import {
  EaterPayload,
  OnboardDiningFrequencyRefMethods,
  OnboardSelectionMethods,
  OnboardSocialRefMethods,
  PersonalFavoriteMethods,
  StepsPayloadMap,
} from '../../../../types'
import OnboardDiningFrequency from '../../onboardPages/OnboardDiningFrequency'
import OnboardPersonalFavorites from '../../onboardPages/OnboardPersonalFavorites'
import OnboardPreferences from '../../onboardPages/OnboardPreferences'
import OnboardSocial from '../../onboardPages/OnboardSocial'
import { MuiAlert, MuiBackdrop, MuiSnackbar } from '../../styled/global.styled'
import {
  UserPreferencesContentGridContainer,
  UserPreferencesContentInnerContainer,
  UserPreferencesGridContainer,
  UserPreferencesSidebarGridContainer,
  UserPreferencesSidebarInnerContainer,
  UserPreferencesStepContainer,
  UserPreferencesStepsTypography,
} from '../styled/Preferences.styled'

const Preferences: React.FC = (): JSX.Element => {
  const { theme, dispatch, isMediumScreen } = useAppUtilities()
  const message = useAppSelector(selectMessage)
  const userDetail = useAppSelector(selectEater)
  const severity = useAppSelector(selectSeverity)
  const isLoading = useAppSelector(selectIsLoading)
  const openServer = useAppSelector(selectShouldOpen)
  const isProfileEdit = useAppSelector(selectIsProfileEdit)
  const isProfileUpdate = useAppSelector(selectIsUpdateData)
  const isMobileSearch = useAppSelector(selectIsMobileSearch)
  const onboardPayload = useAppSelector(selectOnboardPayload)
  const isProfileCancel = useAppSelector(selectIsEditCancelledData)
  const isEdit = useAppSelector((state: RootState) =>
    selectIsProfileEdit(state),
  )

  const [activeStep, setActiveStep] = useState(eSteps[0])
  const [isUpdate, setIsUpdate] = useState(!isProfileEdit)
  const [stepsPayloads, setStepsPayloads] =
    useState<StepsPayloadMap>(onboardPayload)

  const itemListRef = useRef<OnboardSelectionMethods>(null)
  const personalFavoriteRef = useRef<PersonalFavoriteMethods>(null)
  const onboardSocialRef = useRef<OnboardSocialRefMethods>(null)
  const onboardDiningFrequencyRef =
    useRef<OnboardDiningFrequencyRefMethods>(null)

  const changeStep = (step: any): void => {
    dispatch(setIsProfileEdit(false))
    setActiveStep(step)
  }

  useEffect(() => {
    onPreferences()
  }, [])

  useEffect(() => {
    setIsUpdate(!isProfileEdit)
  }, [isProfileEdit])

  useEffect(() => {
    if (isProfileUpdate) {
      handleNext()
        .then(() => {
          dispatch(setIsProfileEdit(false))
        })
        .catch(() => {})
        .finally(() => {
          dispatch(setIsUpdateData(false))
        })
    }
    if (isProfileCancel) {
      onPreferences()
      dispatch(setIsEditCancelledData(false))
    }
  }, [isProfileUpdate, isProfileCancel])

  const onPreferences = (): void => {
    if (userDetail) {
      const data = steps.reduce((acc, { step, likeKey, dislikeKey }) => {
        acc[step] = {
          currentStep: step,
          nextStep: step + 1,
          ...(step !== 10 && {
            likedItems:
              userDetail && userDetail[likeKey]
                ? userDetail[likeKey].split(',')
                : [],
          }),
          ...(step !== 10 && {
            dislikedItems:
              dislikeKey && userDetail && userDetail[dislikeKey]
                ? userDetail[dislikeKey].split(',')
                : [],
          }),
          ...(step === 10 && {
            foodReviewerItems:
              userDetail && userDetail[likeKey]
                ? userDetail[likeKey].split(',')
                : [],
          }),
          ...(step === 10 && {
            favoritesRestaurantItems:
              dislikeKey && userDetail && userDetail[dislikeKey]
                ? userDetail[dislikeKey].split(',')
                : [],
          }),
        }
        return acc
      }, {})

      const words = userDetail.diningFrequency
        ? userDetail?.diningFrequency?.split(' ')
        : ''
      if (words && words.length) {
        dispatch(
          setDiningFrequency({
            number: Number(words[0]),
            frequency: words[words.length - 1].toUpperCase(),
          }),
        )
      }

      const socialLinksData = {
        facebook: userDetail?.facebook ?? '',
        instagram: userDetail?.instagram ?? '',
        tiktok: userDetail?.tiktok ?? '',
        x: userDetail?.x ?? '',
      }

      dispatch(setSocialLinks(socialLinksData))

      setStepsPayloads(data)
    }
  }

  const handleNext = async (): Promise<void> => {
    const payload: EaterPayload = {
      page: OnboardPage[activeStep.page],
    }

    switch (activeStep.step) {
      case onboardStepTypes.INGREDIENTS:
      case onboardStepTypes.CUISINES:
      case onboardStepTypes.FOOD_PREPARATIONS:
      case onboardStepTypes.DINING_AMBIENCE:
      case onboardStepTypes.DIETARY_PREFERENCE_RESTRICTION:
      case onboardStepTypes.ALLERGIES:
        if (itemListRef.current) {
          const { likedItems, dislikedItems } = itemListRef.current.getPayload()
          const updatedPayload = {
            currentStep: activeStep.step,
            likedItems,
            dislikedItems,
          }
          setStepsPayloads((prevPayloads) => ({
            ...prevPayloads,
            [activeStep.step]: updatedPayload,
          }))
          switch (activeStep.step) {
            case onboardStepTypes.DIETARY_PREFERENCE_RESTRICTION:
            case onboardStepTypes.ALLERGIES:
              payload.value = likedItems
              break
            default:
              payload.likes = likedItems
              payload.dislikes = dislikedItems
              break
          }
        }
        break

      case onboardStepTypes.PERSONAL_FAVORITES:
        if (personalFavoriteRef.current) {
          const { favoritesRestaurantItems, foodReviewerItems } =
            personalFavoriteRef.current.getPayload()

          const updatedPayload = {
            currentStep: activeStep.step,
            favoritesRestaurantItems,
            foodReviewerItems,
          }

          setStepsPayloads((prevPayloads) => ({
            ...prevPayloads,
            [activeStep.step]: updatedPayload,
          }))

          payload.favorites = {
            reviewers: foodReviewerItems,
            restaurants: favoritesRestaurantItems,
          }
        }
        break
      case onboardStepTypes.SOCIAL:
        if (onboardSocialRef.current) {
          const socialValue = onboardSocialRef.current.getPayload()
          payload.socialValue = socialValue
        }
        break

      case onboardStepTypes.DINING_FREQUENCY:
        if (onboardDiningFrequencyRef.current) {
          const diningFrequency = onboardDiningFrequencyRef.current.getPayload()
          payload.diningFrequency = diningFrequency
        }
        break

      default:
        return
    }

    await dispatch(updateEaterProfile(payload))
  }

  const setOpenServer = (): void => {
    dispatch(setOpen(false))
  }

  return (
    <>
      <UserPreferencesGridContainer>
        <UserPreferencesSidebarGridContainer>
          {isMediumScreen ? (
            <UserPreferencesStepContainer className="hide-scrollbar">
              {eSteps.map((step, index) => (
                // TODO: this chip need to convert to styled component
                <Chip
                  key={index}
                  onClick={() => changeStep(step)}
                  sx={{
                    height: '24px',
                    borderRadius: '6px',
                    cursor: 'pointer',
                    color:
                      activeStep.step === step.step
                        ? theme.palette.secondary.dark
                        : 'black',
                    backgroundColor:
                      activeStep.step === step.step
                        ? theme.palette.secondary[50]
                        : theme.palette.grey[50],
                    border: `1px solid ${
                      (activeStep.step === step.step
                        ? theme.palette.secondary.dark
                        : theme.palette.grey[200]) ?? ''
                    }`,
                    fontSize: '12px',
                  }}
                  label={step.componentTitle}
                />
              ))}
            </UserPreferencesStepContainer>
          ) : (
            <UserPreferencesSidebarInnerContainer>
              {eSteps.map((step, index) => (
                <UserPreferencesStepsTypography
                  key={index}
                  color={
                    activeStep.step === step.step
                      ? theme.palette.secondary.dark
                      : 'black'
                  }
                  onClick={() => changeStep(step)}
                >{`${index + 1}. ${
                  step.title
                }`}</UserPreferencesStepsTypography>
              ))}
            </UserPreferencesSidebarInnerContainer>
          )}
        </UserPreferencesSidebarGridContainer>
        <UserPreferencesContentGridContainer>
          <UserPreferencesContentInnerContainer
            isSearchOpen={isMobileSearch}
            isEdit={isEdit}
          >
            {activeStep.step === onboardStepTypes.INGREDIENTS && (
              <OnboardPreferences
                ref={itemListRef}
                key={onboardStepTypes.INGREDIENTS}
                title={
                  isMediumScreen ? undefined : 'What Ingredients do you like?'
                }
                subTitle="Likes"
                secondSubTitle="Dislikes"
                listName="ingredients"
                indexKey="REACT_APP_INGREDIENTS_INDEX"
                liked={
                  stepsPayloads?.[onboardStepTypes.INGREDIENTS]?.likedItems ??
                  []
                }
                dislike={
                  stepsPayloads?.[onboardStepTypes.INGREDIENTS]
                    ?.dislikedItems ?? []
                }
                isEditPreference={isUpdate}
                alignItems={isMediumScreen ? 'center' : 'left'}
              />
            )}
            {activeStep.step === onboardStepTypes.CUISINES && (
              <OnboardPreferences
                ref={itemListRef}
                key={onboardStepTypes.CUISINES}
                title={
                  isMediumScreen ? undefined : 'What Cuisines do you like?'
                }
                subTitle="Likes"
                secondSubTitle="Dislikes"
                listName="cuisines"
                liked={
                  stepsPayloads?.[onboardStepTypes.CUISINES]?.likedItems ?? []
                }
                dislike={
                  stepsPayloads?.[onboardStepTypes.CUISINES]?.dislikedItems ??
                  []
                }
                indexKey="REACT_APP_CUISINES_INDEX"
                isEditPreference={isUpdate}
                alignItems={isMediumScreen ? 'center' : 'left'}
              />
            )}
            {activeStep.step === onboardStepTypes.FOOD_PREPARATIONS && (
              <OnboardPreferences
                ref={itemListRef}
                key={onboardStepTypes.FOOD_PREPARATIONS}
                title={isMediumScreen ? undefined : 'Food Preparations'}
                listName="foodPreparations"
                subTitle="Likes"
                secondSubTitle="Dislikes"
                indexKey="REACT_APP_FOOD_PREPARATION_INDEX"
                liked={
                  stepsPayloads?.[onboardStepTypes.FOOD_PREPARATIONS]
                    ?.likedItems ?? []
                }
                dislike={
                  stepsPayloads?.[onboardStepTypes.FOOD_PREPARATIONS]
                    ?.dislikedItems ?? []
                }
                isEditPreference={isUpdate}
                alignItems={isMediumScreen ? 'center' : 'left'}
              />
            )}
            {activeStep.step === onboardStepTypes.DINING_AMBIENCE && (
              <OnboardPreferences
                ref={itemListRef}
                key={onboardStepTypes.DINING_AMBIENCE}
                title={
                  isMediumScreen
                    ? undefined
                    : 'What Dining Ambience do you like?'
                }
                subTitle="Likes"
                secondSubTitle="Dislikes"
                listName="ambience"
                liked={
                  stepsPayloads?.[onboardStepTypes.DINING_AMBIENCE]
                    ?.likedItems ?? []
                }
                dislike={
                  stepsPayloads?.[onboardStepTypes.DINING_AMBIENCE]
                    ?.dislikedItems ?? []
                }
                indexKey="REACT_APP_AMBIENCE_INDEX"
                isEditPreference={isUpdate}
                alignItems={isMediumScreen ? 'center' : 'left'}
              />
            )}
            {activeStep.step ===
              onboardStepTypes.DIETARY_PREFERENCE_RESTRICTION && (
              <OnboardPreferences
                ref={itemListRef}
                key={onboardStepTypes.DIETARY_PREFERENCE_RESTRICTION}
                title={
                  isMediumScreen
                    ? undefined
                    : 'Dietary Preferences and Restrictions'
                }
                listName="dietaryPreferences"
                liked={
                  stepsPayloads?.[
                    onboardStepTypes.DIETARY_PREFERENCE_RESTRICTION
                  ]?.likedItems ?? []
                }
                indexKey="REACT_APP_DIETARY_PREFERENCES_INDEX"
                isEditPreference={isUpdate}
                alignItems={isMediumScreen ? 'center' : 'left'}
              />
            )}
            {activeStep.step === onboardStepTypes.ALLERGIES && (
              <OnboardPreferences
                ref={itemListRef}
                key={onboardStepTypes.ALLERGIES}
                title={
                  isMediumScreen ? undefined : 'What Allergies do you have?'
                }
                listName="allergies"
                liked={
                  stepsPayloads?.[onboardStepTypes.ALLERGIES]?.likedItems ?? []
                }
                indexKey="REACT_APP_ALLERGIES_INDEX"
                isEditPreference={isUpdate}
                alignItems={isMediumScreen ? 'center' : 'left'}
              />
            )}
            {activeStep.step === onboardStepTypes.DINING_FREQUENCY && (
              <OnboardDiningFrequency
                ref={onboardDiningFrequencyRef}
                title={isMediumScreen ? undefined : 'Add your dining frequency'}
                isEditPreference={isUpdate}
                alignItems={isMediumScreen ? 'center' : 'left'}
              />
            )}
            {activeStep.step === onboardStepTypes.PERSONAL_FAVORITES && (
              <OnboardPersonalFavorites
                ref={personalFavoriteRef}
                key={onboardStepTypes.PERSONAL_FAVORITES}
                title={isMediumScreen ? undefined : 'Personal Favorites'}
                subTitle="What are your favorite restaurants?"
                secondSubTitle="What are your favorite reviewers?"
                firstListName="favoritesRestaurant"
                secondListName="foodReviewers"
                foodReviewers={
                  stepsPayloads?.[onboardStepTypes.PERSONAL_FAVORITES]
                    ?.foodReviewerItems ?? []
                }
                favoritesRestaurant={
                  stepsPayloads?.[onboardStepTypes.PERSONAL_FAVORITES]
                    ?.favoritesRestaurantItems ?? []
                }
                firstIndexKey="REACT_APP_RESTAURANT_INDEX"
                secondIndexKey="REACT_APP_FAVORITE_REVIEWERS_INDEX"
                isEditPreference={isUpdate}
                alignItems={isMediumScreen ? 'center' : 'left'}
              />
            )}
            {activeStep.step === onboardStepTypes.SOCIAL && (
              <OnboardSocial
                isEditPreference={isUpdate}
                ref={onboardSocialRef}
                title={
                  isMediumScreen ? undefined : 'Add your social media links'
                }
                alignItems={isMediumScreen ? 'center' : 'left'}
              />
            )}
          </UserPreferencesContentInnerContainer>
        </UserPreferencesContentGridContainer>
      </UserPreferencesGridContainer>
      <MuiBackdrop open={isLoading}>
        <CircularProgress color="inherit" />
      </MuiBackdrop>
      <MuiSnackbar open={openServer} onClose={setOpenServer}>
        <MuiAlert onClose={setOpenServer} severity={severity ?? 'success'}>
          {message}
        </MuiAlert>
      </MuiSnackbar>
    </>
  )
}

export default Preferences

const eSteps = [
  {
    step: onboardStepTypes.INGREDIENTS,
    title: 'What Ingredients do you like?',
    path: 'ingredients',
    page: 'INGREDIENTS',
    componentTitle: 'Ingredients',
  },
  {
    step: onboardStepTypes.CUISINES,
    title: 'What Cuisines do you like?',
    path: 'cuisines',
    page: 'CUISINES',
    componentTitle: 'Cuisines',
  },
  {
    step: onboardStepTypes.FOOD_PREPARATIONS,
    title: 'Food Preparations',
    path: 'food-preparations',
    page: 'PREPARATIONS',
    componentTitle: 'Food Preparations',
  },
  {
    step: onboardStepTypes.DINING_AMBIENCE,
    title: 'What Dining Ambience do you like?',
    path: 'dining-ambience',
    page: 'AMBIENCE',
    componentTitle: 'Ambience',
  },
  {
    step: onboardStepTypes.DIETARY_PREFERENCE_RESTRICTION,
    title: 'Dietary Preferences and Restrictions',
    path: 'dietary-preferences',
    page: 'DIETARY_PREFERENCES',
    componentTitle: 'Dietary',
  },

  {
    step: onboardStepTypes.ALLERGIES,
    title: 'What Allergies do you have?',
    path: 'allergies',
    page: 'ALLERGIES',
    componentTitle: 'Allergies',
  },
  {
    step: onboardStepTypes.DINING_FREQUENCY,
    title: 'Dining Frequency',
    path: 'dining-frequency',
    page: 'DINING_FREQUENCY',
    componentTitle: 'Dining Frequency',
  },
  {
    step: onboardStepTypes.PERSONAL_FAVORITES,
    title: 'Personal Favorites',
    path: 'favorites',
    page: 'FAVORITES',
    componentTitle: 'Personals',
  },
  {
    step: onboardStepTypes.SOCIAL,
    title: 'Social',
    path: 'social-media',
    page: 'SOCIAL_MEDIA',
    componentTitle: 'Social',
  },
]

const steps = [
  {
    step: 3,
    likeKey: 'ingredientLikes',
    dislikeKey: 'ingredientDislikes',
  },
  { step: 4, likeKey: 'cuisineLikes', dislikeKey: 'cuisineDislikes' },
  {
    step: 5,
    likeKey: 'foodPreparationLiked',
    dislikeKey: 'foodPreparationDisliked',
  },
  { step: 6, likeKey: 'ambienceLiked', dislikeKey: 'ambienceDisliked' },
  { step: 7, likeKey: 'dietaryPreferences', dislikeKey: null },
  { step: 8, likeKey: 'allergies', dislikeKey: null },
  {
    step: 10,
    likeKey: 'favoriteReviewers',
    dislikeKey: 'favoriteRestaurants',
  },
]
