import { Box, IconButton, Link as MuiLink } from '@mui/material'
import { IconChevronsLeft, IconChevronsRight } from '@tabler/icons-react'
import React from 'react'
import { Link } from 'react-router-dom'
import useAppUtilities from '../../hooks/useAppUtilities'

interface RestaurantLayoutHeaderWebProps {
  isDrawerOpen: boolean
  setIsDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const RestaurantLayoutHeaderWeb: React.FC<RestaurantLayoutHeaderWebProps> = ({
  isDrawerOpen,
  setIsDrawerOpen,
}) => {
  const { theme } = useAppUtilities()

  const handleDrawerOpen = (): void => {
    setIsDrawerOpen(true)
  }

  const handleDrawerClose = (): void => {
    setIsDrawerOpen(false)
  }

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      {isDrawerOpen ? (
        <Box
          component="div"
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            transition: 'all 0.3s ease',
          }}
        >
          <MuiLink component={Link} to="/">
            <img
              src="/logo.svg"
              style={{
                height: '56px',
                cursor: 'pointer',
                transition: 'height 0.3s ease',
              }}
              loading="lazy"
              alt="Resactly-Logo"
            />
          </MuiLink>
          <IconButton
            onClick={handleDrawerClose}
            style={{
              border: `2px solid ${theme.palette.primary[900] as string}`,
              height: '30px',
              width: '30px',
              padding: '0px',
              alignSelf: 'start',
              transition: 'border-color 0.3s ease',
            }}
          >
            <IconChevronsLeft
              stroke={2}
              height={'20px'}
              width={'20px'}
              style={{
                margin: 'auto',
                color: theme.palette.background.paper,
                transition: 'color 0.3s ease',
              }}
            />
          </IconButton>
        </Box>
      ) : (
        <IconButton
          onClick={handleDrawerOpen}
          style={{
            border: `2px solid ${theme.palette.primary[900] as string}`,
            height: '30px',
            width: '30px',
            padding: '0px',
            marginBottom: '24px',
            transition: 'border-color 0.3s ease',
          }}
        >
          <IconChevronsRight
            stroke={2}
            height={'20px'}
            width={'20px'}
            style={{
              margin: 'auto',
              color: theme.palette.background.paper,
              transition: 'color 0.3s ease',
            }}
          />
        </IconButton>
      )}
    </Box>
  )
}

export default RestaurantLayoutHeaderWeb
