import { SearchBox, SearchProvider, withSearch } from '@elastic/react-search-ui'
import '@elastic/react-search-ui-views/lib/styles/styles.css'
import { SearchResult } from '@elastic/search-ui'
import ElasticsearchAPIConnector from '@elastic/search-ui-elasticsearch-connector'
import { Box, TextField } from '@mui/material'
import React from 'react'
import { OnboardSearchContainer } from '../../pages/eater/onboardPages/styled/OnboardSearch.styled'
import { useAppSelector } from '../../store'
import { selectEater } from '../../store/profileSlice'
import { Member } from '../../types/groupTypes'
import { NavbarSearchAutocompleteViewInnerContainer } from '../navBar/styled/SearchStyled'
import {
  UserEmailAutocompleteViewListDetailBox,
  UserEmailAutocompleteViewListDetailImage,
  UserEmailAutocompleteViewListDetailSubTypography,
  UserEmailAutocompleteViewListDetailTypography,
  UserEmailAutocompleteViewMainBox,
  UserEmailAutocompleteViewSecondBox,
  UserEmailOptionDialogChip,
  UserEmailOptionDialogDetailBox,
  UserEmailOptionDialogDetailIcon,
  UserEmailOptionDialogDetailTypography,
  UserEmailOptionDialogMainBox,
} from '../styled/social/SocialUserEmailSearch.styled'

interface AutocompleteProps {
  autocompletedResults?: SearchResult[]
  searchTerm: string
  reset: () => void
  searchResults: SearchResult[]
  isLoading: boolean
}

interface SocialUserEmailSearchProps {
  memberList: Member[]
  setMemberList: React.Dispatch<React.SetStateAction<Member[]>>
  type?: 'create' | 'update'
  isEmailValid: boolean
}

const SocialUserEmailSearch: React.FC<SocialUserEmailSearchProps> = ({
  memberList,
  setMemberList,
  type = 'create',
  isEmailValid,
}): JSX.Element => {
  const userDetail = useAppSelector(selectEater)
  const connector = new ElasticsearchAPIConnector({
    cloud: {
      id: process.env.REACT_APP_ELASTIC_CLOUD_ID ?? '',
    },
    apiKey: process.env.REACT_APP_ELASTIC_API_KEY ?? '',
    index: process.env.REACT_APP_EATER_INDEX ?? '',
  })

  const config = {
    trackUrlState: false,
    searchQuery: {
      search_fields: {
        email: {
          weight: 5,
        },
        firstName: {
          weight: 5,
        },
        lastName: {
          weight: 5,
        },
        phone: {
          weight: 1,
        },
        facebook: {
          weight: 2,
        },
        tiktok: {
          weight: 2,
        },
        instagram: {
          weight: 2,
        },
      },
      result_fields: {
        firstName: {
          snippet: {},
        },
        lastName: {
          snippet: {},
        },
        phone: {},
        email: {},
        photo: {},
        eaterId: {},
      },
    },
    autocompleteQuery: {
      results: {
        search_fields: {
          email: {
            weight: 5,
          },
          firstName: {
            weight: 5,
          },
          lastName: {
            weight: 5,
          },
          phone: {
            weight: 1,
          },
          facebook: {
            weight: 2,
          },
          tiktok: {
            weight: 2,
          },
          instagram: {
            weight: 2,
          },
        },
        result_fields: {
          email: {},
          phone: {},
          firstName: {},
          lastName: {},
          photo: {},
          eaterId: {},
        },
      },
    },
    apiConnector: connector,
  }

  const removeUser = (idx: number): void => {
    setMemberList(memberList.filter((_, index) => index !== idx))
  }

  const addEmail = (user: Member): void => {
    if (
      (memberList.length < 1 &&
        type === 'update' &&
        user &&
        user.eaterId !== userDetail?.eaterId &&
        !memberList.find((member) => member.eaterId === user.eaterId)) ||
      (type === 'create' &&
        user &&
        user.eaterId !== userDetail?.eaterId &&
        !memberList.find((member) => member.eaterId === user.eaterId))
    ) {
      setMemberList([...memberList, user])
    }
  }

  const AutoCompleteResults = ({
    autocompletedResults,
    searchTerm,
    reset,
  }: AutocompleteProps): JSX.Element => {
    const addExistingValue = (selection: any): void => {
      addEmail({
        name: `${selection.firstName.raw as string} ${
          selection.lastName.raw as string
        }`,
        email: selection.email.raw ?? '',
        phone: selection.phone.raw ?? '',
        eaterId: selection.eaterId.raw ?? '',
      })
      type === 'create' && reset()
    }
    return (
      <OnboardSearchContainer
        customWidth={'386px'}
        style={{
          flexGrow: 1,
        }}
      >
        <SearchBox
          className="main-autocomplete-search-container main-autocomplete-search-position"
          autocompleteResults={true}
          autocompleteSuggestions={true}
          debounceLength={1000}
          autocompleteMinimumCharacters={1}
          onSelectAutocomplete={(selection, options) => {
            addExistingValue(selection)
          }}
          autocompleteView={({
            autocompletedResults,
            getItemProps,
            getMenuProps,
          }) => {
            const data = autocompletedResults.filter(
              (item) => item.eaterId.raw !== userDetail?.eaterId,
            )
            return (
              <>
                {data.length > 0 && (
                  <UserEmailAutocompleteViewMainBox
                    {...getMenuProps({ className: 'autocomplete-menu' })}
                  >
                    <UserEmailAutocompleteViewSecondBox>
                      {data.map((result, i) => {
                        const itemProps = getItemProps({
                          item: result,
                          index: i,
                          key: result.id.raw,
                        })
                        const { key, ...restItemProps } = itemProps
                        return (
                          <NavbarSearchAutocompleteViewInnerContainer
                            key={result.id.raw}
                            id="MuiInputBase-input-MuiOutlinedInput-input-check"
                            {...restItemProps}
                            style={{
                              width: '100%',
                            }}
                          >
                            <UserEmailAutocompleteViewListDetailBox>
                              <UserEmailAutocompleteViewListDetailImage
                                src={result?.photo?.raw}
                              />
                              <Box pl="10px">
                                <UserEmailAutocompleteViewListDetailTypography>
                                  {`${result?.firstName?.raw as string} ${
                                    result?.lastName?.raw as string
                                  }`}
                                </UserEmailAutocompleteViewListDetailTypography>
                                <UserEmailAutocompleteViewListDetailSubTypography>
                                  {result?.email?.raw}
                                </UserEmailAutocompleteViewListDetailSubTypography>
                              </Box>
                            </UserEmailAutocompleteViewListDetailBox>
                          </NavbarSearchAutocompleteViewInnerContainer>
                        )
                      })}
                    </UserEmailAutocompleteViewSecondBox>
                  </UserEmailAutocompleteViewMainBox>
                )}
              </>
            )
          }}
          inputView={({ getAutocomplete, getInputProps, getButtonProps }) => {
            const inputProps = getInputProps({
              placeholder: '',
            })

            return (
              <>
                <div style={{ width: '100%', flexGrow: 1 }}>
                  <TextField
                    {...inputProps}
                    autoFocus
                    fullWidth
                    sx={{
                      flexGrow: 1,
                      '&.sui-search-box__text-input': {
                        boxShadow: 'none !important',
                      },
                      '& .MuiInput-root': {
                        padding: '5px',
                        fontFamily: "'General Sans', sans-serif",
                        fontSize: '16px',
                        lineHeight: '16px',
                        fontWeight: 500,
                      },
                    }}
                    variant="standard"
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />

                  {getAutocomplete()}
                </div>
                <input
                  {...getButtonProps({
                    className: 'member-search',
                  })}
                  style={{
                    display: 'none',
                  }}
                />
              </>
            )
          }}
        />
      </OnboardSearchContainer>
    )
  }

  const EnhancedAutoCompleteResults = withSearch(
    ({ autocompletedResults, searchTerm, reset, isLoading }) => ({
      autocompletedResults,
      searchTerm,
      reset,
      isLoading,
    }),
  )(AutoCompleteResults)

  return (
    <UserEmailOptionDialogMainBox isHeight={type === 'update'}>
      {type === 'create' && (
        <>
          {memberList.map((user, index) => (
            <UserEmailOptionDialogChip
              key={index}
              label={user.email}
              onDelete={() => removeUser(index)}
            />
          ))}
        </>
      )}

      {memberList.length > 0 && type === 'update' && (
        <UserEmailOptionDialogDetailBox>
          <UserEmailOptionDialogDetailTypography>
            {memberList?.[0]?.email}
          </UserEmailOptionDialogDetailTypography>
          <UserEmailOptionDialogDetailIcon
            onClick={() => {
              removeUser(0)
            }}
          />
        </UserEmailOptionDialogDetailBox>
      )}

      {(type === 'create' || (memberList.length <= 0 && type === 'update')) && (
        <SearchProvider config={config}>
          <EnhancedAutoCompleteResults />
        </SearchProvider>
      )}
    </UserEmailOptionDialogMainBox>
  )
}

export default React.memo(SocialUserEmailSearch)
