import { Box, Typography, useTheme } from '@mui/material'
import styled from 'styled-components'

export const StyledAddMenuBox = styled(Box).attrs(() => {
  const theme = useTheme()
  return {
    sx: {
      padding: '6px',
      border: 1.5,
      borderRadius: '12px',
      borderStyle: 'dashed',
      borderColor: theme.palette.grey[300],
      backgroundColor: 'transparent',
      height: { xs: '70vw', sm: '47vw', md: '28vw', lg: '21vw' },
      maxHeight: { xs: '216px', md: '250px' },
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
    },
  }
})(() => ({}))

export const AddMenuTypography = styled(Typography).attrs(() => {
  const theme = useTheme()
  return {
    noWrap: true,
    variant: 'subtitle1',
    color: theme.palette.grey[400],
    fontWeight: 600,
    sx: { marginTop: '8px' },
  }
})(() => ({}))
