import { Box, FormControl, Grid, TextField, useTheme } from '@mui/material'
import styled from 'styled-components'

export const OnboardDiningFrequencyWrapper = styled(Box).attrs(() => ({
  component: 'form',
  sx: {
    margin: 'auto',
    marginTop: '80px',
  },
}))(({ theme }) => ({}))

export const OnboardDiningFrequencyGridContainer = styled(Grid).attrs(() => ({
  container: true,
  spacing: 5,
  rowSpacing: 2,
}))(({ theme }) => ({}))

export const OnboardDiningFrequencyInnerGrid = styled(Grid).attrs(() => ({
  item: true,
  xs: 12,
  sm: 6,
}))(({ theme }) => ({}))

export const OnboardDiningFrequencyTextField = styled(TextField).attrs(() => ({
  type: 'number',
  variant: 'outlined',
  size: 'small',
}))(() => {
  const theme = useTheme()
  return {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      fontSize: '16px',
      fontWeight: 500,
      '& fieldset': {
        borderColor: theme.palette.grey[300],
        borderRadius: '8px',
      },
      '&:hover fieldset': {
        borderColor: theme.palette.grey[300],
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.grey[300],
      },
    },
  }
})

export const OnboardDiningFrequencyFormControl = styled(FormControl).attrs(
  () => ({}),
)(() => {
  const theme = useTheme()
  return {
    width: '100%',
    fontSize: '16px',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.grey[300],
        borderRadius: '8px',
      },
      '&:hover fieldset': {
        borderColor: theme.palette.grey[300],
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.grey[300],
      },
    },
  }
})
