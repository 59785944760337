import { Box } from '@mui/material'
import React from 'react'
import InfoCircleTooltip from '../../../../../components/dashboard/InfoCircleTooltip'
import { SquareAvatar } from '../../../../../components/styled/Avatar.styled'
import {
  TableBodyCell,
  TableBodyRow,
} from '../../../../../components/styled/Table.styled'
import AvatarTableCell from '../../../../../components/table/AvatarTableCell'
import RestaurantTable from '../../../../../components/table/RestaurantTable'
import TextTableCell from '../../../../../components/table/TextTableCell'
import { DEFAULT_CARD_IMAGE } from '../../../../../helpers/static-constants'
import { useAppSelector } from '../../../../../store'
import {
  selectIsLoading,
  selectPricing,
} from '../../../../../store/restaurant/dashboard'
import {
  HighestPriceBox,
  SubOneTypography,
} from '../../styled/competitorInsights/PricingTabTable.styled'

interface Column {
  id: string
  label: string
  minWidth?: string
  maxWidth?: string
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify'
  format?: (value: number) => string
  type?: string
  width?: string
}

const columns: readonly Column[] = [
  { id: '2', label: 'Your Dishes', minWidth: '300px' },
  { id: '3', label: 'Menu Name', minWidth: '150px' },
  { id: '4', label: 'Your Price', minWidth: '110px' },
  { id: '5', label: 'avg. price', minWidth: '110px' },
  { id: '6', label: 'highest price', minWidth: '160px', maxWidth: '170px' },
]

const PricingTab: React.FC = () => {
  const isLoading = useAppSelector(selectIsLoading)
  const pricingList = useAppSelector(selectPricing)
  return (
    <RestaurantTable
      columns={columns}
      data={pricingList ?? []}
      isLoading={isLoading}
      noBorder={true}
      maxHeight={346}
      renderRow={(row, index) => (
        <TableBodyRow key={index}>
          <AvatarTableCell
            imageUrl={row?.dishDTO?.imageurl}
            primaryText={row?.dishDTO?.name}
            maxWidth={columns?.[0].maxWidth}
            minWidth={columns?.[0].minWidth}
          />

          <TextTableCell text={row.menuName} />
          <TextTableCell
            text={
              row?.dishDTO?.price && row.dishDTO.price > 0
                ? `$ ${row?.dishDTO?.price?.toFixed(2)}`
                : '-'
            }
          />
          <TextTableCell
            text={
              row?.avgPrice && row.avgPrice > 0
                ? `$ ${row.avgPrice?.toFixed(2)}`
                : '-'
            }
          />
          <TableBodyCell
            align="left"
            sx={{
              maxWidth: columns?.[4].maxWidth,
              minWidth: columns?.[4].minWidth,
            }}
          >
            <HighestPriceBox>
              <SubOneTypography>
                {row?.highestPrice && row.highestPrice > 0
                  ? `$ ${row.highestPrice?.toFixed(2)}`
                  : '-'}
              </SubOneTypography>
              <InfoCircleTooltip
                data={
                  <Box display="flex" alignItems="center" width="100%">
                    <SquareAvatar
                      src={
                        row?.highestPricedRestaurant?.imageurl ??
                        DEFAULT_CARD_IMAGE
                      }
                      size="32px"
                      name={`${row?.highestPricedRestaurant?.name}-restaurant`}
                    />
                    <Box>
                      <SubOneTypography noWrap pl="14px">
                        {row?.highestPricedRestaurant?.name}
                      </SubOneTypography>
                      <SubOneTypography noWrap pl="14px">
                        {row?.highestPricedRestaurant?.address1 ?? 'New York'}
                      </SubOneTypography>
                    </Box>
                  </Box>
                }
              />
            </HighestPriceBox>
          </TableBodyCell>
        </TableBodyRow>
      )}
    />
  )
}

export default PricingTab
