import {
  Badge,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import useAppUtilities from '../../hooks/useAppUtilities'
import Talk from 'talkjs'
import { useUnreads } from '@talkjs/react'
import { IRestaurantMenuItem } from './Layout'

interface RestaurantSaidBarMenuListProps {
  isDrawerOpen: boolean
  activeItem: string
  handleMenuItemClick: (label: string, path: string) => void
  RESTAURANT_MENU_LIST: IRestaurantMenuItem[]
}

const RestaurantSaidBarMenuList: React.FC<RestaurantSaidBarMenuListProps> = ({
  isDrawerOpen,
  activeItem,
  handleMenuItemClick,
  RESTAURANT_MENU_LIST,
}) => {
  const { theme } = useAppUtilities()
  const [hoveredItem, setHoveredItem] = useState<string | null>(null)

  const unreads: Talk.UnreadConversation[] | undefined = useUnreads()
  const [unreadCount, setUnreadCount] = useState(0)

  useEffect(() => {
    if (unreads) {
      const unreadCount =
        unreads?.reduce(
          (total, conv) => total + (conv.unreadMessageCount || 0),
          0,
        ) ?? 0
      setUnreadCount(unreadCount)
    } else {
      setUnreadCount(0)
    }
  }, [unreads])

  return (
    <List>
      {RESTAURANT_MENU_LIST.map((menu, index) => (
        <ListItem
          key={index}
          disablePadding
          sx={{
            display: 'block',
            transition: 'background-color 0.3s ease',
          }}
          onMouseEnter={() => setHoveredItem(menu.label)}
          onMouseLeave={() => setHoveredItem(null)}
        >
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: isDrawerOpen ? 'initial' : 'center',
              px: 2.5,
              borderRadius: '10px',
              backgroundColor:
                activeItem === menu.label
                  ? theme.palette.primary.light
                  : 'transparent',
              transition: 'background-color 0.3s ease',
              '&:hover': {
                backgroundColor:
                  activeItem === menu.label
                    ? theme.palette.primary.light
                    : 'transparent',
              },
            }}
            onClick={() => handleMenuItemClick(menu.label, menu.path)}
          >
            {menu.label === 'Messages' && !isDrawerOpen ? (
              <Badge color="secondary" badgeContent={unreadCount}>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: isDrawerOpen ? 3 : 'auto',
                    justifyContent: 'center',
                    color:
                      activeItem === menu.label || hoveredItem === menu.label
                        ? theme.palette.grey.A100
                        : theme.palette.grey[400],
                    transition: 'color 0.3s ease',
                  }}
                >
                  {menu.icon}
                </ListItemIcon>
              </Badge>
            ) : (
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: isDrawerOpen ? 3 : 'auto',
                  justifyContent: 'center',
                  color:
                    activeItem === menu.label || hoveredItem === menu.label
                      ? theme.palette.grey.A100
                      : theme.palette.grey[400],
                  transition: 'color 0.3s ease',
                }}
              >
                {menu.icon}
              </ListItemIcon>
            )}
            {menu.label === 'Messages' && isDrawerOpen ? (
              <>
                <ListItemText
                  primary={menu.label}
                  sx={{
                    opacity: isDrawerOpen ? 1 : 0,
                    transition: 'opacity 0.3s ease',
                    '& .MuiTypography-root': {
                      color:
                        activeItem === menu.label || hoveredItem === menu.label
                          ? theme.palette.grey.A100
                          : theme.palette.grey[400],
                      fontSize: '14px',
                      fontWeight: 600,
                      lineHeight: '20px',
                      transition: 'color 0.3s ease',
                    },
                  }}
                />
                <Badge color="secondary" badgeContent={unreadCount}></Badge>
              </>
            ) : (
              <ListItemText
                primary={menu.label}
                sx={{
                  opacity: isDrawerOpen ? 1 : 0,
                  transition: 'opacity 0.3s ease',
                  '& .MuiTypography-root': {
                    color:
                      activeItem === menu.label || hoveredItem === menu.label
                        ? theme.palette.grey.A100
                        : theme.palette.grey[400],
                    fontSize: '14px',
                    fontWeight: 600,
                    lineHeight: '20px',
                    transition: 'color 0.3s ease',
                  },
                }}
              />
            )}
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  )
}

export default RestaurantSaidBarMenuList
