import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { useAppSelector } from '../../../../store'
import {
  selectIsSettingEdit,
  selectIsUpdateData,
  selectRestaurant,
  setIsEditCancelledData,
  setIsSettingEdit,
  setIsUpdateData,
  setIsValidData,
  updateRestaurant,
} from '../../../../store/restaurant/setting'
import useAppUtilities from '../../../../hooks/useAppUtilities'
import InputComponent from '../../../../components/ui/InputComponent'

const formSchema = z.object({
  name: z
    .string()
    .min(3, { message: 'Name must be at least 3 characters long' })
    .nonempty({ message: 'Name is required' }),
  // phoneNumber: z
  //   .string()
  //   .min(10, { message: 'Phone number must be at least 10 digits long' })
  //   .regex(/^\d+$/, { message: 'Phone number must contain only digits' }),
  // website: z
  //   .string()
  //   .url({ message: 'Please enter a valid URL' })
  //   .nonempty({ message: 'Website is required' }),
  phoneNumber: z.string().optional(),
  website: z.string().optional(),
})

const ContactTab: React.FC = () => {
  const { dispatch } = useAppUtilities()
  const isEditMode = useAppSelector(selectIsSettingEdit)
  const isUpdateMode = useAppSelector(selectIsUpdateData)
  const restaurant = useAppSelector(selectRestaurant)
  const [contactData] = useState({
    name: restaurant?.contact_name ?? '',
    phoneNumber: restaurant?.phone ?? '',
    website: restaurant?.website ?? '',
    googleMapLink: restaurant?.place_id ?? '',
  })

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    defaultValues: contactData,
  })

  useEffect(() => {
    reset(contactData)
  }, [contactData, reset])

  useEffect(() => {
    if (isUpdateMode) {
      void updateContact()
    }
  }, [isEditMode, isUpdateMode])

  const updateContact = async (): Promise<void> => {
    await handleSubmit(
      async (data: z.infer<typeof formSchema>): Promise<void> => {
        const isAnyChange =
          restaurant?.contact_name !== data.name ||
          restaurant.phone !== data.phoneNumber ||
          restaurant.website !== data.website
        if (restaurant && isAnyChange) {
          await dispatch(
            updateRestaurant({
              ...restaurant,
              contact_name: data.name,
              phone: data.phoneNumber,
              website: data.website,
            }),
          )
        }
      },
    )()

    dispatch(setIsSettingEdit(false))
    dispatch(setIsUpdateData(false))
    dispatch(setIsValidData(false))
    dispatch(setIsEditCancelledData(false))
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12} lg={6}>
        <Box>
          <InputComponent
            disabled={!isEditMode}
            id="name"
            label="Name"
            labelShrink
            labelFontSize="16px"
            isEditBackgroundMode
            labelWeight={500}
            placeholder="Enter name"
            {...register('name')}
            error={!!errors.name}
            helperText={errors.name?.message ?? ' '}
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={12} lg={6}>
        <Box>
          <InputComponent
            disabled={!isEditMode}
            id="phoneNumber"
            label="Phone Number"
            labelShrink
            labelFontSize="16px"
            isEditBackgroundMode
            labelWeight={500}
            placeholder="Enter phone number"
            {...register('phoneNumber')}
            error={!!errors.phoneNumber}
            helperText={errors.phoneNumber?.message ?? ' '}
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={12} lg={6}>
        <Box>
          <InputComponent
            disabled={!isEditMode}
            id="website"
            label="Website"
            labelShrink
            labelFontSize="16px"
            isEditBackgroundMode
            labelWeight={500}
            placeholder="Enter website URL"
            {...register('website')}
            error={!!errors.website}
            helperText={errors.website?.message ?? ' '}
          />
        </Box>
      </Grid>
    </Grid>
  )
}

export default ContactTab
