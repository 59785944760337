import { Box, useTheme } from '@mui/material'
import styled from 'styled-components'
import React from 'react'

interface DateMainBoxProps {
  activeColor: string
}

export const DashboardHeaderMainBox = styled(Box).attrs(() => {
  return {
    sx: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      maxWidth: { xs: 400, md: 392 },
    },
  }
})(() => ({}))
export const HeaderCalendarButton = styled(Box).attrs(() => {
  const theme = useTheme()
  return {
    component: 'button',
    sx: {
      border: 1,
      borderColor: theme.palette.grey[300],
      borderRadius: 2,
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      height: { xs: 48, md: 54 },
      p: 1,
      backgroundColor: theme.palette.common.white,
      width: { xs: 38.75, md: 42 },
    },
  }
})(() => ({}))

export const DateMainBox = styled(({ activeColor, ...rest }) => (
  <Box {...rest} />
)).attrs<DateMainBoxProps>(({ activeColor }) => {
  const theme = useTheme()
  return {
    sx: {
      border: 1,
      borderColor: activeColor,
      borderRadius: 2,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: { xs: 48, md: 54 },
      p: 1,
      backgroundColor: theme.palette.common.white,
      width: { xs: 38.75, md: 42 },
    },
  }
})<DateMainBoxProps>(() => ({}))
