import { Chip, Typography } from '@mui/material'
import styled from 'styled-components'
import React from 'react'

interface ReviewsSummaryChipProps {
  textColor: string
  borderColor: string
  backgroundColor: string
}

export const ReviewsSummaryChip = styled(
  ({ textColor, backgroundColor, borderColor, ...rest }) => <Chip {...rest} />,
).attrs<ReviewsSummaryChipProps>(
  ({ textColor, backgroundColor, borderColor }) => {
    return {
      sx: {
        height: '24px',
        borderRadius: '6px',
        color: textColor as string,
        backgroundColor,
        border: `1px solid ${borderColor as string}`,
        fontSize: { xs: '10px !important', md: '12px !important' },
        cursor: 'pointer',
        '&:hover': {
          borderColor,
          backgroundColor,
        },
        '& .MuiChip-label': {
          padding: '4px 8px',
        },
      },
    }
  },
)<ReviewsSummaryChipProps>(() => ({}))

interface ReviewsSummaryChipTypographyProps {
  textColor: string
  countBgColor: string
}

export const ReviewsSummaryChipTypography = styled(
  ({ textColor, countBgColor, ...rest }) => <Typography {...rest} />,
).attrs<ReviewsSummaryChipTypographyProps>(({ textColor, countBgColor }) => {
  return {
    component: 'span',
    fontSize: '10px',
    variant: 'subtitle2',
    sx: {
      marginLeft: '12px',
      padding: '1.5px 4px',
      borderRadius: '4px',
      height: '20px',
      width: '20px',
      backgroundColor: countBgColor,
      color: textColor,
    },
  }
})<ReviewsSummaryChipTypographyProps>(() => ({}))
