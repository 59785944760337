import { Box, Paper } from '@mui/material'
import { IconPlus, IconTrash } from '@tabler/icons-react'
import React from 'react'
import useAppUtilities from '../../../../hooks/useAppUtilities'
import { useAppSelector } from '../../../../store'
import { selectIsSettingEdit } from '../../../../store/restaurant/setting'
import {
  ImageDeleteButton,
  ImageUploadBox,
} from '../styled/ImageUpload.styled'

interface ImageProps {
  id: number
  index: number
  uploadImage: (index: number, file: File | null) => void
  style?: React.CSSProperties
  imgUrl: string | null
}

const ImageUpload: React.FC<ImageProps> = ({
  id,
  index,
  uploadImage,
  style,
  imgUrl,
}): JSX.Element => {
  const { theme } = useAppUtilities()
  const isEditMode = useAppSelector(selectIsSettingEdit)

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    if (event.target.files && event.target.files[0] && isEditMode) {
      uploadImage(index, event.target.files[0])
    }
  }

  const handleDelete = (): void => {
    if (isEditMode) {
      uploadImage(index, null)
    }
  }

  return (
    <Paper
      sx={{
        ...style,
        opacity: 1,
        border:
          imgUrl && imgUrl !== ''
            ? 'none'
            : `1px dashed ${theme.palette.grey[300]}`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: isEditMode ? 'pointer' : 'not-allowed',
        textAlign: 'center',
        borderRadius: '8px',
        position: 'relative',
      }}
    >
      {imgUrl && imgUrl !== '' ? (
        <ImageUploadBox>
          <Box
            component="img"
            src={imgUrl}
            alt="restaurant-profile"
            sx={{
              width: '100%',
              height: '100%',
              borderRadius: '8px',
              objectFit: 'cover',
              cursor: 'default',
              aspectRatio: { xs: 6 / 1.5, md: 6 / 3 },
            }}
          />
          {isEditMode && (
            <>
              <ImageDeleteButton onClick={handleDelete}>
                <IconTrash stroke={2} color={theme.palette.common.white} />
              </ImageDeleteButton>
            </>
          )}
        </ImageUploadBox>
      ) : (
        <>
          {isEditMode ? (
            <>
              <IconPlus stroke={2} color={theme.palette.grey[500]} />
              <input
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                style={{
                  position: 'absolute',
                  opacity: 0,
                  cursor: 'pointer',
                  width: '100%',
                  height: '100%',
                }}
              />
            </>
          ) : (
            <IconPlus stroke={2} color={theme.palette.grey[500]} />
          )}
        </>
      )}
    </Paper>
  )
}

export default ImageUpload
