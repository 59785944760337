import { Box, useTheme } from '@mui/material'
import styled from 'styled-components'

export const ReviewsSummaryWrapper = styled(Box).attrs(() => {
  const theme = useTheme()
  return {
    sx: {
      padding: 2,
      border: 1.5,
      borderRadius: '12px',
      borderColor: theme.palette.grey[200],
      backgroundColor: theme.palette.background.paper,
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
  }
})(({ theme }) => ({}))

export const ReviewsWrapper = styled(Box).attrs(() => {
  const theme = useTheme()
  return {
    sx: {
      marginTop: '20px',
      padding: 2,
      border: 1.5,
      borderRadius: '12px',
      borderColor: theme.palette.grey[200],
      backgroundColor: theme.palette.background.paper,
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'hidden',
    },
  }
})(({ theme }) => ({}))
