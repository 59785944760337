import { Typography } from '@mui/material'
import React from 'react'
import { TableBodyCell } from '../styled/Table.styled'

interface TextTableCellProps {
  text: string | number | undefined
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify'
  maxWidth?: string
  minWidth?: string
}

const TextTableCell: React.FC<TextTableCellProps> = ({
  text,
  align = 'left',
  maxWidth = '180px',
  minWidth,
}) => {
  return (
    <TableBodyCell align={align} style={{ maxWidth, minWidth }}>
      <Typography
        noWrap
        variant="subtitle1"
        color={'grey.800'}
        fontWeight={500}
      >
        {text !== undefined && text !== null ? text : '-'}
      </Typography>
    </TableBodyCell>
  )
}

export default TextTableCell
