import { Grid } from '@mui/material'
import React from 'react'
import EmptyScreen from '../../../components/EmptyScreen'
import RestaurantsPageLayout from '../../../components/restaurants/RestaurantsPageLayout'
import NotificationsCard, {
  NotificationsCardProps,
} from './components/NotificationsCard'
import NotificationsUpdateCard from './components/NotificationsUpdateCard'
import {
  NotificationsCardContainer,
  NotificationsMainContainer,
  NotificationsUpdateCardContainer,
} from './styled/Notifications.styled'

const dataAvailable = true

const Notifications: React.FC = () => {
  return (
    <RestaurantsPageLayout
      title={'Notification'}
      body={
        dataAvailable ? (
          <NotificationsMainContainer className="hide-scrollbar">
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={7} order={{ xs: 2, md: 1 }}>
                <NotificationsCardContainer>
                  {mockNotifications.map((item, i) => (
                    <NotificationsCard
                      key={i}
                      text={item.text}
                      time={item.time}
                      date={item.date}
                      active={item.active}
                    />
                  ))}
                </NotificationsCardContainer>
              </Grid>
              <Grid item xs={12} md={6} lg={5} order={{ xs: 1, md: 2 }}>
                <NotificationsUpdateCardContainer>
                  <NotificationsUpdateCard />
                </NotificationsUpdateCardContainer>
              </Grid>
            </Grid>
          </NotificationsMainContainer>
        ) : (
          <EmptyScreen
            imageUrl="/new/images/empty-notification-img.svg"
            containerHeight={'80vh'}
            secondaryText="No notifications yet. We'll let you know when something new comes up!"
          />
        )
      }
    />
  )
}

export default Notifications

const mockNotifications: NotificationsCardProps[] = [
  {
    text: 'You have a new message from John',
    time: '10:15 AM',
    date: '2024-08-20',
    active: true,
  },
  {
    text: 'Your order has been shipped',
    time: '11:30 AM',
    date: '2024-08-19',
    active: false,
  },
  {
    text: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam facilis
      incidunt quaerat minus fuga cum dicta voluptatum rem at quisquam? Quos sed
      aut optio quisquam adipisci exercitationem, quasi magni neque!`,
    time: '01:45 PM',
    date: '2024-08-18',
    active: true,
  },
  {
    text: 'New comment on your post',
    time: '09:05 AM',
    date: '2024-08-17',
    active: false,
  },
  {
    text: 'Your subscription is expiring soon',
    time: '02:20 PM',
    date: '2024-08-16',
    active: true,
  },
  {
    text: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam facilis
      incidunt quaerat minus fuga cum dicta voluptatum rem at quisquam? Quos sed
      aut optio quisquam adipisci exercitationem, quasi magni neque!`,
    time: '04:50 PM',
    date: '2024-08-15',
    active: false,
  },
  {
    text: 'Password changed successfully',
    time: '08:00 AM',
    date: '2024-08-14',
    active: true,
  },
  {
    text: 'New promotion: 20% off!',
    time: '12:10 PM',
    date: '2024-08-13',
    active: false,
  },
  {
    text: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam facilis
      incidunt quaerat minus fuga cum dicta voluptatum rem at quisquam? Quos sed
      aut optio quisquam adipisci exercitationem, quasi magni neque!`,
    time: '03:35 PM',
    date: '2024-08-12',
    active: true,
  },
  {
    text: 'Backup completed successfully',
    time: '07:45 AM',
    date: '2024-08-11',
    active: false,
  },
  {
    text: 'Backup completed successfully',
    time: '07:45 AM',
    date: '2024-08-11',
    active: false,
  },
  {
    text: 'Backup completed successfully',
    time: '07:45 AM',
    date: '2024-08-11',
    active: false,
  },
]
