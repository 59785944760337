import { useAuth0 } from '@auth0/auth0-react'
import React, { useState } from 'react'
import CustomButton from '../../../../components/ui/CustomButton'
import useAppUtilities from '../../../../hooks/useAppUtilities'
import {
  ResactlyCommunityAvatar,
  ResactlyCommunityAvatarGroup,
  ResactlyCommunityDataDriveContainer,
  ResactlyCommunityDataDriveContentWrapper,
  ResactlyCommunityDataDriveDescriptionTypography,
  ResactlyCommunityDataDriveGrid,
  ResactlyCommunityDataDriveImgSkeleton,
  ResactlyCommunityDataDriveImgWrapper,
  ResactlyCommunityDataDriveTypography,
  ResactlyCommunityForFoodLoversDescriptionTypography,
  ResactlyCommunityForFoodLoversTypography,
  ResactlyCommunityInvitationContainer,
  ResactlyCommunityInvitationDescriptionTypography,
  ResactlyCommunityInvitationGrid,
  ResactlyCommunityInvitationGridContainer,
  ResactlyCommunityInvitationGridContainerSecond,
  ResactlyCommunityInvitationGridInnerContainer,
  ResactlyCommunityInvitationInnerContainer,
  ResactlyCommunityInvitationTypography,
  ResactlyCommunityJoinDescriptionTypography,
  ResactlyCommunityJoinTypography,
  ResactlyCommunityMainGrid,
} from '../styled/ResactlyCommunityInvitation.styled'

const ResactlyCommunityInvitation: React.FC = (): JSX.Element => {
  const { isMediumScreen } = useAppUtilities()
  const [loadingImage, setLoadingImage] = useState(true)
  const { loginWithRedirect, isAuthenticated } = useAuth0()

  const login = async (): Promise<void> => {
    await loginWithRedirect()
  }

  return (
    <div>
      <ResactlyCommunityMainGrid>
        <ResactlyCommunityInvitationGrid>
          <ResactlyCommunityInvitationGridContainer>
            <ResactlyCommunityInvitationGridInnerContainer>
              <ResactlyCommunityJoinTypography>
                Join the Resactly Community.
              </ResactlyCommunityJoinTypography>
              <ResactlyCommunityJoinDescriptionTypography>
                {`By joining Resactly, you become part of a vibrant ecosystem
                where food lovers and restaurants come together to celebrate the
                art of dining. Whether you're seeking your next culinary
                adventure or aiming to bring your restaurant's vision to life,
                Resactly is your partner in crafting experiences that delight
                and satisfy.`}
              </ResactlyCommunityJoinDescriptionTypography>
            </ResactlyCommunityInvitationGridInnerContainer>
            <ResactlyCommunityAvatarGroup>
              {images.map((src, index) => (
                <ResactlyCommunityAvatar
                  key={index}
                  alt={`Avatar ${index + 1}`}
                  src={src}
                />
              ))}
            </ResactlyCommunityAvatarGroup>
          </ResactlyCommunityInvitationGridContainer>

          <ResactlyCommunityInvitationGridContainerSecond>
            <div>
              <ResactlyCommunityForFoodLoversTypography>
                For Food Lovers: Your Culinary Journey, Reimagined.
              </ResactlyCommunityForFoodLoversTypography>
              <ResactlyCommunityForFoodLoversDescriptionTypography>
                {`Gone are the days of hit-or-miss dining experiences. Resactly harnesses the power of AI and ML to understand your unique taste profile, dietary preferences, and culinary cravings, connecting you with restaurants eager to serve up your next favorite meal. Whether you're a vegan, a meat-lover, or somewhere in between, our platform ensures that every recommendation is tailored just for you. Ready to transform your dining experiences from mundane to extraordinary? Let Resactly be your guide to discovering restaurants that resonate with your soul.`}
              </ResactlyCommunityForFoodLoversDescriptionTypography>
            </div>
            <div>
              <img
                alt="resactly-match"
                width="100%"
                height="100%"
                srcSet={`/images/about/match.svg`}
                src={`/images/about/match.svg`}
                loading="lazy"
              />
            </div>
          </ResactlyCommunityInvitationGridContainerSecond>
        </ResactlyCommunityInvitationGrid>
        <ResactlyCommunityDataDriveGrid>
          <ResactlyCommunityDataDriveContainer>
            <ResactlyCommunityDataDriveContentWrapper>
              {!isMediumScreen ? (
                <>
                  <ResactlyCommunityDataDriveTypography>
                    For Restaurants
                  </ResactlyCommunityDataDriveTypography>
                  <ResactlyCommunityDataDriveTypography>
                    Data-Driven Success on Your Terms.
                  </ResactlyCommunityDataDriveTypography>
                </>
              ) : (
                <ResactlyCommunityDataDriveTypography>
                  For Restaurants Data-Driven Success on Your Terms.
                </ResactlyCommunityDataDriveTypography>
              )}
              <ResactlyCommunityDataDriveDescriptionTypography>
                {`But there's another side to Resactly - a suite of cutting-edge
                tools designed for restaurants to thrive in today's competitive
                market. We provide real-time analytical data that empowers you
                to make informed decisions about your menu, understand your
                customer base better, and tailor your offerings to meet the
                evolving tastes of your diners. In partnering with us, you gain
                insights that not only enhance your operational efficiency but
                also elevate the dining experience for your guests.`}
              </ResactlyCommunityDataDriveDescriptionTypography>
            </ResactlyCommunityDataDriveContentWrapper>
            <ResactlyCommunityDataDriveImgWrapper>
              {loadingImage && <ResactlyCommunityDataDriveImgSkeleton />}
              <img
                width="100%"
                alt="dashboard"
                srcSet={`/images/about/dashboard.png`}
                src={`/images/about/dashboard.png`}
                style={{
                  display: !loadingImage ? 'block' : 'none',
                  aspectRatio: 5 / 4,
                }}
                onLoad={() => setLoadingImage(false)}
                onError={() => setLoadingImage(false)}
              />
            </ResactlyCommunityDataDriveImgWrapper>
          </ResactlyCommunityDataDriveContainer>
        </ResactlyCommunityDataDriveGrid>
      </ResactlyCommunityMainGrid>
      <ResactlyCommunityInvitationContainer>
        <ResactlyCommunityInvitationInnerContainer>
          <ResactlyCommunityInvitationTypography>
            {`Don't Just Eat. Experience. With Resactly.`}
          </ResactlyCommunityInvitationTypography>
          <ResactlyCommunityInvitationDescriptionTypography>
            {`Ready to embark on a gastronomic journey that caters exclusively to you? Or eager to align your restaurant with the tastes of tomorrow? Create your account today and see how Resactly is changing the way the world eats, one plate at a time.`}
          </ResactlyCommunityInvitationDescriptionTypography>
          {!isAuthenticated && (
            <CustomButton
              title="Sign up now"
              sx={{ px: 3, py: 1.5, fontSize: '14px !important' }}
              onClick={() => {
                void login()
              }}
            />
          )}
        </ResactlyCommunityInvitationInnerContainer>
      </ResactlyCommunityInvitationContainer>
    </div>
  )
}

const images = [
  'https://img.freepik.com/free-photo/portrait-white-man-isolated_53876-40306.jpg',
  'https://t4.ftcdn.net/jpg/04/25/65/97/360_F_425659755_5BaLdNbbCtQbbiz1JNFBikshfiCWI5NE.jpg',
  'https://images.pexels.com/photos/614810/pexels-photo-614810.jpeg?cs=srgb&dl=pexels-simon-robben-55958-614810.jpg&fm=jpg',
  'https://images.rawpixel.com/image_800/czNmcy1wcml2YXRlL3Jhd3BpeGVsX2ltYWdlcy93ZWJzaXRlX2NvbnRlbnQvbHIvcm0zMjgtMzY2LXRvbmctMDhfMS5qcGc.jpg',
  'https://i.pinimg.com/736x/dc/c4/59/dcc459cef6213a9d5bf9fc2f17765ae4.jpg',
  'https://qodebrisbane.com/wp-content/uploads/2019/07/This-is-not-a-person-10-1.jpeg',
  'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTGmt7mgLLJbU_An415Sur0-Iq8kRKQzzAwCw&s',
  'https://t4.ftcdn.net/jpg/02/45/56/35/360_F_245563558_XH9Pe5LJI2kr7VQuzQKAjAbz9PAyejG1.jpg',
  'https://img.freepik.com/free-photo/close-up-portrait-handsome-smiling-young-man-white-t-shirt-looking-away-blurry-outdoor-nature_176420-6304.jpg',
]

export default ResactlyCommunityInvitation
