import { Box, IconButton, useTheme } from '@mui/material'
import styled from 'styled-components'

export const StyledBox = styled(Box).attrs(() => {
  const theme = useTheme()
  return {
    sx: {
      padding: '6px',
      border: 1.5,
      borderRadius: '12px',
      borderColor: theme.palette.grey[200],
      backgroundColor: theme.palette.background.paper,
      height: { xs: '70vw', sm: '47vw', md: '28vw', lg: '21vw' },
      maxHeight: { xs: '216px', md: '250px' },
      position: 'relative',
      cursor: 'pointer',
      '&:hover .open-menubar': {
        visibility: 'visible',
      },
    },
  }
})(() => ({}))

export const StyledImage = styled('img').attrs(() => ({
  style: {
    borderRadius: '8px',
    width: '100%',
    height: 'calc(100% - 50px)',
    objectFit: 'cover',
  },
}))(() => ({}))

export const InnerBox = styled(Box).attrs(() => ({
  sx: {
    margin: '9px 12px 5px 12px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}))(() => ({}))

export const StyledIconButton = styled(IconButton).attrs(() => ({
  size: 'small',
  'aria-haspopup': 'true',
  className: 'open-menubar',
  sx: {
    marginLeft: '5px',
    padding: '5px',
    visibility: { md: 'hidden' },
  },
}))(() => ({}))
