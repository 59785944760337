import { Box, SxProps, Theme } from '@mui/material'
import React from 'react'

interface StepperIconProps {
  icon?: string
  iconElement?: React.ReactNode
  imgSize?: number
  width?: string
  height?: string
  alt: string
  wrapperStyle?: SxProps<Theme>
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  disable?: boolean
}

const ImgIcon: React.FC<StepperIconProps> = ({
  icon,
  iconElement,
  imgSize = 20,
  width,
  height,
  wrapperStyle,
  onClick,
  alt,
  disable = false,
}: StepperIconProps): JSX.Element => {
  return (
    <Box
      onClick={onClick}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '40px',
        height: '40px',
        borderRadius: '50%',
        cursor:disable ? 'not-allowed':'pointer',
        ...(disable&&{pointerEvents:'unset'}),
        ...wrapperStyle,
      }}
    >
      {icon && (
        <img
          src={icon}
          alt={alt}
          width={width ?? imgSize}
          height={height ?? imgSize}
        />
      )}
      {iconElement && iconElement}
    </Box>
  )
}

export default ImgIcon
