import { Box, useTheme } from '@mui/material'
import styled from 'styled-components'

export const MainTopCard = styled(Box).attrs(() => {
  const theme = useTheme()
  return {
    py: { xs: '16px', md: '20px' },
    px: { xs: '12px', md: '16px' },
    borderRadius: { xs: '12px', md: '16px' },
    border: `2px solid ${theme.palette.grey[200]}`,
    bgcolor: theme.palette.common.white,
  }
})(() => ({}))
export const CardTitleBox = styled(Box).attrs(() => {
  return {
    sx: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'start',
      pb: { xs: '16px', md: '28px' },
    },
  }
})(() => ({}))
export const CardMiddleBox = styled(Box).attrs(() => {
  return {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
  }
})(() => ({}))
export const CardBottomBox = styled(Box).attrs(() => {
  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 0.5,
    paddingTop: '4px',
  }
})(() => ({}))
