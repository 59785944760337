import React from 'react'
import {
  RestaurantBasicInfoPopoverDayTypography,
  RestaurantBasicInfoPopoverInnerWrapper,
  RestaurantBasicInfoPopoverTimeTypography,
  RestaurantBasicInfoPopoverTimeWrapper,
  RestaurantBasicInfoPopoverWrapper,
} from '../../pages/eater/restaurant/styled/RestaurantInfo.styled'
import useAppUtilities from '../../hooks/useAppUtilities'
import moment from 'moment'
import { Restaurant } from '../../types'
import { restaurantTimezone } from '../../helpers/restaurant-radius'

interface IRestaurantBasicTimeZoneProps {
  restaurant: Restaurant
}

const RestaurantBasicTimeZone: React.FC<IRestaurantBasicTimeZoneProps> = ({
  restaurant,
}) => {
  const { theme } = useAppUtilities()
  const today = moment().format('dddd')
  return (
    <RestaurantBasicInfoPopoverWrapper>
      {restaurantTimezone(restaurant)?.map((time, index) => {
        const isActiveDay = time.day === today

        return (
          <RestaurantBasicInfoPopoverInnerWrapper
            key={index}
            color={
              isActiveDay ? theme.palette.grey[800] : theme.palette.grey[500]
            }
          >
            <RestaurantBasicInfoPopoverDayTypography
              fontWeight={isActiveDay ? 600 : 500}
            >
              {time.day}
            </RestaurantBasicInfoPopoverDayTypography>
            <RestaurantBasicInfoPopoverTimeWrapper>
              {time.time.map((item, idx) => {
                return (
                  <RestaurantBasicInfoPopoverTimeTypography
                    color={
                      item === 'Closed'
                        ? theme.palette.secondary.main
                        : isActiveDay
                        ? theme.palette.grey[800]
                        : theme.palette.grey[500]
                    }
                    fontWeight={isActiveDay ? 600 : 500}
                    key={idx}
                  >
                    {item}
                  </RestaurantBasicInfoPopoverTimeTypography>
                )
              })}
            </RestaurantBasicInfoPopoverTimeWrapper>
          </RestaurantBasicInfoPopoverInnerWrapper>
        )
      })}
    </RestaurantBasicInfoPopoverWrapper>
  )
}

export default RestaurantBasicTimeZone
