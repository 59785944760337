import { Box, Typography } from '@mui/material'
import { IconStarFilled } from '@tabler/icons-react'
import React from 'react'
import useAppUtilities from '../../../../../hooks/useAppUtilities'
import { LinearProgress, RatingBarBox } from '../../styled/RatingBar.styled'

interface IRatingBar {
  starCount: number
  maxValue: number
  count: number
}

const RatingBar: React.FC<IRatingBar> = ({ starCount, maxValue, count }) => {
  const { theme } = useAppUtilities()
  const progress = (count / maxValue) * 100

  return (
    <RatingBarBox>
      <Box display="flex" alignItems="center">
        <Typography variant="subtitle1" mr="4px">
          {starCount}
        </Typography>
        <IconStarFilled
          style={{
            color: theme.palette.secondary[300],
            marginRight: '8px',
          }}
          size="12px"
        />
      </Box>

      <Box flexGrow={1}>
        <LinearProgress value={progress} />
      </Box>

      <Box minWidth={'50px'}>
        <Typography
          variant="subtitle2"
          textAlign="end"
          color={theme.palette.grey[400]}
        >
          {count}
        </Typography>
      </Box>
    </RatingBarBox>
  )
}

export default RatingBar
