import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import useAppUtilities from '../../../../hooks/useAppUtilities'
import { useAppSelector } from '../../../../store'
import {
  selectIsSettingEdit,
  selectIsUpdateData,
  selectRestaurant,
  setIsEditCancelledData,
  setIsSettingEdit,
  setIsUpdateData,
  setIsValidData,
  updateRestaurant,
} from '../../../../store/restaurant/setting'
import { BasicDetailTextField } from '../styled/BasicDetails.styled'
import RestaurantBasicTimeZone from '../../../../components/restaurant/RestaurantBasicTimeZone'

const BasicDetails: React.FC = (): JSX.Element => {
  const { theme, dispatch } = useAppUtilities()
  const isEditMode = useAppSelector(selectIsSettingEdit)
  const isUpdateMode = useAppSelector(selectIsUpdateData)
  const restaurant = useAppSelector(selectRestaurant)
  const [description, setDescription] = useState<string>(
    restaurant?.description ?? '',
  )

  useEffect(() => {
    if (isUpdateMode) {
      void updateSocial()
    }
  }, [isEditMode, isUpdateMode])

  const updateSocial = async (): Promise<void> => {
    if (restaurant && restaurant.description !== description) {
      await dispatch(
        updateRestaurant({
          ...restaurant,
          description,
        }),
      )
    }
    dispatch(setIsSettingEdit(false))
    dispatch(setIsUpdateData(false))
    dispatch(setIsValidData(false))
    dispatch(setIsEditCancelledData(false))
  }

  return (
    <Box>
      <BasicDetailTextField
        isEditMode={isEditMode}
        value={description}
        label="About Restaurant"
        labelWeight={500}
        labelColor={theme.palette.grey[800]}
        setValue={(e) => setDescription(e)}
      />
      <Box sx={{ maxWidth: '300px' }}>
        {restaurant && <RestaurantBasicTimeZone restaurant={restaurant} />}
      </Box>
    </Box>
  )
}

export default BasicDetails
