import { Box, CircularProgress, Typography, debounce } from '@mui/material'
import { IconMapPinFilled } from '@tabler/icons-react'
import React, { useEffect, useMemo, useState } from 'react'
import { SquareAvatar } from '../../../../../components/styled/Avatar.styled'
import InputComponent from '../../../../../components/ui/InputComponent'
import { calculateDistance } from '../../../../../helpers/restaurant-radius'
import useAppUtilities from '../../../../../hooks/useAppUtilities'
import useElasticRestaurantSearch from '../../../../../hooks/useElasticRestaurantSearch'
import { useAppSelector } from '../../../../../store'
import { selectRestaurantLocation } from '../../../../../store/authSlice'
import {
  fetchRegionInsights,
  setFIPS,
  setIsFIPS,
  setRegionInsightSearch,
} from '../../../../../store/restaurant/dashboard'
import {
  findFipsByCountyState,
  getCountyAndStateByPlaceId,
} from '../../../../../helpers/fips-code-helper'

interface RestaurantSearchProps {
  onClose: () => void
}

const RestaurantSearch: React.FC<RestaurantSearchProps> = ({ onClose }) => {
  const { theme, dispatch } = useAppUtilities()
  const restaurantLocation = useAppSelector(selectRestaurantLocation)

  const [search, setSearch] = useState('')
  const [googleResults, setGoogleResults] = useState<
    google.maps.places.Place[]
  >([])
  const [isFetching, setIsFetching] = useState(false)

  const { results: elasticResults, loading: elasticLoading } =
    useElasticRestaurantSearch(search)

  const fetchPlaces = async (query: string): Promise<void> => {
    setIsFetching(true)
    try {
      const { Place } = (await google.maps.importLibrary(
        'places',
      )) as google.maps.PlacesLibrary
      const { places } = await Place.searchByText({
        textQuery: query,
        fields: [
          'displayName',
          'location',
          'formattedAddress',
          'addressComponents',
        ],
        language: 'en-US',
        maxResultCount: 1,
        useStrictTypeFiltering: true,
      })

      setGoogleResults(places ?? [])
    } catch (error) {
      setGoogleResults([])
    } finally {
      setIsFetching(false)
    }
  }

  const debouncedFetchPlaces = useMemo(
    () =>
      debounce(async (query: string) => {
        if (query.trim()) {
          await fetchPlaces(query)
        } else {
          setGoogleResults([])
        }
      }, 500),
    [],
  )

  useEffect(() => {
    void debouncedFetchPlaces(search)
  }, [search, debouncedFetchPlaces])

  const onPlaceAddressClick = async (
    displayName: string | null | undefined,
    addressComponents?: google.maps.places.AddressComponent[],
  ): Promise<void> => {
    if (displayName) {
      dispatch(setRegionInsightSearch(displayName))
    }
    const county = addressComponents?.find((comp) =>
      comp.types.includes('administrative_area_level_2'),
    )
    const countyState = addressComponents?.find((comp) =>
      comp.types.includes('administrative_area_level_1'),
    )

    if (county && county?.longText && countyState && countyState?.longText) {
      await onCountyByFips(county?.longText, countyState?.longText)
    } else {
      dispatch(setIsFIPS(true))
      onClose()
    }
  }

  const onRestaurantClick = async (
    placeId: string,
    name: string,
  ): Promise<void> => {
    dispatch(setRegionInsightSearch(name))
    await onRestaurantPlaceId(placeId)
  }

  const onRestaurantPlaceId = async (placeId: string): Promise<void> => {
    const countyName = await getCountyAndStateByPlaceId(placeId)
    if (countyName) {
      await onCountyByFips(countyName.county, countyName.countyState)
    }
  }

  const onCountyByFips = async (
    countyName: string,
    CountyState: string,
  ): Promise<void> => {
    const fips = await findFipsByCountyState(countyName, CountyState)

    if (fips) {
      dispatch(setIsFIPS(false))
      onClose()
      await dispatch(
        fetchRegionInsights({
          fips,
        }),
      )
    } else {
      dispatch(setIsFIPS(true))
      onClose()
    }
    dispatch(setFIPS(fips))
  }

  return (
    <Box>
      <InputComponent
        id="restaurant-autocomplete-search-input"
        value={search}
        setValue={setSearch}
        isFieldRequired
        fullWidth
        autoFocus
        placeholder="Search by restaurant, location, zip"
      />

      {(isFetching || elasticLoading) && (
        <Box display="flex" justifyContent="center" mt={2}>
          <CircularProgress size={24} />
        </Box>
      )}

      {!isFetching &&
      !elasticLoading &&
      (googleResults?.length > 0 || elasticResults?.length > 0) ? (
        <Box height={250} mt={1} overflow="auto">
          {googleResults.map(
            (
              { displayName, formattedAddress, location, addressComponents },
              index,
            ) => (
              <Box
                key={`google-${index}`}
                display="flex"
                alignItems="start"
                mb={1}
                sx={{
                  '&:hover': {
                    backgroundColor: 'primary.50',
                    borderRadius: '6px',
                    cursor: 'pointer',
                  },
                }}
                onClick={() => {
                  void onPlaceAddressClick(displayName, addressComponents)
                }}
              >
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  bgcolor="secondary.100"
                  m={1}
                  mr={2}
                  width={30}
                  height={30}
                  borderRadius="6px"
                >
                  <IconMapPinFilled
                    size={20}
                    color={theme.palette.secondary.main}
                  />
                </Box>
                <Box>
                  <Typography
                    variant="subtitle1"
                    fontWeight={500}
                    maxWidth={200}
                    noWrap
                  >
                    {displayName} - {formattedAddress}{' '}
                    {location &&
                      `(${calculateDistance(
                        restaurantLocation?.lat ?? 0,
                        restaurantLocation?.lng ?? 0,
                        location.lat(),
                        location.lng(),
                      )})`}
                  </Typography>
                  <Typography variant="subtitle2" fontWeight={400}>
                    {location &&
                      `${calculateDistance(
                        restaurantLocation?.lat ?? 0,
                        restaurantLocation?.lng ?? 0,
                        location.lat(),
                        location.lng(),
                      )}`}
                  </Typography>
                </Box>
              </Box>
            ),
          )}

          {elasticResults.map(
            ({ name, address1, city, zipCode, placeId }, index) => (
              <Box
                key={`elastic-${index}`}
                display="flex"
                sx={{
                  '&:hover': {
                    backgroundColor: 'primary.50',
                    borderRadius: '6px',
                    cursor: 'pointer',
                  },
                }}
                onClick={() => {
                  void onRestaurantClick(placeId?.raw, name?.raw)
                }}
              >
                <Box display="flex" justifyContent="center" p={1} mr={1}>
                  <SquareAvatar
                    src={'/logoPin.svg'}
                    size="32px"
                    name={name?.raw}
                  />
                </Box>
                <Box>
                  <Typography
                    variant="subtitle1"
                    fontWeight={400}
                    maxWidth={200}
                    noWrap
                  >
                    {name?.raw}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    fontWeight={500}
                    maxWidth={150}
                    noWrap
                  >
                    {address1?.raw}, {city?.raw}, {zipCode?.raw}
                  </Typography>
                </Box>
              </Box>
            ),
          )}
        </Box>
      ) : (
        <Box height={250}></Box>
      )}
    </Box>
  )
}

export default RestaurantSearch
