import { useAuth0 } from '@auth0/auth0-react'
import {
  AppBar,
  Badge,
  Box,
  IconButton,
  Link as MuiLink,
  Toolbar,
  Typography,
} from '@mui/material'
import { IconLogout, IconMenu2, IconSearch } from '@tabler/icons-react'
import { useUnreads } from '@talkjs/react'
import React, { useEffect, useMemo, useState } from 'react'
import { NavLink } from 'react-router-dom'
import Talk from 'talkjs'
import { ROUTES, navBarRoutes } from '../../helpers/routes-helper'
import {
  MOBILE_NAV_HEIGHT,
  MOBILE_SEARCH_HEIGHT,
  WEB_NAV_HEIGHT,
} from '../../helpers/static-constants'
import useAppUtilities from '../../hooks/useAppUtilities'
import { useAuthHelper } from '../../hooks/useAuthHelper'
import { useDevice } from '../../hooks/useDevice'
import { setMobileSearchOpen } from '../../store/searchSlice'
import useRudderStackAnalytics from '../../useRudderAnalytics'
import GlobalSearch from './GlobalSearch'
import LoginButton from './LoginButton'
import CustomDrawer from './MobileDrawer'
import Range from './Range'

const NavBar: React.FC = () => {
  const { theme, dispatch, navigate, location, isMediumScreen } =
    useAppUtilities()
  const [analytics, isAnalyticsReady] = useRudderStackAnalytics()
  const { pathname } = location
  const { isHybrid } = useDevice()
  const { isAuthenticated } = useAuth0()
  const { logoutAuthUser } = useAuthHelper()
  const unreads: Talk.UnreadConversation[] | undefined = useUnreads()

  const mappedRoutes = navBarRoutes.filter((item) => item.isPublic)

  const [open, setOpen] = useState(false)
  const [routes, setRoutes] = useState(mappedRoutes)
  const [isSearchOpen, setIsSearchOpen] = useState(false)

  const toggleDrawer = (newOpen: boolean) => (): void => {
    setOpen(newOpen)
  }

  useEffect(() => {
    const mappedRoutes = navBarRoutes.filter((item) => {
      return item.isPublic || (!item.isPublic && isAuthenticated)
    })
    setRoutes(mappedRoutes)
  }, [isAuthenticated])
  useEffect(() => {
    dispatch(setMobileSearchOpen(isSearchOpen))
  }, [isSearchOpen])

  const onSearchToggle = (): void => {
    setIsSearchOpen((prevState) => !prevState)
  }

  const navList = [
    {
      name: 'Home',
      path: '/',
    },
    {
      name: 'About',
      path: '/about',
    },
    ...(isAuthenticated
      ? [
          {
            name: 'Social',
            path: '/social',
          },
          {
            name: 'Profile',
            path: '/profile',
          },
        ]
      : []),
  ]

  const logoutUser = (): void => {
    if (analytics && isAnalyticsReady) {
      analytics?.identify('', { isLoggedIn: false })
      analytics?.reset()
    }
    logoutAuthUser()
    toggleDrawer(false)
  }

  const unreadCount = useMemo(() => {
    return (
      unreads?.reduce(
        (total, conv) => total + (conv.unreadMessageCount || 0),
        0,
      ) ?? 0
    )
  }, [unreads])

  return (
    <>
      <AppBar
        position="static"
        component="header"
        sx={{
          width: '100%',
          flex: ' 0 0 auto',
          minHeight: {
            xs: isHybrid
              ? `${MOBILE_SEARCH_HEIGHT}px`
              : isSearchOpen
              ? `${MOBILE_SEARCH_HEIGHT + MOBILE_NAV_HEIGHT}px`
              : `${MOBILE_NAV_HEIGHT}px`,
            md: `${WEB_NAV_HEIGHT}px`,
          },
          backgroundColor: theme.palette.background.paper,
          borderBottom: `1px solid ${theme.palette.grey[300]}`,
          boxShadow: 'none',
        }}
      >
        <Toolbar
          sx={{
            width: '100%',
            minHeight: {
              xs: isHybrid
                ? `${MOBILE_SEARCH_HEIGHT}px`
                : isSearchOpen
                ? `${MOBILE_SEARCH_HEIGHT + MOBILE_NAV_HEIGHT}px`
                : `${MOBILE_NAV_HEIGHT}px`,
              md: `${WEB_NAV_HEIGHT}px`,
            },
            padding: {
              xs: isHybrid ? '0px' : isSearchOpen ? '16px 0px 0px 0px' : '16px',
              md: '0px',
            },
          }}
        >
          {!isMediumScreen ? (
            <Box display={'flex'} justifyContent={'space-between'} width="100%">
              <Box
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <MuiLink component={NavLink} to={ROUTES.HOME}>
                  <img
                    srcSet={`${'/logo.svg'}`}
                    src={`${'/logo.svg'}`}
                    style={{
                      height: '100%',
                      width: '120px',
                      marginRight: '48px',
                      cursor: 'pointer',
                      padding: '10px 0px',
                      marginLeft: '40px',
                    }}
                    loading="lazy"
                    alt="Resactly-Logo"
                  />
                </MuiLink>
                {routes.map((item, index) => (
                  <MuiLink
                    key={index}
                    component={NavLink}
                    to={item?.path}
                    variant="subtitle1"
                    underline="none"
                    sx={{
                      '&:hover': {
                        color: theme.palette.secondary.main,
                      },
                      paddingX: '32px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '100%',
                      '&.active': {
                        color: theme.palette.secondary.main,
                        fontWeight: 600,
                        backgroundColor: theme.palette.secondary[50],
                      },
                    }}
                  >
                    {item.name === 'Social' ? (
                      <Box>
                        {item.name}
                        <Badge
                          key={index}
                          color="secondary"
                          badgeContent={unreadCount}
                          sx={{
                            ml: unreadCount > 0 ? 3 : 0,
                          }}
                        ></Badge>
                      </Box>
                    ) : (
                      item.name
                    )}
                  </MuiLink>
                ))}
              </Box>
              <Box
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <GlobalSearch />
                <Range />
                <LoginButton />
              </Box>
            </Box>
          ) : (
            <Box width="100%">
              {!isHybrid && (
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                  px={isSearchOpen ? '16px' : '0px'}
                  zIndex={open ? 6000 : 1000}
                >
                  <Box
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <img
                      srcSet={`${'/logo.svg'}?w=20&h=20&fit=crop&auto=format&dpr=2 2x`}
                      src={`${'/logo.svg'}?w=30&h=30&fit=crop&auto=format`}
                      style={{
                        height: '40px',
                        marginRight: '16px',
                        cursor: 'pointer',
                      }}
                      loading="lazy"
                      onClick={() => navigate(ROUTES.HOME)}
                      alt="Resactly-Logo"
                    />

                    {!isHybrid && (
                      <Typography
                        color={theme.palette.grey[800]}
                        fontWeight={700}
                        fontSize="20px"
                        lineHeight="28px"
                      >
                        {navList.find((route) => route.path === pathname)?.name}
                      </Typography>
                    )}
                  </Box>

                  <Box display={'flex'}>
                    <Box
                      sx={{
                        border: '1px solid',
                        borderRadius: '50%',
                        borderColor: theme.palette.grey[300],
                        marginX: '2px',
                      }}
                    >
                      <IconButton
                        size="medium"
                        sx={{
                          color: theme.palette.primary.light,
                        }}
                        onClick={onSearchToggle}
                      >
                        <IconSearch size={16} />
                      </IconButton>
                    </Box>
                    {(!isHybrid || (isHybrid && isAuthenticated)) && (
                      <Box
                        sx={{
                          border: '1px solid',
                          borderColor: theme.palette.grey[300],
                          borderRadius: '50%',
                          marginX: '2px',
                        }}
                      >
                        {!isHybrid ? (
                          <IconButton
                            size="medium"
                            sx={{ color: theme.palette.primary.light }}
                            onClick={toggleDrawer(true)}
                          >
                            <IconMenu2 size={16} />
                          </IconButton>
                        ) : (
                          <IconButton
                            size="medium"
                            sx={{ color: theme.palette.primary.light }}
                            onClick={logoutUser}
                          >
                            <IconLogout size={16} />
                          </IconButton>
                        )}
                      </Box>
                    )}
                  </Box>
                </Box>
              )}

              {(isSearchOpen || isHybrid) && (
                <Box
                  display={'flex'}
                  justifyContent={'flex-end'}
                  alignItems={'stretch'}
                  borderTop={`1px solid ${theme.palette.grey[300]}`}
                  height={`${MOBILE_SEARCH_HEIGHT}px`}
                  mt={isHybrid ? '0px' : '15px'}
                  zIndex={10}
                >
                  <GlobalSearch />
                  <Range />
                </Box>
              )}
            </Box>
          )}
        </Toolbar>
      </AppBar>
      <CustomDrawer open={open} toggleDrawer={setOpen} routeList={navList} />
    </>
  )
}
export default NavBar
