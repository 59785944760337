import React, { useCallback, useEffect, useState } from 'react'
import { Box, Divider, Drawer, Typography } from '@mui/material'
import useAppUtilities from '../../hooks/useAppUtilities'
import ImgIcon from '../ui/ImgIcon'
import Talk from 'talkjs'
import { IconX } from '@tabler/icons-react'
import { useAppSelector } from '../../store'
import { selectRestaurantDetail } from '../../store/restaurantSlice'
import { Chatbox, useSession } from '@talkjs/react'
import { selectEater } from '../../store/profileSlice'
import { DEFAULT_CARD_IMAGE } from '../../helpers/static-constants'
import EmptyScreen from '../EmptyScreen'

interface ChatDrawerProps {
  open: boolean
  toggleDrawer: React.Dispatch<React.SetStateAction<boolean>>
}

const ChatDrawer: React.FC<ChatDrawerProps> = ({ open, toggleDrawer }) => {
  const { isSmallScreen, theme } = useAppUtilities()
  const restaurant = useAppSelector(selectRestaurantDetail)?.restaurantDTO
  const eater = useAppSelector(selectEater)
  const session = useSession()
  const [isSession, setIsSession] = useState(false)

  useEffect(() => {
    if (!session?.isAlive) {
      setIsSession(true)
    }
  }, [session?.isAlive])

  const address = restaurant
    ? `${restaurant?.address1 ?? ''} ${restaurant?.city ?? ''}, ${
        restaurant?.state ?? ''
      }`
    : ''

  const syncConversation = useCallback(
    (session) => {
      const conversation = session.getOrCreateConversation(
        `Restaurant-${restaurant?.alias}-${eater?.eaterId}`,
      )

      const other = new Talk.User({
        id: restaurant?.restaurantID ?? '',
        name: restaurant?.name ?? '',
        email: restaurant?.contact_email ?? null,
        photoUrl: restaurant?.imageurl ?? DEFAULT_CARD_IMAGE,
        role: 'restaurant-admin-messages',
        custom: {
          email: restaurant?.contact_email ?? null,
          address: address.trim(),
        },
      })
      conversation.setParticipant(session.me)
      conversation.setParticipant(other)

      return conversation
    },
    [restaurant],
  )

  return (
    <Drawer
      open={open}
      onClose={() => toggleDrawer(false)}
      anchor={isSmallScreen ? 'bottom' : 'right'}
      sx={{
        '& .MuiPaper-root': {
          width: isSmallScreen ? '100%' : '400px',
          height: '100%',
        },
      }}
    >
      <Box>
        <Box sx={{ px: '16px', py: { xs: '16px', md: '30px' } }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="h6" color="text.primary" noWrap>
              {restaurant?.name}
            </Typography>
            <ImgIcon
              iconElement={<IconX stroke={2} style={{ margin: '7px' }} />}
              alt="close-drawer"
              wrapperStyle={{
                backgroundColor: theme.palette.background.paper,
                border: `1px solid ${theme.palette.grey[300]}`,
                borderRadius: '50%',
                height: '32px',
                width: '32px',
                cursor: 'pointer',
              }}
              onClick={() => toggleDrawer(false)}
            />
          </Box>
        </Box>
        <Divider
          orientation="horizontal"
          flexItem
          sx={{ color: theme.palette.grey[300] }}
        />
      </Box>
      <Box
        sx={{
          bgcolor: 'background.paper',
          width: '100%',
          height: 'calc(100% - 5px)',
        }}
      >
        {!isSession ? (
          <Chatbox
            syncConversation={syncConversation}
            style={{ width: '100%', height: '100%' }}
            showChatHeader={false}
          ></Chatbox>
        ) : (
          <EmptyScreen
            mainText="Please wait while we initialize your chat session..."
            secondaryText="Session is loading..."
            imageUrl="/images/empty-screen.svg"
            containerHeight="400px"
          />
        )}
      </Box>
    </Drawer>
  )
}

export default ChatDrawer
