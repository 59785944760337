import {
  createAsyncThunk,
  createSlice,
  isAnyOf,
  PayloadAction,
} from '@reduxjs/toolkit'
import okaoAxios from '../../helpers/axios'
import { RootState } from '..'

import { AlertColor } from '@mui/material'
import {
  Dish,
  ICopyDishRequest,
  IDishAddImage,
  IDishAddRequest,
  IDishDeleteRequest,
  ILoadMenuRequest,
  IMenuAddRequest,
  IMenuDeleteRequest,
  IMenuUpdateRequest,
  Menu,
} from '../../types/menuTypes'
import { IUserStaticMessage, Restaurant } from '../../types'
import { loginAudit } from '../authSlice'
import { fetchRestaurantAdmin } from './setting'

export interface IRestaurantSlice {
  message: string | undefined
  severity: AlertColor | undefined
  shouldOpen: boolean
  status: string
  menus: Menu[]
  selectedMenu: Menu | undefined
  selectedDish: Dish | undefined
}

const initialState: IRestaurantSlice = {
  status: 'idle',
  shouldOpen: false,
  message: undefined,
  severity: undefined,
  menus: [],
  selectedMenu: undefined,
  selectedDish: undefined,
}

export const addMenu = createAsyncThunk<Restaurant, IMenuAddRequest>(
  'api/addMenu',
  async (menuData, { rejectWithValue }) => {
    try {
      const response = await okaoAxios.post('/menu/add-new-menu', menuData)
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)
export const publishMenuById = createAsyncThunk<Restaurant, { menuId: string }>(
  'api/publishMenuById',
  async ({ menuId }, { rejectWithValue }) => {
    try {
      const response = await okaoAxios.post(`/menu/publish/${menuId}`)
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)
export const fetchMenuById = createAsyncThunk<Menu, { id: string }>(
  'api/fetchMenuById',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await okaoAxios.get(`/menu/${payload.id}`)
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)
export const updateMenuName = createAsyncThunk<Restaurant, IMenuUpdateRequest>(
  'api/updateMenu',
  async (menuData, { rejectWithValue }) => {
    try {
      const response = await okaoAxios.put(
        `/menu/${menuData.menuId}/name`,
        menuData,
      )
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const deleteMenu = createAsyncThunk<Restaurant, IMenuDeleteRequest>(
  'api/deleteMenu',
  async (menu, { rejectWithValue }) => {
    try {
      const response = await okaoAxios.delete(`/menu/${menu.menuId}`)
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)
export const addMenuDish = createAsyncThunk<Restaurant, IDishAddRequest>(
  'api/addMenuDish',
  async (dish, { rejectWithValue }) => {
    try {
      const response = await okaoAxios.post(`/menu/save-dish`, dish)
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)
export const fetchLoadMenu = createAsyncThunk<Restaurant, ILoadMenuRequest>(
  'api/fetchLoadMenu',
  async (menu, { rejectWithValue }) => {
    try {
      const response = await okaoAxios.post(
        `/menu/request-menu-load/${menu.id}`,
        { name: menu.name, url: menu.url },
      )
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)
export const deleteMenuDish = createAsyncThunk<Restaurant, IDishDeleteRequest>(
  'api/deleteMenuDish',
  async (dish, { rejectWithValue }) => {
    try {
      const response = await okaoAxios.delete(`/menu/dish/${dish.dishId}`)
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)
export const addCopyMenuDish = createAsyncThunk<Restaurant, ICopyDishRequest>(
  'api/addCopyMenuDish',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await okaoAxios.post(
        `/menu/copy-dish/${payload.menuId}/${payload.dishId}`,
      )
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)
export const deleteDishImage = createAsyncThunk<Restaurant, { id: string }>(
  'api/deleteDishImage',
  async (dish, { rejectWithValue }) => {
    try {
      const response = await okaoAxios.delete(
        `api/upload/restaurant/dish/${dish.id}`,
      )
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const addDishImage = createAsyncThunk(
  'api/addDishImage',
  async (payload: IDishAddImage) => {
    const formData = new FormData()
    formData.append('file', payload.file)
    const response = await okaoAxios.post(
      `api/upload/restaurant/dish/${payload.dishId}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    )
    const data = {
      ...response.data,
      headers: { ...response.headers },
    }
    return data
  },
)

export const menuSlice = createSlice({
  name: 'restaurant-menu',
  initialState,
  reducers: {
    setOpen: (state, action: PayloadAction<boolean>) => {
      state.shouldOpen = action.payload
    },
    setOpenStaticMessage: (
      state,
      action: PayloadAction<IUserStaticMessage>,
    ) => {
      state.shouldOpen = action.payload.shouldOpen
      state.message = action.payload.message
      state.severity = action.payload.severity
      if (action.payload.status) {
        state.status = action.payload.status
      }
    },
    setSelectedDish: (state, action: PayloadAction<Dish | undefined>) => {
      state.selectedDish = action.payload
    },
    setSelectedMenu: (state, action: PayloadAction<Menu | undefined>) => {
      state.selectedMenu = action.payload
    },
    clearData: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(addMenuDish.fulfilled, (state, action) => {
        state.status = 'idle'
      })
      .addCase(loginAudit.fulfilled, (state, action) => {
        state.menus = action.payload?.restaurantDTO?.menus ?? []
        state.status = 'idle'
      })
      .addCase(fetchMenuById.fulfilled, (state, action) => {
        state.selectedMenu = action.payload ?? undefined
        const selectedDish = action.payload.dishes?.find(
          (dish) => dish?.dishID === state.selectedDish?.dishID,
        )
        if (selectedDish) {
          state.selectedDish = selectedDish
        }
        state.status = 'idle'
      })
      .addMatcher(
        isAnyOf(fetchLoadMenu.fulfilled, publishMenuById.fulfilled),
        (state, action) => {
          state.menus = action.payload?.menus ?? []
          state.status = 'idle'
        },
      )
      .addMatcher(
        isAnyOf(
          addMenu.fulfilled,
          updateMenuName.fulfilled,
          deleteMenu.fulfilled,
          deleteMenuDish.fulfilled,
          fetchRestaurantAdmin.fulfilled,
        ),
        (state, action: PayloadAction<Restaurant>) => {
          state.menus = action.payload?.menus ?? []
          const selectedMenu = action.payload?.menus?.find(
            (menu) => menu?.menuID === state.selectedMenu?.menuID,
          )
          if (selectedMenu) {
            state.selectedMenu = selectedMenu
          }
          state.status = 'idle'
        },
      )
      .addMatcher(
        isAnyOf(
          addMenu.rejected,
          updateMenuName.rejected,
          deleteMenu.rejected,
          deleteMenuDish.rejected,
          fetchMenuById.rejected,
          addMenuDish.rejected,
          fetchLoadMenu.rejected,
          deleteDishImage.rejected,
          addDishImage.rejected,
        ),
        (state, action) => {
          state.shouldOpen = true
          state.severity = 'error'
          state.status = 'error'
          state.message =
            'Oops! Looks like something went wrong on our end. But don’t worry, a quick refresh might just do the trick! Give it a whirl and let’s try this again. If the problem persists, we’ll be here to sort it out!'
        },
      )
      .addMatcher(
        isAnyOf(
          addMenu.pending,
          updateMenuName.pending,
          deleteMenu.pending,
          deleteMenuDish.pending,
          fetchLoadMenu.pending,
        ),
        (state, action) => {
          state.status = 'loading'
        },
      )
  },
})

export const selectIsLoading = (state: RootState): boolean =>
  state.restaurantMenu.status === 'loading'
export const selectShouldOpen = (state: RootState): boolean =>
  state.restaurantMenu.shouldOpen
export const selectMessage = (state: RootState): string | undefined =>
  state.restaurantMenu.message
export const selectSeverity = (state: RootState): AlertColor | undefined =>
  state.restaurantMenu.severity
export const selectMenus = (state: RootState): Menu[] | [] =>
  state.restaurantMenu.menus
export const selectedMenu = (state: RootState): Menu | undefined =>
  state.restaurantMenu.selectedMenu
export const selectDish = (state: RootState): Dish | undefined =>
  state.restaurantMenu.selectedDish

export const {
  setOpen,
  setSelectedDish,
  setSelectedMenu,
  clearData,
  setOpenStaticMessage,
} = menuSlice.actions

export default menuSlice.reducer
