import { Box, useTheme } from '@mui/material'
import styled from 'styled-components'
import CustomButton from '../../../../components/ui/CustomButton'

export const EditModeButtonsMainBox = styled(Box).attrs(() => {
  return {
    sx: {
      display: 'flex',
      justifyContent: 'flex-end',
      gap: '14px',
    },
  }
})(() => ({}))

export const EditModeCancelButton = styled(CustomButton).attrs(() => {
  const theme = useTheme()
  return {
    title: 'Cancel',
    sx: {
      backgroundColor: 'transparent',
      borderColor: theme.palette.primary.light,
      color: theme.palette.primary.light,
      py: '8px',
      '& .MuiButton-icon': {
        marginRight: '0px !important',
      },
    },
  }
})(() => ({}))

export const EditModeUpdateButton = styled(CustomButton).attrs(() => {
  const theme = useTheme()
  return {
    title: 'Update',
    sx: {
      backgroundColor: theme.palette.primary.light,
      borderColor: theme.palette.primary.light,
      color: theme.palette.common.white,
      padding: '8px 16px',
      '& .MuiButton-icon': {
        marginRight: '0px !important',
      },
    },
  }
})(() => ({}))
