import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useAppSelector } from '../../../../store'
import { selectSelectedUser } from '../../../../store/restaurant/messages'
import ChatConversationPanel from './ChatConversationPanel'
import { IChatPanelUser } from '../../../../types/messagesTypes'

const ChatDashboard: React.FC = () => {
  const selectedUser = useAppSelector(selectSelectedUser)
  const [chatUser, setChatUser] = useState<IChatPanelUser | undefined>()

  useEffect(() => {
    if (!selectedUser) {
      setChatUser(undefined)
    } else {
      setChatUser({
        id: selectedUser?.id,
        name: selectedUser?.name,
        photoUrl: selectedUser?.photoUrl ?? selectedUser?.name,
        email: selectedUser?.email?.length
          ? selectedUser?.email?.[0]
          : 'test@test.com',
        phone: selectedUser.phone,
        custom: selectedUser?.custom,
      })
    }
  }, [selectedUser])

  return (
    <Box sx={{ height: '100%', overflow: 'hidden' }}>
      <ChatConversationPanel user={chatUser} />
    </Box>
  )
}

export default ChatDashboard
