import {
  Box,
  useTheme,
  LinearProgress as MuiLinearProgress,
} from '@mui/material'
import styled from 'styled-components'

export const RatingBarBox = styled(Box).attrs(() => {
  return {
    display: 'flex',
    alignItems: 'center',
    py: ['2px', '8px'],
  }
})(() => ({}))

export const LinearProgress = styled(MuiLinearProgress).attrs(() => {
  const theme = useTheme()
  return {
    variant: 'determinate',
    sx: {
      height: { xs: '12px', md: '20px' },
      borderRadius: { xs: '4px', md: '6px' },
      '&.MuiLinearProgress-root': {
        backgroundColor: theme.palette.grey[50],
      },
      '& .MuiLinearProgress-bar': {
        background: 'linear-gradient( #FF864D 11.98%, #FFA980 56.64%)',
      },
    },
  }
})(() => ({}))
