import { Box, useTheme } from '@mui/material'
import styled from 'styled-components'

export const RatingBox = styled(Box).attrs(() => {
  const theme = useTheme()
  return {
    sx: {
      bgcolor: theme.palette.common.white,
      borderRadius: '12px',
      border: `2px solid ${theme.palette.grey[200]}`,
      p: { xs: '12px 14px', md: '16px 20px' },
      height: '100%',
    },
  }
})(() => ({}))
