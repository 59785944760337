import {
  createAsyncThunk,
  createSlice,
  isAnyOf,
  PayloadAction,
} from '@reduxjs/toolkit'
import okaoAxios from '../../helpers/axios'
import { RootState } from '..'
import axios from 'axios'
import {
  ICompetitorDietaryAndCuisineResponse,
  ICompetitorInsightsAnswersRequest,
  ICompetitorInsightsAnswersResponse,
  ICompetitorPopularDishesRequest,
  ICompetitorPricingRequest,
  ICompetitorPricingResponse,
  ICompetitorRegionInsightsRequest,
  ICompetitorRegionInsightsResponse,
  ICompetitorSuggestedIngredientsRequest,
  ICompetitorSuggestedIngredientsResponse,
  IDashboardRequest,
  IDashboardResponse,
  IPopularDishes,
  IVisitorsResponse,
  VistitorEater,
} from '../../types/dashboard'
import { AlertColor } from '@mui/material'
import dayjs from 'dayjs'
import { DATE_FORMAT } from '../../helpers/static-constants'

export interface IRestaurantSlice {
  message: string | undefined
  severity: AlertColor | undefined
  shouldOpen: boolean
  status: string
  startDate: string
  endDate: string
  restaurantMiles: number
  isSideDrawerOpen: boolean
  dashboardDetail: IDashboardResponse | undefined
  competitorPricing: ICompetitorPricingResponse[]
  competitorPopularDishes: IPopularDishes[]
  selectedCompetitorPopularDish: IPopularDishes | undefined
  competitorDietary: ICompetitorDietaryAndCuisineResponse | undefined
  competitorCuisine: ICompetitorDietaryAndCuisineResponse | undefined
  visitors: IVisitorsResponse[] | undefined
  visitorsEater: VistitorEater[]
  validVisitorsTotalCount: number
  suggestedIngredients: ICompetitorSuggestedIngredientsResponse[]
  regionInsights: ICompetitorRegionInsightsResponse | undefined
  selectedFIPS: string | null
  isFIPS: boolean
  restaurantFIPS: string | null
  regionInsightSearch: string
}

const initialState: IRestaurantSlice = {
  status: 'idle',
  shouldOpen: false,
  message: undefined,
  severity: undefined,
  startDate: dayjs().subtract(1, 'day').format(DATE_FORMAT),
  endDate: dayjs().format(DATE_FORMAT),
  restaurantMiles: 5,
  isSideDrawerOpen: false,
  dashboardDetail: undefined,
  competitorPricing: [],
  competitorPopularDishes: [],
  selectedCompetitorPopularDish: undefined,
  competitorDietary: undefined,
  competitorCuisine: undefined,
  visitors: undefined,
  visitorsEater: [],
  validVisitorsTotalCount: 0,
  suggestedIngredients: [],
  selectedFIPS: null,
  regionInsights: undefined,
  isFIPS: false,
  restaurantFIPS: null,
  regionInsightSearch: '',
}

export const fetchRestaurantDashboard = createAsyncThunk<
  IDashboardResponse,
  IDashboardRequest,
  { rejectValue: { message: string; status?: number } }
>(
  'api/fetchRestaurantDashboard',
  async ({ fromDate, toDate }, { rejectWithValue }) => {
    try {
      const response = await okaoAxios.get(
        `/restaurant-admin/dashboard?fromDate=${
          fromDate ?? ('' as string)
        }&toDate=${toDate ?? ('' as string)}`,
      )
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue({
          message: error.response?.data?.message || error.message,
          status: error.response?.status,
        })
      }
      return rejectWithValue({ message: 'An unknown error occurred' })
    }
  },
)
export const fetchCompetitorPricing = createAsyncThunk<
  ICompetitorPricingResponse[],
  ICompetitorPricingRequest,
  { rejectValue: { message: string; status?: number } }
>(
  'api/fetchCompetitorPricing',
  async ({ longitude, latitude, radius }, { rejectWithValue }) => {
    try {
      const response = await okaoAxios.get(
        `/restaurant-admin/pricing?latitude=${latitude ?? ''}&longitude=${
          longitude ?? ''
        }&radius=${radius ?? ''}`,
      )

      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue({
          message: error.response?.data?.message || error.message,
          status: error.response?.status,
        })
      }
      return rejectWithValue({ message: 'An unknown error occurred' })
    }
  },
)
export const fetchCompetitorPopularDishes = createAsyncThunk<
  IPopularDishes[],
  ICompetitorPopularDishesRequest,
  { rejectValue: { message: string; status?: number } }
>(
  'api/fetchCompetitorPopularDishes',
  async (
    { latitude, longitude, radius, fromDate, toDate },
    { rejectWithValue },
  ) => {
    try {
      const response = await okaoAxios.get(
        `/restaurant-admin/popular-dishes?latitude=${
          latitude ?? ('' as string)
        }&longitude=${longitude ?? ('' as string)}&radius=${
          radius ?? ('' as string)
        }&fromDate=${fromDate ?? ('' as string)}&toDate=${
          toDate ?? ('' as string)
        }`,
      )

      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue({
          message: error.response?.data?.message || error.message,
          status: error.response?.status,
        })
      }
      return rejectWithValue({ message: 'An unknown error occurred' })
    }
  },
)

export const fetchCompetitorDietary = createAsyncThunk<
  ICompetitorDietaryAndCuisineResponse,
  ICompetitorPricingRequest,
  { rejectValue: { message: string; status?: number } }
>(
  'api/fetchCompetitorDietary',
  async ({ longitude, latitude, radius }, { rejectWithValue }) => {
    try {
      const response = await okaoAxios.get(
        `/restaurant-admin/dietary?latitude=${latitude ?? ''}&longitude=${
          longitude ?? ''
        }&radius=${radius ?? ''}`,
      )

      return response.data
    } catch (error) {
      return rejectWithValue({ message: 'Failed to fetch dietary data' })
    }
  },
)

export const fetchCompetitorCuisine = createAsyncThunk<
  ICompetitorDietaryAndCuisineResponse,
  ICompetitorPricingRequest,
  { rejectValue: { message: string; status?: number } }
>(
  'api/fetchCompetitorCuisine',
  async ({ longitude, latitude, radius }, { rejectWithValue }) => {
    try {
      const response = await okaoAxios.get(
        `/restaurant-admin/cuisine?latitude=${latitude ?? ''}&longitude=${
          longitude ?? ''
        }&radius=${radius ?? ''}`,
      )

      return response.data
    } catch (error) {
      return rejectWithValue({ message: 'Failed to fetch cuisine data' })
    }
  },
)

export const fetchVisitors = createAsyncThunk<
  IVisitorsResponse[],
  IDashboardRequest,
  { rejectValue: { message: string; status?: number } }
>('api/fetchVisitors', async ({ fromDate, toDate }, { rejectWithValue }) => {
  try {
    const response = await okaoAxios.get(
      `/restaurant-admin/visitors?fromDate=${
        fromDate ?? ('' as string)
      }&toDate=${toDate ?? ('' as string)}`,
    )

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return rejectWithValue({
        message: error.response?.data?.message || error.message,
        status: error.response?.status,
      })
    }
    return rejectWithValue({ message: 'Failed to fetch Visitor data' })
  }
})

export const fetchSuggestedIngredients = createAsyncThunk<
  ICompetitorSuggestedIngredientsResponse[],
  ICompetitorSuggestedIngredientsRequest,
  { rejectValue: { message: string; status?: number } }
>(
  'api/fetchSuggestedIngredients',
  async (
    { longitude, latitude, radius, fromDate, toDate },
    { rejectWithValue },
  ) => {
    try {
      const response = await okaoAxios.get(
        `/restaurant-admin/suggested-ingredients?latitude=${
          latitude ?? ''
        }&longitude=${longitude ?? ''}&radius=${radius ?? ''}&fromDate=${
          fromDate ?? ('' as string)
        }&toDate=${toDate ?? ('' as string)}`,
      )

      return response.data
    } catch (error) {
      return rejectWithValue({
        message: 'Failed to fetch Suggested Ingredient data',
      })
    }
  },
)

export const fetchRegionInsights = createAsyncThunk<
  ICompetitorRegionInsightsResponse,
  ICompetitorRegionInsightsRequest,
  { rejectValue: { message: string; status?: number } }
>('api/fetchRegionInsights', async (payload, { rejectWithValue }) => {
  try {
    const response = await okaoAxios.post(
      `/restaurant-admin/ask-census`,
      payload,
    )

    return response.data
  } catch (error) {
    return rejectWithValue({ message: 'Failed to fetch Region Insights data' })
  }
})

export const fetchInsightsAnswers = createAsyncThunk<
  ICompetitorInsightsAnswersResponse,
  ICompetitorInsightsAnswersRequest,
  { rejectValue: { message: string; status?: number } }
>('api/fetchInsightsAnswers', async (payload, { rejectWithValue }) => {
  try {
    const response = await okaoAxios.post(
      `/restaurant-admin/ask-census`,
      payload,
    )

    return response.data
  } catch (error) {
    return rejectWithValue({ message: 'Failed to fetch Region Insights data' })
  }
})

export const dashboardSlice = createSlice({
  name: 'restaurant-dashboard',
  initialState,
  reducers: {
    setOpen: (state, action: PayloadAction<boolean>) => {
      state.shouldOpen = action.payload
    },
    setStartDate: (state, action: PayloadAction<string>) => {
      state.startDate = action.payload
    },

    setEndDate: (state, action: PayloadAction<string>) => {
      state.endDate = action.payload
    },
    setRestaurantMiles: (state, action: PayloadAction<number>) => {
      state.restaurantMiles = action.payload
    },
    setSideDrawer: (state, action: PayloadAction<boolean>) => {
      state.isSideDrawerOpen = action.payload
    },
    setSelectedCompetitorPopularDish: (
      state,
      action: PayloadAction<IPopularDishes | undefined>,
    ) => {
      state.selectedCompetitorPopularDish = action.payload
    },
    setFIPS: (state, action: PayloadAction<string | null>) => {
      state.selectedFIPS = action.payload
    },
    setIsFIPS: (state, action: PayloadAction<boolean>) => {
      state.isFIPS = action.payload
    },
    setRestaurantFIPS: (state, action: PayloadAction<string | null>) => {
      state.restaurantFIPS = action.payload
    },
    setRegionInsightSearch: (state, action: PayloadAction<string>) => {
      state.regionInsightSearch = action.payload
    },
    clearData: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompetitorPricing.fulfilled, (state, action) => {
        state.competitorPricing = action.payload
        state.status = 'idle'
      })
      .addCase(fetchCompetitorPopularDishes.fulfilled, (state, action) => {
        state.competitorPopularDishes = action.payload
        state.status = 'idle'
      })
      .addCase(fetchRestaurantDashboard.fulfilled, (state, action) => {
        state.dashboardDetail = action.payload
        state.status = 'idle'
      })
      .addCase(fetchCompetitorDietary.fulfilled, (state, action) => {
        if (!action.payload || Object.keys(action.payload).length === 0) {
          state.competitorDietary = undefined
        } else if (
          (action.payload.restaurants?.count === 0 ||
            Object.keys(action.payload.restaurants ?? {}).length === 0) &&
          (action.payload.users?.count === 0 ||
            Object.keys(action.payload.users ?? {}).length === 0)
        ) {
          state.competitorDietary = undefined
        } else {
          state.competitorDietary = action.payload
        }
        state.status = 'idle'
      })
      .addCase(fetchCompetitorCuisine.fulfilled, (state, action) => {
        if (!action.payload || Object.keys(action.payload).length === 0) {
          state.competitorCuisine = undefined
        } else if (
          (action.payload.restaurants?.count === 0 ||
            Object.keys(action.payload.restaurants ?? {}).length === 0) &&
          (action.payload.users?.count === 0 ||
            Object.keys(action.payload.users ?? {}).length === 0)
        ) {
          state.competitorCuisine = undefined
        } else {
          state.competitorCuisine = action.payload
        }
        state.status = 'idle'
      })
      .addCase(fetchVisitors.fulfilled, (state, action) => {
        state.visitors = action.payload

        const visitorsEater = action.payload.flatMap((entry) => entry.eaters)

        const groupedData = new Map<string, VistitorEater>()

        visitorsEater.forEach(
          ({
            email,
            name,
            anonymousId,
            startTimes,
            endTimes,
            viewCount,
            imageUrl,
            requestIp,
            reservationCount,
            eaterId,
          }) => {

            let groupKey;
            if(name === undefined || name === null){
              groupKey = anonymousId;
              name = 'null null';
            }else if(name.trim() === 'null null'){
              groupKey = name;
            }else{
              groupKey = email;
            }

            let existing = groupedData.get(groupKey)

            if (!existing) {
              existing = {
                email,
                name: name === 'null null' ? 'Anonymous user' : name,
                anonymousId,
                startTimes,
                endTimes,
                viewCount: 0,
                imageUrl,
                requestIp,
                reservationCount,
                eaterId,
              }
              groupedData.set(groupKey, existing)
            }

            existing.viewCount += viewCount
          },
        )

        state.visitorsEater = Array.from(groupedData.values())

        state.validVisitorsTotalCount = action.payload?.reduce(
          (sum, eater) => sum + eater?.viewCount,
          0,
        )

        state.status = 'idle'
      })
      .addCase(fetchSuggestedIngredients.fulfilled, (state, action) => {
        state.suggestedIngredients = action.payload
        state.status = 'idle'
      })
      .addCase(fetchRegionInsights.fulfilled, (state, action) => {
        state.regionInsights = action.payload
        state.status = 'idle'
      })

      .addMatcher(
        isAnyOf(
          fetchRestaurantDashboard.rejected,
          fetchCompetitorPricing.rejected,
          fetchCompetitorPopularDishes.rejected,
          fetchCompetitorDietary.rejected,
          fetchCompetitorCuisine.rejected,
          fetchVisitors.rejected,
          fetchSuggestedIngredients.rejected,
          fetchRegionInsights.rejected,
        ),
        (state, action) => {
          state.shouldOpen = true
          state.severity = 'error'
          state.status = 'error'
          state.message =
            action.payload?.message ??
            'Oops! Looks like something went wrong on our end. But don’t worry, a quick refresh might just do the trick! Give it a whirl and let’s try this again. If the problem persists, we’ll be here to sort it out!'
        },
      )
      .addMatcher(
        isAnyOf(
          fetchCompetitorPricing.pending,
          fetchCompetitorPopularDishes.pending,
          fetchRestaurantDashboard.pending,
          fetchCompetitorDietary.pending,
          fetchCompetitorCuisine.pending,
          fetchVisitors.pending,
          fetchSuggestedIngredients.pending,
          fetchRegionInsights.pending,
        ),
        (state, action) => {
          state.status = 'loading'
        },
      )
  },
})

export const selectStartDate = (state: RootState): string => {
  return state?.restaurantDashboard.startDate
}
export const selectEndDate = (state: RootState): string => {
  return state?.restaurantDashboard.endDate
}

export const selectRestaurantMiles = (state: RootState): number => {
  return state?.restaurantDashboard.restaurantMiles
}
export const selectSideDrawerOpen = (state: RootState): boolean => {
  return state?.restaurantDashboard.isSideDrawerOpen
}
export const selectDashboardDetail = (
  state: RootState,
): IDashboardResponse | undefined => {
  return state?.restaurantDashboard.dashboardDetail
}
export const selectPricing = (
  state: RootState,
): ICompetitorPricingResponse[] => {
  return state?.restaurantDashboard.competitorPricing
}
export const selectPopularDishes = (state: RootState): IPopularDishes[] => {
  return state?.restaurantDashboard.competitorPopularDishes
}
export const selectIsLoading = (state: RootState): boolean =>
  state.restaurantDashboard.status === 'loading'
export const selectShouldOpen = (state: RootState): boolean =>
  state.restaurantDashboard.shouldOpen
export const selectMessage = (state: RootState): string | undefined =>
  state.restaurantDashboard.message
export const selectSeverity = (state: RootState): AlertColor | undefined =>
  state.restaurantDashboard.severity
export const selectCompetitorPopularDish = (
  state: RootState,
): IPopularDishes | undefined =>
  state.restaurantDashboard.selectedCompetitorPopularDish
export const selectCompetitorDietary = (
  state: RootState,
): ICompetitorDietaryAndCuisineResponse | undefined =>
  state.restaurantDashboard.competitorDietary
export const selectCompetitorCuisine = (
  state: RootState,
): ICompetitorDietaryAndCuisineResponse | undefined =>
  state.restaurantDashboard.competitorCuisine
export const selectVisitors = (
  state: RootState,
): IVisitorsResponse[] | undefined => state.restaurantDashboard.visitors
export const selectVisitorsEaters = (
  state: RootState,
): VistitorEater[] | undefined => state.restaurantDashboard.visitorsEater
export const selectValidVisitorsTotalCount = (state: RootState): number =>
  state.restaurantDashboard.validVisitorsTotalCount
export const selectSuggestedIngredients = (
  state: RootState,
): ICompetitorSuggestedIngredientsResponse[] =>
  state.restaurantDashboard.suggestedIngredients
export const selectSelectedFIPS = (state: RootState): string | null =>
  state.restaurantDashboard.selectedFIPS
export const selectRegionInsights = (
  state: RootState,
): ICompetitorRegionInsightsResponse | undefined =>
  state.restaurantDashboard.regionInsights
export const selectIsFIPS = (state: RootState): boolean =>
  state.restaurantDashboard.isFIPS
export const selectRestaurantFIPS = (state: RootState): string | null =>
  state.restaurantDashboard.restaurantFIPS
export const selectRegionInsightSearch = (state: RootState): string =>
  state.restaurantDashboard.regionInsightSearch

export const {
  setFIPS,
  setRestaurantFIPS,
  setIsFIPS,
  setRegionInsightSearch,
  setStartDate,
  setEndDate,
  setRestaurantMiles,
  setSideDrawer,
  setOpen,
  setSelectedCompetitorPopularDish,
  clearData,
} = dashboardSlice.actions

export default dashboardSlice.reducer
