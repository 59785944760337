import { Typography } from '@mui/material'
import { IconThumbDown, IconThumbUp } from '@tabler/icons-react'
import React from 'react'
import useAppUtilities from '../../../../hooks/useAppUtilities'
import {
  AllReviewsContentInnerWrapper,
  AllReviewsContentMainContainer,
  AllReviewsContentWrapper,
  AllReviewsHeaderIconStarFilled,
  AllReviewsHeaderMainContainer,
  AllReviewsHeaderRatingMainContainer,
  AllReviewsHeaderRatingTypography,
  AllReviewsHeaderTitleTypography,
} from '../styled/AllReviews.styled'
import { selectAdminReview } from '../../../../store/restaurant/review'
import { useAppSelector } from '../../../../store'
import EmptyScreen from '../../../../components/EmptyScreen'

const AllReviews: React.FC = () => {
  const review = useAppSelector(selectAdminReview)
  const { theme, isMediumScreen } = useAppUtilities()

  return (
    <>
      <AllReviewsHeaderMainContainer>
        <AllReviewsHeaderTitleTypography>
          All Reviews
        </AllReviewsHeaderTitleTypography>
        <AllReviewsHeaderRatingMainContainer>
          <AllReviewsHeaderIconStarFilled />
          <AllReviewsHeaderRatingTypography>
            {Number(review?.averageRating.toFixed(1)) ?? 0}
          </AllReviewsHeaderRatingTypography>
        </AllReviewsHeaderRatingMainContainer>
      </AllReviewsHeaderMainContainer>
      <AllReviewsContentMainContainer>
        {review && review?.reviews?.length > 0 ? (
          review?.reviews?.map((review, i) => {
            return (
              <AllReviewsContentWrapper key={i}>
                <AllReviewsContentInnerWrapper
                  type={review?.sentimentLabel?.toLowerCase()}
                >
                  {review?.sentimentLabel?.toLowerCase() === 'positive' ? (
                    <IconThumbUp stroke={2} size={isMediumScreen ? 20 : 24} />
                  ) : (
                    <IconThumbDown stroke={2} size={isMediumScreen ? 20 : 24} />
                  )}
                </AllReviewsContentInnerWrapper>
                <Typography variant="subtitle1" color={theme.palette.grey[700]}>
                  {review.review}
                </Typography>
              </AllReviewsContentWrapper>
            )
          })
        ) : (
          <EmptyScreen
            mainText="No data available"
            secondaryText="No reviews found"
            imageUrl="/images/empty-screen.svg"
            containerHeight={isMediumScreen ? '40vh' : '400px'}
          />
        )}
      </AllReviewsContentMainContainer>
    </>
  )
}

export default AllReviews
