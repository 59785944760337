import React, { useEffect, useState } from 'react'
import {
  SimilarDishesDivider,
  SimilarDishesMainBox,
} from '../../../styled/competitorInsights/dish/SimilarDishes.styled'
import DashboardDishCard from './DashboardDishCard'
import { useAppSelector } from '../../../../../../store'
import { selectCompetitorPopularDish } from '../../../../../../store/restaurant/dashboard'
import { getAllRestaurantDishes } from '../../../../../../helpers/restaurant'
import { selectRestaurant } from '../../../../../../store/restaurant/setting'
import { Dish } from '../../../../../../types/menuTypes'
import NoDataFound from '../../../../../../components/NoDataFound'
import { Box, CircularProgress } from '@mui/material'

interface SimilarDishesProps {
  setSimilarSelectedDish: React.Dispatch<Dish>
}

const SimilarDishes: React.FC<SimilarDishesProps> = ({
  setSimilarSelectedDish,
}) => {
  const dish = useAppSelector(selectCompetitorPopularDish)
  const restaurant = useAppSelector(selectRestaurant)
  const [dishes, setDishes] = useState<Dish[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    init()
  }, [dish])

  const init = (): void => {
    if (!restaurant || !dish?.dish?.ingredients) {
      setDishes([])
      return
    }

    setLoading(true)

    const selectedIngredients = new Set(
      dish.dish?.ingredients
        .toLowerCase()
        .split(',')
        .map((i) => i.trim()),
    )

    const allDishes = getAllRestaurantDishes(restaurant) || []

    const matchingDishes = allDishes.filter((dish) =>
      dish.ingredients
        .toLowerCase()
        .split(',')
        .some((ing) =>
          Array.from(selectedIngredients).some(
            (selected) => ing.includes(selected) || selected.includes(ing),
          ),
        ),
    )

    setDishes(matchingDishes)
    setLoading(false)
  }

  return (
    <SimilarDishesMainBox>
      {loading ? (
        <Box textAlign="center">
          <CircularProgress />
        </Box>
      ) : dishes.length > 0 ? (
        dishes.map((dish, i) => (
          <React.Fragment key={i}>
            <DashboardDishCard
              setSimilarSelectedDish={setSimilarSelectedDish}
              similarSelectedDish={dish}
            />
            {dishes.length - 1 !== i && <SimilarDishesDivider />}
          </React.Fragment>
        ))
      ) : (
        <NoDataFound height="100%" width="100%" />
      )}
    </SimilarDishesMainBox>
  )
}

export default SimilarDishes
