import Box from '@mui/material/Box'
import React, { useCallback, useMemo, useRef } from 'react'
import { Outlet, useLocation } from 'react-router'
import Footer from '../components/Footer'
import BottomAppBar from '../components/navBar/BottomAppBar'
import NavBar from '../components/navBar/NavBar'
import ScrollToTop from '../components/ScrollToTop'
import { WebLayoutProvider } from '../context/WebLayoutContext'
import { ROUTES } from '../helpers/routes-helper'
import { useDevice } from '../hooks/useDevice'
import { Session } from '@talkjs/react'
import { useAppSelector } from '../store'
import { selectEater } from '../store/profileSlice'
import Talk from 'talkjs'
import { DEFAULT_AVATAR_IMAGE } from '../helpers/static-constants'
import { useAuth0 } from '@auth0/auth0-react'

const WebMobileLayout: React.FC = () => {
  const location = useLocation()
  const { isHybrid } = useDevice()
  const { isAuthenticated } = useAuth0()
  const eater = useAppSelector(selectEater)
  const scrollableContainerRef = useRef<HTMLDivElement>(null)

  const hideFooterPaths = useMemo(() => [ROUTES.HOME, ROUTES.ABOUT], [])
  const showFooter = useMemo(
    () => hideFooterPaths.includes(location.pathname),
    [hideFooterPaths, location.pathname],
  )
  const hideNavBar = useMemo(
    () => isHybrid && [ROUTES.PROFILE].includes(location.pathname),
    [isHybrid, location.pathname],
  )

  const syncUser = useCallback(
    () => new Talk.User({
      id: eater?.eaterId ?? '',
      name: `${eater?.firstName ?? ''} ${eater?.lastName ?? ''}`,
      email: eater?.email ?? null,
      photoUrl: eater?.imageUrl?.length
        ? eater?.imageUrl
        : DEFAULT_AVATAR_IMAGE,
      role: 'eater-restaurant-inbox',
      custom: {
        email: eater?.email ?? null,
      },
    }),
    [eater],
  )

  const layoutContent = (
    <Box
      display="flex"
      flexDirection="column"
      height="100vh"
      sx={{
        paddingTop: 'env(safe-area-inset-top)',
        paddingBottom: 'env(safe-area-inset-bottom)',
      }}
    >
      {!hideNavBar && <NavBar />}
      <WebLayoutProvider>
        <Box
          ref={scrollableContainerRef}
          sx={{
            flex: 1,
            overflowY: 'auto',
            scrollBehavior: 'smooth',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Outlet context={{ scrollableContainerRef }} />
          <ScrollToTop scrollableContainerRef={scrollableContainerRef} />
          {showFooter && !isHybrid && <Footer />}
        </Box>
      </WebLayoutProvider>
      {isHybrid && <BottomAppBar />}
    </Box>
  )

  return (
    <>
      {isAuthenticated && eater?.eaterId ? (
        <Session
          appId={process.env.REACT_APP_TALK_JS ?? ''}
          syncUser={syncUser}
        >
          {layoutContent}
        </Session>
      ) : (
        layoutContent
      )}
    </>
  )
}

export default React.memo(WebMobileLayout)
