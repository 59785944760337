import React, { forwardRef } from 'react'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import {
  Box,
  InputBaseProps,
  InputLabel,
  SxProps,
  Theme,
  styled,
} from '@mui/material'
import useAppUtilities from '../../hooks/useAppUtilities'

interface InputComponentProps extends Omit<TextFieldProps, 'ref'> {
  id?: string
  label?: string
  placeholder?: string
  value?: unknown
  setValue?: (value: string) => void
  sx?: SxProps<Theme>
  fieldType?: 'outline' | 'fill'
  inputProps?: InputBaseProps['inputProps']
  // Label Props
  isFieldRequired?: boolean
  labelWeight?: number
  labelFontSize?: string & {}
  labelColor?: string
  labelShrink?:boolean
  // Edit Background Color Props
  isEditBackgroundMode?: boolean 
  editBackgroundColor?: string
  noneEditBackgroundColor?: string
}

// Use forwardRef to pass ref to MUI TextField
const InputComponent = forwardRef<HTMLInputElement, InputComponentProps>(
  (
    {
      id,
      label,
      placeholder,
      value,
      setValue,
      sx,
      fieldType = 'outline',
      isFieldRequired = false,
      labelFontSize,
      labelWeight,
      labelColor,
      labelShrink,
      isEditBackgroundMode,
      editBackgroundColor,
      noneEditBackgroundColor,
      error,
      ...rest
    },
    ref,
  ) => {
    return (
      <>
        {label && (
          <ResactlyInputLabel
            isFieldRequired={isFieldRequired}
            htmlFor={id ?? 'label-id'}
            label={label}
            labelColor={labelColor}
            isValid={error}
            labelFontSize={labelFontSize}
            labelWeight={labelWeight}
            labelShrink={labelShrink}

          />
        )}
        <ResactlyTextField
          id={id}
          placeholder={placeholder}
          sx={{ fontSize: '16px', ...sx }}
          fieldType={fieldType}
          inputRef={ref} // Pass ref here
          isEditBackgroundMode={isEditBackgroundMode}
          editBackgroundColor={editBackgroundColor}
          noneEditBackgroundColor={noneEditBackgroundColor}
          error={error}
          {...(value !== undefined ? { value } : {})}
          {...(setValue !== undefined
            ? { onChange: (e) => setValue(e.target.value) }
            : {})}
          {...rest}
        />
      </>
    )
  },
)
InputComponent.displayName = 'InputComponent'
export default InputComponent

interface StyledProps {
  sx?: SxProps<Theme>
  fieldType?: 'outline' | 'fill'
  isFieldRequired?: boolean
  isEditBackgroundMode?: boolean 
  editBackgroundColor?: string
  noneEditBackgroundColor?: string
}

export const ResactlyTextField = styled(
  ({ sx, fieldType, isEditBackgroundMode, editBackgroundColor, noneEditBackgroundColor, ...rest }: StyledProps & TextFieldProps) => (
    <TextField variant="outlined" size="small" fullWidth {...rest} sx={sx} />
  ),
)<StyledProps & TextFieldProps>(
  ({ theme, noneEditBackgroundColor, editBackgroundColor, fieldType, disabled, isEditBackgroundMode }) => ({
    '& .MuiOutlinedInput-root': {
      borderRadius: '8px',
      fontSize: '16px',
      fontWeight: 400,
      color:
        fieldType === 'outline'
          ? theme.palette.grey[800]
          : theme.palette.grey[400],

      backgroundColor:
        fieldType === 'outline'
          ? isEditBackgroundMode
            ? !disabled
              ? editBackgroundColor ?? theme.palette.background.paper
              : noneEditBackgroundColor ?? theme.palette.grey[50]
            : 'none'
          : 'rgba(255, 255, 255, 0.15)',
      '& fieldset': {
        borderRadius: '8px',
        borderColor: theme.palette.grey[300],
        borderWidth: fieldType === 'outline' ? '1px' : '0px',
      },
      '&:hover fieldset': {
        borderColor: theme.palette.grey[300],
        borderWidth: fieldType === 'outline' ? '1px' : '0px',
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.grey[300],
        borderWidth: fieldType === 'outline' ? '1px' : '0px',
      },
    },
    '& .MuiOutlinedInput-root.Mui-error': {
      '&:hover fieldset': {
        borderColor: theme.palette.error.main,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.error.main,
      },
    },
  }),
)
interface StyledLabelProps {
  label: string
  htmlFor: string
  isFieldRequired?: boolean
  isValid?: boolean
  labelWeight?: number
  labelFontSize?: string & {}
  labelColor?: string
  labelShrink?: boolean
}
export const ResactlyInputLabel: React.FC<StyledLabelProps> = ({
  isFieldRequired,
  labelFontSize,
  labelWeight = 600,
  label,
  htmlFor,
  labelColor,
  isValid,
  labelShrink = false,
}) => {
  const { theme } = useAppUtilities()
  return (
    <Box
      sx={{
        display: 'flex',
      }}
    >
      <InputLabel
        htmlFor={htmlFor}
        shrink={labelShrink}
        sx={{
          fontWeight: labelWeight,
          fontSize: labelFontSize ?? { xs: '12px', md: '14px' },
          color: labelColor ?? theme.palette.grey[500],
        }}
      >
        {label}
      </InputLabel>
      {isFieldRequired && (
        <Box
          sx={{
            color: isValid
              ? theme.palette.error.dark
              : labelColor ?? theme.palette.grey[500],
          }}
        >
          *
        </Box>
      )}
    </Box>
  )
}
