import React from 'react'
import useAppUtilities from '../../../../hooks/useAppUtilities'
import {
  HeroSectionAsOnContainer,
  HeroSectionContainer,
  HeroSectionContainerSideImg,
  HeroSectionGridContainer,
  HeroSectionGridContainerTypographyAsOn,
  HeroSectionGridContainerTypographyChip,
  HeroSectionGridContainerTypographyInfo,
  HeroSectionGridContainerTypographyTitle,
  HeroSectionGridContainerTypographyTitleSecond,
  HeroSectionImg,
  HeroSectionImgAsOnContainer,
  HeroSectionImgOne,
  HeroSectionImgTwo,
  HeroSectionInnerGridContent,
  HeroSectionInnerGridImg,
  HeroSectionMainContainer,
  HeroSectionMainGrid,
} from '../styled/HeroSection.styled'
import HeroImages from './HeroImages'

const HeroSection: React.FC = () => {
  const { theme, isMediumScreen } = useAppUtilities()

  const imgList = [
    '/images/homepage/fox-logo.png',
    '/images/homepage/cnbc-logo.png',
    '/images/homepage/abs-logo.png',
    '/images/homepage/cbs-logo.png',
  ]

  const imgListInSmallScreen = [
    '/images/homepage/fox-logo-small.png',
    '/images/homepage/cnbc-logo-small.png',
    '/images/homepage/abs-logo-small.png',
    '/images/homepage/cbs-logo-small.png',
  ]

  return (
    <HeroSectionMainContainer>
      <HeroSectionImgOne />
      <HeroSectionImgTwo />
      <HeroSectionContainer>
        <HeroSectionMainGrid>
          <HeroSectionInnerGridContent>
            <HeroSectionGridContainer>
              <HeroSectionGridContainerTypographyChip
                noWrap
                component="span"
                variant="subtitle2"
                textTransform={'uppercase'}
                color={theme.palette.secondary.dark}
              >
                A.I powered Recommendations
              </HeroSectionGridContainerTypographyChip>
              <HeroSectionGridContainerTypographyTitle
                noWrap
                component="p"
                variant="h1"
                color={theme.palette.primary.main}
              >
                We help you find the
              </HeroSectionGridContainerTypographyTitle>
              <HeroSectionGridContainerTypographyTitleSecond
                noWrap
                component="span"
                variant="h1"
                color={theme.palette.secondary.main}
              >
                best restaurants
              </HeroSectionGridContainerTypographyTitleSecond>
              <HeroSectionGridContainerTypographyInfo
                component="p"
                variant="body1"
                color={theme.palette.grey[700]}
              >
                Be one of the first to experience the future of dining with our
                Patent Pending AI Recommendation engine that knows your tastes
                better than you.
              </HeroSectionGridContainerTypographyInfo>

              {!isMediumScreen && (
                <div>
                  <HeroSectionGridContainerTypographyAsOn
                    component="p"
                    variant="subtitle1"
                    color={theme.palette.grey[500]}
                  >
                    As Seen On
                  </HeroSectionGridContainerTypographyAsOn>
                  <HeroSectionImgAsOnContainer>
                    {imgList.map((img) => (
                      <HeroSectionImg
                        alt="as-seen-on"
                        srcSet={img}
                        src={img}
                        loading="lazy"
                        key={img}
                      />
                    ))}
                  </HeroSectionImgAsOnContainer>
                </div>
              )}
            </HeroSectionGridContainer>
          </HeroSectionInnerGridContent>
          <HeroSectionInnerGridImg>
            <HeroSectionContainerSideImg>
              <HeroImages />
            </HeroSectionContainerSideImg>
          </HeroSectionInnerGridImg>
          {isMediumScreen && (
            <HeroSectionAsOnContainer>
              <HeroSectionGridContainerTypographyAsOn
                component="p"
                variant="subtitle1"
                color={theme.palette.grey[500]}
              >
                As Seen On
              </HeroSectionGridContainerTypographyAsOn>
              <HeroSectionImgAsOnContainer>
                {imgListInSmallScreen.map((img) => (
                  <HeroSectionImg
                    alt="as-seen-on"
                    srcSet={img}
                    src={img}
                    loading="lazy"
                    key={img}
                  />
                ))}
              </HeroSectionImgAsOnContainer>
            </HeroSectionAsOnContainer>
          )}
        </HeroSectionMainGrid>
      </HeroSectionContainer>
    </HeroSectionMainContainer>
  )
}

export default HeroSection
