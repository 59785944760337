import { Box, Grid } from '@mui/material'
import React from 'react'
import { ROUTES } from '../../helpers/routes-helper'
import { DEFAULT_CARD_IMAGE } from '../../helpers/static-constants'
import useAppUtilities from '../../hooks/useAppUtilities'
import { useDevice } from '../../hooks/useDevice'
import { MuiAlert, MuiSnackbar } from '../../pages/eater/styled/global.styled'
import { useAppSelector } from '../../store'
import {
  selectMessage,
  selectSeverity,
  selectShouldOpen,
  setOpen,
} from '../../store/restaurantSlice'
import { RestaurantAndPredictedRestaurant } from '../../types'
import RestaurantCard from './RestaurantCard'

interface RestaurantListProps {
  cardList: RestaurantAndPredictedRestaurant[]
  openMassager?: boolean
  mobileSearchHight: string
  noneMobileSearchHight: string
}

const RestaurantList: React.FC<RestaurantListProps> = ({
  cardList,
  openMassager,
  mobileSearchHight,
  noneMobileSearchHight,
}) => {
  const { navigate, dispatch } = useAppUtilities()
  const message = useAppSelector(selectMessage)
  const severity = useAppSelector(selectSeverity)
  const openServer = useAppSelector(selectShouldOpen)
  const { isHybrid } = useDevice()

  const setOpenServer = (): void => {
    dispatch(setOpen(false))
  }

  

  return (
    <Box
      sx={{
        mt: { xs: '16px', md: '40px' },
        mb: { md: '40px' },
        height: {
          xs: `calc(100vh - ${
            isHybrid ? mobileSearchHight : noneMobileSearchHight
          })`,
          md: '100%',
        },
        overflow: 'auto',
      }}
    >
      <Grid container spacing={2}>
        {cardList.map((card, index: number) => (
          <Grid
            key={index}
            item
            xs={12}
            sm={openMassager ? 12 : 6}
            md={openMassager ? 6 : 4}
            lg={4}
          >
            <div
              key={index}
              style={{
                height: '280px',
                width: '100%',
              }}
              className="restaurant-card-swiper-slide"
              onClick={() =>
                navigate(
                  `${ROUTES.RESTAURANT_DETAILS}/${card?.restaurantDTO?.alias}/details`,
                )
              }
            >
              <RestaurantCard
                id={card.restaurantDTO.restaurantID}
                alias={card?.restaurantDTO?.alias ?? ''}
                imgList={[
                  card?.restaurantDTO.imageurl ??
                  card?.restaurantDTO.imageurl !== ''
                    ? card?.restaurantDTO?.imageurl ?? DEFAULT_CARD_IMAGE
                    : DEFAULT_CARD_IMAGE,
                ]}
                title={card.restaurantDTO.name}
                isVerified={card?.restaurantDTO?.verified ?? false}
                subTitle={[
                  card?.restaurantDTO?.address1,
                  card?.restaurantDTO?.city,
                  card?.restaurantDTO?.state,
                ]
                  .filter(Boolean)
                  .join(', ')}
                distance={`${card?.restaurantDTO?.distance ?? ''}`}
                tagChipBgColor={'liteOrange'}
                tagChipData={[
                  {
                    percentage: `${card.value?.toLocaleString() ?? ''}`,
                    title: 'Match',
                  },
                  ...(card &&
                  card.groupRecommendations &&
                  card.groupRecommendations.length
                    ? [
                        {
                          percentage: `${
                            card.groupValue?.toLocaleString() ?? ''
                          }`,
                          title: 'Group Match',
                          matchData: card.groupRecommendations.map((item) => {
                            return {
                              name: item.name ?? '',
                              email: item?.email,
                              image:
                                'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQmJUeQCIV5gK-gudX5l3OIhRcmgnbtGDhExw&s',
                              percentage: `${item?.yes.toFixed(2)}`,
                            }
                          }),
                        },
                      ]
                    : []),
                ]}
              />
            </div>
          </Grid>
        ))}
      </Grid>
      <MuiSnackbar open={openServer} onClose={setOpenServer}>
        <MuiAlert onClose={setOpenServer} severity={severity ?? 'success'}>
          {message}
        </MuiAlert>
      </MuiSnackbar>
    </Box>
  )
}

export default RestaurantList
