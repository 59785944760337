import { Autocomplete, Box, Paper } from '@mui/material'
import { IconChevronDown } from '@tabler/icons-react'
import React, { useEffect, useImperativeHandle } from 'react'
import { useLocation } from 'react-router'
import useAppUtilities from '../../../hooks/useAppUtilities'
import { useAppSelector } from '../../../store'
import {
  selectIsNewRestaurant,
  selectLocateRestaurant,
  selectWebsite,
  setIsNewRestaurant,
  setLocateRestaurant,
  setRestaurant,
  setWebsiteUrl,
} from '../../../store/onboardSlice'
import {
  fetchOnboardRestaurants,
  selectIsLoading,
  selectOnboardRestaurants,
} from '../../../store/restaurantSlice'
import { IRestaurantName, OnboardRestaurantMethods } from '../../../types'
import useRudderStackAnalytics from '../../../useRudderAnalytics'
import { debugLog } from '../../../utils/log-helper'
import OnboardTitleTypography from './OnboardTitleTypography'
import { OnboardLocateYourRestaurantContainer } from './styled/OnboardLocateYourRestaurant.styled'
import InputComponent from '../../../components/ui/InputComponent'

interface OnboardRestaurantProps {
  title?: string
  subTitle?: string
  setNextButtonDisable: (boolean) => void
}

const newRestaurantData = {
  name: 'Add New Restaurant',
  restaurantID: 'Add New Restaurant',
  alias: '',
  website: '',
}
const OnboardLocateYourRestaurant = React.forwardRef<
  OnboardRestaurantMethods,
  OnboardRestaurantProps
>(function OnboardRestaurant(
  { title, subTitle, setNextButtonDisable },
  ref,
): JSX.Element {
  const { theme, dispatch, isSmallScreen } = useAppUtilities()
  const [analytics, isAnalyticsReady] = useRudderStackAnalytics()

  const isNewRestaurant = useAppSelector(selectIsNewRestaurant)
  const selectedWebsite = useAppSelector(selectWebsite)
  const isLoadingRestaurant = useAppSelector(selectIsLoading)
  const restaurants = useAppSelector(selectOnboardRestaurants)
  const locateRestaurant = useAppSelector(selectLocateRestaurant)
  const [website, setWebsite] = React.useState(selectedWebsite ?? '')
  const [websiteError, setWebsiteError] = React.useState<boolean>(false)
  const location = useLocation()
  const [restaurantAC, setRestaurantAC] = React.useState<
    IRestaurantName | undefined
  >(isNewRestaurant ? newRestaurantData : locateRestaurant)

  React.useEffect(() => {
    if (restaurantAC && restaurantAC.restaurantID === 'Add New Restaurant') {
      if (restaurantAC !== undefined && website.length > 0)
        setNextButtonDisable(false)
      setWebsite('')
    } else if (
      restaurantAC !== undefined &&
      website.length > 0 &&
      website === restaurantAC?.website
    ) {
      setNextButtonDisable(false)
    } else setNextButtonDisable(true)
  }, [restaurantAC, website])

  const augmentedOptions = React.useMemo(() => {
    return [newRestaurantData, ...restaurants]
  }, [restaurants])

  React.useEffect(() => {
    if (analytics && isAnalyticsReady) {
      analytics?.page(
        'PAGE_OPENED',
        {
          path: '/find-restaurant',
          referrer: 'https://www.resactly.com/choose-your-role',
          search: '',
          title: 'Resactly Onboarding - Find Restaurant',
          url: 'https://www.resactly.com/find-restaurant',
          startTime: new Date().toISOString(),
          category: 'FindRestaurant',
          name: 'FindRestaurant Viewed',
        },
        () => {
          debugLog('page call')
        },
      )
    }
  }, [analytics, isAnalyticsReady])

  React.useEffect(() => {
    return () => {
      analytics?.page(
        'PAGE_CLOSED',
        {
          category: 'FindRestaurant',
          name: 'FindRestaurant Viewed',
          path: '/find-restaurant',
          referrer: 'https://www.resactly.com/choose-your-role',
          search: '',
          title: 'Resactly Onboarding - Find Restaurant',
          url: 'https://www.resactly.com/find-restaurant',
          endTime: new Date().toISOString(),
        },
        () => {
          debugLog('page call')
        },
      )
    }
  }, [location])

  useEffect(() => {
    dispatch(fetchOnboardRestaurants()).catch(() => {
      debugLog('error in fetching restaurants')
    })
  }, [dispatch])

  const handleAutocompleteChange = (
    event: any,
    newRestaurant: IRestaurantName | null,
  ): void => {
    if (newRestaurant && newRestaurant.restaurantID === 'Add New Restaurant') {
      setRestaurantAC(newRestaurantData)
      setNextButtonDisable(false)
    } else {
      if (newRestaurant) {
        setRestaurantAC(newRestaurant)
      } else {
        setRestaurantAC(undefined)
      }
      setNextButtonDisable(!newRestaurant)
    }
  }

  const getPayload = (): void => {
    if (restaurantAC && restaurantAC.restaurantID === 'Add New Restaurant') {
      dispatch(setRestaurant(undefined))
      dispatch(setLocateRestaurant(undefined))
      dispatch(setIsNewRestaurant(true))
      dispatch(setWebsiteUrl(''))
    } else if (
      restaurantAC !== undefined &&
      website.length > 0 &&
      restaurantAC.restaurantID !== 'Add New Restaurant'
    ) {
      let cleansed = website.replace('https://', '')
      cleansed = cleansed.replace('http://', '')
      if (cleansed.indexOf('/') > 0) {
        cleansed = cleansed.substring(0, cleansed.indexOf('/'))
      }

      dispatch(setWebsiteUrl(website))
      dispatch(setIsNewRestaurant(false))
      dispatch(setLocateRestaurant(restaurantAC))
      dispatch(
        setRestaurant(
          restaurants.find(
            (restaurant) =>
              restaurant.website?.includes(cleansed) &&
              restaurant.restaurantID === restaurantAC.restaurantID,
          ),
        ),
      )
    } else {
      dispatch(setRestaurant(undefined))
    }
  }

  useImperativeHandle(ref, () => ({ getPayload }))

  const getOptionKey = (option: IRestaurantName): string =>
    `${option.restaurantID}-${option.name ?? ''}-${option.website ?? ''}`

  const onChangeWebsite = (link: string): void => {
    setWebsite(link)
    if (link === restaurantAC?.website) setWebsiteError(false)
    else setWebsiteError(true)
  }

  return (
    <div>
      <OnboardTitleTypography title={title ?? ''} subTitle={subTitle ?? ''} />

      <OnboardLocateYourRestaurantContainer>
        <div>
          <Autocomplete
            size="small"
            fullWidth
            autoHighlight
            PaperComponent={(props) => (
              <Paper
                {...props}
                sx={{
                  fontSize: '14px !important',
                  fontWeight: `400 !important`,
                }}
              />
            )}
            id="restaurant"
            loading={isLoadingRestaurant}
            sx={{
              width: isSmallScreen ? 'calc(100vw - 32px)' : '400px',
            }}
            value={restaurantAC}
            onChange={handleAutocompleteChange}
            options={augmentedOptions}
            popupIcon={
              <IconChevronDown
                stroke={2}
                size={22}
                style={{ color: theme.palette.grey[300] }}
              />
            }
            getOptionLabel={(option) => option.name}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                {...props}
                key={getOptionKey(option)}
              >
                {option.name}
              </Box>
            )}
            renderInput={(params) => (
              <InputComponent
                {...params}
                id="select-restaurant"
                label="Select your Restaurant"
                labelShrink
                labelFontSize="14px"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password',
                }}
                isEditBackgroundMode
                labelWeight={500}
                placeholder="Choose a country"
              />
            )}
          />
        </div>
        <OnboardLocateYourRestaurantContainer>
          <InputComponent
            id="website"
            label="Website Link"
            placeholder="Website"
            labelShrink
            labelFontSize="14px"
            labelWeight={500}
            value={website}
            disabled={
              restaurantAC && restaurantAC.restaurantID === 'Add New Restaurant'
            }
            setValue={onChangeWebsite}
            error={websiteError}
            helperText={
              websiteError
                ? `Your website link doesn't match with the restaurant data`
                : ''
            }
          />
        </OnboardLocateYourRestaurantContainer>
      </OnboardLocateYourRestaurantContainer>
    </div>
  )
})

export default OnboardLocateYourRestaurant
