import { configureStore } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { combineReducers } from 'redux'
import authReducer from './authSlice'
import eaterReducer from './eaterSlice'
import landingReducer from './landingSlice'
import onboardingReducer from './onboardSlice'
import profileReducer from './profileSlice'
import dashboardReducer from './restaurant/dashboard'
import restaurantMenuReducer from './restaurant/menu'
import restaurantSettingReducer from './restaurant/setting'
import restaurantReviewReducer from './restaurant/review'
import restaurantReducer from './restaurantSlice'
import searchReducer from './searchSlice'
import socialReducer from './socialSlice'
import restaurantMessageReducer from './restaurant/messages'

const rootReducer = combineReducers({
  auth: authReducer,
  eater: eaterReducer,
  search: searchReducer,
  restaurant: restaurantReducer,
  social: socialReducer,
  onboarding: onboardingReducer,
  profile: profileReducer,
  landing: landingReducer,
  restaurantDashboard: dashboardReducer,
  restaurantSetting: restaurantSettingReducer,
  restaurantMenu: restaurantMenuReducer,
  restaurantReview: restaurantReviewReducer,
  restaurantMessage: restaurantMessageReducer,
})
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const configureAppStore = (initialState = {}) => {
  const store = configureStore({
    reducer: rootReducer,
    preloadedState: initialState,
    devTools: process.env.NODE_ENV !== 'production',
  })
  return store
}

export const store = configureAppStore()
export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppStore = ReturnType<typeof configureAppStore>

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
