import { Box, useTheme } from '@mui/material'
import styled from 'styled-components'

export const NotificationsCardMainContainer = styled(Box).attrs(() => {
  const theme = useTheme()

  return {
    sx: {
      padding: 2,
      border: 1.5,
      borderRadius: '12px',
      borderColor: theme.palette.grey[200],
      backgroundColor: theme.palette.background.paper,
    },
  }
})(({ theme }) => ({}))

export const NotificationsCardInnerContainer = styled(Box).attrs(() => {
  const theme = useTheme()

  return {
    sx: {
      color: theme.palette.grey[500],
      marginTop: '8px',
    },
  }
})(({ theme }) => ({}))
