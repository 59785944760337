import {
  createAsyncThunk,
  createSlice,
  isAnyOf,
  PayloadAction,
} from '@reduxjs/toolkit'
import { RootState } from '..'
import { IUserStaticMessage, Restaurant } from '../../types'
import okaoAxios from '../../helpers/axios'
import { AlertColor } from '@mui/material'
import {
  IRestaurantAddImageRequest,
  IRestaurantAddMainImageRequest,
  IRestaurantDeleteImageRequest,
  IRestaurantDeleteMainImageRequest,
} from '../../types/restaurantSettingType'
import { loginAudit, updateRestaurantOnboard } from '../authSlice'
import axios from 'axios'

export interface IRestaurantSlice {
  isSettingEdit: boolean
  isValidData: boolean
  isUpdate: boolean
  isEditCancelled: boolean
  currentTab: string
  status: string
  restaurant: Restaurant | undefined
  message: string | undefined
  severity: AlertColor | undefined
  shouldOpen: boolean
}

const initialState: IRestaurantSlice = {
  isSettingEdit: false,
  isValidData: false,
  isUpdate: false,
  isEditCancelled: false,
  currentTab: 'basicDetails',
  status: 'idle',
  shouldOpen: false,
  message: undefined,
  severity: undefined,
  restaurant: undefined,
}

export const addRestaurantImageByIndex = createAsyncThunk<Restaurant,IRestaurantAddImageRequest>(
  'api/addRestaurantImageByIndex',
  async (payload: IRestaurantAddImageRequest) => {
    const formData = new FormData()
    formData.append('file', payload.file)
    const response = await okaoAxios.post(
      `/api/upload/restaurant/${payload.id}/other/${payload.index}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    )
    const data = {
      ...response.data,
      headers: { ...response.headers },
    }
    return data
  },
)

export const deleteRestaurantImageByIndex = createAsyncThunk<
  Restaurant,
  IRestaurantDeleteImageRequest
>('api/deleteRestaurantImageByIndex', async (payload, { rejectWithValue }) => {
  try {
    const response = await okaoAxios.delete(
      `/api/upload/restaurant/${payload.id}/other/${payload.index}`,
    )
    return response.data
  } catch (error) {
    return rejectWithValue(error)
  }
})
export const deleteRestaurantMainImage = createAsyncThunk<
  Restaurant,
  IRestaurantDeleteMainImageRequest
>('api/deleteRestaurantMainImage', async (image, { rejectWithValue,dispatch }) => {
  try {
    await okaoAxios.delete(
      `/api/upload/restaurant/${image.alias}/main`,
    )
    const res = await dispatch(fetchRestaurantAdmin({ alias: image.alias }))
    return res.payload as Restaurant
  } catch (error) {
    return rejectWithValue(error)
  }
})
export const addRestaurantMainImage = createAsyncThunk<
  Restaurant,
  IRestaurantAddMainImageRequest
>(
  'api/addRestaurantMainImage',
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const formData = new FormData()
      formData.append('file', payload.file)
      await okaoAxios.post(
        `/api/upload/restaurant/${payload.alias}/main`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )

      const res = await dispatch(fetchRestaurantAdmin({ alias: payload.alias }))

      return res.payload as Restaurant
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)
export const updateRestaurant = createAsyncThunk<Restaurant, Restaurant>(
  'api/updateRestaurants',
  async (restaurant, { rejectWithValue, dispatch }) => {
    try {
      const response = await okaoAxios.post('/restaurants', restaurant)
      await dispatch(fetchRestaurantAdmin({ alias: response.data.alias }))
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)
export const fetchRestaurantAdmin = createAsyncThunk<
  Restaurant,
  { alias: string },
  { rejectValue: { message: string; status?: number } }
>('api/fetchRestaurantAdmin', async (payload, { rejectWithValue }) => {
  try {
    const response = await okaoAxios.get(`/restaurants/${payload.alias}/admin`)
    return response.data
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return rejectWithValue({
        message: error.response?.data?.message || error.message,
        status: error.response?.status,
      })
    }
    return rejectWithValue({ message: 'An unknown error occurred' })
  }
})

export const restaurantSettingSlice = createSlice({
  name: 'restaurant-setting',
  initialState,
  reducers: {
    setOpen: (state, action: PayloadAction<boolean>) => {
      state.shouldOpen = action.payload
    },
    setOpenStaticMessage: (
      state,
      action: PayloadAction<IUserStaticMessage>,
    ) => {
      state.shouldOpen = action.payload.shouldOpen
      state.message = action.payload.message
      state.severity = action.payload.severity
      if (action.payload.status) {
        state.status = action.payload.status
      }
    },
    setIsSettingEdit: (state, action: PayloadAction<boolean>) => {
      state.isSettingEdit = action.payload
    },
    setIsValidData: (state, action: PayloadAction<boolean>) => {
      state.isValidData = action.payload
    },
    setIsUpdateData: (state, action: PayloadAction<boolean>) => {
      state.isUpdate = action.payload
    },
    setIsEditCancelledData: (state, action: PayloadAction<boolean>) => {
      state.isEditCancelled = action.payload
    },
    setCurrentTab: (state, action: PayloadAction<string>) => {
      state.currentTab = action.payload
    },
    setRestaurant: (state, action: PayloadAction<Restaurant>) => {
      state.restaurant = action.payload
    },
    clearData: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateRestaurant.fulfilled, (state, action) => {
        state.status = 'idle'
        state.shouldOpen = true
        state.severity = 'success'
        state.message = `${action.payload.name} restaurant updated successfully.`
      })
      .addCase(fetchRestaurantAdmin.fulfilled, (state, action) => {
        state.restaurant = action.payload
        state.status = 'idle'
      })
      .addMatcher(
        isAnyOf(loginAudit.fulfilled, updateRestaurantOnboard.fulfilled),
        (state, action) => {
          state.restaurant = action.payload?.restaurantDTO ?? []
          state.status = 'idle'
        },
      )
      .addMatcher(
        isAnyOf(
          deleteRestaurantMainImage.fulfilled,
          deleteRestaurantImageByIndex.fulfilled,
        ),
        (state, action) => {
          state.restaurant = action.payload
          state.status = 'idle'
          state.shouldOpen = true
          state.severity = 'success'
          state.message = `${action.payload.name} restaurant image uploaded successfully.`
        },
      )
      .addMatcher(
        isAnyOf(
          addRestaurantMainImage.fulfilled,
          addRestaurantImageByIndex.fulfilled,
        ),
        (state, action) => {
          state.restaurant = action.payload
          state.status = 'idle'
          state.shouldOpen = true
          state.severity = 'success'
          state.message = `${action.payload.name} restaurant image uploaded successfully.`
        },
      )
      .addMatcher(
        isAnyOf(
          fetchRestaurantAdmin.rejected,
          addRestaurantImageByIndex.rejected,
          deleteRestaurantImageByIndex.rejected,
          deleteRestaurantMainImage.rejected,
          addRestaurantMainImage.rejected,
          updateRestaurant.rejected,
        ),
        (state, action) => {
          state.shouldOpen = true
          state.severity = 'error'
          state.status = 'error'
          state.message =
            'Oops! Looks like something went wrong on our end. But don’t worry, a quick refresh might just do the trick! Give it a whirl and let’s try this again. If the problem persists, we’ll be here to sort it out!'
        },
      )
      .addMatcher(
        isAnyOf(
          addRestaurantMainImage.pending,
          deleteRestaurantMainImage.pending,
          deleteRestaurantImageByIndex.pending,
          addRestaurantImageByIndex.pending,
          fetchRestaurantAdmin.pending,
          updateRestaurant.pending,
        ),
        (state, action) => {
          state.status = 'loading'
        },
      )
  },
})

// export const selectStartDate = (state: RootState): string | null => {
//   return state?.restaurantDashboard.startDate
// }
export const selectIsSettingEdit = (state: RootState): boolean =>
  state.restaurantSetting.isSettingEdit
export const selectIsValidData = (state: RootState): boolean =>
  state.restaurantSetting.isValidData
export const selectIsUpdateData = (state: RootState): boolean =>
  state.restaurantSetting.isUpdate
export const selectIsEditCancelledData = (state: RootState): boolean =>
  state.restaurantSetting.isEditCancelled
export const selectCurrentTab = (state: RootState): string =>
  state.restaurantSetting.currentTab
export const selectRestaurant = (state: RootState): Restaurant | undefined =>
  state.restaurantSetting.restaurant
export const selectIsLoading = (state: RootState): boolean =>
  state.restaurantSetting.status === 'loading'
export const selectShouldOpen = (state: RootState): boolean =>
  state.restaurantSetting.shouldOpen
export const selectMessage = (state: RootState): string | undefined =>
  state.restaurantSetting.message
export const selectSeverity = (state: RootState): AlertColor | undefined =>
  state.restaurantSetting.severity

export const {
  setOpen,
  setIsSettingEdit,
  setIsValidData,
  setIsUpdateData,
  setIsEditCancelledData,
  setCurrentTab,
  clearData,
  setOpenStaticMessage,
} = restaurantSettingSlice.actions

export default restaurantSettingSlice.reducer
