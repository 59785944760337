import { AlertColor } from '@mui/material'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '..'
import { IChatUser } from '../../types/messagesTypes'

export interface IReviewSlice {
  message: string | undefined
  severity: AlertColor | undefined
  shouldOpen: boolean
  status: string
  users: IChatUser[]
  selectedUser: IChatUser | undefined
}

const initialState: IReviewSlice = {
  status: 'idle',
  shouldOpen: false,
  message: undefined,
  severity: undefined,
  users: [],
  selectedUser: undefined,
}

export const messageSlice = createSlice({
  name: 'restaurant-message',
  initialState,
  reducers: {
    setOpen: (state, action: PayloadAction<boolean>) => {
      state.shouldOpen = action.payload
    },

    setSelectedUser: (state, action: PayloadAction<IChatUser | undefined>) => {
      state.selectedUser = action.payload
    },
    clearData: () => initialState,
  },
})

export const selectIsLoading = (state: RootState): boolean =>
  state?.restaurantMessage?.status === 'loading'
export const selectShouldOpen = (state: RootState): boolean =>
  state?.restaurantMessage?.shouldOpen
export const selectMessage = (state: RootState): string | undefined =>
  state?.restaurantMessage?.message
export const selectSeverity = (state: RootState): AlertColor | undefined =>
  state?.restaurantMessage?.severity

export const selectSelectedUser = (state: RootState): IChatUser | undefined =>
  state?.restaurantMessage?.selectedUser

export const { setOpen, setSelectedUser, clearData } = messageSlice.actions

export default messageSlice.reducer
