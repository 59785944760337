import { Box } from '@mui/material'
import React from 'react'
import { Outlet } from 'react-router-dom'
import useAppUtilities from '../../hooks/useAppUtilities'

const RestaurantPageWrapper: React.FC = () => {
  const { theme } = useAppUtilities()

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        height: {
          xs: 'calc(100vh - 88px)',
          sm: 'calc(100vh - 96px)',
          md: '100vh',
        },
      }}
    >
      <Box
        sx={{
          marginTop: { md: '24px' },
          marginRight: { md: '24px' },
          paddingX: { xs: '12px', md: '20px' },
          paddingY: { xs: '0px', md: '20px' },
          height: { xs: '100%', md: 'calc(100% - 24px)' },
          borderTopLeftRadius: { md: '20px' },
          borderTopRightRadius: { md: '20px' },
          backgroundColor: theme.palette.background.default,
          position: 'relative',
        }}
      >
        <Outlet />
      </Box>
    </Box>
  )
}

export default RestaurantPageWrapper
