import { useAuth0 } from '@auth0/auth0-react'
import { Box } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Autoplay, EffectFade, Navigation, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Swiper as SwiperType } from 'swiper/types'
import useAppUtilities from '../../hooks/useAppUtilities'
import { useAppSelector } from '../../store'
import { selectSavedRestaurantIds } from '../../store/profileSlice'
import { favoriteRestaurant } from '../../store/restaurantSlice'
import {
  RestaurantCardImgSectionCircularProgress,
  RestaurantCardImgSectionContainer,
  RestaurantCardImgSectionIconBookmark,
  RestaurantCardImgSectionIconBookmarkFilled,
  RestaurantCardImgSectionIconBookmarkFilledContainer,
  RestaurantCardImgSectionSkeletonContainer,
  RestaurantCardImgSectionTagChipContainer,
  RestaurantCardInfoContainer,
  RestaurantCardInfoDescriptionContainer,
  RestaurantCardInfoDescriptionDistanceTypography,
  RestaurantCardInfoDescriptionIconPointFilled,
  RestaurantCardInfoDescriptionTypography,
  RestaurantCardInfoTitleContainer,
  RestaurantCardInfoTitleIconRosetteDiscountCheck,
  RestaurantCardInfoTitleTypography,
} from '../styled/restaurant/RestaurantCard.styled'
import ImgIcon from '../ui/ImgIcon'
import TagChip, { IMatchData } from '../ui/TagChip'

interface Data {
  percentage: string
  title?: string
  matchData?: IMatchData[]
}

export interface RestaurantCardProps {
  id: string
  title: string
  subTitle: string
  imgList: string[]
  isVerified: boolean
  alias?: string
  distance?: string
  tagChipData?: Data[]
  tagChipBgColor?: 'orange' | 'liteOrange' | 'white'
}

const RestaurantCard: React.FC<RestaurantCardProps> = ({
  id,
  title,
  imgList,
  subTitle,
  distance,
  tagChipData,
  isVerified,
  alias = '',
  tagChipBgColor = 'orange',
}) => {
  const { dispatch, isMediumScreen } = useAppUtilities()
  const { isAuthenticated } = useAuth0()
  const saveRestaurants = useAppSelector(selectSavedRestaurantIds)
  const [isSaved, setIsSaved] = useState(false)
  const [staticLoader, setStaticLoader] = useState(false)
  const [loadingImage, setLoadingImage] = useState(true)
  const swiperRef = useRef<SwiperType | null>(null)

  useEffect(() => {
    if (swiperRef.current?.autoplay) {
      swiperRef.current.autoplay.stop()
    }
  }, [])

  useEffect(() => {
    if (saveRestaurants?.length) {
      const isSaved = saveRestaurants.includes(id ?? '')
      setIsSaved(isSaved)
    }
  }, [saveRestaurants])

  const handleMouseEnter = (): void => {
    swiperRef.current?.autoplay.start()
  }

  const handleMouseLeave = (): void => {
    swiperRef.current?.autoplay.stop()
  }

  const onClickBookmark = async (e): Promise<void> => {
    e.stopPropagation()
    setStaticLoader(true)
    await dispatch(favoriteRestaurant(alias))
    setStaticLoader(false)
  }

  return (
    <div>
      <RestaurantCardImgSectionContainer
        id="imgSection"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className="restaurant-card-swipe">
          <Swiper
            spaceBetween={30}
            centeredSlides={true}
            effect={imgList.length > 1 ? 'fade' : undefined}
            loop={imgList.length > 1}
            autoplay={
              imgList.length > 1
                ? {
                    delay: 100,
                    disableOnInteraction: false,
                  }
                : undefined
            }
            pagination={imgList.length > 1 ? { clickable: true } : undefined}
            grabCursor={imgList.length > 1}
            modules={[Autoplay, Pagination, Navigation, EffectFade]}
            className="mySwiper"
            onSwiper={(swiper) => {
              swiperRef.current = swiper
            }}
          >
            {imgList.map((img: string, index: number) => (
              <SwiperSlide
                key={index}
                virtualIndex={index}
                style={{
                  background: 'transparent',
                }}
              >
                {loadingImage && <RestaurantCardImgSectionSkeletonContainer />}
                <img
                  className="no-select"
                  src={img}
                  alt={`Resactly | ${title}`}
                  style={{
                    borderRadius: '20px',
                    objectFit: 'cover',
                    height: isMediumScreen ? '182px' : '200px',
                    display: !loadingImage ? 'block' : 'none',
                    filter: 'brightness(80%) saturate(115%)',
                  }}
                  onLoad={() => setLoadingImage(false)}
                  onError={() => setLoadingImage(false)}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <RestaurantCardImgSectionTagChipContainer>
          {isAuthenticated && tagChipData?.[0].percentage && (
            <TagChip data={tagChipData} bgColor={tagChipBgColor} />
          )}
        </RestaurantCardImgSectionTagChipContainer>
        <RestaurantCardImgSectionIconBookmarkFilledContainer
          id={isMediumScreen || isSaved ? '' : 'saveBtn'}
        >
          {isAuthenticated && (
            <ImgIcon
              onClick={(evt) => {
                void onClickBookmark(evt)
              }}
              alt="bookmark"
              iconElement={
                staticLoader ? (
                  <RestaurantCardImgSectionCircularProgress />
                ) : isSaved ? (
                  <RestaurantCardImgSectionIconBookmarkFilled />
                ) : (
                  <RestaurantCardImgSectionIconBookmark />
                )
              }
              wrapperStyle={{
                backgroundColor: 'rgba(255, 255, 255, 0.2)',
                height: '30px',
                width: '30px',
                boxShadow: `0px 0px 4px 0px #00000040`,
              }}
            />
          )}
        </RestaurantCardImgSectionIconBookmarkFilledContainer>
      </RestaurantCardImgSectionContainer>

      <RestaurantCardInfoContainer>
        <RestaurantCardInfoTitleContainer>
          <RestaurantCardInfoTitleTypography>
            {title}
          </RestaurantCardInfoTitleTypography>
          {isVerified && (
            <Box>
              <RestaurantCardInfoTitleIconRosetteDiscountCheck />
            </Box>
          )}
        </RestaurantCardInfoTitleContainer>
        <RestaurantCardInfoDescriptionContainer>
          <RestaurantCardInfoDescriptionTypography>
            {subTitle}
          </RestaurantCardInfoDescriptionTypography>
          {distance && (
            <>
              <RestaurantCardInfoDescriptionIconPointFilled />
              <RestaurantCardInfoDescriptionDistanceTypography>
                {distance} Mi
              </RestaurantCardInfoDescriptionDistanceTypography>
            </>
          )}
        </RestaurantCardInfoDescriptionContainer>
      </RestaurantCardInfoContainer>
    </div>
  )
}

export default RestaurantCard
