import { Box, Typography, useTheme } from '@mui/material'
import styled from 'styled-components'
import CustomButton from '../../../../components/ui/CustomButton'
import { IconPlus } from '@tabler/icons-react'

export const SocialHeaderMainContainer = styled(Box).attrs(() => ({
  sx: {
    pt: '46px',
    pb: '36px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}))(() => ({}))

export const SocialHeaderCustomButton = styled(CustomButton).attrs(() => ({
  sx: {
    py: '10px',
  },
}))(() => {
  const theme = useTheme()
  return {
    backgroundColor: theme.palette.background.paper,
    borderColor: theme.palette.primary.light,
    color: theme.palette.primary.light,
  }
})

export const SocialHeaderTypography = styled(Typography).attrs(() => ({
  variant: 'h3',
}))(() => {
  const theme = useTheme()
  return {
    color: theme.palette.grey[800],
  }
})

export const SocialHeaderIconPlus = styled(IconPlus).attrs(() => ({
  size: 18,
  stroke: 2,
}))(() => ({}))
