import styled from 'styled-components'
import React from 'react'
import InputComponent from '../../../../components/ui/InputComponent'

interface BasicDetailTextFieldsProps {
  isEditMode: boolean
}

export const BasicDetailTextField = styled(({ isEditMode, ...rest }) => (
  <InputComponent {...rest} />
)).attrs<BasicDetailTextFieldsProps>(({ isEditMode }) => {
  return {
    fullWidth: true,
    multiline: true,
    rows: 8,
    disabled: !isEditMode,
    isEditBackgroundMode: true,
    sx: {
      marginTop: '6px',
    },
  }
})<BasicDetailTextFieldsProps>(() => ({}))
