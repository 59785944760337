import { IconPlus, IconTrash } from '@tabler/icons-react'
import React, { useEffect, useState } from 'react'
import useAppUtilities from '../../../../hooks/useAppUtilities'
import { useAppSelector } from '../../../../store'
import {
  addRestaurantMainImage,
  deleteRestaurantMainImage,
  selectIsSettingEdit,
  selectIsUpdateData,
  selectRestaurant,
  setIsEditCancelledData,
  setIsSettingEdit,
  setIsUpdateData,
  setIsValidData,
  updateRestaurant,
} from '../../../../store/restaurant/setting'
import {
  ContentTypography,
  IconActionDeleteButton,
  ImagePlaceholderBox,
  ImageUploadBox,
  InfoBox,
  LabelTypography,
  MainBox,
} from '../styled/ProfileInfoCard.styled'
import { Box, Skeleton } from '@mui/material'
import { IRestaurantAddMainImageRequest } from '../../../../types/restaurantSettingType'
import InputComponent from '../../../../components/ui/InputComponent'

const ProfileInfoCard: React.FC<{ isMediumScreen: boolean }> = ({
  isMediumScreen,
}) => {
  const { theme, dispatch } = useAppUtilities()
  const isEditMode = useAppSelector(selectIsSettingEdit)
  const restaurant = useAppSelector(selectRestaurant)
  const isUpdateMode = useAppSelector(selectIsUpdateData)
  const [name, setName] = useState<string>(restaurant?.name ?? '')
  const [imageUrl, setImageUrl] = useState<string | null>(
    restaurant?.imageurl ?? null,
  )
  const [isImageRender, setIsImageRender] = useState<boolean>(true)

  useEffect(() => {
    setImageUrl(restaurant?.imageurl ?? null)
  }, [restaurant])

  useEffect(() => {
    if (isUpdateMode) {
      void onUpdateRestaurant()
    }
  }, [isEditMode, isUpdateMode])

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ): Promise<void> => {
    const file = event.target.files?.[0]
    if (file) {
      const payload: IRestaurantAddMainImageRequest = {
        alias: restaurant?.alias ?? '',
        file,
      }
      await dispatch(addRestaurantMainImage(payload))
    }
  }

  const handleImageRemove = async (): Promise<void> => {
    await dispatch(
      deleteRestaurantMainImage({
        alias: restaurant?.alias ?? '',
      }),
    )
  }

  const onLoadImage = (): void => {
    setIsImageRender(true)
  }

  const onUpdateRestaurant = async (): Promise<void> => {
    if (restaurant && restaurant.name.trim() !== name.trim()) {
      await dispatch(
        updateRestaurant({
          ...restaurant,
          name,
        }),
      )
    }
    dispatch(setIsSettingEdit(false))
    dispatch(setIsUpdateData(false))
    dispatch(setIsValidData(false))
    dispatch(setIsEditCancelledData(false))
  }

  return (
    <MainBox
      sx={{
        padding: 2,
        border: 1.5,
        borderRadius: '12px',
        borderColor: theme.palette.grey[200],
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        flexDirection: { xs: 'row', md: 'column' },
        alignItems: { xs: 'center', md: 'start' },
        height: '100%',
      }}
    >
      <ImageUploadBox>
        {imageUrl ? (
          isImageRender ? (
            <Box
              component="img"
              src={imageUrl}
              alt="restaurant-profile"
              onLoadCapture={onLoadImage}
              sx={{
                borderRadius: '8px',
                width: isMediumScreen ? '114px' : '100%',
                height: '100%',
                objectFit: 'cover',
                aspectRatio: { xs: 1.6 / 2, md: 5 / 3 },
              }}
            />
          ) : (
            <Skeleton
              variant="rectangular"
              sx={{
                borderRadius: '8px',
                width: isMediumScreen ? '114px' : '100%',
                height: '100%',
                aspectRatio: { xs: 1.6 / 2, md: 5 / 3 },
              }}
            />
          )
        ) : (
          <ImagePlaceholderBox
            isEditMode={isEditMode}
            isMediumScreen={isMediumScreen}
          >
            {isEditMode && (
              <>
                <IconPlus stroke={2} color={theme.palette.grey[500]} />
                <input
                  type="file"
                  accept="image/*"
                  onChange={(evt) => {
                    void handleImageUpload(evt)
                  }}
                  style={{
                    position: 'absolute',
                    opacity: 0,
                    cursor: 'pointer',
                    width: '100%',
                    height: '100%',
                  }}
                />
              </>
            )}
          </ImagePlaceholderBox>
        )}

        {isEditMode && imageUrl && (
          <>
            <IconActionDeleteButton
              onClick={() => {
                void handleImageRemove()
              }}
            >
              <IconTrash stroke={2} color={theme.palette.common.white} />
            </IconActionDeleteButton>
          </>
        )}
      </ImageUploadBox>
      <InfoBox>
        <LabelTypography>Restaurant Name</LabelTypography>
        {isEditMode ? (
          <InputComponent
            id="name"
            placeholder="Enter Name"
            value={name}
            setValue={(name) => setName(name)}
          />
        ) : (
          <ContentTypography>{name}</ContentTypography>
        )}

        <LabelTypography mt="16px">Email</LabelTypography>
        <ContentTypography>{restaurant?.contact_email}</ContentTypography>
      </InfoBox>
    </MainBox>
  )
}

export default ProfileInfoCard
