import { Typography } from '@mui/material'
import React from 'react'
import useAppUtilities from '../../../../hooks/useAppUtilities'
import {
  NotificationsCardInnerContainer,
  NotificationsCardMainContainer,
} from '../styled/NotificationsCard.styled'

export interface NotificationsCardProps {
  text: string
  time: string
  date: string
  active: boolean
}

const NotificationsCard: React.FC<NotificationsCardProps> = ({
  active,
  date,
  text,
  time,
}) => {
  const { theme } = useAppUtilities()

  return (
    <NotificationsCardMainContainer>
      <Typography
        variant="subtitle1"
        sx={{
          fontWeight: active ? 500 : 400,
          color: active ? theme.palette.grey[800] : theme.palette.grey[600],
        }}
      >
        {text}
      </Typography>

      <NotificationsCardInnerContainer>
        <Typography component="span" variant="subtitle2">
          {time}
        </Typography>
        ,{' '}
        <Typography component="span" variant="subtitle2">
          {date}
        </Typography>
      </NotificationsCardInnerContainer>
    </NotificationsCardMainContainer>
  )
}

export default NotificationsCard
