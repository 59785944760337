import { Box, Menu, MenuItem, Typography, useTheme } from '@mui/material'
import { IconPointFilled } from '@tabler/icons-react'
import styled from 'styled-components'

export const SummaryListHighlightTypography = styled(Typography).attrs(() => {
  return {
    variant: 'subtitle1',
    component: 'span',
    sx: {
      backgroundColor: '#e0e0e0',
      borderRadius: '4px',
      padding: '0 4px',
      margin: '0 2px',
    },
  }
})(({ theme }) => ({}))

export const SummaryListItemTextMainContainer = styled(Box).attrs(() => {
  return {
    sx: {
      display: 'flex',
      alignItems: 'start',
      justifyContent: 'space-between',
    },
  }
})(({ theme }) => ({}))

export const SummaryListItemTextInnerContainer = styled(Box).attrs(() => {
  return {
    sx: {
      display: 'flex',
      alignItems: 'start',
    },
  }
})(({ theme }) => ({}))

export const SummaryListTypography = styled(Typography).attrs(() => {
  const theme = useTheme()

  return {
    variant: 'subtitle1',
    sx: {
      color: theme.palette.grey[800],
    },
  }
})(({ theme }) => ({}))

export const SummaryListSubTitleTypography = styled(Typography).attrs(() => {
  const theme = useTheme()

  return {
    variant: 'subtitle2',
    sx: {
      color: theme.palette.grey[400],
    },
  }
})(({ theme }) => ({}))

export const SummaryListItemTextMainWrapper = styled(Box).attrs(() => {
  return {
    sx: {
      marginLeft: '4px',
    },
  }
})(({ theme }) => ({}))

export const SummaryListItemTextInnerWrapper = styled(Box).attrs(() => {
  return {
    sx: {
      display: 'flex',
      alignItems: 'center',
      marginTop: '8px',
    },
  }
})(({ theme }) => ({}))

export const SummaryListIconPointFilled = styled(IconPointFilled).attrs(() => {
  const theme = useTheme()

  return {
    stroke: 2,
    color: theme.palette.grey[400],
    height: '10px',
    width: '10px',
    margin: '0 4px',
  }
})(({ theme }) => ({}))

export const SummaryListMenu = styled(Menu).attrs(() => {
  return {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'right',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'right',
    },
    TransitionProps: { timeout: 300 },
    sx: {
      '& .MuiPaper-root': { borderRadius: '8px' },
    },
  }
})(({ theme }) => ({}))

export const SummaryListMenuItem = styled(MenuItem).attrs(() => {
  return {
    sx: {
      minHeight: '30px',
    },
  }
})(({ theme }) => ({}))
