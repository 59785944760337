import React, { useState } from 'react'
import ResactlyTab from '../../../../../../components/ResactlyTab'
import {
  CloseButton,
  SelectedDishSectionMainBox,
} from '../../../styled/competitorInsights/dish/SelectedDishSection.styled'
import DashboardDishDetail from './DashboardDishDetail'
import DashboardSimilarDishDetail from './DashboardSimilarDishDetail'
import SimilarDishes from './SimilarDishes'
import { Dish } from '../../../../../../types/menuTypes'

interface SelectedDishSectionProps {
  closeDrawer: () => void
}

const SelectedDishSection: React.FC<SelectedDishSectionProps> = ({
  closeDrawer,
}) => {
  const [selectedSimilarDish, setSelectedSimilarDish] = useState<
    Dish | undefined
  >()

  const tabComponents = [
    <DashboardDishDetail key="dish-details" />,
    <SimilarDishes
      key="similar-dishes"
      setSimilarSelectedDish={setSelectedSimilarDish}
    />,
  ]

  return (
    <SelectedDishSectionMainBox>
      {!selectedSimilarDish ? (
        <ResactlyTab
          list={tabList}
          tabComponent={tabComponents}
          align="space-between"
          filterSlot={<CloseButton onClick={() => closeDrawer()} />}
        />
      ) : (
        <DashboardSimilarDishDetail
          setSimilarSelectedDish={setSelectedSimilarDish}
          similarDish={selectedSimilarDish}
        />
      )}
    </SelectedDishSectionMainBox>
  )
}

export default SelectedDishSection

const tabList = [
  { label: 'Dish Details', value: 'dish-details' },
  { label: 'Dishes with Similar Ingredients', value: 'similar-dishes' },
]
