import { IconButton, List, ListItem, ListItemText } from '@mui/material'
import { IconDotsVertical } from '@tabler/icons-react'
import dayjs from 'dayjs'
import React, { useEffect, useMemo, useState } from 'react'
import EmptyScreen from '../../../../components/EmptyScreen'
import useAppUtilities from '../../../../hooks/useAppUtilities'
import { useAppSelector } from '../../../../store'
import { selectAdminReview } from '../../../../store/restaurant/review'
import {
  SummaryListHighlightTypography,
  SummaryListIconPointFilled,
  SummaryListItemTextInnerContainer,
  SummaryListItemTextInnerWrapper,
  SummaryListItemTextMainContainer,
  SummaryListItemTextMainWrapper,
  SummaryListMenu,
  SummaryListMenuItem,
  SummaryListSubTitleTypography,
  SummaryListTypography,
} from '../styled/SummaryList.styled'
import ReviewRespondDialog from './ReviewRespondDialog'
import { ROUTES } from '../../../../helpers/routes-helper'
import { IRestaurantSentimentReview } from '../../../../types/reviewTypes'
import { Eater } from '../../../../types'
import { MuiAlert, MuiSnackbar } from '../../../eater/styled/global.styled'
import { setSelectedUser } from '../../../../store/restaurant/messages'
import { DEFAULT_AVATAR_IMAGE } from '../../../../helpers/static-constants'

interface SummaryListProps {
  likeSelectedWord: string[] | []
  dislikeSelectedWord: string[] | []
  onSelectedWord: (word: Record<string, number | null>) => void
}

const SummaryList: React.FC<SummaryListProps> = ({
  likeSelectedWord,
  dislikeSelectedWord,
  onSelectedWord,
}) => {
  const { theme, isMediumScreen, navigate, dispatch } = useAppUtilities()
  const adminReview = useAppSelector(selectAdminReview)
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null)
  const [modalOpen, setModalOpen] = useState(false)
  const [, setSelectedReviewId] = useState<string | null>(null)
  const [selectedWord, setSelectedWord] = useState<string[] | []>([])
  const [selectEater, setSelectEater] = useState<Eater | undefined>()
  const [openServer, setOpenServer] = useState(false)

  useEffect(() => {
    setSelectedWord([...likeSelectedWord, ...dislikeSelectedWord])
  }, [likeSelectedWord, dislikeSelectedWord, adminReview?.reviews])

  const wordCounts = useMemo(() => {
    if (!adminReview?.reviews || selectedWord.length === 0) return {}

    const counts: Record<string, number> = {}

    const allReviewsText = adminReview.reviews
      .map((review) => review.review.toLowerCase())
      .join(' ')

    selectedWord.forEach((word) => {
      const regex = new RegExp(`\\b${word.toLowerCase()}\\b`, 'g')
      const matches = allReviewsText.match(regex)
      counts[word] = matches ? matches.length : 0
    })

    return counts
  }, [selectedWord, adminReview?.reviews])

  useEffect(() => {
    onSelectedWord(wordCounts)
  }, [wordCounts])

  const openMenu = (
    event: React.MouseEvent<HTMLElement>,
    review: IRestaurantSentimentReview,
  ): void => {
    setMenuAnchorEl(event.currentTarget)
    setSelectedReviewId(review.sentimentReviewId)
    setSelectEater(review.eater)
  }

  const closeMenu = (): void => {
    setMenuAnchorEl(null)
    setSelectedReviewId(null)
  }

  const handleRespondClick = (): void => {
    if (selectEater) {
      dispatch(
        setSelectedUser({
          id: selectEater?.eaterId ?? '',
          name: `${selectEater?.firstName} ${selectEater?.lastName}`,
          email: [selectEater?.email ?? ''],
          role: 'customer',
          photoUrl:
            selectEater?.imageUrl && selectEater?.imageUrl.length > 0
              ? selectEater?.imageUrl
              : DEFAULT_AVATAR_IMAGE,
          phone: [selectEater?.phone ?? ''],
        }),
      )
      navigate(ROUTES.RESTAURANT_MESSAGES)
    } else {
      setOpenServer(true)
    }
    closeMenu()
  }

  const onHandleReview = (): void => {
    setModalOpen(false)
  }

  const onHandleModalReviewCancel = (): void => {
    setModalOpen(false)
  }

  const highlightWords = (content: string, words: string[]): JSX.Element => {
    const parts = content.split(new RegExp(`(${words.join('|')})`, 'gi'))
    return (
      <>
        {parts.map((part, idx) =>
          words.includes(part.toLowerCase()) ? (
            <SummaryListHighlightTypography key={idx}>
              {part}
            </SummaryListHighlightTypography>
          ) : (
            part
          ),
        )}
      </>
    )
  }

  const filteredReviews = useMemo(() => {
    if (selectedWord.length === 0) return adminReview?.reviews
    return adminReview?.reviews.filter((review) =>
      selectedWord.some((word) =>
        review.review.toLowerCase().includes(word.toLowerCase()),
      ),
    )
  }, [selectedWord])

  return (
    <>
      <List>
        {filteredReviews && filteredReviews.length > 0 ? (
          filteredReviews?.map((review, index) => (
            <ListItem key={index} sx={{ padding: 0, marginBottom: '18px' }}>
              <ListItemText
                primary={
                  <SummaryListItemTextMainContainer>
                    <SummaryListItemTextInnerContainer>
                      <SummaryListTypography>
                        {index + 1}.
                      </SummaryListTypography>
                      <SummaryListItemTextMainWrapper>
                        <SummaryListTypography>
                          {highlightWords(review?.review, selectedWord)}
                        </SummaryListTypography>
                        <SummaryListItemTextInnerWrapper>
                          <SummaryListSubTitleTypography>
                            {`${review?.eater?.firstName ?? ''} ${
                              review?.eater?.lastName ?? ''
                            }`}
                          </SummaryListSubTitleTypography>
                          <SummaryListIconPointFilled />
                          <SummaryListSubTitleTypography>
                            {dayjs(review?.created).format('D MMM, YYYY')}
                          </SummaryListSubTitleTypography>
                        </SummaryListItemTextInnerWrapper>
                      </SummaryListItemTextMainWrapper>
                    </SummaryListItemTextInnerContainer>
                    <IconButton onClick={(event) => openMenu(event, review)}>
                      <IconDotsVertical
                        stroke={2}
                        color={theme.palette.grey[500]}
                        size={20}
                      />
                    </IconButton>
                  </SummaryListItemTextMainContainer>
                }
              />
            </ListItem>
          ))
        ) : (
          <EmptyScreen
            mainText="No data available"
            secondaryText="No reviews found"
            imageUrl="/images/empty-screen.svg"
            containerHeight={isMediumScreen ? '40vh' : '400px'}
          />
        )}

        <SummaryListMenu
          anchorEl={menuAnchorEl}
          open={Boolean(menuAnchorEl)}
          onClose={closeMenu}
        >
          <SummaryListMenuItem onClick={handleRespondClick}>
            <SummaryListTypography>Respond</SummaryListTypography>
          </SummaryListMenuItem>
        </SummaryListMenu>
      </List>
      <ReviewRespondDialog
        handleClose={() => setModalOpen(false)}
        onCancel={onHandleModalReviewCancel}
        onConfirm={onHandleReview}
        open={modalOpen}
        title="Respond to “@sheldoncooper”"
      />

      <MuiSnackbar open={openServer} onClose={() => setOpenServer(false)}>
        <MuiAlert onClose={() => setOpenServer(false)} severity={'error'}>
          {'Eater Data not found'}
        </MuiAlert>
      </MuiSnackbar>
    </>
  )
}

export default React.memo(SummaryList)
