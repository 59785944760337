import { Box, Fade, Grid, Skeleton } from '@mui/material'
import { IconPlus } from '@tabler/icons-react'
import React, { useEffect, useState } from 'react'
import EmptyScreen from '../../../components/EmptyScreen'
import CustomButton from '../../../components/ui/CustomButton'
import useAppUtilities from '../../../hooks/useAppUtilities'
import DishCard from './components/DishCard'
import DishDetail from './components/DishDetail'
import DishHeader from './components/DishHeader'
import { AddDishButton, DishBox } from './styled/Dish.styled'
import {
  fetchMenuById,
  selectedMenu,
  selectMessage,
  selectSeverity,
  selectShouldOpen,
  setOpen,
} from '../../../store/restaurant/menu'
import { RootState, useAppSelector } from '../../../store'
import { useParams } from 'react-router'
import { MuiAlert, MuiSnackbar } from '../../eater/styled/global.styled'
import { IDishStatus } from '../../../types/menuTypes'

const Dish: React.FC = () => {
  const { theme, isMediumScreen, dispatch } = useAppUtilities()
  const { id } = useParams()
  const openServer = useAppSelector(selectShouldOpen)
  const message = useAppSelector(selectMessage)
  const severity = useAppSelector(selectSeverity)
  const menu = useAppSelector((state: RootState) => selectedMenu(state))
  const [isSelectedDish, setIsSelectedDish] = useState(false)
  const [showSidebar, setShowSidebar] = useState(false)
  const [isMenuLoaded, setIsMenuLoaded] = useState<boolean>(false)

  const [addDialog, setAddDialog] = useState<boolean>(false)

  useEffect(() => {
    if (id) {
      void fetchMenu(id)
    }
  }, [dispatch, id])

  useEffect(() => {
    if (isSelectedDish) {
      const timeout = setTimeout(() => setShowSidebar(true), 300)
      return () => clearTimeout(timeout)
    } else {
      setShowSidebar(false)
    }
  }, [isSelectedDish])

  const fetchMenu = async (id: string): Promise<void> => {
    setIsMenuLoaded(true)
    await dispatch(fetchMenuById({ id }))
    setIsMenuLoaded(false)
  }

  const setOpenServer = (): void => {
    dispatch(setOpen(false))
  }

  return (
    <>
      {isMediumScreen && <AddDishButton onClick={() => setAddDialog(true)} />}

      <DishHeader isAddDialogOpen={addDialog} setAddDialogOpen={setAddDialog} />

      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          sm={12}
          md={isSelectedDish ? 9 : 12}
          sx={{
            transition:
              'flex-basis 300ms ease-in-out, max-width 300ms ease-in-out',
          }}
        >
          <DishBox>
            {!isMenuLoaded ? (
              menu?.dishes &&
              menu?.dishes?.filter(
                (item) => item.status !== IDishStatus.DELETED,
              ).length > 0 ? (
                <Grid container spacing={2}>
                  {menu?.dishes
                    ?.filter((item) => item.status !== IDishStatus.DELETED)
                    .map((dish, i) => (
                      <Fade key={i} in={!isMenuLoaded} timeout={i * 500}>
                        <Grid item xs={6} sm={4} md={3} lg={2}>
                          <DishCard dish={dish} onSelect={setIsSelectedDish} />
                        </Grid>
                      </Fade>
                    ))}
                </Grid>
              ) : (
                <EmptyScreen
                  imageUrl="/new/images/empty-menu-img.svg"
                  containerHeight={'100%'}
                  secondaryText="No dishes to display! Click below to create your first one."
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: '14px',
                    }}
                  >
                    <CustomButton
                      icon={<IconPlus size={18} stroke={2} />}
                      title="Add Dish"
                      sx={{
                        backgroundColor: theme.palette.primary.light,
                        color: theme.palette.common.white,
                        padding: '8px 16px',
                      }}
                      onClick={() => setAddDialog(true)}
                    />
                  </Box>
                </EmptyScreen>
              )
            ) : (
              <Grid container spacing={2}>
                {Array.from({ length: 4 }).map((_, index) => (
                  <Grid item xs={6} sm={4} md={3} key={index}>
                    <Skeleton
                      key={index}
                      variant="rectangular"
                      sx={{
                        borderRadius: 4,
                        width: '100%',
                        height: { xs: '41vw', sm: '32vw', md: '18vw' },
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            )}
          </DishBox>
        </Grid>
        {!isMediumScreen &&
          menu?.dishes &&
          menu?.dishes?.length > 0 &&
          showSidebar && (
            <Grid
              item
              md={3}
              sx={{
                animation: 'moveInRight 90ms ease-in',
                '@keyframes moveInRight': {
                  '0%': { opacity: 0, transform: 'scale(0.8)' },
                  '100%': { opacity: 1, transform: 'scale(1)' },
                },
              }}
            >
              <DishDetail onSelect={setIsSelectedDish} />
            </Grid>
          )}
      </Grid>

      <MuiSnackbar open={openServer} onClose={setOpenServer}>
        <MuiAlert onClose={setOpenServer} severity={severity ?? 'success'}>
          {message}
        </MuiAlert>
      </MuiSnackbar>
    </>
  )
}

export default Dish
