import { Box, Typography, useTheme } from '@mui/material'
import { IconStar } from '@tabler/icons-react'
import styled from 'styled-components'

export const SummaryRatingCardMainContainer = styled(Box).attrs(() => {
  const theme = useTheme()
  return {
    sx: {
      py: { xs: '12px', md: '16px' },
      px: { xs: '14px', md: '20px' },
      borderRadius: { xs: '12px', md: '16px' },
      border: `2px solid ${theme.palette.grey[200]}`,
      bgcolor: theme.palette.common.white,
      height: '100%',
      display: 'flex',
      alignItems: 'start',
      justifyContent: 'space-between',
      flexDirection: 'column',
    },
  }
})(({ theme }) => ({}))

export const SummaryRatingCardHeaderWrapper = styled(Box).attrs(() => {
  return {
    gap: 1,
    sx: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'start',
      pb: '16px',
    },
  }
})(({ theme }) => ({}))

export const SummaryRatingCardContentWrapper = styled(Box).attrs(() => {
  return {
    gap: 1,
    display: 'flex',
    alignItems: 'center',
  }
})(({ theme }) => ({}))

export const SummaryRatingCardTitleTypography = styled(Typography).attrs(() => {
  const theme = useTheme()
  return {
    variant: 'subtitle1',
    color: theme.palette.grey[800],
    fontWeight: 500,
  }
})(({ theme }) => ({}))

export const SummaryRatingCardIconStar = styled(IconStar).attrs(() => {
  return {
    stroke: 1.5,
  }
})(() => {
  const theme = useTheme()
  return { color: theme.palette.secondary.main, fontSize: 28 }
})


export const SummaryRatingCardValueTypography = styled(Typography).attrs(() => {
  const theme = useTheme()
  return {
    variant: 'h4',
    color: theme.palette.grey[800],
    fontWeight: 600,
  }
})(({ theme }) => ({}))