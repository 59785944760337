import React from 'react'
import {
  SocialHeaderCustomButton,
  SocialHeaderIconPlus,
  SocialHeaderMainContainer,
  SocialHeaderTypography,
} from '../styled/SocialHeader.styled'
interface ISocialHeaderProp {
  onAddGroup: () => void
  tabName: string
}

const SocialHeader: React.FC<ISocialHeaderProp> = ({ onAddGroup, tabName }) => {
  return (
    <SocialHeaderMainContainer>
      <SocialHeaderTypography>Social</SocialHeaderTypography>
      {tabName === 'group' && (
        <SocialHeaderCustomButton
          title="New Group"
          textVariant="subtitle1"
          onClick={onAddGroup}
          startIcon={<SocialHeaderIconPlus />}
        />
      )}
    </SocialHeaderMainContainer>
  )
}

export default SocialHeader
