import React from 'react'
import {
  SummaryRatingCardContentWrapper,
  SummaryRatingCardHeaderWrapper,
  SummaryRatingCardIconStar,
  SummaryRatingCardMainContainer,
  SummaryRatingCardTitleTypography,
  SummaryRatingCardValueTypography,
} from '../styled/SummaryRatingCard.styled'

interface SummaryRatingCardProps {
  card: ISummaryRatingCard
}

export interface ISummaryRatingCard {
  title: string
  value: number
  icon: React.ReactElement
}

const SummaryRatingCard: React.FC<SummaryRatingCardProps> = ({ card }) => {
  return (
    <SummaryRatingCardMainContainer>
      <SummaryRatingCardHeaderWrapper>
        {card.icon}
        <SummaryRatingCardTitleTypography>
          {card.title}
        </SummaryRatingCardTitleTypography>
      </SummaryRatingCardHeaderWrapper>
      <SummaryRatingCardContentWrapper>
        <SummaryRatingCardIconStar />
        <SummaryRatingCardValueTypography>
          {card.value}
        </SummaryRatingCardValueTypography>
      </SummaryRatingCardContentWrapper>
    </SummaryRatingCardMainContainer>
  )
}

export default SummaryRatingCard
