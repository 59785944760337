import {
  AutocompleteQueryConfig,
  AutocompletedResult,
} from '@elastic/search-ui'
import ElasticsearchAPIConnector from '@elastic/search-ui-elasticsearch-connector'

const elasticConfig = {
  cloud: {
    id: process.env.REACT_APP_ELASTIC_CLOUD_ID ?? '',
  },
  apiKey: process.env.REACT_APP_ELASTIC_API_KEY ?? '',
}

const FIPSConnector = new ElasticsearchAPIConnector({
  ...elasticConfig,
  index: process.env.REACT_APP_SEARCH_FIPS_INDEX ?? '',
})

declare global {
  interface Window {
    maps: {}
  }
}

export const onCountyBaseFindFipsCode = async (
  countyName: string,
  stateName: string,
): Promise<AutocompletedResult> => {
  try {
    const autocompleteQueryConfig: AutocompleteQueryConfig = {
      results: {
        search_fields: {
          County: {},
          State: {},
        },
        result_fields: {},
        filters: [{ field: 'State', values: [stateName.trim()], type: 'all' }],
        resultsPerPage: 2,
      },
    }

    const results = await FIPSConnector.onAutocomplete(
      { searchTerm: countyName },
      autocompleteQueryConfig,
    )

    return results.autocompletedResults ?? []
  } catch (error) {
    return []
  }
}

export const findFipsByCountyState = async (
  county: string,
  countyState: string,
): Promise<string | null> => {
  try {
    const results = await onCountyBaseFindFipsCode(county, countyState)
    const findCode = results.find(
      (item) => item?.State?.raw?.trim() === countyState.trim(),
    )?.FIPS?.raw

    return findCode ?? null
  } catch (error) {
    return null
  }
}

export const getCountyAndStateByPlaceId = async (
  placeId: string,
): Promise<{ county: string; countyState: string } | null> => {
  try {
    const { Place } = (await google.maps.importLibrary(
      'places',
    )) as google.maps.PlacesLibrary
    const place = new Place({
      id: placeId,
      requestedLanguage: 'en',
    })
    await place.fetchFields({
      fields: [
        'displayName',
        'formattedAddress',
        'location',
        'addressComponents',
      ],
    })
    const county = place.addressComponents?.find((comp) =>
      comp.types.includes('administrative_area_level_2'),
    )?.longText
    const countyState = place.addressComponents?.find((comp) =>
      comp.types.includes('administrative_area_level_1'),
    )?.longText

    return county && countyState ? { county, countyState } : null
  } catch (error) {
    return null
  }
}
