import { Box, Link, Typography } from '@mui/material'
import {
  IconArrowDownRight,
  IconArrowUpRight,
  IconCircleArrowUpRight,
} from '@tabler/icons-react'
import React, { useEffect, useState } from 'react'
import InfoCircleTooltip from '../../../../components/dashboard/InfoCircleTooltip'
import useAppUtilities from '../../../../hooks/useAppUtilities'
import { IDashboardCard } from '../../../../types/dashboard'
import {
  CardBottomBox,
  CardMiddleBox,
  CardTitleBox,
  MainTopCard,
} from '../styled/TopCard.style'
import { useAppSelector } from '../../../../store'
import { selectStartDate } from '../../../../store/restaurant/dashboard'
import dayjs from 'dayjs'

interface ITopCardProps {
  card: IDashboardCard
  onCardClick: () => void
}

const TopCard: React.FC<ITopCardProps> = ({ card, onCardClick }) => {
  const fromDate = useAppSelector(selectStartDate)
  const { theme, isMediumScreen } = useAppUtilities()
  const [data, setDate] = useState<string>('')
  const [isHover, setIsHover] = useState<boolean>(false)

  useEffect(() => {
    void init()
  }, [fromDate])

  const init = async (): Promise<void> => {
    if (dayjs(fromDate).isSame(dayjs(), 'day')) {
      setDate('From today')
    } else if (dayjs(fromDate).isSame(dayjs().subtract(1, 'day'), 'day')) {
      setDate('From Yesterday')
    } else {
      setDate(`From ${dayjs(fromDate).format('D MMM, YYYY')}`)
    }
  }

  const handleModelOpen = (): void => {
    card.isModelShow && onCardClick()
  }

  return (
    <MainTopCard
      onClick={handleModelOpen}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      sx={{
        cursor: card.isModelShow ? 'pointer' : 'auto',
        position: 'relative',
      }}
    >
      <CardTitleBox>
        <Typography
          variant="subtitle1"
          color={theme.palette.grey[800]}
          fontWeight={500}
        >
          {card.title}
        </Typography>
        {card.isInfo && !isMediumScreen && (
          <InfoCircleTooltip
            data={
              <Box sx={{ p: 1 }}>
                <Typography variant="subtitle2" color={theme.palette.grey[800]}>
                  This number indicates how many times your restaurant has been
                  recommended to users based on
                </Typography>
                {/* TODO: Add link Properly */}
                <Link
                  href={`${
                    process.env.REACT_APP_SERVICE_URL ?? ''
                  }/restaurant/recommendations`}
                  underline="hover"
                  target="_parent"
                >
                  Recommendations Page
                </Link>
              </Box>
            }
          />
        )}
      </CardTitleBox>
      <CardMiddleBox>
        {card.icon}
        <Typography
          variant="h4"
          sx={{ fontWeight: 'bold', color: theme.palette.grey[800] }}
        >
          {card.value}
        </Typography>
      </CardMiddleBox>
      <CardBottomBox>
        <Box display="flex" alignItems="center">
          {card.percentageChange && Number(card.percentageChange) > 0 ? (
            <>
              <IconArrowUpRight
                stroke={2}
                style={{
                  color: theme.palette.success.dark,
                }}
                size={isMediumScreen ? 14 : 18}
              />
              <Typography
                variant="subtitle1"
                color={
                  Number(card.percentageChange) > 0
                    ? theme.palette.success.dark
                    : theme.palette.error.dark
                }
                fontWeight={{ xs: 500, md: 600 }}
                sx={{
                  fontSize: [10, 10, 12],
                }}
              >
                +
              </Typography>
            </>
          ) : (
            <>
              <IconArrowDownRight
                stroke={2}
                style={{
                  color: theme.palette.error.dark,
                }}
                size={isMediumScreen ? 14 : 18}
              />
            </>
          )}
          <Typography
            variant="subtitle1"
            color={
              Number(card.percentageChange) > 0
                ? theme.palette.success.dark
                : theme.palette.error.dark
            }
            fontWeight={{ xs: 500, md: 600 }}
            sx={{
              fontSize: [10, 10, 12],
            }}
          >
            {card.percentageChange}%
          </Typography>
          <Typography
            noWrap
            variant="subtitle1"
            color={theme.palette.grey[500]}
            fontWeight={{ xs: 500, md: 600 }}
            textTransform={'capitalize'}
            sx={{ marginLeft: '5px', fontSize: [10, 10, 12] }}
          >
            {data}
          </Typography>
        </Box>
        {card.isInfo && isMediumScreen && (
          <InfoCircleTooltip
            data={
              <Box sx={{ p: 1 }}>
                <Typography variant="subtitle2" color={theme.palette.grey[800]}>
                  This number indicates how many times your restaurant has been
                  recommended to users based on
                </Typography>
                <Link href="#" underline="hover">
                  Recommendations Page
                </Link>
              </Box>
            }
          />
        )}
      </CardBottomBox>
      {card.isModelShow && isHover && (
        <Box position="absolute" bottom={10} right={10} display="flex">
          <IconCircleArrowUpRight
            stroke={1}
            size={30}
            color={theme.palette.grey[500]}
            style={{ filter: 'drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.2))' }}
          />
        </Box>
      )}
    </MainTopCard>
  )
}

export default TopCard
