import React from 'react'
import {
  Fade,
  Skeleton,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import NoDataFound from '../NoDataFound'
import {
  StickyTable,
  TableMainPaper,
  TableRowTableCell,
} from '../styled/Table.styled'

interface Column {
  id: string
  label: string
  minWidth?: string
  maxWidth?: string
  width?: string
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify'
}

interface GlobalTableProps<T> {
  columns: readonly Column[]
  data: T[]
  renderRow: (row: T, index: number) => React.ReactNode
  maxHeight?: number
  isLoading?: boolean
  noBorder?: boolean
}

const RestaurantTable = <T,>({
  columns,
  data,
  renderRow,
  maxHeight = 284,
  isLoading = false,
  noBorder = false,
}: GlobalTableProps<T>): JSX.Element => {
  return (
    <TableMainPaper
      sx={{
        ...(noBorder
          ? {
              borderTopRightRadius: '0px',
              borderTopLeftRadius: '0px',
              border: 'none',
            }
          : {}),
      }}
    >
      <TableContainer sx={{ maxHeight }}>
        {!isLoading ? (
          data.length > 0 ? (
            <Fade in={!isLoading} timeout={1000}>
              <StickyTable>
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableRowTableCell
                        key={column.id}
                        align={column.align}
                        minWidth={column.minWidth}
                        maxWidth={column.maxWidth}
                        width={column?.width}
                      >
                        {column.label}
                      </TableRowTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>{data.map(renderRow)}</TableBody>
              </StickyTable>
            </Fade>
          ) : (
            <NoDataFound height={`${maxHeight}px`} />
          )
        ) : (
          <Skeleton
            variant="rounded"
            component={'p'}
            height={maxHeight}
            width={'100%'}
          />
        )}
      </TableContainer>
    </TableMainPaper>
  )
}

export default RestaurantTable
