import React from 'react'
import { Box } from '@mui/material'
import DishDetailCommon from '../../../../../../components/restaurants/DishDetailCommon'

import {
  BackButton,
  DashboardSimilarDishDetailMainBox,
  DashboardSimilarDishDetailTypography,
} from '../../../styled/competitorInsights/dish/DashboardSimilarDishDetail.styled'
import DashboardDishCard from './DashboardDishCard'
import { Dish } from '../../../../../../types/menuTypes'

interface DashboardSimilarDishDetailProps {
  setSimilarSelectedDish: React.Dispatch<undefined>
  similarDish: Dish
}

const DashboardSimilarDishDetail: React.FC<DashboardSimilarDishDetailProps> = ({
  setSimilarSelectedDish,
  similarDish,
}) => {
  return (
    <DashboardSimilarDishDetailMainBox>
      <BackButton onClick={() => setSimilarSelectedDish(undefined)} />
      <DashboardDishCard similarSelectedDish={similarDish} />
      {similarDish?.description && (
        <Box sx={{ marginTop: '20px' }}>
          <DashboardSimilarDishDetailTypography>
            {similarDish?.description}
          </DashboardSimilarDishDetailTypography>
        </Box>
      )}
      <DishDetailCommon
        Ingredients={similarDish?.ingredients}
        Preparation={similarDish?.preparation}
        suitableDietary={similarDish?.dietary}
        unsuitableDietary={similarDish?.unsuitabledietary}
      />
    </DashboardSimilarDishDetailMainBox>
  )
}

export default DashboardSimilarDishDetail
