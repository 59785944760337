import { Box, useTheme } from '@mui/material'
import { IconPlus } from '@tabler/icons-react'
import styled from 'styled-components'
import ImgIcon from '../../../../components/ui/ImgIcon'
import {
  RESTAURANT_HEADER_MOBILE,
  RESTAURANT_HEADER_WEB,
} from '../../../../helpers/static-constants'
import React from 'react'

export const AddDishButton = styled(ImgIcon).attrs(() => {
  const theme = useTheme()
  return {
    iconElement: <IconPlus stroke={2} color={theme.palette.common.white} />,
    alt: 'add-dish',
    wrapperStyle: {
      zIndex: 10,
      position: 'absolute',
      right: '15px',
      bottom: '25px',
      backgroundColor: theme.palette.primary.light,
    },
  }
})(() => ({}))

export const DishBox = styled(Box).attrs(() => {
  const theme = useTheme()
  return {
    className: 'hide-scrollbar',
    sx: {
      height: {
        xs: `calc(100vh - ${55 + RESTAURANT_HEADER_MOBILE}px)`,
        sm: `calc(100vh - ${63 + RESTAURANT_HEADER_MOBILE}px)`,
        md: `calc(100vh - ${RESTAURANT_HEADER_WEB}px)`,
      },
      overflowY: 'scroll',
      padding: { md: '12px' },
      borderRadius: { md: '16px' },
      borderColor: theme.palette.grey[200],
      backgroundColor: { md: theme.palette.background.paper },
    },
  }
})(() => ({}))
