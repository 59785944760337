import { Box, Divider } from '@mui/material'
import styled from 'styled-components'

export const SimilarDishesMainBox = styled(Box).attrs(() => {
  return {
    className: 'hide-scrollbar',
    sx: {
      marginTop: '8px',
      position: 'relative',
      paddingTop: '10px',
      overflow: 'scroll',
      maxHeight: { xs: '400px', md: 275 },
      height: '100%',
    },
  }
})(() => ({}))

export const SimilarDishesDivider = styled(Divider).attrs(() => {
  return {
    orientation: 'horizontal',
    flexItem: true,
    sx: { marginY: '20px' },
  }
})(() => ({}))
