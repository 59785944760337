import {
  Box,
  Container,
  Grid,
  styled as muiStyled,
  Typography,
  TypographyProps,
} from '@mui/material'
import styled from 'styled-components'

export const DownloadAppMainContainer = styled(Box).attrs(() => ({
  sx: {
    mt: { xs: '0px', md: '110px' },
  },
}))(({ theme }) => ({}))

export const DownloadAppContainer = styled(Container).attrs(() => ({
  component: 'main',
  sx: {
    flex: 1,
  },
}))(({ theme }) => ({}))

export const DownloadAppBoxContainer = styled(Box).attrs(() => ({
  sx: {
    position: 'relative',
    width: '100%',
    height: '70%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundImage: `url(${'/new/images/downloadAppBg.png'})`,
    backgroundRepeat: 'no-repeat',
    py: { xs: '32px', sm: '56px' },
    overflow: {
      xs: 'hidden',
      md: 'unset',
    },
  },
}))(({ theme }) => ({}))

export const DownloadAppInnerContainer = muiStyled('div')(() => {
  return {
    position: 'relative',
  }
})

export const DownloadAppMainGrid = styled(Grid).attrs(() => ({
  container: true,
  spacing: 2,
}))(({ theme }) => ({}))

export const DownloadAppInnerGrid = styled(Grid).attrs(() => ({
  item: true,
  xs: 6,
  md: 6,
}))(({ theme }) => ({}))

export const DownloadAppTypographyTitle = muiStyled(
  Typography,
)<TypographyProps>(() => {
  return {
    marginBottom: '16px',
  }
})

export const DownloadAppTypographySubTitle = muiStyled(
  Typography,
)<TypographyProps>(() => {
  return {
    marginBottom: '48px',
  }
})

export const DownloadAppImgContainer = muiStyled('div')(() => {
  return {
    display: 'flex',
    gap: '16px',
  }
})

interface DownloadAppSideImgProps {
  isMediumScreen: boolean
  isSmallScreen: boolean
}

export const DownloadAppSideImg = muiStyled('img')<DownloadAppSideImgProps>(
  ({ isMediumScreen, isSmallScreen }) => {
    return {
      zIndex: 1,
      position: 'absolute',
      bottom: isSmallScreen ? '-70px' : isMediumScreen ? '-125px' : '-160px',
      right: isSmallScreen ? '-120px' : isMediumScreen ? '-120px' : '-20px',
      height: isSmallScreen ? '300px' : isMediumScreen ? '400px' : '520px',
    }
  },
)
