import { useAuth0 } from '@auth0/auth0-react'
import { CircularProgress } from '@mui/material'
import moment from 'moment'
import React, { MouseEvent, useEffect, useState } from 'react'
import RoundedButton from '../../../../components/ui/RoundedButton'
import TagChip from '../../../../components/ui/TagChip'
import { generateAddress } from '../../../../helpers/converters'
import useAppUtilities from '../../../../hooks/useAppUtilities'
import { useAppSelector } from '../../../../store'
import { selectLogin } from '../../../../store/authSlice'
import { selectSavedRestaurantIds } from '../../../../store/profileSlice'
import {
  favoriteRestaurant,
  selectRestaurantDetail,
} from '../../../../store/restaurantSlice'
import useRudderStackAnalytics from '../../../../useRudderAnalytics'
import { MuiBackdrop } from '../../styled/global.styled'
import {
  RestaurantBasicInfoActiveTimeTypography,
  RestaurantBasicInfoAddressTypography,
  RestaurantBasicInfoAddressWrapper,
  RestaurantBasicInfoChipChip,
  RestaurantBasicInfoChipWrapper,
  RestaurantBasicInfoDistanceTypography,
  RestaurantBasicInfoIconMapPin,
  RestaurantBasicInfoIconPointFilled,
  RestaurantBasicInfoPopover,
  RestaurantBasicInfoPriceRangeIconCurrencyDollar,
  RestaurantBasicInfoPriceRangePerPersonTypography,
  RestaurantBasicInfoPriceRangeTypography,
  RestaurantBasicInfoPriceRangeWrapper,
  RestaurantBasicInfoTimeButton,
  RestaurantBasicInfoTimeButtonIconChevronDown,
  RestaurantBasicInfoTimeDivider,
  RestaurantBasicInfoTimeIconClockHour7,
  RestaurantBasicInfoTimeTodayTypography,
  RestaurantBasicInfoTimeWrapper,
  RestaurantBasicInfoTimezoneWrapper,
  RestaurantBasicInfoWrapper,
  RestaurantInfoActionContainer,
  RestaurantInfoActionDirectionsWebButton,
  RestaurantInfoActionDirectionsWebButtonIconArrowBearRight,
  RestaurantInfoActionIconArrowBearRight,
  RestaurantInfoActionIconBookmark,
  RestaurantInfoActionIconBookmarkFilled,
  RestaurantInfoActionIconCalendarCheck,
  RestaurantInfoActionIconMessage,
  RestaurantInfoActionIconPhone,
  RestaurantInfoActionReserveTableButton,
  RestaurantInfoAiMatchTypography,
  RestaurantInfoInnerContainer,
  RestaurantInfoMainContainer,
  RestaurantInfoSignUpTypography,
  RestaurantInfoTitleChip,
  RestaurantInfoTitleChipIconRosetteDiscountCheck,
  RestaurantInfoTitleChipWrapper,
  RestaurantInfoTitleTypography,
  RestaurantInfoTitleVerifiedTypography,
  RestaurantInfoTitleWrapper,
} from '../styled/RestaurantInfo.styled'
import RestaurantShare from './RestaurantShare'
import { restaurantTimezone } from '../../../../helpers/restaurant-radius'
import RestaurantBasicTimeZone from '../../../../components/restaurant/RestaurantBasicTimeZone'
import ChatDrawer from '../../../../components/restaurant/ChatDrawer'

const RestaurantInfo: React.FC = () => {
  const [analytics, isAnalyticsReady] = useRudderStackAnalytics()
  const today = moment().format('dddd')
  const { loginWithRedirect, isAuthenticated } = useAuth0()
  const { theme, isSmallScreen, isMediumScreen, dispatch } = useAppUtilities()
  const restaurant = useAppSelector(selectRestaurantDetail)?.restaurantDTO
  const recommendation = useAppSelector(selectRestaurantDetail)?.recommendation
  const saveRestaurants = useAppSelector(selectSavedRestaurantIds)
  const userDetail = useAppSelector(selectLogin)
  const [staticLoader, setStaticLoader] = useState(false)
  const [isSaved, setIsSaved] = useState(false)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [mapUrl, setMapUrl] = useState<string>('')
  const [openDrawer, setOpenDrawer] = useState<boolean>(false)
  const openPop = Boolean(anchorEl)
  const id = openPop ? 'simple-popover' : undefined

  useEffect(() => {
    const isSaved = saveRestaurants.includes(restaurant?.restaurantID ?? '')
    setIsSaved(isSaved)
  }, [saveRestaurants])

  useEffect(() => {
    const fetchMapUrl = async (): Promise<void> => {
      const url = await onMapDirections()
      setMapUrl(url ?? '')
    }
    void fetchMapUrl()
  }, [restaurant?.restaurantID])

  const handleClose = (): void => {
    setAnchorEl(null)
  }

  const handleClick = (event: MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const login: any = async (): Promise<void> => {
    await loginWithRedirect()
  }

  const tagsArray =
    restaurant?.cuisine
      ?.trim()
      ?.split(',')
      ?.map((tag) => tag.trim())
      .filter((tag) => tag.length > 0) ?? []

  const addressParts = [
    restaurant?.address1,
    restaurant?.city,
    restaurant?.state,
  ]
  const address = addressParts.filter(Boolean).join(', ')

  const onActiveTime = (): string[] => {
    const data = restaurantTimezone(restaurant)?.find(
      (day) => day.day === today,
    )
    return data?.time ?? []
  }

  const onClickBookmark = async (): Promise<void> => {
    setStaticLoader(true)
    await dispatch(favoriteRestaurant(restaurant?.alias ?? ''))
    setStaticLoader(false)
  }

  const onMapDirections = async (): Promise<string> => {
    if (!restaurant) {
      return 'https://www.google.com/maps/dir/?api=1&destination='
    }

    if (restaurant.place_id) {
      return `https://www.google.com/maps/search/?api=1&query=%20&query_place_id=${restaurant.place_id}`
    }

    return `https://www.google.com/maps/place/${generateAddress(restaurant)}`
  }

  const trackClick = (): void => {
    if (analytics && isAnalyticsReady) {
      analytics?.track('RESERVATION_MADE', {
        category: 'RESTAURANT',
        eaterId: `${userDetail?.eaterDTO?.eaterId ?? ''}`,
        eaterName: `${userDetail?.eaterDTO?.firstName ?? ''} ${
          userDetail?.eaterDTO?.lastName ?? ''
        }`,
        eaterEmail: `${userDetail?.eaterDTO?.email ?? ''}`,
        restaurantAlias: `${restaurant?.alias ?? ''}`,
      })
    }

    const reservationUrl = restaurant?.reservationUrl ?? restaurant?.website
    window.open(`${reservationUrl ?? ''}`, '_blank')
  }

  return (
    <>
      <RestaurantInfoMainContainer>
        <RestaurantInfoInnerContainer>
          <div>
            <RestaurantInfoTitleWrapper>
              <RestaurantInfoTitleTypography>
                {restaurant?.name?.toLocaleLowerCase()}
                {restaurant?.verified && (
                  <RestaurantInfoTitleChip
                    label={
                      <RestaurantInfoTitleChipWrapper>
                        <RestaurantInfoTitleChipIconRosetteDiscountCheck />
                        <RestaurantInfoTitleVerifiedTypography>
                          Verified
                        </RestaurantInfoTitleVerifiedTypography>
                      </RestaurantInfoTitleChipWrapper>
                    }
                  />
                )}
              </RestaurantInfoTitleTypography>
              {!isAuthenticated || recommendation?.yes ? (
                <TagChip
                  data={[
                    {
                      percentage: recommendation?.yes
                        ? recommendation.yes.toFixed(2)
                        : '15',
                      title: 'Match',
                    },
                  ]}
                  textBlur={!isAuthenticated}
                />
              ) : null}
              {!isAuthenticated && !isMediumScreen && (
                <>
                  <RestaurantInfoSignUpTypography onClick={login}>
                    Sign up
                  </RestaurantInfoSignUpTypography>
                  <RestaurantInfoAiMatchTypography>
                    to view AI match
                  </RestaurantInfoAiMatchTypography>
                </>
              )}
            </RestaurantInfoTitleWrapper>
            <RestaurantBasicInfoWrapper>
              <RestaurantBasicInfoAddressWrapper>
                <RestaurantBasicInfoIconMapPin />
                <RestaurantBasicInfoAddressTypography>
                  {address}
                </RestaurantBasicInfoAddressTypography>
                {restaurant && restaurant.distance !== 0 && (
                  <>
                    <RestaurantBasicInfoIconPointFilled />
                    <RestaurantBasicInfoDistanceTypography>
                      {restaurant.distance} mi
                    </RestaurantBasicInfoDistanceTypography>
                  </>
                )}
              </RestaurantBasicInfoAddressWrapper>

              <RestaurantBasicInfoTimeWrapper>
                {restaurantTimezone?.length && (
                  <RestaurantBasicInfoTimezoneWrapper>
                    {!isMediumScreen && <RestaurantBasicInfoTimeDivider />}
                    <RestaurantBasicInfoTimeIconClockHour7 />
                    {/* TODO: if not working then write color in sx */}
                    <RestaurantBasicInfoActiveTimeTypography
                      color={
                        onActiveTime()[0] === 'Closed'
                          ? theme.palette.secondary.main
                          : theme.palette.grey[600]
                      }
                    >
                      {onActiveTime()}
                    </RestaurantBasicInfoActiveTimeTypography>

                    <RestaurantBasicInfoTimeButton
                      onClick={handleClick}
                      aria-describedby={id}
                      endIcon={<RestaurantBasicInfoTimeButtonIconChevronDown />}
                    >
                      <RestaurantBasicInfoTimeTodayTypography>
                        Today
                      </RestaurantBasicInfoTimeTodayTypography>
                    </RestaurantBasicInfoTimeButton>
                  </RestaurantBasicInfoTimezoneWrapper>
                )}

                {restaurant?.priceRange && (
                  <RestaurantBasicInfoPriceRangeWrapper>
                    {!isMediumScreen && <RestaurantBasicInfoTimeDivider />}
                    <RestaurantBasicInfoPriceRangeIconCurrencyDollar />
                    <RestaurantBasicInfoPriceRangeTypography>
                      {restaurant?.priceRange}
                    </RestaurantBasicInfoPriceRangeTypography>
                    <RestaurantBasicInfoPriceRangePerPersonTypography>
                      /per person
                    </RestaurantBasicInfoPriceRangePerPersonTypography>
                  </RestaurantBasicInfoPriceRangeWrapper>
                )}
              </RestaurantBasicInfoTimeWrapper>

              <RestaurantBasicInfoPopover
                id={id}
                open={openPop}
                anchorEl={anchorEl}
                onClose={handleClose}
              >
                {restaurant && (
                  <RestaurantBasicTimeZone restaurant={restaurant} />
                )}
              </RestaurantBasicInfoPopover>
            </RestaurantBasicInfoWrapper>
            <RestaurantBasicInfoChipWrapper>
              {tagsArray.map((item, index) => (
                <RestaurantBasicInfoChipChip key={index} label={item} />
              ))}
            </RestaurantBasicInfoChipWrapper>
          </div>
        </RestaurantInfoInnerContainer>
        <RestaurantInfoActionContainer>
          {(restaurant?.reservationUrl ?? restaurant?.website) && (
            <RestaurantInfoActionReserveTableButton
              href={restaurant?.reservationUrl ?? restaurant?.website}
              onClick={trackClick}
              rel="noopener noreferrer"
              startIcon={<RestaurantInfoActionIconCalendarCheck />}
            >
              <span>Reserve Table</span>
            </RestaurantInfoActionReserveTableButton>
          )}

          {!isSmallScreen ? (
            <RestaurantInfoActionDirectionsWebButton
              href={mapUrl}
              rel="noopener noreferrer"
              startIcon={
                <RestaurantInfoActionDirectionsWebButtonIconArrowBearRight />
              }
            >
              {<span>Directions</span>}
            </RestaurantInfoActionDirectionsWebButton>
          ) : (
            <RoundedButton
              target="_blank"
              component="a"
              href={mapUrl}
              rel="noopener noreferrer"
              borderColor={theme.palette.grey[300]}
              diameter={37}
            >
              <RestaurantInfoActionIconArrowBearRight />
            </RoundedButton>
          )}

          {restaurant?.phone && (
            <RoundedButton
              component="a"
              href={`tel:${restaurant?.phone}`}
              borderColor={theme.palette.grey[300]}
              diameter={37}
            >
              <RestaurantInfoActionIconPhone />
            </RoundedButton>
          )}
          {isAuthenticated && (
            <>
              <RoundedButton
                borderColor={theme.palette.grey[300]}
                diameter={37}
                onClick={() => setOpenDrawer(true)}
              >
                <RestaurantInfoActionIconMessage />
              </RoundedButton>
              <RoundedButton
                onClick={() => {
                  void onClickBookmark()
                }}
                borderColor={theme.palette.grey[300]}
                diameter={37}
              >
                {isSaved ? (
                  <RestaurantInfoActionIconBookmarkFilled />
                ) : (
                  <RestaurantInfoActionIconBookmark />
                )}
              </RoundedButton>
            </>
          )}
          <RestaurantShare
            imgUrl={restaurant?.imageurl ?? ''}
            description={restaurant?.description ?? ''}
            title={restaurant?.name ?? ''}
            cuisine={restaurant?.cuisine?.replace(/,/g, ' ') ?? ''}
            city={restaurant?.city ?? ''}
          />
        </RestaurantInfoActionContainer>
      </RestaurantInfoMainContainer>
      <MuiBackdrop open={staticLoader}>
        <CircularProgress color="inherit" />
      </MuiBackdrop>
      <ChatDrawer open={openDrawer} toggleDrawer={setOpenDrawer} />
    </>
  )
}

export default RestaurantInfo
