import { Box, Grid, Modal, Skeleton } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import NoDataFound from '../../../../../components/NoDataFound'
import { ModelMainBox } from '../../../../../components/styled/ModalInnerBox.styled'
import useAppUtilities from '../../../../../hooks/useAppUtilities'
import { useAppSelector } from '../../../../../store'
import {
  selectCompetitorPopularDish,
  selectIsLoading,
  selectPopularDishes,
  setSelectedCompetitorPopularDish,
} from '../../../../../store/restaurant/dashboard'
import { IPopularDishes } from '../../../../../types/dashboard'
import { PopularDishesDrawer } from '../../styled/competitorInsights/PopularDishesTab.styled'
import CompetitorInsightsMap from '../CompetitorInsightsMap'
import PopularTabTable from './PopularTabTable'
import SelectedDishSection from './dish/SelectedDishSection'

interface IPopularDishesTabProp {
  openMap: boolean
  setOpenMap: (value: boolean) => void
}

const PopularDishesTab: React.FC<IPopularDishesTabProp> = ({
  openMap,
  setOpenMap,
}) => {
  const { isMediumScreen, dispatch } = useAppUtilities()
  const dish = useAppSelector(selectCompetitorPopularDish)
  const popularDishes = useAppSelector(selectPopularDishes)
  const isLoading = useAppSelector(selectIsLoading)
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  useEffect(() => {
    return () => {
      closeDrawer()
    }
  }, [])

  const handleDishSelection = (dish: IPopularDishes): void => {
    dispatch(setSelectedCompetitorPopularDish(dish))
    if (isMediumScreen) {
      setIsDrawerOpen(true)
    }
  }

  const closeDrawer = (): void => {
    setIsDrawerOpen(false)
    dispatch(setSelectedCompetitorPopularDish(undefined))
  }

  const placeIds = useMemo(() => {
    return popularDishes.map((dish) => dish.placeId)?.filter(Boolean)
  }, [popularDishes])

  return (
    <Box pt={2}>
      <Grid
        container
        spacing={2}
        px={{ xs: '12px', md: '16px' }}
        pb={{ xs: '12px', md: '16px' }}
      >
        <Grid item xs={12} lg={8}>
          <PopularTabTable onSelectDish={handleDishSelection} />
        </Grid>

        {!isMediumScreen && (
          <Grid item xs={12} lg={4}>
            {!isLoading ? (
              dish ? (
                <SelectedDishSection closeDrawer={closeDrawer} />
              ) : popularDishes.length > 0 ? (
                <CompetitorInsightsMap placeIds={placeIds} />
              ) : (
                <NoDataFound height={'346px'} />
              )
            ) : (
              <Skeleton
                variant="rectangular"
                component={'p'}
                height={'346px'}
                width={'100%'}
                sx={{ borderRadius: 4 }}
              />
            )}
          </Grid>
        )}
      </Grid>

      {isMediumScreen && dish && (
        <PopularDishesDrawer open={isDrawerOpen} onClose={closeDrawer}>
          <SelectedDishSection closeDrawer={closeDrawer} />
        </PopularDishesDrawer>
      )}

      <Modal
        open={openMap}
        onClose={() => setOpenMap(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelMainBox>
          <CompetitorInsightsMap placeIds={placeIds} />
        </ModelMainBox>
      </Modal>
    </Box>
  )
}

export default PopularDishesTab
