import { Autocomplete, AutocompleteProps, Paper } from '@mui/material'
import { IconChevronDown } from '@tabler/icons-react'
import React from 'react'
import styled from 'styled-components'

export const DietaryTagInputAutocomplete = styled(({ ...rest }) => (
  <Autocomplete {...rest} />
)).attrs<AutocompleteProps<[], true, false, false>>(({ value }) => {
  return {
    multiple: true,
    popupIcon: <IconChevronDown stroke={2} size={18} />,
    PaperComponent: (props) => (
      <Paper
        {...props}
        sx={{
          fontSize: '14px !important',
          fontWeight: `400 !important`,
          borderRadius: '12px',
          mt: '4px',
        }}
      />
    ),
  }
})<AutocompleteProps<[], true, false, false>>(() => ({}))
