import React from 'react'
import { TableBodyRow } from '../../../../../components/styled/Table.styled'
import useAppUtilities from '../../../../../hooks/useAppUtilities'
import { useAppSelector } from '../../../../../store'
import {
  selectCompetitorPopularDish,
  selectIsLoading,
  selectPopularDishes,
} from '../../../../../store/restaurant/dashboard'
import { IPopularDishes } from '../../../../../types/dashboard'
import RestaurantTable from '../../../../../components/table/RestaurantTable'
import AvatarTableCell from '../../../../../components/table/AvatarTableCell'
import TextTableCell from '../../../../../components/table/TextTableCell'

interface PopularTabTableProps {
  onSelectDish: (dish: IPopularDishes) => void
}

const PopularTabTable: React.FC<PopularTabTableProps> = ({ onSelectDish }) => {
  const { theme } = useAppUtilities()
  const popularDishes = useAppSelector(selectPopularDishes)
  const isLoading = useAppSelector(selectIsLoading)
  const dish = useAppSelector(selectCompetitorPopularDish)

  const handleRowClick = (row: IPopularDishes): void => {
    onSelectDish(row)
  }

  return (
    <RestaurantTable
      columns={columns}
      data={popularDishes ?? []}
      isLoading={isLoading}
      maxHeight={346}
      renderRow={(row, index) => (
        <TableBodyRow
          key={index}
          hover
          role="checkbox"
          tabIndex={-1}
          onClick={() => handleRowClick(row)}
          sx={{
            cursor: 'pointer',
            backgroundColor:
              dish?.dish?.dishID === row?.dish?.dishID
                ? theme.palette.secondary[50]
                : theme.palette.common.white,
          }}
        >
          <AvatarTableCell
            imageUrl={row.dish?.imageurl}
            primaryText={row?.dish?.name}
            secondaryText={row?.dish?.description}
            maxWidth={columns?.[0].maxWidth}
            minWidth={columns?.[0].minWidth}
          />

          <TextTableCell text={row?.restaurantName} />
          <TextTableCell text={row?.views} />
          <TextTableCell text={row?.dish?.price} />
        </TableBodyRow>
      )}
    />
  )
}

export default PopularTabTable

interface Column {
  id: string
  label: string
  minWidth?: string
  maxWidth?: string
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify'
  format?: (value: number) => string
  type?: string
}

const columns: readonly Column[] = [
  {
    id: 'popularDishes',
    label: 'popular dishes',
    minWidth: '190px',
    maxWidth: '350px',
  },
  { id: 'restaurant', label: 'restaurant', minWidth: '120px' },
  { id: 'views', label: 'views', minWidth: '120px' },
  { id: 'price', label: 'price', minWidth: '90px' },
]
