import { Box, Typography, useTheme } from '@mui/material'
import styled from 'styled-components'

export const DashboardDishDetailMainBox = styled(Box).attrs(() => {
  return {
    className: 'hide-scrollbar',
    sx: {
      marginTop: '8px',
      position: 'relative',
      paddingTop: '10px',
      overflow: 'scroll',
      maxHeight: { xs: '400px', md: 275 },
      pb: 1,
    },
  }
})(() => ({}))

export const DashboardDishDetailTopBox = styled(Box).attrs(() => {
  return {
    sx: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'start',
    },
  }
})(() => ({}))

export const DashboardDishDetailTopInnerBox = styled(Box).attrs(() => {
  return {
    sx: {
      marginLeft: '16px',
    },
  }
})(() => ({}))

export const DashboardDishDetailTopTypography = styled(Typography).attrs(() => {
  const theme = useTheme()
  return {
    variant: 'body1',
    color: theme.palette.grey[800],
  }
})(() => ({}))

export const DashboardDishDetailTopPriceWrapperBox = styled(Box).attrs(() => {
  const theme = useTheme()
  return {
    sx: {
      marginTop: '8px',
      padding: '4px 8px',
      borderRadius: '8px',
      border: `1.5px solid ${theme.palette.grey[300]}`,
      width: 'fit-content',
    },
  }
})(() => ({}))

export const DashboardDishDetailTopPriceTypography = styled(Typography).attrs(
  () => {
    const theme = useTheme()
    return {
      variant: 'body1',
      fontWeight: 600,
      color: theme.palette.grey[800],
    }
  },
)(() => ({}))

export const DashboardDishDetailDescriptionWrapperBox = styled(Box).attrs(
  () => {
    return {
      sx: {
        marginTop: '20px',
      },
    }
  },
)(() => ({}))

export const DashboardDishDetailDescriptionTypography = styled(
  Typography,
).attrs(() => {
  const theme = useTheme()
  return {
    variant: 'subtitle1',
    fontWeight: 400,
    color: theme.palette.grey[600],
    sx: { marginTop: '2px' },
  }
})(() => ({}))
