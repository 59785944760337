import { Box, Menu, MenuItem, Typography, useTheme } from '@mui/material'
import styled from 'styled-components'
import ImgIcon from '../../../../components/ui/ImgIcon'
import {
  IconCheck,
  IconChevronLeft,
  IconDotsVertical,
  IconPencil,
  IconPlus,
  IconTrash,
  IconX,
} from '@tabler/icons-react'
import React from 'react'
import CustomButton from '../../../../components/ui/CustomButton'

export const DishHeaderMainBox = styled(Box).attrs(() => {
  return {
    sx: {
      paddingTop: { xs: 1, md: 0 },
      mb: { xs: 1, md: 4 },
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  }
})(() => ({}))

export const DishHeaderBackButton = styled(ImgIcon).attrs(() => {
  const theme = useTheme()
  return {
    iconElement: <IconChevronLeft stroke={2} />,
    alt: 'back-navigation',
    imgSize: 18,
    wrapperStyle: {
      backgroundColor: theme.palette.grey[200],
      height: '30px',
      width: '30px',
      outline: `1px solid ${theme.palette.grey[300]}`,
      marginLeft: '8px',
      marginRight: '14px',
    },
  }
})(() => ({}))
export const DishHeaderTitleCanCelButton = styled(ImgIcon).attrs(() => {
  const theme = useTheme()
  return {
    iconElement: <IconX stroke={2} size={18} color={theme.palette.grey[500]} />,
    alt: 'cancel-edit',
    imgSize: 18,
    wrapperStyle: {
      width: '40px',
      height: '30px',
      marginLeft: '6px',
      backgroundColor: theme.palette.grey[50],
      border: `1px solid ${theme.palette.grey[300]}`,
    },
  }
})(() => ({}))
export const DishHeaderTitleSaveButton = styled(ImgIcon).attrs(() => {
  const theme = useTheme()
  return {
    iconElement: (
      <IconCheck stroke={2} size={18} color={theme.palette.grey[500]} />
    ),
    alt: 'confirm-edit',
    imgSize: 18,
    wrapperStyle: {
      width: '40px',
      height: '30px',
      marginLeft: '6px',
      backgroundColor: theme.palette.grey[50],
      border: `1px solid ${theme.palette.grey[300]}`,
    },
  }
})(() => ({}))
export const DishHeaderEditMenuButton = styled(ImgIcon).attrs(() => {
  const theme = useTheme()
  return {
    iconElement: <IconPencil stroke={2} size={18} />,
    alt: 'edit-menu',
    imgSize: 18,
    wrapperStyle: {
      backgroundColor: 'transparent',
      height: '35px',
      width: '35px',
      marginLeft: '8px',
      color: theme.palette.grey[500],
      '&:hover': {
        backgroundColor: theme.palette.grey[200],
        cursor: 'pointer',
      },
    },
  }
})(() => ({}))
export const AddDishButton = styled(CustomButton).attrs(() => {
  const theme = useTheme()
  return {
    icon: <IconPlus size={18} stroke={2} />,
    title: 'Add Dish',
    sx: {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.common.white,
      padding: '8px 16px',
    },
  }
})(() => ({}))
export const DeleteDishButton = styled(CustomButton).attrs(() => {
  const theme = useTheme()
  return {
    icon: <IconTrash size={18} stroke={2} />,
    title: 'Delete Menu',
    sx: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.common.white,
      padding: '8px 16px',
    },
  }
})(() => ({}))
export const DishMoreButton = styled(CustomButton).attrs(() => {
  const theme = useTheme()
  return {
    icon: <IconDotsVertical size={16} stroke={2} />,
    sx: {
      backgroundColor: theme.palette.common.white,
      borderColor: theme.palette.grey[300],
      color: theme.palette.primary.main,
      marginRight: '12px',
      paddingY: '8px',
      paddingX: '8px',
      minWidth: '44px !important',
    },
    'aria-haspopup': 'true',
  }
})(() => ({}))

export const DishMenu = styled(Menu).attrs(() => {
  return {
    id: 'edit-menu',
    anchorOrigin: {
      vertical: 40,
      horizontal: 'right',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'right',
    },
    TransitionProps: { timeout: 300 },
    sx: {
      marginRight: '10px',
      '& .MuiPaper-root': { borderRadius: '8px' },
    },
  }
})(() => ({}))
export const DishMenuItem = styled(MenuItem).attrs(() => {
  return {
    sx: {
      minHeight: { xs: '35px' },
    },
  }
})(() => ({}))
export const DishMenuItemTypography = styled(Typography).attrs(() => {
  const theme = useTheme()
  return {
    variant: 'subtitle1',
    sx: { color: theme.palette.grey[800] },
  }
})(() => ({}))
