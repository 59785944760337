import { Box, Menu, Typography, useTheme } from '@mui/material'
import styled from 'styled-components'
import CustomButton from '../../../../components/ui/CustomButton'
import { IconPlus } from '@tabler/icons-react'
import React from 'react'
import ImgIcon from '../../../../components/ui/ImgIcon'

export const HeaderRightBox = styled(Box).attrs(() => ({
  sx: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '14px',
  },
}))(() => ({}))

export const LoadMenuButton = styled(CustomButton).attrs(() => {
  const theme = useTheme()
  return {
    title: 'Load Menu',
    sx: {
      backgroundColor: 'transparent',
      borderColor: theme.palette.primary.light,
      color: theme.palette.primary.light,
      py: '8px',
      '& .MuiButton-icon': {
        marginRight: '0px !important',
      },
    },
  }
})(() => ({}))

export const AddMenuButton = styled(CustomButton).attrs(() => {
  const theme = useTheme()
  return {
    startIcon: (
      <IconPlus
        color={theme.palette.common.white}
        height={'18px'}
        width={'18px'}
        stroke={2}
      />
    ),
    title: 'Add Menu',
    sx: {
      backgroundColor: theme.palette.primary.light,
      borderColor: theme.palette.primary.light,
      color: theme.palette.common.white,
      padding: '8px 16px',
      '& .MuiButton-icon': {
        marginRight: '0px !important',
      },
    },
  }
})(() => ({}))

export const StyledMenuPopup = styled(Menu).attrs(() => {
  return {
    id: 'menu',
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 130,
      horizontal: 'left',
    },
    TransitionProps: { timeout: 300 },
    sx: {
      zIndex: 110,
      borderRadius: '8px',
      '& .MuiPaper-root': { borderRadius: '8px' },
    },
  }
})(() => ({}))

export const MenuPopupTypography = styled(Typography).attrs(() => {
  const theme = useTheme()
  return {
    variant: 'subtitle2',
    fontSize: '10px !important',
    lineHeight: '16px',
    fontWeight: 500,
    sx: { color: theme.palette.grey[500] },
  }
})(() => ({}))

export const MenuAddMenuButton = styled(ImgIcon).attrs(() => {
  const theme = useTheme()
  return {
    iconElement: <IconPlus stroke={2} color={theme.palette.common.white} />,
    alt: 'add-menu',
    wrapperStyle: {
      zIndex: 10,
      position: 'absolute',
      right: '15px',
      bottom: '25px',
      backgroundColor: theme.palette.primary.light,
    },
  }
})(() => ({}))
export const ShowToasterBox = styled(Box).attrs(() => {
  const theme = useTheme()
  return {
    sx: {
      marginBottom: { xs: '8px', sm: '16px', md: '32px' },
      padding: '8px 16px',
      border: `1px solid ${theme.palette.primary[200] as string}`,
      borderRadius: '8px',
      backgroundColor: '#E4EBFB',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  }
})(() => ({}))
