import { Box, Typography } from '@mui/material'
import React, { ReactNode } from 'react'
import {
  RESTAURANT_HEADER_MOBILE,
  RESTAURANT_PAGE_LAYOUT_HEADER,
} from '../../helpers/static-constants'
import useAppUtilities from '../../hooks/useAppUtilities'

interface RestaurantsPageLayoutProps {
  title: string
  HeaderRight?: ReactNode
  body?: ReactNode
  removeHeight?: number
  headerShow?: boolean
}

const RestaurantsPageLayout: React.FC<RestaurantsPageLayoutProps> = ({
  title,
  HeaderRight,
  body,
  removeHeight = 0,
  headerShow = false,
}) => {
  const { isMediumScreen } = useAppUtilities()

  return (
    <>
      {((headerShow && isMediumScreen) || !isMediumScreen) && (
        <Box
          sx={{
            mb: { xs: 2, md: 4 },
            pt: { xs: '20px', md: 0 },
            display: 'flex',
            justifyContent: { xs: 'flex-end', md: 'space-between' },
            alignItems: 'center',
          }}
        >
          {!isMediumScreen && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography variant="h3" mr={1.5} my={1}>
                {title}
              </Typography>
            </Box>
          )}
          {HeaderRight && HeaderRight}
        </Box>
      )}
      {body && (
        <Box
          className="hide-scrollbar"
          sx={{
            height: {
              xs: `calc(100vh - ${
                removeHeight +
                RESTAURANT_HEADER_MOBILE +
                (headerShow ? RESTAURANT_PAGE_LAYOUT_HEADER : 0)
              }px)`,
              md: 'calc(100vh - 150px)',
            },
            overflowY: 'scroll',
            paddingY: { xs: '16px', md: '0px' },
          }}
        >
          {body}
        </Box>
      )}
    </>
  )
}

export default RestaurantsPageLayout
