import { Auth0Provider } from '@auth0/auth0-react'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import App from './App'
import { useAuthConfig } from './hooks/useAuthConfig'
import useCustomCache from './hooks/useCustomCache'
import { useDevice } from './hooks/useDevice'
import './index.scss'
import reportWebVitals from './reportWebVitals'
import { store } from './store'
import { CssBaseline } from '@mui/material'

// Ensure that React hooks are called inside a React component or a custom hook.
// Moving the call of `useAuthConfig` to a functional component.
const RootComponent: React.FC = () => {
  // eslint-disable-next-line prefer-const
  let { isHybrid, isAndroid } = useDevice()

  // When testing and building the app for Android, set isAndroid to true. For building and testing on iOS, comment out the line below
  // isAndroid = true

  const { scope, domain, clientId, auth0Api, callbackUri } = useAuthConfig(
    isHybrid,
    isAndroid,
  )

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const sessionStorageCache = useCustomCache(isHybrid)

  return (
    <React.StrictMode>
      <Auth0Provider
        domain={domain}
        clientId={clientId}
        // cache={sessionStorageCache}
        cacheLocation="localstorage"
        {...(isHybrid && {
          useRefreshTokens: true,
          useRefreshTokensFallback: false,
        })}
        authorizationParams={{
          scope,
          audience: auth0Api,
          redirect_uri: callbackUri,
        }}
      >
        <Provider store={store}>
          <CssBaseline enableColorScheme />
          <App />
        </Provider>
      </Auth0Provider>
    </React.StrictMode>
  )
}

// Render the component to the root element.
const root = createRoot(document.getElementById('root') as HTMLElement)
root.render(<RootComponent />)

// Optional: Service worker registration for PWA
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// serviceWorkerRegistration.unregister();

// Performance measurement
reportWebVitals()
