import { Box, FormControlLabel, Radio, useMediaQuery, useTheme } from '@mui/material'
import {  IconCopy, IconPencil, IconTrash, IconX } from '@tabler/icons-react'
import styled from 'styled-components'
import CustomButton from '../../../../components/ui/CustomButton'
import ImgIcon from '../../../../components/ui/ImgIcon'
import { RESTAURANT_HEADER_WEB } from '../../../../helpers/static-constants'
import React from 'react'

export const DishDetailMainBox = styled(Box).attrs(() => {
  return {
    sx: {
      position: 'relative',
      margin: '8px 16px',
      height: {
        xs: 'calc(100vh - 104px)',
        sm: `calc(100vh - 113px)`,
        md: `calc(100vh - ${RESTAURANT_HEADER_WEB}px)`,
      },
    },
  }
})(() => ({}))

export const DishDetailSecondScrollBox = styled(Box).attrs(() => {
  return {
    className: 'hide-scrollbar',
    sx: {
      overflowY: 'scroll',
      height: `calc(100% - 50px)`,
    },
  }
})(() => ({}))


export const DishDetailCopyMenuButton = styled(CustomButton).attrs(() => {
  const theme = useTheme()
   const isBetween960And1340 = useMediaQuery("(min-width:960px) and (max-width:1340px)");
  return {
   ...(!isBetween960And1340 && {title: 'Copy to menu'}),
  icon: isBetween960And1340 ? <IconCopy size={16} stroke={2} /> : undefined,
   textVariant:'subtitle1',
    sx: {
      backgroundColor: 'transparent',
      borderColor: theme.palette.primary.light,
      color: theme.palette.primary.light,
      height: '38px',
      px: '10px',
      width: '100%',
      '& .MuiButton-icon': {
        marginRight: '0px !important',
      },
    },
  }
})(() => ({}))

export const DishDetailDeleteButton = styled(CustomButton).attrs(() => {
  const theme = useTheme()
  return {
    icon: <IconTrash size={16} stroke={2} />,
    backgroundColor: theme.palette.error.main,
    sx: {
      backgroundColor: 'transparent',
      borderColor: theme.palette.error.main,
      color: theme.palette.error.main,
      paddingY: '10px',
      paddingX: '8px',
      minWidth: '44px !important',
    },
  }
})(() => ({}))

export const DishDetailEditButton = styled(CustomButton).attrs(() => {
  const theme = useTheme()
  return {
    icon: <IconPencil size={16} stroke={2} />,
    sx: {
      backgroundColor: 'transparent',
      borderColor: theme.palette.primary.light,
      color: theme.palette.primary.light,
      paddingY: '10px',
      paddingX: '8px',
      minWidth: '44px !important',
    },
  }
})(() => ({}))

export const DishDetailBox = styled(Box).attrs(() => {
  return {
    sx: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      display: 'flex',
      gap: '5px',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }
})(() => ({}))

export const DishDetailBackButton = styled(ImgIcon).attrs(() => {
  const theme = useTheme()
  return {
    iconElement: <IconX stroke={2} />,
    alt: 'back-navigation',
    imgSize: 18,
    wrapperStyle: {
      backgroundColor: 'transparent',
      height: '30px',
      width: '30px',
      outline: `1px solid ${theme.palette.grey[300]}`,
      marginLeft: '8px',
      marginRight: '14px',
      position: 'absolute',
      right: '-2px',
      top: '2px',
    },
  }
})(() => ({}))

export const CopyMenuRadioFormControlLabel = styled(FormControlLabel).attrs(
  () => {
    const theme = useTheme()
    return {
      sx: {
        '&.MuiFormControlLabel-root': {
          width: '100%',
          padding: '12px',
          '& .MuiFormControlLabel-label': {
            fontSize: '14px',
            fontWeight: 500,
            paddingLeft: '10px',
            color: theme.palette.grey[800],
          },
        },
      },
    }
  },
)``
export const CopyMenuRadio = styled(Radio).attrs(() => {
  const theme = useTheme()
  return {
    sx: {
      color: theme.palette.grey[300],
      padding: '0px',
      '&.Mui-checked': {
        color: theme.palette.primary.main,
      },
    },
  }
})``