import { Box, Typography } from '@mui/material'
import React from 'react'
import useAppUtilities from '../../../../hooks/useAppUtilities'
import {
  ReviewsSummaryChip,
  ReviewsSummaryChipTypography,
} from '../styled/SummaryChip.styled'

interface SummaryChipProps {
  highlightColor: 'green' | 'red'
  data: Data[]
  selectedChip: string[]
  setSelectedChip: React.Dispatch<React.SetStateAction<string[]>>
}

interface Data {
  label: string
  count?: number | null
}

const SummaryChip: React.FC<SummaryChipProps> = ({
  highlightColor,
  data,
  selectedChip,
  setSelectedChip,
}) => {
  const { theme } = useAppUtilities()

  const handleClick = (label: string): void => {
    setSelectedChip((prevSelectedChip) =>
      prevSelectedChip?.includes(label.toLowerCase())
        ? prevSelectedChip.filter((chip) => chip !== label.toLowerCase())
        : [...prevSelectedChip, label.toLowerCase()],
    )
  }

  const getStyles = (isSelected: boolean): StyleProps => {
    const colorPalette =
      highlightColor === 'red' ? theme.palette.error : theme.palette.success

    return {
      borderColor: isSelected ? colorPalette.main : colorPalette.light,
      textColor: isSelected ? theme.palette.common.white : colorPalette.main,
      backgroundColor: isSelected
        ? colorPalette.light
        : theme.palette.common.white,
      countBgColor: isSelected ? '#0000002E' : colorPalette[100],
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        alignItems: 'center',
        gap: '8px',
      }}
    >
      {data.map((dish, index) => {
        const isSelected = selectedChip.includes(dish.label.toLocaleLowerCase())
        const { borderColor, textColor, backgroundColor, countBgColor } =
          getStyles(isSelected)

        return (
          <ReviewsSummaryChip
            textColor={textColor}
            borderColor={borderColor}
            backgroundColor={backgroundColor}
            key={index}
            label={
              <Box display="flex" alignItems="center">
                <Typography variant="subtitle1" textTransform="capitalize">
                  {dish.label}
                </Typography>
                {dish.count && dish.count > 0 && (
                  <Box>
                    <ReviewsSummaryChipTypography
                      textColor={textColor}
                      countBgColor={countBgColor}
                    >
                      {dish.count}
                    </ReviewsSummaryChipTypography>
                  </Box>
                )}
              </Box>
            }
            onClick={() => handleClick(dish.label)}
          />
        )
      })}
    </Box>
  )
}

export default SummaryChip

interface StyleProps {
  borderColor: string
  textColor: string
  backgroundColor: string
  countBgColor: string
}
