import { Box } from '@mui/material'
import React, { memo, useEffect, useRef } from 'react'
import useAppUtilities from '../../../../hooks/useAppUtilities'
import { useAppSelector } from '../../../../store'
import { selectRestaurantMiles } from '../../../../store/restaurant/dashboard'
import { selectLocation } from '../../../../store/authSlice'
import '../styled/competitorInsights/CompetitorInsightsMap.scss'

declare global {
  interface Window {
    googleMapsLoaded?: boolean
  }
}

interface ICompetitorInsightsMapProps {
  placeIds: string[]
}

const CompetitorInsightsMap: React.FC<ICompetitorInsightsMapProps> = ({
  placeIds,
}) => {
  const { theme } = useAppUtilities()
  const miles = useAppSelector(selectRestaurantMiles)
  const location = useAppSelector(selectLocation)
  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY
  const mapRef = useRef<HTMLDivElement>(null)
  const activeMarkerRef = useRef<google.maps.Marker | null>(null)

  useEffect(() => {
    if (!googleMapsApiKey || !mapRef.current) return

    // TODO: Remove dummy data
    // const currentLocation = location
    //   ? { lat: location.latitude, lng: location.longitude }
    //   : { lat: 40.7128, lng: -74.006 }
    const currentLocation = { lat: 40.7128, lng: -74.006 }

    const initMap = async (): Promise<void> => {
      if (!mapRef.current || !window.google || !google.maps) return

      let activeInfoWindow: google.maps.InfoWindow | null = null
      const map = new google.maps.Map(mapRef.current, {
        center: currentLocation,
        zoom:
          miles === 5
            ? 11
            : miles === 10
            ? 10
            : miles === 15
            ? 9.5
            : miles === 20
            ? 9
            : miles === 25
            ? 8.5
            : 11,
        mapTypeControl: false, // Removes Map/Satellite toggle
        fullscreenControl: false, // Removes Fullscreen button
        streetViewControl: false, // Removes Street View pegman
        zoomControlOptions: {
          position: google.maps.ControlPosition.TOP_RIGHT,
        },
        styles: [
          {
            featureType: 'poi',
            elementType: 'labels',
            stylers: [{ visibility: 'off' }], // Hide points of interest (POIs)
          },
          {
            featureType: 'transit',
            elementType: 'labels',
            stylers: [{ visibility: 'off' }], // Hide transit labels
          },
        ],
      })

      const circle = new google.maps.Circle({
        map,
        center: currentLocation,
        radius: (miles ?? 5) * 1609.344,
        fillColor: theme.palette.secondary.main,
        fillOpacity: 0.1,
        strokeColor: theme.palette.secondary.main,
        strokeOpacity: 0.5,
        strokeWeight: 1.5,
      })

      // Ensure `setMap` is safe
      if (circle) circle.setMap(map)

      // Ensure `google.maps.places` exists before using it
      const { Place } = (await google.maps.importLibrary(
        'places',
      )) as google.maps.PlacesLibrary

      const defaultIcon = {
        url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(`
          <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 0.5C6.81273 0.502481 4.71575 1.37247 3.16911 2.91911C1.62247 4.46575 0.752481 6.56273 0.75 8.75C0.75 15.8094 8.25 21.1409 8.56969 21.3641C8.69579 21.4524 8.84603 21.4998 9 21.4998C9.15397 21.4998 9.30421 21.4524 9.43031 21.3641C9.75 21.1409 17.25 15.8094 17.25 8.75C17.2475 6.56273 16.3775 4.46575 14.8309 2.91911C13.2843 1.37247 11.1873 0.502481 9 0.5ZM9 5.75C9.59334 5.75 10.1734 5.92595 10.6667 6.25559C11.1601 6.58524 11.5446 7.05377 11.7716 7.60195C11.9987 8.15013 12.0581 8.75333 11.9424 9.33527C11.8266 9.91721 11.5409 10.4518 11.1213 10.8713C10.7018 11.2909 10.1672 11.5766 9.58527 11.6924C9.00333 11.8081 8.40013 11.7487 7.85195 11.5216C7.30377 11.2946 6.83524 10.9101 6.50559 10.4167C6.17595 9.92336 6 9.34334 6 8.75C6 7.95435 6.31607 7.19129 6.87868 6.62868C7.44129 6.06607 8.20435 5.75 9 5.75Z" fill="#2B2C5A"/>
          </svg>
        `)}`,
        scaledSize: new google.maps.Size(30, 30),
        anchor: new google.maps.Point(25, 50),
      }
      const selectedIcon = {
        url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(`
          <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 0.5C6.81273 0.502481 4.71575 1.37247 3.16911 2.91911C1.62247 4.46575 0.752481 6.56273 0.75 8.75C0.75 15.8094 8.25 21.1409 8.56969 21.3641C8.69579 21.4524 8.84603 21.4998 9 21.4998C9.15397 21.4998 9.30421 21.4524 9.43031 21.3641C9.75 21.1409 17.25 15.8094 17.25 8.75C17.2475 6.56273 16.3775 4.46575 14.8309 2.91911C13.2843 1.37247 11.1873 0.502481 9 0.5ZM9 5.75C9.59334 5.75 10.1734 5.92595 10.6667 6.25559C11.1601 6.58524 11.5446 7.05377 11.7716 7.60195C11.9987 8.15013 12.0581 8.75333 11.9424 9.33527C11.8266 9.91721 11.5409 10.4518 11.1213 10.8713C10.7018 11.2909 10.1672 11.5766 9.58527 11.6924C9.00333 11.8081 8.40013 11.7487 7.85195 11.5216C7.30377 11.2946 6.83524 10.9101 6.50559 10.4167C6.17595 9.92336 6 9.34334 6 8.75C6 7.95435 6.31607 7.19129 6.87868 6.62868C7.44129 6.06607 8.20435 5.75 9 5.75Z" fill="red"/>
          </svg>
        `)}`,
        scaledSize: new google.maps.Size(30, 30),
        anchor: new google.maps.Point(25, 50),
      }

      // Process each place ID
      for (const placeId of placeIds) {
        try {
          // Use the new Place class to fetch place details
          const place = new Place({
            id: placeId,
            requestedLanguage: 'en', // Optional: specify language
          })

          // Fetch the place details
          await place.fetchFields({
            fields: ['displayName', 'formattedAddress', 'location'],
          })

          if (place.location) {
            const marker = new google.maps.Marker({
              position: place.location,
              map,
              icon: defaultIcon,
            })

            const placeName = place.displayName ?? 'Unknown Place'
            const placeAddress =
              place.formattedAddress ?? 'Address not available'

            const infoWindow = new google.maps.InfoWindow({
              content: `
              <div style="color: white; padding: 8px 12px; border-radius: 8px; font-family: Arial, sans-serif; font-size: 14px; font-weight: bold;">
                <div style="padding-bottom:5px;">${placeName}</div>
                <div style="font-size: 12px; font-weight: normal;">${placeAddress}</div>
              </div>`,
              headerDisabled: true,
            })

            marker.addListener('click', () => {
              if (activeMarkerRef.current) {
                activeMarkerRef.current.setIcon(defaultIcon)
              }
              marker.setIcon(selectedIcon)
              activeMarkerRef.current = marker

              if (activeInfoWindow) {
                activeInfoWindow.close()
              }

              activeInfoWindow = infoWindow
              infoWindow.open(map, marker)
            })

            infoWindow.addListener('closeclick', () => {
              marker.setIcon(defaultIcon)
              activeMarkerRef.current = null
              activeInfoWindow = null
            })
          }
        } catch (error) {
          console.error('Error loading place details:', error)
        }
      }

      // Close the infoWindow when clicking outside
      const handleMapClick = (): void => {
        if (activeInfoWindow) {
          activeInfoWindow.close()
          activeMarkerRef.current?.setIcon(defaultIcon)
          activeInfoWindow = null
          activeMarkerRef.current = null
        }
      }

      map.addListener('click', handleMapClick)
      circle.addListener('click', handleMapClick)
    }

    // Load Google Maps script only once
    if (!window.googleMapsLoaded) {
      const script = document.createElement('script')
      script.src = `https://maps.googleapis.com/maps/api/js?key=${googleMapsApiKey}&libraries=places`
      script.async = true
      script.onload = () => {
        window.googleMapsLoaded = true
        void initMap()
      }
      document.body.appendChild(script)

      return () => {
        document.body.removeChild(script)
      }
    } else {
      void initMap()
    }
  }, [miles, location, placeIds])

  return (
    <Box
      ref={mapRef}
      sx={{
        width: '100%',
        height: '350px',
        borderRadius: '12px',
      }}
    />
  )
}

export default memo(CompetitorInsightsMap)
