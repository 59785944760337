import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Popover,
  Rating,
  TextField,
  Typography,
} from '@mui/material'
import {
  IconDotsVertical,
  IconPhotoPlus,
  IconStarFilled,
  IconTrash,
} from '@tabler/icons-react'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import useAppUtilities from '../../hooks/useAppUtilities'
import { useAppSelector } from '../../store'
import { selectEater } from '../../store/profileSlice'
import {
  IRestaurantReview,
  IRestaurantSentPayloadReview,
  ReviewImage,
} from '../../types'
import CustomButton from '../ui/CustomButton'
import RoundedButton from '../ui/RoundedButton'

interface IReviewProps {
  review?: IRestaurantReview
  mode?: 'readonly' | 'edit' | 'new'
  onReview: (review: IRestaurantSentPayloadReview) => void
  onReviewAction: (action: 'edit' | 'delete') => void
  isLoading?: boolean
}
type ImageType = Blob | ReviewImage

const RestaurantReview: React.FC<IReviewProps> = ({
  review,
  mode = 'readonly',
  onReviewAction,
  onReview,
  isLoading = false,
}) => {
  const { theme } = useAppUtilities()
  const userDetail = useAppSelector(selectEater)
  const [images, setImages] = useState<Blob[]>([])
  const [value, setValue] = useState<number | null>(0)
  const [reviewDetail, setReviewDetail] = useState('')
  const [allImages, setAllImages] = useState<ImageType[]>([])
  const [deleteImage, setDeleteImage] = useState<string[]>([])
  const [selectedImage, setSelectedImage] = useState<ReviewImage[]>([])
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const fileInputRef = useRef<HTMLInputElement>(null)

  const openPop = Boolean(anchorEl)
  const id = openPop ? 'simple-popover' : undefined

  useEffect(() => {
    if (mode === 'new') {
      setValue(0)
      setReviewDetail('')
      setImages([])
    }
    if (review) {
      setValue(review.starCount)
      setReviewDetail(review.review)
      setImages([])
      setSelectedImage(review?.images ?? [])
    }
  }, [mode, review])

  useEffect(() => {
    if (selectedImage?.length && images?.length) {
      setAllImages([...selectedImage, ...images])
    } else if (selectedImage?.length) {
      setAllImages(selectedImage)
    } else if (images?.length) {
      setAllImages(images)
    } else {
      setAllImages([])
    }
  }, [selectedImage, images])

  const handleClose = (): void => {
    setAnchorEl(null)
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleImageDelete = (index: number): void => {
    const deleteImageUrl = allImages.find((item, idx) => idx === index)
    if (
      deleteImageUrl &&
      !(deleteImageUrl instanceof Blob) &&
      deleteImageUrl.url
    ) {
      setDeleteImage((prevState) => [...prevState, deleteImageUrl.url ?? ''])
    }
    const newImages = allImages?.filter((_, i) => i !== index)

    const blobs: Blob[] = []
    const reviewImages: ReviewImage[] = []

    newImages.forEach((item) => {
      if (item instanceof Blob) {
        blobs.push(item)
      } else {
        reviewImages.push(item)
      }
    })

    setImages(blobs)
    setSelectedImage(reviewImages)
    setAllImages(newImages)
  }

  const onUploadImage = (): void => {
    fileInputRef.current?.click()
  }

  const handleFileChange = (event): void => {
    const files = event.target.files
    const newImages = images ? [...images] : []

    for (let i = 0; i < files.length; i++) {
      newImages.push(files[i])
    }

    setImages(newImages)
  }

  const onReviewUpdate = (): void => {
    const payload = {
      starCount: value ?? 0,
      value: reviewDetail,
      images,
      selectedImage,
      deleted: deleteImage,
    }

    onReview(payload)
  }

  const onPopupButtonAction = (name: 'edit' | 'delete'): void => {
    onReviewAction(name)
    setAnchorEl(null)
  }

  return (
    <>
      {mode === 'readonly' ? (
        <Box position="relative">
          <Box
            sx={{
              borderRadius: '16px',
              width: '100%',
              height: '100%',
              border: {
                xs: `1px solid ${theme.palette.grey[300]}`,
                md: 'none',
              },
              p: { xs: '16px', md: '0px' },
              filter: isLoading ? 'blur(2px)' : 'none',
            }}
          >
            <div
              style={{
                width: '100%',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'start',
                  position: 'relative',
                  width: '100%',
                  paddingBottom: '16px',
                }}
              >
                <Avatar
                  alt="Profile Image"
                  sx={{
                    width: 38,
                    height: 38,
                    boxShadow: '0px 0px 2px 0px #1A1A1A40',
                  }}
                  src={userDetail?.imageUrl}
                />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'self-start',
                    flexDirection: 'column',
                    paddingLeft: '16px',
                  }}
                >
                  <div>
                    <Typography
                      component="p"
                      variant="subtitle1"
                      sx={{
                        textTransform: 'capitalize',
                      }}
                      color={theme.palette.grey[500]}
                    >
                      {`${userDetail?.firstName ?? ''} ${
                        userDetail?.lastName ?? ''
                      }`}
                    </Typography>
                    <Typography
                      component="p"
                      variant="subtitle2"
                      color={theme.palette.grey[400]}
                    >
                      {moment(review?.created).format('DD MMM, YYYY')}
                    </Typography>
                  </div>
                </div>

                <Button
                  aria-describedby={id}
                  sx={{
                    p: '0px',
                    minWidth: '16px',
                    position: 'absolute',
                    right: '0px',
                    top: '0px',
                    cursor: 'pointer',
                    color: theme.palette.grey[400],
                    fontSize: '1.5rem',
                  }}
                  onClick={handleClick}
                  variant="text"
                >
                  <IconDotsVertical size={16} stroke={2} />
                </Button>
                <Popover
                  id={id}
                  open={openPop}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  sx={{
                    '& .MuiPaper-root': {
                      borderRadius: '8px',
                    },
                  }}
                >
                  <div
                    style={{
                      padding: '8px',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'start',
                        alignItems: 'center',
                        padding: '8px',
                        color: theme.palette.grey[800],
                        cursor: 'pointer',
                      }}
                      onClick={() => onPopupButtonAction('edit')}
                    >
                      <Typography noWrap component="p" variant="subtitle2">
                        Edit
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'start',
                        alignItems: 'center',
                        padding: '8px',
                        color: theme.palette.error.dark,
                        cursor: 'pointer',
                      }}
                      onClick={() => onPopupButtonAction('delete')}
                    >
                      <Typography noWrap component="p" variant="subtitle2">
                        Delete
                      </Typography>
                    </Box>
                  </div>
                </Popover>
              </div>
              <div>
                <Rating
                  name="simple-controlled"
                  value={value}
                  readOnly
                  size="small"
                  color={theme.palette.warning[400]}
                  onChange={(event, newValue) => {
                    setValue(newValue)
                  }}
                  sx={{
                    '& .MuiRating-icon': {
                      margin: '0 2px',
                    },
                    '& .MuiRating-iconFilled': {
                      color: theme.palette.warning.light,
                    },
                    '& .MuiRating-iconHover': {
                      color: theme.palette.warning[300],
                    },
                  }}
                  icon={<IconStarFilled size={18} />}
                  emptyIcon={
                    <IconStarFilled size={18} color={theme.palette.grey[200]} />
                  }
                />
                <Typography
                  component="p"
                  variant="subtitle1"
                  sx={{
                    pb: '16px',
                    wordBreak: 'break-word',
                  }}
                  color={theme.palette.grey[700]}
                >
                  {review?.review}
                </Typography>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'start',
                gap: '5px',
                width: '100%',
              }}
            >
              {allImages?.slice(0, 3).map((item, index) => (
                <Box
                  key={index}
                  position="relative"
                  sx={{
                    width: { xs: 60, md: 80 },
                    height: { xs: 60, md: 80 },
                    borderRadius: '8px',
                    overflow: 'hidden',
                  }}
                >
                  <Avatar
                    alt="Profile review"
                    sx={{
                      width: '100%',
                      height: '100%',
                      borderRadius: '8px',
                      filter: index === 2 ? 'blur(2px)' : 'none',
                    }}
                    src={
                      item instanceof Blob
                        ? URL.createObjectURL(item)
                        : item.url
                    }
                    variant="square"
                  />
                  {index === 2 && allImages && (
                    <Box
                      position="absolute"
                      top={0}
                      left={0}
                      width="100%"
                      height="100%"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      bgcolor="rgba(0, 0, 0, 0.5)"
                    >
                      <Typography
                        variant="subtitle1"
                        color="white"
                        sx={{
                          textAlign: 'center',
                          fontSize: '12px',
                          fontWeight: 'bold',
                        }}
                      >
                        {allImages?.length - 2} More
                      </Typography>
                    </Box>
                  )}
                </Box>
              ))}
            </div>
          </Box>
          {isLoading && (
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            >
              <CircularProgress color="inherit" />
            </Box>
          )}
        </Box>
      ) : (
        <div
          style={{
            border: `1px solid ${theme.palette.grey[300]}`,
            borderRadius: '8px',
            padding: '16px',
            backgroundColor: theme.palette.grey[50],
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Rating
              name="simple-controlled"
              value={value}
              size="medium"
              sx={{
                '& .MuiRating-icon': {
                  margin: '0 4px',
                },
                '& .MuiRating-iconFilled': {
                  color: theme.palette.warning.light,
                },
                '& .MuiRating-iconHover': {
                  color: theme.palette.warning[300],
                },
              }}
              disabled={isLoading}
              color={theme.palette.warning[400]}
              onChange={(event, newValue) => {
                setValue(newValue)
              }}
              icon={<IconStarFilled fontSize="inherit" />}
              emptyIcon={
                <IconStarFilled
                  fontSize="inherit"
                  color={theme.palette.grey[200]}
                />
              }
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                gap: '8px',
              }}
            >
              <RoundedButton
                br="8px"
                borderColor={theme.palette.grey[300]}
                diameter={36}
                onClick={onUploadImage}
                disabled={allImages?.length >= 4 || isLoading}
              >
                <IconPhotoPlus
                  stroke={2}
                  color={
                    allImages?.length >= 4 || isLoading
                      ? theme.palette.grey[300]
                      : theme.palette.grey[800]
                  }
                  size={18}
                />
              </RoundedButton>
              <input
                ref={fileInputRef}
                type="file"
                accept="image/*"
                style={{ display: 'none' }}
                onChange={(event) => {
                  void handleFileChange(event)
                }}
              />

              <CustomButton
                onClick={onReviewUpdate}
                icon={
                  isLoading ? (
                    <CircularProgress
                      style={{
                        color: theme.palette.grey[800],
                      }}
                      size={16}
                    />
                  ) : null
                }
                textColor="white"
                title={mode === 'new' ? 'Post Review' : 'Update Review'}
                textVariant="subtitle2"
                disabled={!value || isLoading}
                disabledTextColor={theme.palette.grey[800]}
                sx={{
                  py: '9px',
                  '&.Mui-disabled': {
                    color: theme.palette.grey[400],
                    bgcolor: theme.palette.grey[100],
                  },
                }}
              />
            </div>
          </div>
          <TextField
            id="description"
            placeholder="Enter a description..."
            variant="outlined"
            multiline
            disabled={isLoading}
            value={reviewDetail}
            onChange={(event) => {
              const { value } = event.target
              setReviewDetail(value)
            }}
            rows={4}
            sx={{
              my: '12px',
              width: '100%',
              backgroundColor: 'white',
              '& .MuiOutlinedInput-root': {
                minWidth: '100%',
                fontSize: '16px',
                color: theme.palette.grey[800],
                fontWeight: 500,
                '& fieldset': {
                  borderRadius: '8px',
                  borderColor: theme.palette.grey[300],
                },
              },
            }}
          />

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'start',
              gap: '5px',
              height: { xs: '60px', md: '80px' },
              width: '100%',
            }}
          >
            {allImages?.map((item, index) => (
              <Box
                key={index}
                position="relative"
                sx={{
                  width: { xs: 60, md: 80 },
                  height: { xs: 60, md: 80 },
                  borderRadius: '8px',
                }}
              >
                <Avatar
                  alt="Profile review"
                  sx={{
                    width: '100%',
                    height: '100%',
                    borderRadius: '8px',
                  }}
                  src={
                    item instanceof Blob ? URL.createObjectURL(item) : item.url
                  }
                  variant="square"
                />
                {!isLoading && (
                  <IconButton
                    size="small"
                    onClick={() => handleImageDelete(index)}
                    sx={{
                      position: 'absolute',
                      top: '2px',
                      right: '2px',
                      backgroundColor: '#00000066',
                    }}
                  >
                    <IconTrash color="white" size={18} stroke={2} />
                  </IconButton>
                )}
              </Box>
            ))}
          </Box>
        </div>
      )}
    </>
  )
}

export default RestaurantReview
