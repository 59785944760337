import { Fab } from '@mui/material'
import styled from 'styled-components'

export const FabButtonStyled = styled(Fab).attrs(() => {
  return {
    color: 'primary',
    size: 'small',
    sx: {
      position: 'fixed',
      bottom: 16,
      right: 16,
    },
  }
})(() => ({}))
