import '@elastic/react-search-ui-views/lib/styles/styles.css'
import {
  Autocomplete,
  Box,
  CircularProgress,
  InputAdornment,
  ListItem,
  Paper,
  TextField,
  Typography,
} from '@mui/material'
import { IconMapPin, IconPointFilled, IconSearch } from '@tabler/icons-react'
import React, { useState } from 'react'
import { ROUTES } from '../../helpers/routes-helper'
import {
  MOBILE_SEARCH_HEIGHT,
  WEB_NAV_HEIGHT,
} from '../../helpers/static-constants'
import useAppUtilities from '../../hooks/useAppUtilities'
import useElasticGlobalSearch from '../../hooks/useElasticGlobalSearch'
import {
  setElasticSearchDishes,
  setElasticSearchRestaurants,
} from '../../store/searchSlice'

interface RenderOptionProps extends React.HTMLAttributes<HTMLLIElement> {
  key: string
}

const GlobalSearch: React.FC = (): JSX.Element => {
  const { theme, dispatch, navigate } = useAppUtilities()
  const [searchInputValue, setSearchInputValue] = useState('')
  const { results, loading } = useElasticGlobalSearch(searchInputValue)

  const handleSubmit = (result: any): void => {
    if (!result) return

    if (typeof result === 'string' && results.length > 0) {
      const restaurantArray = results
        .filter((obj) => obj?.restaurantID)
        .map((obj) => obj?._meta?.rawHit?._source)

      const dishArray = results
        .filter((obj) => obj?.dishID)
        .map((obj) => obj?._meta?.rawHit?._source)

      dispatch(setElasticSearchRestaurants(restaurantArray))
      dispatch(setElasticSearchDishes(dishArray))
      navigate(`${ROUTES.SEARCH}?type=restaurant&q=${result}`)
    }

    const data = result?._meta?.rawHit?._source

    if (data?.restaurantID && data?.alias) {
      navigate(`${ROUTES.RESTAURANT_DETAILS}/${data?.alias as string}/details`)
    }
    if (data?.dishID && data?.restaurant) {
      navigate(
        `${ROUTES.RESTAURANT_DETAILS}/${data?.restaurant as string}/details`,
      )
    }
    if (data?.value && data?.key) {
      navigate(`${ROUTES.SEARCH}?type=cuisine&q=${data?.key as string}`)
    }
    if (result?.cityName && result?.value) {
      navigate(`${ROUTES.SEARCH}?type=city&q=${result?.value as string}`)
    }
  }

  const processedResults = React.useMemo(() => {
    const cityMatch = results.find(
      (result) =>
        result?.city?.raw.toLowerCase() === searchInputValue.toLowerCase(),
    )

    return cityMatch
      ? [
          {
            cityName: searchInputValue,
            value: searchInputValue.toLowerCase().replace(/\s+/g, '_'),
          },
          ...results,
        ]
      : results
  }, [results, searchInputValue])

  return (
    <Box
      sx={{
        borderLeft: { xs: 'none', md: `1px solid ${theme.palette.grey[300]}` },
        borderRight: `1px solid ${theme.palette.grey[300]}`,
        width: { xs: '100%', md: 'inherit' },
      }}
    >
      <Autocomplete
        freeSolo
        loading={loading}
        onChange={(event, value) => handleSubmit(value)}
        options={processedResults}
        inputValue={searchInputValue}
        onInputChange={(event, newInputValue) => {
          setSearchInputValue(newInputValue)
        }}
        filterOptions={(x) => x}
        handleHomeEndKeys
        getOptionLabel={(option: any) =>
          option?.name?.raw ?? option?.key?.raw ?? option?.cityName ?? ''
        }
        PaperComponent={(props) => (
          <>
            {processedResults && processedResults.length > 0 && (
              <Paper
                {...props}
                sx={{
                  backgroundColor: 'white',
                  mt: { xs: '0px', md: '4px' },
                  boxShadow: {
                    xs: '0px 12px 20px 0px rgba(112, 112, 112, 0.25)',
                    md: '0px 0px 20px 0px rgba(112, 112, 112, 0.25)',
                  },
                  border: `1px solid ${theme.palette.grey[300]}`,
                  borderRadius: { xs: '0px', md: '8px' },
                  width: { xs: '100vw', md: '415px', lg: '100%' },
                }}
              />
            )}
          </>
        )}
        renderOption={(props: RenderOptionProps, option) => {
          const { key, ...otherProps } = props

          return (
            <Box key={key} width="100%" px={'8px'}>
              {option?.cityName ? (
                <ListItem
                  {...otherProps}
                  sx={{
                    '&:hover': {
                      backgroundColor: '#f0f0f0',
                    },
                    padding: '8px !important',
                    borderRadius: '8px',
                  }}
                >
                  <Box display="flex" alignItems="center" gap={2}>
                    <Box display="flex" justifyContent="center">
                      <img
                        srcSet={`${'/logoPin.svg'}?w=20&h=20&fit=crop&auto=format&dpr=2 2x`}
                        src={`${'/logoPin.svg'}?w=30&h=30&fit=crop&auto=format`}
                        alt={option?.name?.raw}
                        style={{
                          height: '48px',
                          borderRadius: '12px',
                          marginRight: '8px',
                          cursor: 'pointer',
                        }}
                        loading="lazy"
                      />
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="space-evenly"
                    >
                      <Typography
                        color={theme.palette.grey[800]}
                        variant="body2"
                        fontWeight={500}
                        textTransform={'capitalize'}
                      >
                        {option?.cityName}
                      </Typography>
                    </Box>
                  </Box>
                </ListItem>
              ) : (
                <ListItem
                  {...otherProps}
                  sx={{
                    '&:hover': {
                      backgroundColor: '#f0f0f0',
                    },
                    padding: '8px !important',
                    borderRadius: '8px',
                  }}
                >
                  <Box display="flex" alignItems="center">
                    <Box display="flex" justifyContent="center">
                      <img
                        srcSet={`${'/logoLetter.svg'}?w=20&h=20&fit=crop&auto=format&dpr=2 2x`}
                        src={`${'/logoLetter.svg'}?w=30&h=30&fit=crop&auto=format`}
                        alt={option?.name?.raw}
                        style={{
                          height: '48px',
                          borderRadius: '12px',
                          marginRight: '8px',
                          cursor: 'pointer',
                        }}
                        loading="lazy"
                      />
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="space-evenly"
                    >
                      <Typography
                        color={theme.palette.grey[800]}
                        variant="body2"
                        fontWeight={500}
                      >
                        {option?.name?.raw ?? option?.key?.raw}
                      </Typography>
                      <Box display="flex">
                        <Typography
                          color={theme.palette.grey[500]}
                          variant="subtitle2"
                          fontWeight={500}
                        >
                          {option?.restaurantID
                            ? 'Restaurant '
                            : option?.dishID
                            ? 'Dish'
                            : 'Cuisine'}
                        </Typography>
                        {option?.restaurantID && (
                          <>
                            <IconPointFilled
                              size={12}
                              stroke={2}
                              color={theme.palette.grey[300]}
                              style={{ marginLeft: '6px', alignSelf: 'center' }}
                            />
                            <IconMapPin
                              size={14}
                              stroke={2}
                              color={theme.palette.grey[600]}
                              style={{ marginLeft: '6px' }}
                            />
                            <Typography
                              variant="subtitle2"
                              component="p"
                              fontWeight={500}
                              sx={{ textTransform: 'capitalize' }}
                              color={theme.palette.grey[600]}
                              paddingLeft={'5px'}
                            >
                              {[
                                option?.address1.raw,
                                option?.city.raw,
                                option?.state.raw,
                              ].join(', ')}
                            </Typography>
                          </>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </ListItem>
              )}
            </Box>
          )
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Search for location, restaurant, cuisine or dish"
            variant="outlined"
            sx={{
              width: {
                xs: '100%',
                md: '100%',
                lg: '415px',
                xl: '415px',
              },
              minWidth: { md: '220px' },
              '& .MuiOutlinedInput-root': {
                fontSize: '16px',
                fontWeight: 500,
                height: {
                  xs: `${MOBILE_SEARCH_HEIGHT}px`,
                  md: `${WEB_NAV_HEIGHT}px`,
                },
                '& fieldset': {
                  border: 'none',
                },
              },
            }}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <IconSearch
                    stroke={2}
                    size={16}
                    style={{
                      color: theme.palette.grey[400],
                      marginLeft: '7px',
                    }}
                  />
                </InputAdornment>
              ),
              endAdornment: (
                <>
                  {loading ? <CircularProgress size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </Box>
  )
}

export default GlobalSearch
