import { Box, Divider, Typography } from '@mui/material'
import React from 'react'
import useAppUtilities from '../../hooks/useAppUtilities'
import DietaryTags from './DietaryTags'

interface DishDetailCommonProps {
  suitableDietary?: string
  unsuitableDietary?: string
  Ingredients?: string
  Preparation?: string
}

const DishDetailCommon: React.FC<DishDetailCommonProps> = ({
  suitableDietary,
  unsuitableDietary,
  Ingredients,
  Preparation,
}) => {
  const { theme } = useAppUtilities()

  return (
    <div>
      <Divider
        orientation="horizontal"
        flexItem
        sx={{ color: theme.palette.grey[300], marginY: '20px' }}
      />
      {Ingredients && (
        <Box>
          <Typography variant="subtitle1" color={theme.palette.grey[500]}>
            Ingredients:
          </Typography>
          <Typography
            variant="subtitle1"
            fontWeight={400}
            color={theme.palette.grey[800]}
            textTransform='capitalize'
            sx={{
              marginTop: '2px',
              color: theme.palette.grey[800],
              wordBreak: 'break-all',
            }}
          >
            {Ingredients}
          </Typography>
        </Box>
      )}
      {Preparation && (
        <Box sx={{ marginTop: '20px' }}>
          <Typography variant="subtitle1" color={theme.palette.grey[500]}>
            Preparation:
          </Typography>
          <Typography
            variant="subtitle1"
            fontWeight={400}
            color={theme.palette.grey[800]}
            sx={{ marginTop: '2px' }}
          >
            {Preparation}
          </Typography>
        </Box>
      )}
      <Divider
        orientation="horizontal"
        flexItem
        sx={{ color: theme.palette.grey[300], marginY: '20px' }}
      />
      {suitableDietary && (
        <Box sx={{ marginTop: '20px' }}>
          <Typography
            variant="subtitle1"
            color={theme.palette.grey[500]}
            sx={{ marginBottom: '4px' }}
          >
            Suitable Dietary:
          </Typography>
          <DietaryTags dietaryData={suitableDietary} suitable={true} />
        </Box>
      )}
      {unsuitableDietary && (
        <Box sx={{ marginTop: '20px' }}>
          <Typography
            variant="subtitle1"
            color={theme.palette.grey[500]}
            sx={{ marginBottom: '4px' }}
          >
            Unsuitable Dietary:
          </Typography>
          <DietaryTags dietaryData={unsuitableDietary} suitable={false} />
        </Box>
      )}
    </div>
  )
}

export default DishDetailCommon
