import { Box, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import DeleteDialog from '../../../../components/restaurants/DeleteDialog'
import { ROUTES } from '../../../../helpers/routes-helper'
import useAppUtilities from '../../../../hooks/useAppUtilities'
import {
  AddDishButton,
  DeleteDishButton,
  DishHeaderBackButton,
  DishHeaderEditMenuButton,
  DishHeaderMainBox,
  DishHeaderTitleCanCelButton,
  DishHeaderTitleSaveButton,
  DishMenu,
  DishMenuItem,
  DishMenuItemTypography,
  DishMoreButton,
} from '../styled/DishHeader.styled'
import AddEditDishDialog, { dishDialogMethods } from './AddEditDishDialog'
import {
  deleteMenu,
  selectedMenu,
  setOpenStaticMessage,
  updateMenuName,
} from '../../../../store/restaurant/menu'
import {
  IMenuDeleteRequest,
  IMenuUpdateRequest,
} from '../../../../types/menuTypes'
import BasicModal from '../../../../components/modal/BasicModal'
import { useParams } from 'react-router'
import { MuiBackdrop } from '../../../eater/styled/global.styled'
import { ProfileCircularProgress } from '../../../eater/profile/styled/ProfilePage.styled'
import AddEditMenuDialog from '../../menu/components/AddEditMenuDialog'
import { useAppSelector } from '../../../../store'
import InputComponent from '../../../../components/ui/InputComponent'

interface DishHeaderProps {
  isAddDialogOpen: boolean
  setAddDialogOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const DishHeader: React.FC<DishHeaderProps> = ({
  isAddDialogOpen,
  setAddDialogOpen,
}) => {
  const { navigate, isMediumScreen, dispatch, theme } = useAppUtilities()
  const { id } = useParams()
  const menu = useAppSelector(selectedMenu)
  const [currentTitle, setCurrentTitle] = useState(menu?.name ?? '')
  const [isEditingTitle, setIsEditingTitle] = useState(false)
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [isLoadingMenu, setIsLoadingMenu] = useState(false)
  const [isValid, setIsValid] = useState(false)
  const [modalOpenEditMenu, setModalOpenEdit] = useState(false)
  const isMenuOpen = Boolean(anchorEl)
  const dishDialogRef = useRef<dishDialogMethods>(null)

  useEffect(() => {
    setCurrentTitle(menu?.name ?? '')
  }, [menu])

  const handleNavigateBack = (): void => navigate(ROUTES.RESTAURANT_MENUS)

  const handleDelete = async (): Promise<void> => {
    if (id) {
      const payload: IMenuDeleteRequest = {
        menuId: id,
      }
      const res = await dispatch(deleteMenu(payload))
      if (deleteMenu.fulfilled.match(res)) {
        dispatch(
          setOpenStaticMessage({
            message: `${menu?.name ?? ''} Menu deleted successfully.`,
            severity: 'success',
            shouldOpen: true,
            status: 'idle',
          }),
        )
      }
      navigate(`${ROUTES.RESTAURANT_MENUS}`)
      setDeleteDialogOpen(false)
    }
  }

  const handleMenuClose = (): void => setAnchorEl(null)

  const handleTitleSave = async (): Promise<void> => {
    if (id && currentTitle.length > 0) {
      setIsLoadingMenu(true)
      const payload: IMenuUpdateRequest = {
        menuId: id,
        name: currentTitle,
      }
      const res = await dispatch(updateMenuName(payload))
      if (updateMenuName.fulfilled.match(res)) {
        dispatch(
          setOpenStaticMessage({
            message: `${currentTitle} menu updated successfully.`,
            severity: 'success',
            shouldOpen: true,
            status: 'idle',
          }),
        )
      }
      setIsLoadingMenu(false)
      setIsEditingTitle(false)
    }
  }

  const handleTitleCancel = (): void => {
    setCurrentTitle(menu?.name ?? '')
    setIsEditingTitle(false)
  }

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const onHandleAddDish = async (): Promise<void> => {
    if (dishDialogRef.current) {
      await dishDialogRef.current.getPayload()
      setAddDialogOpen(false)
    }
  }

  return (
    <>
      <DishHeaderMainBox>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <DishHeaderBackButton onClick={handleNavigateBack} />

          {isEditingTitle && !isMediumScreen ? (
            <Box sx={{ display: 'flex', alignItems: 'center' }} py="5.4px">
              <InputComponent
                id="menu-name"
                value={currentTitle}
                setValue={(e) => setCurrentTitle(e)}
                fullWidth
                placeholder="Enter Menu Name"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    backgroundColor: theme.palette.background.paper,
                  },
                }}
              />
              <DishHeaderTitleCanCelButton onClick={handleTitleCancel} />
              <DishHeaderTitleSaveButton
                disable={currentTitle.length <= 0}
                onClick={() => {
                  void handleTitleSave()
                }}
              />
            </Box>
          ) : (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="h3" my={1} textTransform="capitalize">
                {currentTitle}
              </Typography>
              {!isMediumScreen && (
                <DishHeaderEditMenuButton
                  onClick={() => setIsEditingTitle(true)}
                />
              )}
            </Box>
          )}
        </Box>

        {!isMediumScreen ? (
          <Box sx={{ display: 'flex', gap: '14px' }}>
            <AddDishButton onClick={() => setAddDialogOpen(true)} />
            <DeleteDishButton onClick={() => setDeleteDialogOpen(true)} />
          </Box>
        ) : (
          <DishMoreButton
            aria-controls={isMenuOpen ? 'edit-button' : undefined}
            aria-expanded={isMenuOpen ? 'true' : undefined}
            onClick={handleMenuOpen}
          />
        )}

        <BasicModal
          open={isAddDialogOpen}
          onCancel={() => setAddDialogOpen(false)}
          onConfirm={() => {
            void onHandleAddDish()
          }}
          title="Add Dish"
          buttonName="Add"
          modelWidth="500px"
          isLoader={isLoading}
          saveBtnDisabled={!isValid}
          handleClose={() => setAddDialogOpen(false)}
        >
          <AddEditDishDialog
            ref={dishDialogRef}
            setLoading={(load) => setIsLoading(load)}
            setIsValid={(valid) => setIsValid(valid)}
          />
        </BasicModal>

        <DishMenu
          anchorEl={anchorEl}
          open={isMenuOpen}
          onClose={handleMenuClose}
        >
          <DishMenuItem
            onClick={(): void => {
              setAddDialogOpen(true)
              handleMenuClose()
            }}
          >
            <DishMenuItemTypography>Add Dish</DishMenuItemTypography>
          </DishMenuItem>
          <DishMenuItem
            onClick={(): void => {
              setModalOpenEdit(true)
              handleMenuClose()
            }}
          >
            <DishMenuItemTypography>Edit Menu</DishMenuItemTypography>
          </DishMenuItem>
          <DishMenuItem
            onClick={(): void => {
              setDeleteDialogOpen(true)
              handleMenuClose()
            }}
          >
            <DishMenuItemTypography>Delete Menu</DishMenuItemTypography>
          </DishMenuItem>
        </DishMenu>
      </DishHeaderMainBox>

      <DeleteDialog
        open={isDeleteDialogOpen}
        onCancel={() => setDeleteDialogOpen(false)}
        onConfirm={() => {
          void handleDelete()
        }}
        title="Delete Menu?"
        handleClose={() => setDeleteDialogOpen(false)}
      />
      <AddEditMenuDialog
        openEditModel={modalOpenEditMenu}
        setOpenEditModel={setModalOpenEdit}
        menu={menu}
      />
      <MuiBackdrop open={isLoadingMenu}>
        <ProfileCircularProgress />
      </MuiBackdrop>
    </>
  )
}

export default DishHeader
