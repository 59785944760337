import { Box, MenuItem, Typography } from '@mui/material'
import React, { useState } from 'react'
import InputComponent from '../../../../../../components/ui/InputComponent'
import useAppUtilities from '../../../../../../hooks/useAppUtilities'
import { setRestaurantMiles } from '../../../../../../store/restaurant/dashboard'
import { CompetitorInsightsSelectOption } from '../../../styled/CompetitorInsights.styled'
import RestaurantSearch from '../../restaurantSearch/RestaurantSearch'

interface RangAndFilterDialogProps {
  selectedTabName: string
}

const RangAndFilterDialog: React.FC<RangAndFilterDialogProps> = ({
  selectedTabName,
}) => {
  const { dispatch, isMediumScreen, theme } = useAppUtilities()
  const [search, setSearch] = useState<string>('')

  const handleChange = (name: number): void => {
    dispatch(setRestaurantMiles(name))
  }

  return (
    <Box p={1}>
      <Box
        py={2}
        sx={{
          borderBottom: 1,
          borderColor: theme.palette.grey[300],
        }}
      >
        <Typography
          variant="body2"
          textAlign="center"
          color={theme.palette.primary.light}
        >
          Range
        </Typography>
      </Box>
      {selectedTabName !== 'knowYourNeighbourhood' ? (
        <Box key="range">
          <Box py={1}>
            <InputComponent
              id="search"
              value={search}
              setValue={setSearch}
              placeholder="Search by restaurant, location, zip"
              sx={{
                width: isMediumScreen ? '100%' : 'auto',
                marginRight: '10px',
              }}
              inputProps={{
                autoComplete: 'off',
              }}
            />
          </Box>
          <MenuItem onClick={() => handleChange(5)}>
            <CompetitorInsightsSelectOption>
              Under 5 miles
            </CompetitorInsightsSelectOption>
          </MenuItem>
          <MenuItem onClick={() => handleChange(10)}>
            <CompetitorInsightsSelectOption>
              Under 10 miles
            </CompetitorInsightsSelectOption>
          </MenuItem>
          <MenuItem onClick={() => handleChange(15)}>
            <CompetitorInsightsSelectOption>
              Under 15 miles
            </CompetitorInsightsSelectOption>
          </MenuItem>
          <MenuItem onClick={() => handleChange(20)}>
            <CompetitorInsightsSelectOption>
              Under 20 miles
            </CompetitorInsightsSelectOption>
          </MenuItem>
          <MenuItem onClick={() => handleChange(25)}>
            <CompetitorInsightsSelectOption>
              Under 25 miles
            </CompetitorInsightsSelectOption>
          </MenuItem>
        </Box>
      ) : (
        <Box pt={1}>
          <RestaurantSearch onClose={() => {}} />
        </Box>
      )}
    </Box>
  )
}

export default RangAndFilterDialog
