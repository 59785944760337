import { Box } from '@mui/material'
import styled from 'styled-components'

export const ImageUploadBox = styled(Box).attrs(() => {
  return {
    sx: {
      width: {
        xs: 'auto',
        md: '100%',
      },
      height: '100%',
      position: 'relative',
    },
  }
})(() => ({}))

export const ImageDeleteButton = styled(Box).attrs(() => {
  return {
    sx: {
      position: 'absolute',
      backgroundColor: '#00000080',
      borderRadius: '50%',
      height: '40px',
      width: '40px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      top: '8px',
      right: '8px',
      zIndex: '1',
      cursor: 'pointer',
    },
  }
})(() => ({}))

export const ImageRotateButton = styled(Box).attrs(() => {
  return {
    sx: {
      position: 'absolute',
      backgroundColor: '#00000080',
      borderRadius: '50%',
      height: '40px',
      width: '40px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      top: '56px',
      right: '8px',
      zIndex: '1',
      cursor: 'pointer',
    },
  }
})(() => ({}))
