import { Box, Fade, Grid, Skeleton } from '@mui/material'
import { IconPencil } from '@tabler/icons-react'
import React, { useEffect, useState } from 'react'
import ResactlyTab from '../../../components/ResactlyTab'
import RestaurantsPageLayout from '../../../components/restaurants/RestaurantsPageLayout'
import { FabButtonStyled } from '../../../components/styled/FabButton.styled'
import useAppUtilities from '../../../hooks/useAppUtilities'
import { useAppSelector } from '../../../store'
import {
  fetchRestaurantAdmin,
  selectIsLoading,
  selectIsSettingEdit,
  selectMessage,
  selectSeverity,
  selectShouldOpen,
  setCurrentTab,
  setIsEditCancelledData,
  setIsSettingEdit,
  setIsUpdateData,
  setIsValidData,
  setOpen,
} from '../../../store/restaurant/setting'
import {
  ProfileHeaderCustomButtonCancel,
  ProfileHeaderCustomButtonUpdate,
} from '../../eater/profile/styled/ProfileHeader.styled'
import { RecommendationsMobileCancelUpdateButtonBox } from '../recommendations/styled/Recommendations.styled'
import AddressTab from './components/AddressTab'
import BasicDetails from './components/BasicDetails'
import ContactTab from './components/ContactTab'
import EditModeButtons from './components/EditModeButtons'
import ImagesTab from './components/ImagesTab'
import ProfileInfoCard from './components/ProfileInfoCard'
import SocialTab from './components/SocialTab'
import ViewModeButton from './components/ViewModeButton'
import { SettingTabMainBox } from './styled/Settings.styled'
import { selectRestaurantAlias } from '../../../store/authSlice'
import {
  MuiAlert,
  MuiBackdrop,
  MuiSnackbar,
} from '../../eater/styled/global.styled'
import { ProfileCircularProgress } from '../../eater/profile/styled/ProfilePage.styled'

const Settings: React.FC = () => {
  const { dispatch, isMediumScreen } = useAppUtilities()
  const isEdit = useAppSelector(selectIsSettingEdit)
  const restaurantAlias = useAppSelector(selectRestaurantAlias)
  const openServer = useAppSelector(selectShouldOpen)
  const isLoadingData = useAppSelector(selectIsLoading)
  const message = useAppSelector(selectMessage)
  const severity = useAppSelector(selectSeverity)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    void getRestaurant()
  }, [])

  const getRestaurant = async (): Promise<void> => {
    setIsLoading(true)
    await dispatch(fetchRestaurantAdmin({ alias: restaurantAlias ?? '' }))
    setIsLoading(false)
  }

  const getCurrentTab = (item: any): void => {
    dispatch(setIsSettingEdit(false))
    dispatch(setIsUpdateData(false))
    dispatch(setIsEditCancelledData(false))
    dispatch(setCurrentTab(item?.value))
  }

  const onUpdate = (): void => {
    dispatch(setIsUpdateData(true))
    dispatch(setIsSettingEdit(false))
  }
  const onCancel = (): void => {
    dispatch(setIsEditCancelledData(true))
    dispatch(setIsSettingEdit(false))
  }

  const tabComponents = componentList.map((component, i) => (
    <Box
      key={i}
      sx={{
        marginTop: '20px',
      }}
    >
      {component}
    </Box>
  ))

  const setOpenServer = (): void => {
    dispatch(setOpen(false))
  }

  const onEditBtn = (): void => {
    dispatch(setIsSettingEdit(true))
    dispatch(setIsUpdateData(false))
    dispatch(setIsEditCancelledData(false))
    dispatch(setIsValidData(false))
  }

  return (
    <RestaurantsPageLayout
      title="Settings"
      removeHeight={isEdit ? 50 : 0}
      HeaderRight={
        isEdit ? (
          <EditModeButtons onCancel={onCancel} onUpdate={onUpdate} />
        ) : (
          <ViewModeButton onEdit={() => onEditBtn()} />
        )
      }
      body={
        <Box>
          {!isEdit ? (
            <>
              {isMediumScreen && (
                <FabButtonStyled
                  id="edit-button"
                  onClick={() => dispatch(setIsSettingEdit(true))}
                >
                  <IconPencil stroke={2} size={16} />
                </FabButtonStyled>
              )}
            </>
          ) : (
            <>
              {isMediumScreen && (
                <RecommendationsMobileCancelUpdateButtonBox>
                  <ProfileHeaderCustomButtonCancel
                    title="Cancel"
                    onClick={onCancel}
                    fullWidth
                  />
                  <ProfileHeaderCustomButtonUpdate
                    title="Update"
                    disabled={false}
                    onClick={onUpdate}
                    fullWidth
                  />
                </RecommendationsMobileCancelUpdateButtonBox>
              )}
            </>
          )}

          <Grid container spacing={2}>
            {!isLoading ? (
              <>
                <Fade in={!isLoading} timeout={1000}>
                  <Grid item xs={12} md={4} lg={3}>
                    <ProfileInfoCard isMediumScreen={isMediumScreen} />
                  </Grid>
                </Fade>
                <Fade in={!isLoading} timeout={1000}>
                  <Grid item xs={12} md={8} lg={9}>
                    <SettingTabMainBox>
                      <ResactlyTab
                        align="start"
                        list={tabList}
                        tabComponent={tabComponents}
                        onChangeTab={(a) => getCurrentTab(a)}
                      />
                    </SettingTabMainBox>
                  </Grid>
                </Fade>
              </>
            ) : (
              <>
                <Grid item xs={12} md={4} lg={3}>
                  <Skeleton
                    variant="rectangular"
                    sx={{ height: { xs: '20vh', md: '60vh' }, borderRadius: 4 }}
                  ></Skeleton>
                </Grid>
                <Grid item xs={12} md={8} lg={9}>
                  <Skeleton
                    variant="rectangular"
                    sx={{ height: { xs: '50vh', md: '60vh' }, borderRadius: 4 }}
                  ></Skeleton>
                </Grid>
              </>
            )}
          </Grid>
          {!isLoading && (
            <MuiBackdrop open={isLoadingData}>
              <ProfileCircularProgress />
            </MuiBackdrop>
          )}
          <MuiSnackbar open={openServer} onClose={setOpenServer}>
            <MuiAlert onClose={setOpenServer} severity={severity ?? 'success'}>
              {message}
            </MuiAlert>
          </MuiSnackbar>
        </Box>
      }
    />
  )
}

export default Settings

const tabList = [
  { label: 'Basic Details', value: 'basicDetails' },
  { label: 'Images', value: 'image' },
  { label: 'Address', value: 'address' },
  { label: 'Contact', value: 'contact' },
  { label: 'Social', value: 'social' },
]

const componentList = [
  <BasicDetails key={'basicDetails'} />,
  <ImagesTab key="image" />,
  <AddressTab key="address" />,
  <ContactTab key="contact" />,
  <SocialTab key="social" />,
]
