import {
  Box,
  InputBase,
  Paper,
  Typography,
  CircularProgress,
} from '@mui/material'
import { IconMessages, IconSend } from '@tabler/icons-react'
import React, { useEffect, useRef, useState } from 'react'
import CustomButton from '../../../../../components/ui/CustomButton'
import useAppUtilities from '../../../../../hooks/useAppUtilities'
import {
  fetchInsightsAnswers,
  selectRestaurantFIPS,
  selectSelectedFIPS,
} from '../../../../../store/restaurant/dashboard'
import { useAppSelector } from '../../../../../store'
import dayjs from 'dayjs'
import MessageDisplay from '../../../../../components/MessageDisplay'

interface IMessagesPayload {
  type?: 'user' | 'bot' | 'loading' | 'error'
  text?: string
  time?: string
}

const GetInsightsAnswers: React.FC = () => {
  const { theme, dispatch } = useAppUtilities()
  const adminFIPS = useAppSelector(selectRestaurantFIPS)
  const selectedFIPS = useAppSelector(selectSelectedFIPS)
  const [messages, setMessages] = useState<IMessagesPayload[]>([])
  const messagesEndRef = useRef<HTMLDivElement | null>(null)
  const [input, setInput] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })
  }, [messages])

  const handleSend = async (): Promise<void> => {
    if (!input.trim()) return

    const search = input.trim()

    const newMessage: IMessagesPayload = {
      type: 'user',
      text: search,
      time: dayjs().format('HH:mm'),
    }
    setMessages([...messages, newMessage])
    setIsLoading(true)
    setInput('')

    try {
      const res = await dispatch(
        fetchInsightsAnswers({
          fips: selectedFIPS?.length
            ? selectedFIPS
            : adminFIPS && adminFIPS.length
            ? adminFIPS
            : '',
          question: search,
        }),
      )

      if (fetchInsightsAnswers.fulfilled.match(res)) {
        const resMessage: IMessagesPayload = {
          type: 'bot',
          text: res.payload.answer,
          time: dayjs().format('HH:mm'),
        }
        setMessages((messages) => [...messages, resMessage])
      } else if (fetchInsightsAnswers.rejected.match(res)) {
        const errorMessage: IMessagesPayload = {
          type: 'error',
          text: 'An error occurred while fetching the answer. Please try again later.',
          time: dayjs().format('HH:mm'),
        }
        setMessages((messages) => [...messages, errorMessage])
      }
    } catch (error) {
      const errorMessage: IMessagesPayload = {
        type: 'error',
        text: 'An unexpected error occurred. Please try again later.',
        time: dayjs().format('HH:mm'),
      }
      setMessages((messages) => [...messages, errorMessage])
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Box
      sx={{
        height: '330px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        bgcolor: 'background.paper',
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          overflowY: 'auto',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: messages.length === 0 ? 'center' : 'flex-start',
          pr: '4px',
        }}
      >
        {messages.length === 0 ? (
          <>
            <Box
              height="56px"
              width="56px"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '50%',
                backgroundColor: theme.palette.grey[100],
              }}
            >
              <IconMessages
                stroke={1.5}
                size={22}
                color={theme.palette.grey[600]}
              />
            </Box>
            <Typography
              variant="subtitle1"
              fontWeight={600}
              color="grey.600"
              letterSpacing=".3px"
              mt={2}
            >
              Get Insights & Answers
            </Typography>
            <Typography
              variant="subtitle2"
              color="grey.500"
              letterSpacing=".3px"
              mt="4px"
            >
              Ask regional-based questions anytime
            </Typography>
          </>
        ) : (
          messages.map((msg, index) => (
            <Paper
              ref={messagesEndRef}
              key={index}
              sx={{
                p: 1,
                mb: 1,
                maxWidth: '80%',
                alignSelf: msg.type === 'user' ? 'flex-end' : 'flex-start',
                bgcolor:
                  msg.type !== 'error'
                    ? msg.type !== 'user'
                      ? theme.palette.background.paper
                      : theme.palette.grey[100]
                    : theme.palette.error[50],
                border: 1,
                borderColor: 'grey.200',
                borderRadius: '8px',
                display: 'flex',
                flexDirection: 'row',
                boxShadow: 'none',
              }}
            >
              {msg?.type === 'user' ? (
                <Typography
                  variant="subtitle2"
                  lineHeight={1.5}
                  letterSpacing={0.4}
                  color="grey.800"
                >
                  {msg.text?.toLocaleLowerCase()}
                </Typography>
              ) : (
                <Box sx={{ typography: 'subtitle2' }}>
                  <MessageDisplay text={msg?.text ?? ''} />
                </Box>
              )}
              <Typography
                sx={{
                  alignSelf: 'end',
                }}
                variant="subtitle2"
                fontSize={'10px'}
                color="grey.400"
                pl="4px"
              >
                {msg.time}
              </Typography>
            </Paper>
          ))
        )}
        {isLoading && (
          <Paper
            sx={{
              p: 1,
              mb: 1,
              maxWidth: '80%',
              alignSelf: 'flex-start',
              bgcolor: theme.palette.background.paper,
              border: 1,
              borderColor: 'grey.200',
              borderRadius: '8px',
              display: 'flex',
              flexDirection: 'row',
              boxShadow: 'none',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <svg width={0} height={0}>
                <defs>
                  <linearGradient
                    id="my_gradient"
                    x1="0%"
                    y1="0%"
                    x2="0%"
                    y2="100%"
                  >
                    <stop offset="0%" stopColor="#e01cd5" />
                    <stop offset="100%" stopColor="#1CB5E0" />
                  </linearGradient>
                </defs>
              </svg>
              <CircularProgress
                sx={{ 'svg circle': { stroke: 'url(#my_gradient)' } }}
                size={20}
              />
            </Box>
            <Typography
              variant="subtitle2"
              letterSpacing=".3px"
              color="grey.500"
              px="6px"
            >
              Loading...
            </Typography>
          </Paper>
        )}
      </Box>

      <Paper
        sx={{
          p: 1,
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          border: ` 1px solid ${theme.palette.grey[300]}`,
          boxShadow: `0px 2px 8px 0px #3333330F`,
          borderRadius: '12px',
        }}
      >
        <InputBase
          sx={{
            ml: 1,
            flex: 1,
            color: theme.palette.grey[400],
            fontSize: '16px',
            fontWeight: 400,
          }}
          autoFocus
          fullWidth
          disabled={isLoading}
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault()
              void handleSend()
            }
          }}
          placeholder="Type here..."
          inputProps={{ 'aria-label': 'Type here...' }}
        />

        <CustomButton
          onClick={() => {
            void handleSend()
          }}
          disabled={isLoading}
          sx={{
            backgroundColor: theme.palette.grey[100],
            borderColor: theme.palette.grey[100],
            color: theme.palette.primary.main,
            paddingX: '11px',
            minWidth: '40px',
            minHeight: '40px',
            boxShadow: '0px 1px 2px 0px #1018280D',
          }}
          icon={
            <IconSend stroke={2} color={theme.palette.grey[400]} size={16} />
          }
        />
      </Paper>
    </Box>
  )
}

export default GetInsightsAnswers
