import { Box } from '@mui/material'
import React from 'react'
import { InputComponentStyle } from '../styled/RecommendationInput.styled'

interface IRecommendationInputProps {
  label: string
  id: string
  isEdit: boolean
  onChange: React.Dispatch<React.SetStateAction<string>>
  value: string
}

const RecommendationInput: React.FC<IRecommendationInputProps> = ({
  label,
  id,
  isEdit,
  onChange,
  value,
}) => {
  return (
    <Box>
      <InputComponentStyle
        id={id}
        value={value}
        setValue={onChange}
        placeholder={`Press enter to add ${label}`}
        isEdit={isEdit}
        label={label}
        isEditBackgroundMode
      />
    </Box>
  )
}

export default RecommendationInput
