import { IconCheck, IconCircle, IconCircleFilled } from '@tabler/icons-react'
import React from 'react'
import styled from 'styled-components'
import useAppUtilities from '../../../hooks/useAppUtilities'

interface StepperIconProps {
  step: number
  activeStep?: boolean
  activeStepCount: number
}

interface IconWrapperProps {
  activeStep: boolean
  isSmallScreen: boolean
}

const IconWrapper = styled.div<IconWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: ${({ activeStep, isSmallScreen }) =>
    activeStep && isSmallScreen ? '#FCFCFD' : '#2B2C5A'};
  border: ${({ activeStep, isSmallScreen }) =>
    activeStep && isSmallScreen ? '1px solid #D0D5DD' : 'none'};
`

const StepperIcon: React.FC<StepperIconProps> = ({
  step,
  activeStepCount,
}: StepperIconProps): JSX.Element => {
  const { theme, isSmallScreen } = useAppUtilities()

  return (
    <IconWrapper
      activeStep={step > activeStepCount}
      isSmallScreen={isSmallScreen}
    >
      {step === activeStepCount ? (
        <IconCircleFilled
          size={12}
          color={
            step > activeStepCount
              ? theme.palette.grey[200]
              : theme.palette.background.paper
          }
        />
      ) : step < activeStepCount ? (
        <IconCheck
          stroke={2}
          size={12}
          color={
            step > activeStepCount
              ? theme.palette.grey[200]
              : theme.palette.background.paper
          }
        />
      ) : (
        <IconCircle
          stroke={2}
          size={12}
          color={
            step > activeStepCount
              ? theme.palette.grey[200]
              : theme.palette.background.paper
          }
        />
      )}
    </IconWrapper>
  )
}

export default StepperIcon
