import React from 'react'
import DishDetailCommon from '../../../../../../components/restaurants/DishDetailCommon'
import { DEFAULT_CARD_IMAGE } from '../../../../../../helpers/static-constants'
import {
  DashboardDishDetailDescriptionTypography,
  DashboardDishDetailDescriptionWrapperBox,
  DashboardDishDetailMainBox,
  DashboardDishDetailTopBox,
  DashboardDishDetailTopInnerBox,
  DashboardDishDetailTopPriceTypography,
  DashboardDishDetailTopPriceWrapperBox,
  DashboardDishDetailTopTypography,
} from '../../../styled/competitorInsights/dish/DashboardDishDetail.styled'
import { useAppSelector } from '../../../../../../store'
import { selectCompetitorPopularDish } from '../../../../../../store/restaurant/dashboard'
import { Box } from '@mui/material'

const DashboardDishDetail: React.FC = () => {
  const dish = useAppSelector(selectCompetitorPopularDish)
  return (
    <DashboardDishDetailMainBox>
      <DashboardDishDetailTopBox>
        <Box
          component="img"
          height="107px"
          width="107px"
          sx={{
            borderRadius: '8px',
            aspectRatio: 1,
            objectFit: 'cover',
          }}
          src={dish?.dish?.imageurl ?? DEFAULT_CARD_IMAGE}
          alt="restaurant-dish"
        />
        <DashboardDishDetailTopInnerBox>
          <DashboardDishDetailTopTypography>
            {dish?.dish?.name ?? ''}
          </DashboardDishDetailTopTypography>
          {dish?.dish?.price && (
            <DashboardDishDetailTopPriceWrapperBox>
              <DashboardDishDetailTopPriceTypography>
                ${dish?.dish?.price}
              </DashboardDishDetailTopPriceTypography>
            </DashboardDishDetailTopPriceWrapperBox>
          )}
        </DashboardDishDetailTopInnerBox>
      </DashboardDishDetailTopBox>
      {dish?.dish?.description && (
        <DashboardDishDetailDescriptionWrapperBox>
          <DashboardDishDetailDescriptionTypography>
            {dish?.dish?.description}
          </DashboardDishDetailDescriptionTypography>
        </DashboardDishDetailDescriptionWrapperBox>
      )}
      <DishDetailCommon
        Ingredients={dish?.dish?.ingredients}
        Preparation={dish?.dish?.preparation}
        suitableDietary={dish?.dish?.dietary}
        unsuitableDietary={dish?.dish?.unsuitabledietary}
      />
    </DashboardDishDetailMainBox>
  )
}

export default DashboardDishDetail
