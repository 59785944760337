import { AlertColor } from '@mui/material'
import {
  createAsyncThunk,
  createSlice,
  isAnyOf,
  PayloadAction,
} from '@reduxjs/toolkit'
import axios from 'axios'
import { RootState } from '..'
import okaoAxios from '../../helpers/axios'
import {
  IReviewAllReviews,
  IReviewSummaryRequest,
  IReviewSummaryResponse,
} from '../../types/reviewTypes'

export interface IReviewSlice {
  message: string | undefined
  severity: AlertColor | undefined
  shouldOpen: boolean
  status: string
  adminReview: IReviewSummaryResponse | undefined
  likesAmbience: number
  likesCuisine: number
  likesMenu: number
  likesPrice: number
  likesStaff: number
  likesWaitingTime: number
}

const initialState: IReviewSlice = {
  status: 'idle',
  shouldOpen: false,
  message: undefined,
  severity: undefined,
  adminReview: undefined,
  likesAmbience: 0,
  likesCuisine: 0,
  likesMenu: 0,
  likesPrice: 0,
  likesStaff: 0,
  likesWaitingTime: 0,
}

export const fetchReviewSummary = createAsyncThunk<
  IReviewSummaryResponse,
  IReviewSummaryRequest,
  { rejectValue: { message: string; status?: number } }
>(
  'api/fetchReviewSummary',
  async ({ fromDate, toDate }, { rejectWithValue }) => {
    try {
      const response = await okaoAxios.get(
        `/restaurant-admin/reviews-summary?fromDate=${fromDate ?? ''}&toDate=${
          toDate ?? ''
        }`,
      )
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue({
          message: error.response?.data?.message || error.message,
          status: error.response?.status,
        })
      }
      return rejectWithValue({ message: 'An unknown error occurred' })
    }
  },
)

export const fetchAllReviews = createAsyncThunk<
  any,
  IReviewAllReviews,
  { rejectValue: { message: string; status?: number } }
>(
  'api/fetchAllReviews',
  async ({ fromDate, toDate, page }, { rejectWithValue }) => {
    try {
      const response = await okaoAxios.get(
        `/restaurant-admin/all-reviews/${page}?fromDate=${
          fromDate ?? ''
        }&toDate=${toDate ?? ''}`,
      )
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue({
          message: error.response?.data?.message || error.message,
          status: error.response?.status,
        })
      }
      return rejectWithValue({ message: 'An unknown error occurred' })
    }
  },
)

export const calculateScaledRating = (
  totalLikes: number,
  totalVotes: number,
): number => {
  const likeRatio = totalVotes === 0 ? 0.0 : totalLikes / totalVotes
  return likeRatio * 5
}

export const reviewSlice = createSlice({
  name: 'restaurant-reviews',
  initialState,
  reducers: {
    setOpen: (state, action: PayloadAction<boolean>) => {
      state.shouldOpen = action.payload
    },

    clearData: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchReviewSummary.fulfilled, (state, action) => {
        state.status = 'idle'

        state.adminReview = action.payload

        let likesCuisine = 0
        let likesAmbience = 0
        let likesStaff = 0
        let likesWaitingTime = 0
        let likesPrice = 0
        let likesMenu = 0

        action.payload?.reviews?.forEach((review) => {
          if (review.likesCuisine) likesCuisine++
          if (review.likesAmbience) likesAmbience++
          if (review.likesStaff) likesStaff++
          if (review.likesWaitingTime) likesWaitingTime++
          if (review.likesPrice) likesPrice++
          if (review.likesMenu) likesMenu++
        })

        const total = action.payload?.reviews?.length
        state.likesCuisine = calculateScaledRating(likesCuisine, total)
        state.likesAmbience = calculateScaledRating(likesAmbience, total)
        state.likesStaff = calculateScaledRating(likesStaff, total)
        state.likesWaitingTime = calculateScaledRating(likesWaitingTime, total)
        state.likesPrice = calculateScaledRating(likesPrice, total)
        state.likesMenu = calculateScaledRating(likesMenu, total)
      })
      .addCase(fetchAllReviews.fulfilled, (state, action) => {
        state.status = 'idle'
      })
      .addMatcher(
        isAnyOf(fetchReviewSummary.rejected, fetchAllReviews.rejected),
        (state, action) => {
          state.shouldOpen = true
          state.severity = 'error'
          state.status = 'error'
          state.message =
            'Oops! Looks like something went wrong on our end. But don’t worry, a quick refresh might just do the trick! Give it a whirl and let’s try this again. If the problem persists, we’ll be here to sort it out!'
        },
      )
      .addMatcher(
        isAnyOf(fetchReviewSummary.pending, fetchAllReviews.pending),
        (state, action) => {
          state.status = 'loading'
        },
      )
  },
})

export const selectIsLoading = (state: RootState): boolean =>
  state?.restaurantReview?.status === 'loading'
export const selectShouldOpen = (state: RootState): boolean =>
  state?.restaurantReview?.shouldOpen
export const selectMessage = (state: RootState): string | undefined =>
  state?.restaurantReview?.message
export const selectSeverity = (state: RootState): AlertColor | undefined =>
  state?.restaurantReview?.severity
export const selectAdminReview = (
  state: RootState,
): IReviewSummaryResponse | undefined => state?.restaurantReview?.adminReview
export const selectLikesCuisine = (state: RootState): number =>
  state?.restaurantReview?.likesCuisine
export const selectLikesAmbience = (state: RootState): number =>
  state?.restaurantReview?.likesAmbience
export const selectLikesStaff = (state: RootState): number =>
  state?.restaurantReview?.likesStaff
export const selectLikesWaitingTime = (state: RootState): number =>
  state?.restaurantReview?.likesWaitingTime
export const selectLikesPrice = (state: RootState): number =>
  state?.restaurantReview?.likesPrice
export const selectLikesMenu = (state: RootState): number =>
  state?.restaurantReview?.likesMenu

export const { setOpen, clearData } = reviewSlice.actions

export default reviewSlice.reducer
