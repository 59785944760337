import { Box, Typography } from '@mui/material'
import { IconLogout } from '@tabler/icons-react'
import React from 'react'
import { SquareAvatar } from '../../components/styled/Avatar.styled'
import CustomButton from '../../components/ui/CustomButton'
import { ROUTES } from '../../helpers/routes-helper'
import useAppUtilities from '../../hooks/useAppUtilities'
import { useAuthHelper } from '../../hooks/useAuthHelper'
import { useAppSelector } from '../../store'
import { selectRestaurant, selectUserInfo } from '../../store/authSlice'
import { selectEater } from '../../store/profileSlice'
import useRudderStackAnalytics from '../../useRudderAnalytics'

interface UserAccountSectionProps {
  isDrawerOpen: boolean
  setIsDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const UserAccountSection: React.FC<UserAccountSectionProps> = ({
  isDrawerOpen,
  setIsDrawerOpen,
}) => {
  const { theme, navigate } = useAppUtilities()
  const eater = useAppSelector(selectEater)
  const [analytics] = useRudderStackAnalytics()
  const userInfo = useAppSelector(selectUserInfo)
  const restaurant = useAppSelector(selectRestaurant)
  const { logoutAuthUser } = useAuthHelper()

  const logoutUser = (): void => {
    analytics?.identify('', { isLoggedIn: false })
    analytics?.reset()
    logoutAuthUser()
    navigate(ROUTES.HOME)
    setIsDrawerOpen(false)
  }

  const onUserInfo = (): void => {
    navigate(ROUTES.RESTAURANT_SETTINGS)
    setIsDrawerOpen(false)
  }

  return (
    <Box>
      {isDrawerOpen ? (
        <>
          <Box
            display="flex"
            justifyContent="start"
            alignItems="center"
            sx={{
              cursor: 'pointer',
            }}
            onClick={onUserInfo}
          >
            <SquareAvatar
              src={
                userInfo?.picture ??
                'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'
              }
              size="32px"
              name="Profile Picture"
            />
            <Box pl="10px">
              <Typography
                noWrap
                variant="subtitle1"
                maxWidth="150px"
                color={theme.palette.grey.A100}
              >
                {`${restaurant?.contact_name ?? userInfo?.name ?? ''}`}
              </Typography>
              <Typography
                noWrap
                variant="subtitle2"
                maxWidth="195px"
                color={theme.palette.grey[400]}
              >
                {userInfo?.email ?? ''}
              </Typography>
            </Box>
          </Box>
          <CustomButton
            title="Logout"
            sx={{
              backgroundColor: theme.palette.primary.light,
              color: theme.palette.common.white,
              paddingTop: '8px',
              paddingBottom: '8px',
              width: '100%',
              height: '36px',
              mt: '16px',
            }}
            textVariant="subtitle1"
            onClick={logoutUser}
            endIcon={<IconLogout size={14} stroke={2} />}
          />
        </>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
        >
          <Box
            mb={2}
            sx={{
              cursor: 'pointer',
              color: theme.palette.grey[500],
              '&:hover': {
                color: theme.palette.grey[200],
              },
            }}
            onClick={logoutUser}
          >
            <IconLogout size={24} stroke={2} />
          </Box>
          <SquareAvatar
            src={
              eater?.imageUrl ??
              userInfo?.picture ??
              'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'
            }
            size="32px"
            name="Profile Picture"
            onClick={() => navigate(ROUTES.RESTAURANT_SETTINGS)}
          />
        </Box>
      )}
    </Box>
  )
}

export default UserAccountSection
