import { Box, Divider, Typography, useTheme } from '@mui/material'
import styled from 'styled-components'

export const ReviewsSummaryMainContainer = styled(Box).attrs(() => {
  return {
    sx: {
      display: 'flex',
      flexDirection: 'column',
    },
  }
})(() => ({}))

export const ReviewsSummaryTitleTypography = styled(Typography).attrs(() => {
  const theme = useTheme()
  return {
    variant: 'body2',
    sx: {
      color: theme.palette.grey[800],
    },
  }
})(() => ({}))

export const ReviewsSummaryDescriptionTypography = styled(Typography).attrs(
  () => {
    const theme = useTheme()
    return {
      variant: 'subtitle1',
      sx: {
        color: theme.palette.grey[800],
        marginTop: '4px',
      },
    }
  },
)(() => ({}))

export const ReviewsSummaryDivider = styled(Divider).attrs(() => {
  return {
    orientation: 'horizontal',
    flexItem: true,
    sx: {
      marginY: '20px',
    },
  }
})(() => ({}))

export const ReviewsSummaryUserPreferenceTypography = styled(Typography).attrs(
  () => {
    const theme = useTheme()
    return {
      variant: 'subtitle1',
      sx: {
        color: theme.palette.grey[500],
      },
    }
  },
)(() => ({}))

export const ReviewsSummaryListContainer = styled(Box).attrs(() => ({
  sx: {
    overflowY: { xs: 'auto', md: 'scroll' },
    flexGrow: 1,
    maxHeight: { xs: '300px', md: 'none' },
  },
}))(() => ({}))
