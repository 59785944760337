import { generateEater } from '../../../helpers/converters'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useAppSelector } from '../../../store'
import { selectEater } from '../../../store/profileSlice'
import { SocialMainContainer } from './styled/Social.styled'
import useRudderStackAnalytics from '../../../useRudderAnalytics'
import { useLocation } from 'react-router'
import { ROUTES } from '../../../helpers/routes-helper'
import ResactlyTab from '../../../components/ResactlyTab'
import { IconBuildingStore, IconUsersGroup } from '@tabler/icons-react'
import GroupTab from './components/GroupTab'
import RestaurantMessageTab from './components/RestaurantMessageTab'
import SocialHeader from './components/SocialHeader'
import useAppUtilities from '../../../hooks/useAppUtilities'

const DocumentTitle = 'Resactly | Social Connect & Share Dining Recommendations'

const Social: React.FC = () => {
  const { isSmallScreen } = useAppUtilities()
  const eater = useAppSelector(selectEater)
  const [analytics, isAnalyticsReady] = useRudderStackAnalytics()
  const location = useLocation()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedTabValue, setSelectedTabValue] = useState<string>('group')

  useEffect(() => {
    const { fullName, eaterId, email } = generateEater(eater ?? {})

    if (analytics && isAnalyticsReady) {
      analytics?.page('PAGE_OPENED', {
        path: ROUTES.SOCIAL,
        title: DocumentTitle,
        url: `${window.location.origin}${ROUTES.SOCIAL}`,
        category: 'Social',
        name: 'Social Viewed',
        eaterName: fullName,
        startTime: new Date().toISOString(),
        email,
        eaterId,
      })
    }
  }, [analytics, isAnalyticsReady, eater])

  useEffect(() => {
    const { fullName, eaterId, email } = generateEater(eater ?? {})
    return () => {
      analytics?.page('PAGE_CLOSED', {
        path: ROUTES.SOCIAL,
        title: DocumentTitle,
        url: `${window.location.origin}${ROUTES.SOCIAL}`,
        category: 'Social',
        name: 'Social Viewed',
        eaterName: fullName,
        endTime: new Date().toISOString(),
        email,
        eaterId,
      })
    }
  }, [location, analytics])

  const onChangeTab = (item: any): void => {
    setSelectedTabValue(item.value)
  }

  const tabComponentList = [
    <div key="group" style={{ height: '100%' }}>
      <GroupTab isModalOpen={isModalOpen} setOpenAddModel={setIsModalOpen} />
    </div>,
    <div key="restaurant" style={{ height: '100%', border: 1 }}>
      <RestaurantMessageTab />
    </div>,
  ]

  return (
    <SocialMainContainer>
      <Helmet>
        <title>{DocumentTitle}</title>
        <meta
          name="description"
          content="Engage with the Resactly community on the Social page. Share your dining experiences, join group discussions, and connect with fellow food enthusiasts for collective dining recommendations."
        />
      </Helmet>
      {!isSmallScreen && (
        <SocialHeader
          onAddGroup={() => setIsModalOpen(true)}
          tabName={selectedTabValue}
        />
      )}
      <ResactlyTab
        list={tabList}
        tabComponent={tabComponentList}
        align="start"
        onChangeTab={onChangeTab}
      />
    </SocialMainContainer>
  )
}

export default Social
const tabList = [
  {
    label: 'Groups',
    value: 'group',
    icon: <IconUsersGroup stroke={2} />,
  },
  {
    label: 'Conversations',
    value: 'restaurant',
    icon: <IconBuildingStore stroke={2} />,
  },
]
