import React, { useCallback } from 'react'
import { DEFAULT_AVATAR_IMAGE } from '../../helpers/static-constants'
import { Session } from '@talkjs/react'
import Talk from 'talkjs'
import { useAppSelector } from '../../store'
import { selectRestaurant } from '../../store/restaurant/setting'
import { useAuth0 } from '@auth0/auth0-react'
import Layout from './Layout'

const RestaurantLayout: React.FC = () => {
  const { isAuthenticated } = useAuth0()

  const restaurant = useAppSelector(selectRestaurant)

  const address = restaurant
    ? `${restaurant?.address1 ?? ''} ${restaurant?.city ?? ''}, ${
        restaurant?.state ?? ''
      }`
    : ''

  const syncUser = useCallback(
    () =>
      new Talk.User({
        id: restaurant?.restaurantID ?? '',
        name: restaurant?.name ?? '',
        email: restaurant?.contact_email ?? '',
        photoUrl: restaurant?.imageurl?.length
          ? restaurant?.imageurl
          : DEFAULT_AVATAR_IMAGE,
        welcomeMessage: 'Hey, how can I help?',
        role: 'restaurant-admin-messages',
        custom: {
          email: restaurant?.contact_email ?? '',
          address: address.trim(),
        },
      }),
    [restaurant],
  )

  return (
    <>
      {isAuthenticated && restaurant && restaurant?.restaurantID ? (
        <Session
          appId={process.env.REACT_APP_TALK_JS ?? ''}
          syncUser={syncUser}
        >
          <Layout />
        </Session>
      ) : (
        <Layout />
      )}
    </>
  )
}

export default RestaurantLayout
