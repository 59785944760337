import { Box, useTheme } from '@mui/material'
import styled from 'styled-components'
import React from 'react'

interface RecommendationsStyleProps {
  isEdit: boolean
}

export const RecommendationsStyleGridMainBox = styled(({ isEdit, ...rest }) => (
  <Box {...rest} />
)).attrs<RecommendationsStyleProps>(({ isEdit }) => {
  const theme = useTheme()
  return {
    sx: {
      mb: {
        xs: isEdit ? '40px' : 0,
        md: 0,
      },
      padding: 2,
      border: 1.5,
      borderRadius: '12px',
      borderColor: theme.palette.grey[200],
      backgroundColor: theme.palette.background.paper,
    },
  }
})<RecommendationsStyleProps>(() => ({}))

export const RecommendationsMobileCancelUpdateButtonBox = styled(Box).attrs(
  () => {
    return {
      display: 'flex',
      alignItems: 'center',
      sx: {
        position: 'fixed',
        bottom: 0,
        right: 0,
        p: '8px',
        zIndex: 100,
        width: '100%',
      },
    }
  },
)(() => ({}))
