import { zodResolver } from '@hookform/resolvers/zod'
import {
  Autocomplete,
  Box,
  Grid,
  Paper,
  Typography,
} from '@mui/material'
import { IconChevronDown } from '@tabler/icons-react'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { z } from 'zod'
import { countries } from '../../../../helpers/onboard-helper'
import useAppUtilities from '../../../../hooks/useAppUtilities'
import { useAppSelector } from '../../../../store'
import {
  selectIsSettingEdit,
  selectIsUpdateData,
  selectRestaurant,
  setIsEditCancelledData,
  setIsSettingEdit,
  setIsUpdateData,
  setIsValidData,
  updateRestaurant,
} from '../../../../store/restaurant/setting'
import InputComponent from '../../../../components/ui/InputComponent'

const addressSchema = z.object({
  address1: z.string().optional(),
  address2: z.string().optional(),
  address3: z.string().optional(),
  country: z.string().optional(),
  state: z.string().optional(),
  city: z.string().optional(),
  zipcode: z.string().optional(),
})

const AddressTab: React.FC = () => {
  const { theme, dispatch } = useAppUtilities()
  const isEditMode = useAppSelector(selectIsSettingEdit)
  const isUpdateMode = useAppSelector(selectIsUpdateData)
  const restaurant = useAppSelector(selectRestaurant)
  const [addressList] = useState({
    address1: restaurant?.address1 ?? '',
    address2: restaurant?.address2 ?? '',
    address3: restaurant?.address3 ?? '',
    city: restaurant?.city ?? '',
    country: restaurant?.country ?? '',
    state: restaurant?.state ?? '',
    zipcode: restaurant?.zip_code ?? '',
  })

  const {
    reset,
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<z.infer<typeof addressSchema>>({
    resolver: zodResolver(addressSchema),
    mode: 'onChange',
    defaultValues: addressList,
  })

  useEffect(() => {
    reset({
      address1: restaurant?.address1 ?? '',
      address2: restaurant?.address2 ?? '',
      address3: restaurant?.address3 ?? '',
      city: restaurant?.city ?? '',
      country: restaurant?.country ?? '',
      state: restaurant?.state ?? '',
      zipcode: restaurant?.zip_code ?? '',
    })
  }, [addressList, reset])

  useEffect(() => {
    if (isUpdateMode) {
      void updateAddress()
    }
  }, [isEditMode, isUpdateMode])

  const updateAddress = async (): Promise<void> => {
    await handleSubmit(
      async (data: z.infer<typeof addressSchema>): Promise<void> => {
        if (restaurant) {
          await dispatch(
            updateRestaurant({
              ...restaurant,
              address1: data.address1,
              address2: data.address2,
              address3: data.address3,
              city: data.city,
              country: data.country,
              state: data.state,
              zip_code: data.zipcode,
            }),
          )
        }
      },
    )()

    dispatch(setIsSettingEdit(false))
    dispatch(setIsUpdateData(false))
    dispatch(setIsValidData(false))
    dispatch(setIsEditCancelledData(false))
  }

  return (
    <Box component="form">
      <Box>
        <InputComponent
          disabled={!isEditMode}
          id="addressLine1"
          label="Address Line 1"
          labelShrink
          labelFontSize="16px"
          isEditBackgroundMode
          labelWeight={500}
          placeholder="Enter address line 1"
          {...register('address1')}
          error={!!errors.address1}
          helperText={errors.address1?.message ?? ' '}
        />
      </Box>

      <Box>
        <InputComponent
          disabled={!isEditMode}
          id="addressLine2"
          label="Address Line 2"
          labelShrink
          labelFontSize="16px"
          isEditBackgroundMode
          labelWeight={500}
          placeholder="Enter address line 2"
          {...register('address2')}
          error={!!errors.address2}
          helperText={errors.address2?.message ?? ' '}
        />
      </Box>

      <Box>
        <InputComponent
          disabled={!isEditMode}
          id="addressLine3"
          label="Address Line 3"
          labelShrink
          labelFontSize="16px"
          isEditBackgroundMode
          labelWeight={500}
          placeholder="Enter address line 3"
          {...register('address3')}
          error={!!errors.address3}
          helperText={errors.address3?.message ?? ' '}
        />
      </Box>

      <Grid container spacing={{ md: 2 }}>
        <Grid item xs={12} md={3}>
          <Box>
            <InputComponent
              disabled={!isEditMode}
              id="state"
              label="State"
              labelShrink
              labelFontSize="16px"
              isEditBackgroundMode
              labelWeight={500}
              placeholder="Enter state"
              {...register('state')}
              error={!!errors.state}
              helperText={errors.state?.message ?? ' '}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box>
            <InputComponent
              disabled={!isEditMode}
              id="city"
              label="City"
              labelShrink
              labelFontSize="16px"
              isEditBackgroundMode
              labelWeight={500}
              placeholder="Enter city"
              {...register('city')}
              error={!!errors.city}
              helperText={errors.city?.message ?? ' '}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box>
            <InputComponent
              disabled={!isEditMode}
              id="zipcode"
              label="Zipcode"
              labelShrink
              labelFontSize="16px"
              isEditBackgroundMode
              labelWeight={500}
              placeholder="Enter zip code"
              {...register('zipcode')}
              error={!!errors.zipcode}
              helperText={errors.zipcode?.message ?? ' '}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box>
            <Controller
              name="country"
              control={control}
              render={({ field }) => {
                const selectedCountry = React.useMemo(
                  () => countries.find((c) => c.label === field.value),
                  [field.value],
                )
                return (
                  <Autocomplete
                    disabled={!isEditMode}
                    {...field}
                    id="country"
                    options={countries}
                    getOptionLabel={(option) => option.label}
                    value={selectedCountry ?? countries[0]}
                    popupIcon={<IconChevronDown stroke={2} size={18} />}
                    onChange={(_, newValue) => field.onChange(newValue?.label)}
                    PaperComponent={(props) => (
                      <Paper
                        {...props}
                        sx={{ fontSize: '14px', fontWeight: 400 }}
                      />
                    )}
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        <img
                          loading="lazy"
                          width="20"
                          src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                          srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png`}
                          alt={option.code}
                          style={{ marginRight: 8 }}
                        />
                        <Typography
                          variant="subtitle1"
                          sx={{ color: theme.palette.grey[800] }}
                        >
                          {option.label} ({option.code}) +{option.phone}
                        </Typography>
                      </Box>
                    )}
                    renderInput={(params) => (
                      <InputComponent
                        {...params}
                        disabled={!isEditMode}
                        id="country"
                        label="Country"
                        labelShrink
                        labelFontSize="16px"
                        labelWeight={500}
                        isEditBackgroundMode
                        placeholder="Choose a country"
                        error={!!errors.country}
                        helperText={errors.country?.message ?? ''}
                      />
                    )}
                    sx={{
                      height: '40px',
                      '& .MuiOutlinedInput-root': {
                        p: '1px',
                        pl: '12px',
                      },
                    }}
                  />
                )
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default AddressTab
