import Tooltip from '@mui/material/Tooltip'
import { IconInfoCircle } from '@tabler/icons-react'
import React from 'react'
import useAppUtilities from '../../hooks/useAppUtilities'

interface IconInfoCircleTooltipProps {
  data: React.ReactNode
}

const InfoCircleTooltip: React.FC<IconInfoCircleTooltipProps> = ({ data }) => {
  const { theme } = useAppUtilities()

  return (
    <Tooltip title={data} arrow placement="bottom">
      <IconInfoCircle
        stroke={1.25}
        size={'18px'}
        style={{
          color: theme.palette.grey[400],
          marginLeft: '8px',
        }}
      />
    </Tooltip>
  )
}

export default InfoCircleTooltip
