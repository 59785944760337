import { useAuth0 } from '@auth0/auth0-react'
import { Avatar, Badge, Box, Drawer, Link, Typography } from '@mui/material'
import { IconChevronRight } from '@tabler/icons-react'
import React, { FC, useMemo } from 'react'
import { NavLink } from 'react-router-dom'
import { ROUTES } from '../../helpers/routes-helper'
import { MOBILE_NAV_HEIGHT } from '../../helpers/static-constants'
import useAppUtilities from '../../hooks/useAppUtilities'
import { useAuthHelper } from '../../hooks/useAuthHelper'
import { useAppSelector } from '../../store'
import { selectUserInfo } from '../../store/authSlice'
import { selectEater } from '../../store/profileSlice'
import useRudderStackAnalytics from '../../useRudderAnalytics'
import {
  StyledCustomButton,
  StyledCustomOutlineButton,
} from './styled/MobileDrawer.styled'
import Talk from 'talkjs'
import { useUnreads } from '@talkjs/react'
interface CustomDrawerProps {
  open: boolean
  toggleDrawer: React.Dispatch<React.SetStateAction<boolean>>
  routeList: NavList[]
  mt?: string
  variant?: 'admin' | 'restaurant'
  unreadsMessages?: number
}

interface NavList {
  name?: string
  path: string
  label?: string
  icons?: React.ReactElement
}

interface INavbar {
  name?: string
  path: string
  label?: string
  icons?: React.ReactElement
}

const CustomDrawer: FC<CustomDrawerProps> = ({
  open,
  toggleDrawer,
  routeList,
  unreadsMessages,
  mt = `${MOBILE_NAV_HEIGHT}px`,
  variant = 'restaurant',
}) => {
  const { theme, navigate } = useAppUtilities()
  const eater = useAppSelector(selectEater)
  const userInfo = useAppSelector(selectUserInfo)
  const [analytics, isAnalyticsReady] = useRudderStackAnalytics()
  const unreads: Talk.UnreadConversation[] | undefined = useUnreads()

  const { isAuthenticated } = useAuth0()
  const { logoutAuthUser, loginAuthUser } = useAuthHelper()

  const onNextRoute = (route: INavbar): void => {
    toggleDrawer(false)
  }

  const login = async (): Promise<void> => {
    loginAuthUser()
    toggleDrawer(false)
  }

  const logoutUser = (): void => {
    if (analytics && isAnalyticsReady) {
      analytics?.identify('', { isLoggedIn: false })
      analytics?.reset()
    }
    navigate(ROUTES.HOME)
    logoutAuthUser()
    toggleDrawer(false)
  }

  const unreadCount = useMemo(() => {
    return (
      unreads?.reduce(
        (total, conv) => total + (conv.unreadMessageCount || 0),
        0,
      ) ?? 0
    )
  }, [unreads])

  return (
    <Drawer
      transitionDuration={300}
      translate="yes"
      sx={{
        width: 224,
        '& .MuiPaper-root': {
          backgroundColor:
            variant === 'admin'
              ? theme.palette.primary.main
              : theme.palette.common.white,
        },
        '& .MuiDrawer-paper': {
          width: variant === 'admin' ? 250 : 224,
          marginTop: mt,
          border:
            variant === 'admin'
              ? 'none'
              : `1px solid ${theme.palette.grey[300]}`,
          boxShadow:
            '0px 8px 10px -5px rgba(0,0,0,0.2),-1px 26px 10px 2px rgba(0,0,0,0.14),0px 11px 11px 5px rgba(0,0,0,0.12)',
          zIndex: 10,
        },

        '& .MuiModal-backdrop': {
          backgroundColor: 'transparent',
        },
      }}
      open={open}
      anchor="right"
      onClose={() => toggleDrawer(false)}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        height={
          variant === 'admin' ? `calc(100% - ${mt})` : 'calc(100% - 100px)'
        }
        alignItems="stretch"
        flexDirection="column"
        py={variant === 'admin' ? 3 : 0}
        px={variant === 'admin' ? 2 : 0}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="stretch"
          flexDirection="column"
        >
          {routeList.map((item, index) => {
            return (
              <Link
                onClick={() => onNextRoute(item)}
                key={index}
                component={NavLink}
                to={item?.path}
                variant="subtitle1"
                underline="none"
                sx={{
                  paddingY: '16px',
                  paddingLeft: variant === 'admin' ? '16px' : '24px',
                  cursor: 'pointer',
                  color:
                    variant === 'admin' ? theme.palette.grey[400] : 'inherit',
                  '&:hover': {
                    color:
                      variant === 'admin'
                        ? theme.palette.grey.A100
                        : theme.palette.secondary.main,
                  },
                  '&.active': {
                    color:
                      variant === 'admin'
                        ? theme.palette.grey.A100
                        : theme.palette.secondary.main,
                    fontWeight: 600,
                    borderRadius: variant === 'admin' ? '10px' : '0px',
                    backgroundColor:
                      variant === 'admin'
                        ? theme.palette.primary.light
                        : theme.palette.secondary[50],
                  },
                }}
              >
                <Box display="flex" alignItems="center">
                  <Box display="flex" alignItems="center" mr={1}>
                    {item?.icons}
                  </Box>
                  <Typography
                    noWrap
                    component="p"
                    variant="body2"
                    fontWeight={600}
                  >
                    {item.name ?? item.label}
                  </Typography>
                  {item.name === 'Social' && (
                    <Badge
                      color="secondary"
                      badgeContent={unreadCount}
                      sx={{
                        ml: unreadCount > 0 ? 3 : 0,
                      }}
                    ></Badge>
                  )}
                </Box>
              </Link>
            )
          })}
        </Box>
        <Box px="16px">
          {/* <StyledCustomOutlineButton
            title="Logout"
            onClick={logoutUser}
            endIcon={<IconChevronRight size={16} stroke={2} />}
          /> */}
          {!isAuthenticated ? (
            <StyledCustomButton
              title="Login"
              onClick={() => {
                void login()
              }}
            />
          ) : (
            <>
              <Box display="flex" justifyContent="start" alignItems="center">
                <Avatar
                  src={
                    eater?.imageUrl ??
                    userInfo?.picture ??
                    'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'
                  }
                  sx={{
                    width: '32px',
                    height: '32px',
                    borderRadius: '50%',
                  }}
                />
                <Box pl="10px">
                  <Typography
                    noWrap
                    variant="subtitle1"
                    maxWidth="150px"
                    color={
                      variant === 'admin'
                        ? theme.palette.grey.A100
                        : theme.palette.grey[800]
                    }
                  >
                    {`${eater?.firstName ?? ''} ${eater?.lastName ?? ''}`}
                  </Typography>
                  <Typography
                    noWrap
                    variant="subtitle2"
                    maxWidth="150px"
                    color={
                      variant === 'admin'
                        ? theme.palette.grey[400]
                        : theme.palette.grey[500]
                    }
                  >
                    {eater?.email ?? ''}
                  </Typography>
                </Box>
              </Box>
              <StyledCustomOutlineButton
                title="Logout"
                onClick={logoutUser}
                endIcon={<IconChevronRight size={16} stroke={2} />}
              />
            </>
          )}
        </Box>
      </Box>
    </Drawer>
  )
}

export default CustomDrawer
