import styled from 'styled-components'
import InputComponent from '../../../../components/ui/InputComponent'
import React from 'react'
import useAppUtilities from '../../../../hooks/useAppUtilities'

interface DishCardMainProps {
  isEdit: string
}

export const InputComponentStyle = styled(({ isEdit, ...rest }) => (
  <InputComponent {...rest} />
)).attrs<DishCardMainProps>(({ isEdit }) => {
  const { theme } = useAppUtilities()
  return {
    multiline: true,
    rows: 2.93,
    sx: {
      mt: '6px',
      '& .MuiOutlinedInput-root': {
        borderColor: `${theme.palette.grey[300]} !important`,
        pb: '3px',
        '& fieldset': {
          borderColor: `${theme.palette.grey[300]} !important`,
        },
      },
    },
    disabled: !isEdit,
  }
})<DishCardMainProps>(() => ({}))
