import { Box, useTheme } from '@mui/material'
import styled from 'styled-components'
import ImgIcon from '../../../../../../components/ui/ImgIcon'
import { IconX } from '@tabler/icons-react'
import React from 'react'

export const SelectedDishSectionMainBox = styled(Box).attrs(() => {
  const theme = useTheme()
  return {
    sx: {
      px: 2,
      border: {
        md: `1px solid ${theme.palette.grey[300]}`,
      },
      borderRadius: '12px',
      width: '100%',
      height: { md: '100%' },
    },
  }
})(() => ({}))

export const CloseButton = styled(ImgIcon).attrs(() => {
  const theme = useTheme()
  return {
    iconElement: (
      <IconX stroke={2} size={18} color={theme.palette.common.white} />
    ),
    alt: 'cancel-selection',
    imgSize: 18,
    wrapperStyle: {
      width: '30px',
      height: '30px',
      marginLeft: '6px',
      backgroundColor: '#0000004D',
    },
  }
})(() => ({}))
