import { Box } from '@mui/material'
import React from 'react'
import { ViewModeStyleButton } from '../styled/ViewModeButton.styled'

interface IViewModeButtonProps {
  onEdit: () => void
}

const ViewModeButton: React.FC<IViewModeButtonProps> = ({ onEdit }) => {
  return (
    <Box>
      <ViewModeStyleButton onClick={onEdit} />
    </Box>
  )
}

export default ViewModeButton
