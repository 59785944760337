import {
  Avatar,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material'
import Zoom from '@mui/material/Zoom'
import {
  IconCalendarCheck,
  IconEye,
  IconMessageDots,
  IconX,
} from '@tabler/icons-react'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import EmptyScreen from '../../../../components/EmptyScreen'
import { useAppSelector } from '../../../../store'
import {
  selectStartDate,
  selectVisitorsEaters,
  selectValidVisitorsTotalCount,
} from '../../../../store/restaurant/dashboard'
import ImgIcon from '../../../../components/ui/ImgIcon'
import useAppUtilities from '../../../../hooks/useAppUtilities'
import { ROUTES } from '../../../../helpers/routes-helper'
import { setSelectedUser } from '../../../../store/restaurant/messages'
import { DEFAULT_AVATAR_IMAGE } from '../../../../helpers/static-constants'
import { VistitorEater } from '../../../../types/dashboard'

interface VisitorsModalProps {
  handleClose?: () => void
}

const VisitorsModal: React.FC<VisitorsModalProps> = ({ handleClose }) => {
  const visitors = useAppSelector(selectVisitorsEaters)
  const visitorCount = useAppSelector(selectValidVisitorsTotalCount)
  const fromDate = useAppSelector(selectStartDate)
  const { theme, navigate, dispatch } = useAppUtilities()
  const [data, setDate] = useState<string>('')

  useEffect(() => {
    void onDateInit()
  }, [fromDate])

  const onDateInit = async (): Promise<void> => {
    if (dayjs(fromDate).isSame(dayjs().subtract(1, 'day'), 'day')) {
      setDate('From Yesterday')
    } else {
      setDate(`From ${dayjs(fromDate).format('D MMM, YYYY')}`)
    }
  }

  const onVisitorMessage = async (user: VistitorEater): Promise<void> => {
    if (user?.eaterId) {
      dispatch(
        setSelectedUser({
          id: user?.eaterId ?? '',
          name: user.name,
          email: [user.email ?? ''],
          role: 'customer',
          photoUrl:
            user?.imageUrl && user?.imageUrl.length > 0
              ? user?.imageUrl
              : DEFAULT_AVATAR_IMAGE,
        }),
      )
      navigate(ROUTES.RESTAURANT_MESSAGES)
    }
  }

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Box>
          <Typography variant="h4">Visitors ({visitorCount})</Typography>
          <Typography color="grey.400" variant="subtitle1">
            {data}
          </Typography>
        </Box>
        <Box
          sx={{
            cursor: 'pointer',
            '&:hover': { color: 'grey.700' },
          }}
          onClick={handleClose}
          color="grey.500"
        >
          <IconX stroke={2} />
        </Box>
      </Box>
      <List
        sx={{
          maxHeight: 500,
          overflow: 'auto',
        }}
      >
        {visitors && visitors?.length > 0 ? (
          visitors?.map((visitor, index) => (
            <ListItem
              key={index}
              sx={{
                py: '4px',
                px: 0,
              }}
              secondaryAction={
                <Box display="flex" gap={2} alignItems="center">
                  <Tooltip
                    title="Views"
                    arrow
                    placement="top"
                    slotProps={{
                      popper: {
                        sx: {
                          '& .MuiTooltip-tooltip': {
                            color: 'primary.main',
                            bgcolor: 'background.paper',
                          },
                        },
                      },
                      tooltip: {
                        sx: {
                          backgroundColor: 'background.paper',
                          borderRadius: '10px',
                          padding: '8px 12px',
                          boxShadow: 6,
                        },
                      },
                    }}
                    slots={{
                      transition: Zoom,
                    }}
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      gap={0.5}
                      bgcolor="grey.100"
                      py={0.5}
                      borderRadius={8}
                      minWidth={55}
                    >
                      <IconEye stroke={2} size={16} />
                      <Typography variant="subtitle2">
                        {visitor?.viewCount}
                      </Typography>
                    </Box>
                  </Tooltip>
                  <Tooltip
                    title="Reservations"
                    arrow
                    placement="top"
                    slotProps={{
                      popper: {
                        sx: {
                          '& .MuiTooltip-tooltip': {
                            color: 'primary.main',
                            bgcolor: 'background.paper',
                          },
                        },
                      },
                      tooltip: {
                        sx: {
                          backgroundColor: 'background.paper',
                          borderRadius: '10px',
                          padding: '8px 12px',
                          boxShadow: 6,
                        },
                      },
                    }}
                    slots={{
                      transition: Zoom,
                    }}
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      gap={0.5}
                      py={0.5}
                      borderRadius={8}
                      bgcolor="grey.100"
                      minWidth={55}
                    >
                      <IconCalendarCheck stroke={2} size={16} />
                      <Typography variant="subtitle2">
                        {visitor?.reservationCount ?? 0}
                      </Typography>
                    </Box>
                  </Tooltip>
                  <ImgIcon
                    disable={!visitor?.eaterId}
                    onClick={() => {
                      void onVisitorMessage(visitor)
                    }}
                    iconElement={
                      <IconMessageDots
                        stroke={2}
                        size={18}
                        color={theme.palette.grey[500]}
                      />
                    }
                    alt="confirm-edit"
                    wrapperStyle={{
                      width: '36px',
                      height: '36px',
                      backgroundColor: theme.palette.grey[50],
                      border: `1px solid ${theme.palette.grey[300]}`,
                    }}
                  />
                </Box>
              }
            >
              <ListItemAvatar>
                <Avatar
                  sizes="36px"
                  src={visitor?.imageUrl ?? visitor?.name}
                ></Avatar>
              </ListItemAvatar>
              <ListItemText
                primaryTypographyProps={{
                  variant: 'subtitle1',
                  fontWeight: 500,
                  color: 'grey.800',
                }}
                secondaryTypographyProps={{
                  variant: 'subtitle2',
                  color: 'grey.400',
                }}
                primary={
                  visitor?.name === 'null null'
                    ? 'Anonymous user'
                    : visitor?.name
                }
                secondary={
                  visitor?.email?.endsWith('@okaoinc.com') ? '' : visitor?.email
                }
              />
            </ListItem>
          ))
        ) : (
          <EmptyScreen
            mainText="No data available"
            secondaryText="No visitor data found"
            imageUrl="/images/empty-screen.svg"
            containerHeight={'400px'}
          />
        )}
      </List>
    </Box>
  )
}

export default VisitorsModal
