import { MenuItem, Typography } from '@mui/material'
import React, { useState } from 'react'
import DeleteDialog from '../../../../components/restaurants/DeleteDialog'
import useAppUtilities from '../../../../hooks/useAppUtilities'
import { ContentTypography } from '../../settings/styled/ProfileInfoCard.styled'
import { StyledMenuPopup } from '../styled/EditDeleteMenu.styled'
import AddEditMenuDialog from './AddEditMenuDialog'
import { IMenuDeleteRequest, Menu } from '../../../../types/menuTypes'
import {
  deleteMenu,
  setOpenStaticMessage,
} from '../../../../store/restaurant/menu'
interface IEditDeleteMenuProps {
  anchorEl: null | HTMLElement
  open: boolean
  handleMenuClose: () => void
  menu: Menu
}
const EditDeleteMenu: React.FC<IEditDeleteMenuProps> = ({
  anchorEl,
  open,
  handleMenuClose,
  menu,
}) => {
  const { dispatch, theme } = useAppUtilities()
  const [modalOpenDelete, setModalOpenDelete] = useState(false)
  const [modalOpenEdit, setModalOpenEdit] = useState(false)

  const onHandleModalDeleteCancel = (): void => {
    setModalOpenDelete(false)
  }

  const onHandleDelete = async (): Promise<void> => {
    const payload: IMenuDeleteRequest = {
      menuId: menu.menuID,
    }
    const res = await dispatch(deleteMenu(payload))
    if (deleteMenu.fulfilled.match(res)) {
      dispatch(
        setOpenStaticMessage({
          message: `${menu?.name ?? ''} Menu deleted successfully.`,
          severity: 'success',
          shouldOpen: true,
          status: 'idle',
        }),
      )
    }
    setModalOpenDelete(false)
  }

  const onEditMenu = (): void => {
    handleMenuClose()
    setModalOpenEdit(true)
  }
  const onDeleteMenu = (): void => {
    handleMenuClose()
    setModalOpenDelete(true)
  }

  return (
    <>
      <StyledMenuPopup
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={onEditMenu}>
          <ContentTypography>Edit Menu</ContentTypography>
        </MenuItem>
        <MenuItem onClick={onDeleteMenu}>
          <Typography variant="subtitle1" color={theme.palette.error[500]}>
            Delete Menu
          </Typography>
        </MenuItem>
      </StyledMenuPopup>
      <DeleteDialog
        open={modalOpenDelete}
        onCancel={onHandleModalDeleteCancel}
        onConfirm={() => {
          void onHandleDelete()
        }}
        title="Delete Menu?"
        handleClose={() => setModalOpenDelete(false)}
      />
      <AddEditMenuDialog
        openEditModel={modalOpenEdit}
        setOpenEditModel={setModalOpenEdit}
        menu={menu}
      />
    </>
  )
}

export default EditDeleteMenu
