import { Box, Grid, Skeleton } from '@mui/material'
import {
  IconClockHour8,
  IconCurrencyDollar,
  IconFlower,
  IconHeartHandshake,
  IconSoup,
  IconUsers,
} from '@tabler/icons-react'
import React, { useCallback, useEffect, useState } from 'react'
import ResactlyTab from '../../../components/ResactlyTab'
import useAppUtilities from '../../../hooks/useAppUtilities'
import { useAppSelector } from '../../../store'
import {
  selectEndDate,
  selectStartDate,
} from '../../../store/restaurant/dashboard'
import {
  fetchReviewSummary,
  selectLikesAmbience,
  selectLikesCuisine,
  selectLikesMenu,
  selectLikesPrice,
  selectLikesStaff,
  selectLikesWaitingTime,
  selectShouldOpen,
  selectMessage,
  selectSeverity,
  setOpen,
} from '../../../store/restaurant/review'
import DashboardHeader from '../dashboard/components/DashboardHeader'
import AllReviews from './components/AllReviews'
import ReviewsSummary from './components/ReviewsSummary'
import SummaryRatingCard, {
  ISummaryRatingCard,
} from './components/SummaryRatingCard'
import { ReviewsSummaryWrapper, ReviewsWrapper } from './styled/Reviews.styled'
import { MuiAlert, MuiSnackbar } from '../../eater/styled/global.styled'

const Reviews: React.FC = () => {
  const { isMediumScreen, dispatch, theme } = useAppUtilities()
  const fromDate = useAppSelector(selectStartDate)
  const toDate = useAppSelector(selectEndDate)
  const likesCuisine = useAppSelector(selectLikesCuisine)
  const likesAmbience = useAppSelector(selectLikesAmbience)
  const likesMenu = useAppSelector(selectLikesMenu)
  const likesPrice = useAppSelector(selectLikesPrice)
  const likesStaff = useAppSelector(selectLikesStaff)
  const likesWaitingTime = useAppSelector(selectLikesWaitingTime)
  const openServer = useAppSelector(selectShouldOpen)
  const message = useAppSelector(selectMessage)
  const severity = useAppSelector(selectSeverity)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    void init()
  }, [fromDate, toDate])

  const init = async (): Promise<void> => {
    setIsLoading(true)
    await dispatch(fetchReviewSummary({ fromDate, toDate }))
    setIsLoading(false)
  }

  const getRatingCards = useCallback((): ISummaryRatingCard[] => {
    const iconStyle = {
      color: theme.palette.grey[800],
      height: '20px',
      width: '20px',
    }

    return [
      {
        title: 'Food',
        value: likesMenu,
        icon: <IconSoup stroke={1.5} style={iconStyle} />,
      },
      {
        title: 'Ambience',
        value: likesAmbience,
        icon: <IconFlower stroke={1.5} style={iconStyle} />,
      },
      {
        title: 'Staff',
        value: likesStaff,
        icon: <IconUsers stroke={1.5} style={iconStyle} />,
      },
      {
        title: 'Cuisine',
        value: likesCuisine,
        icon: <IconHeartHandshake stroke={1.5} style={iconStyle} />,
      },
      {
        title: 'Waiting Time',
        value: likesWaitingTime,
        icon: <IconClockHour8 stroke={1.5} style={iconStyle} />,
      },
      {
        title: 'Price',
        value: likesPrice,
        icon: <IconCurrencyDollar stroke={1.5} style={iconStyle} />,
      },
    ]
  }, [
    likesPrice,
    likesCuisine,
    likesAmbience,
    likesMenu,
    likesStaff,
    likesWaitingTime,
  ])

  const setOpenServer = (): void => {
    dispatch(setOpen(false))
  }

  return (
    <DashboardHeader
      title="Reviews"
      body={
        <>
          <Grid
            container
            spacing={{ xs: 0, md: 2 }}
            sx={{ alignItems: 'stretch', height: { md: '100%' } }}
          >
            {!isMediumScreen && (
              <Grid item xs={12} md={6} lg={7} sx={{ height: '100%' }}>
                {!isLoading ? (
                  <ReviewsSummaryWrapper>
                    <ReviewsSummary />
                  </ReviewsSummaryWrapper>
                ) : (
                  <Skeleton
                    variant="rounded"
                    height={'100%'}
                    sx={{ borderRadius: '16px' }}
                  />
                )}
              </Grid>
            )}
            <Grid
              item
              xs={12}
              md={6}
              lg={5}
              sx={{
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
              }}
            >
              <Grid container spacing={{ xs: 0.5, md: 2 }}>
                {getRatingCards().map((card, index) => (
                  <Grid item key={index} xs={4} sm={4} md={6} lg={4}>
                    {isLoading ? (
                      <Skeleton
                        variant="rounded"
                        height={'100px'}
                        sx={{ borderRadius: '16px' }}
                      />
                    ) : (
                      <SummaryRatingCard card={card} />
                    )}
                  </Grid>
                ))}
              </Grid>
              {isLoading ? (
                <Skeleton
                  variant="rounded"
                  height={'100%'}
                  sx={{ borderRadius: '16px', mt: '20px' }}
                />
              ) : (
                <ReviewsWrapper>
                  {isMediumScreen ? (
                    <ResactlyTab
                      align="start"
                      list={[{ label: 'Summary' }, { label: 'All Reviews' }]}
                      tabComponent={[
                        <Box
                          key={'summary'}
                          sx={{
                            marginTop: '20px',
                          }}
                        >
                          <ReviewsSummary />
                        </Box>,
                        <Box
                          key={'summary'}
                          sx={{
                            marginTop: '20px',
                          }}
                        >
                          <AllReviews key={'all-reviews'} />
                        </Box>,
                      ]}
                    />
                  ) : (
                    <AllReviews />
                  )}
                </ReviewsWrapper>
              )}
            </Grid>
          </Grid>
          <MuiSnackbar open={openServer} onClose={setOpenServer}>
            <MuiAlert onClose={setOpenServer} severity={severity ?? 'success'}>
              {message}
            </MuiAlert>
          </MuiSnackbar>
        </>
      }
    />
  )
}

export default Reviews
