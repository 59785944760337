import React, { useMemo, useRef } from 'react'
import { Doughnut } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  ChartData,
  ChartOptions,
} from 'chart.js'
import { Box, Stack, Typography } from '@mui/material'
import useAppUtilities from '../../../../../../hooks/useAppUtilities'
import { useAppSelector } from '../../../../../../store'
import { selectSideDrawerOpen } from '../../../../../../store/restaurant/dashboard'

type DonutChartData = ChartData<'doughnut', number[], string>
type DonutOptions = ChartOptions<'doughnut'>

ChartJS.register(ArcElement, Tooltip, Legend)

interface IChartProp {
  chartData: DonutChartData
  labels: string[]
  colors: string[]
  title: string
  total: number
  rightLabels?: boolean
}

const Chart: React.FC<IChartProp> = ({
  chartData,
  labels,
  colors,
  title,
  total,
  rightLabels = true,
}): JSX.Element => {
  const isSideDrawerOpen = useAppSelector(selectSideDrawerOpen)
  const { isMediumScreen } = useAppUtilities()
  const chartRef = useRef<ChartJS<'doughnut'> | null>(null)

  const totalCount = useMemo(() => {
    return total
  }, [total])

  return (
    <Box width="100%">
      {(isSideDrawerOpen || isMediumScreen) && (
        <Box>
          <LegendData
            labels={labels}
            colors={colors}
            fullWidth
            chartRef={chartRef}
          />
        </Box>
      )}
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        gap={[1, 3, 5]}
        width="100%"
      >
        {!isSideDrawerOpen && !isMediumScreen && !rightLabels && (
          <LegendData labels={labels} colors={colors} chartRef={chartRef} />
        )}

        <DonutChart
          data={chartData}
          title={title}
          total={totalCount}
          chartRef={chartRef}
        />

        {!isSideDrawerOpen && !isMediumScreen && rightLabels && (
          <LegendData labels={labels} colors={colors} chartRef={chartRef} />
        )}
      </Box>
    </Box>
  )
}

interface LegendProps {
  labels: string[]
  colors: string[]
  chartRef: React.MutableRefObject<ChartJS<'doughnut'> | null>
  fullWidth?: boolean
}

interface IArcElement extends ArcElement {
  hidden?: boolean
}

const LegendData: React.FC<LegendProps> = ({
  labels,
  colors,
  chartRef,
  fullWidth = false,
}) => {
  const [hiddenIndices, setHiddenIndices] = React.useState<number[]>([])

  const handleLegendClick = (index: number): void => {
    const chart = chartRef?.current
    if (chart) {
      const meta = chart.getDatasetMeta(0)
      const segment = meta.data[index] as IArcElement

      if (segment?.hidden) {
        chart.show(0, index)
      } else {
        chart.hide(0, index)
      }
      chart.update()

      setHiddenIndices((prev) => {
        if (prev.includes(index)) {
          return prev.filter((i) => i !== index)
        } else {
          return [...prev, index]
        }
      })
    }
  }

  return (
    <Stack spacing={1}>
      {fullWidth ? (
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          overflow="scroll"
          pb={1}
          alignItems="center"
          justifyContent="center"
        >
          <Box display="flex" gap={1}>
            {labels
              .filter((item, index) => index % 2 === 0)
              .map((label, idx) => (
                <Box
                  key={idx}
                  display="flex"
                  alignItems="center"
                  sx={{
                    cursor: 'pointer',
                  }}
                  onClick={() => handleLegendClick(idx * 2)}
                >
                  <Box
                    sx={{
                      width: 14,
                      height: 14,
                      borderRadius: '4px',
                      backgroundColor: colors[idx],
                      mr: 1,
                    }}
                  />

                  <Typography
                    variant="subtitle2"
                    textTransform="capitalize"
                    sx={{
                      textDecoration: hiddenIndices.includes(idx * 2)
                        ? 'line-through'
                        : 'none',
                    }}
                  >
                    {label}
                  </Typography>
                </Box>
              ))}
          </Box>
          <Box display="flex" gap={1}>
            {labels
              .filter((item, index) => index % 2 !== 0)
              .map((label, idx) => {
                const originalIndex = labels.indexOf(label)
                return (
                  <Box
                    key={idx}
                    display="flex"
                    alignItems="center"
                    sx={{
                      cursor: 'pointer',
                    }}
                    onClick={() => handleLegendClick(originalIndex)}
                  >
                    <Box
                      sx={{
                        width: 14,
                        height: 14,
                        borderRadius: '4px',
                        backgroundColor: colors[originalIndex],
                        mr: 1,
                      }}
                    />
                    <Typography
                      variant="subtitle2"
                      textTransform="capitalize"
                      sx={{
                        textDecoration: hiddenIndices.includes(originalIndex)
                          ? 'line-through'
                          : 'none',
                      }}
                    >
                      {label}
                    </Typography>
                  </Box>
                )
              })}
          </Box>
        </Box>
      ) : (
        <Box display="flex" flexDirection="column" gap={2}>
          {labels.map((label, idx) => {
            return (
              <Box
                key={idx}
                display="flex"
                alignItems="center"
                sx={{
                  cursor: 'pointer',
                }}
                onClick={() => handleLegendClick(idx)}
              >
                <Box
                  sx={{
                    width: 14,
                    height: 14,
                    borderRadius: '4px',
                    backgroundColor: colors[idx],
                    mr: 1,
                  }}
                />
                <Typography
                  variant="subtitle2"
                  textTransform="capitalize"
                  sx={{
                    textDecoration: hiddenIndices.includes(idx)
                      ? 'line-through'
                      : 'none',
                    color: hiddenIndices.includes(idx)
                      ? 'grey.400'
                      : 'grey.800',
                  }}
                >
                  {label}
                </Typography>
              </Box>
            )
          })}
        </Box>
      )}
    </Stack>
  )
}

interface DonutChartProps {
  data: DonutChartData
  title: string
  total: number
  chartRef: React.MutableRefObject<ChartJS<'doughnut'> | null>
}

const DonutChart: React.FC<DonutChartProps> = ({
  data,
  title,
  total,
  chartRef,
}) => {
  const { theme, isSmallScreen } = useAppUtilities()
  const centerTextPlugin = {
    id: 'centerText',
    beforeDraw: (chart: ChartJS) => {
      const { ctx } = chart
      const { width, height } = chart
      ctx.restore()

      // Set font properties
      const fontSize = isSmallScreen ? 12 : 16
      const fontFamily = 'Arial'
      ctx.font = `${fontSize}px ${fontFamily}`
      ctx.textBaseline = 'middle'
      ctx.textAlign = 'center'

      // Draw the title text
      const textX = width / 2
      const textY = height / 2
      ctx.fillStyle = theme.palette.grey[400]
      ctx.fillText(title, textX, textY + (isSmallScreen ? 7 : 15))

      ctx.save()
    },
  }
  const centerSubTextPlugin = {
    id: 'centerSubTextPlugin',
    beforeDraw: (chart: ChartJS) => {
      const { ctx } = chart
      const { width, height } = chart
      ctx.restore()

      // Set font properties
      const fontSize = isSmallScreen ? 16 : 20
      const fontFamily = 'Arial'
      ctx.font = `bold ${fontSize}px ${fontFamily}`
      ctx.textBaseline = 'middle'
      ctx.textAlign = 'center'

      // Draw the title text
      const textX = width / 2
      const textY = height / 2
      ctx.fillStyle = theme.palette.grey[800]
      ctx.fillText(String(total), textX, textY - (isSmallScreen ? 7 : 15))

      ctx.save()
    },
  }

  const options: DonutOptions = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
      },
    },
    // TODO: config cutout, responsive, maintainAspectRatio, aspectRatio
    // cutout: '50%',
    // responsive: true, // Ensure the chart is responsive
    // maintainAspectRatio: false, // Allow the chart to adjust its aspect ratio
    // aspectRatio:1
  }

  return (
    <Box
      sx={{
        maxWidth: { xs: 150, sm: 220, md: 260 },
      }}
    >
      <Doughnut
        ref={chartRef}
        data={data}
        plugins={[centerTextPlugin, centerSubTextPlugin]}
        options={options}
      />
    </Box>
  )
}

export default Chart
