import { Box, Fade, Grid, Modal, useMediaQuery } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'

import { ModelMainBox } from '../../../../../components/styled/ModalInnerBox.styled'
import useAppUtilities from '../../../../../hooks/useAppUtilities'
import CompetitorInsightsMap from '../CompetitorInsightsMap'
import Chart from './chart/Chart'
import { useAppSelector } from '../../../../../store'
import {
  selectCompetitorCuisine,
  selectCompetitorDietary,
  selectIsLoading,
  // selectRestaurantMiles,
} from '../../../../../store/restaurant/dashboard'
import { ICompetitorDietaryAndCuisineResponse } from '../../../../../types/dashboard'
import { ChartData } from 'chart.js'
import NoDataFound from '../../../../../components/NoDataFound'
import ChartSkeleton from './chart/ChartSkeleton'
import { CHART_COLORS } from '../../../../../helpers/static-constants'

interface IDietaryAndCuisineTabProp {
  openMap: boolean
  setOpenMap: (value: boolean) => void
  type?: 'dietary' | 'cuisines'
}
type DonutChartData = ChartData<'doughnut', number[], string>
const DietaryAndCuisineTab: React.FC<IDietaryAndCuisineTabProp> = ({
  openMap,
  setOpenMap,
  type = 'dietary',
}) => {
  const dietary = useAppSelector(selectCompetitorDietary)
  const cuisines = useAppSelector(selectCompetitorCuisine)
  const isLoading = useAppSelector(selectIsLoading)
  const { isMediumScreen } = useAppUtilities()
  const isCustomBreakpoint = useMediaQuery(
    '(max-width:1350px) and (min-width:1280px)',
  )
  const [dietaryAndCuisineDetail, setDietaryAndCuisineDetail] = useState<
    ICompetitorDietaryAndCuisineResponse | undefined
  >()
  const [placeIds, setPlaceIds] = useState<string[]>([])
  const [usersData, setUsersData] = useState<DonutChartData>({
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: CHART_COLORS,
        borderColor: '#fff',
        borderRadius: 10,
        offset: 5,
      },
    ],
  })

  const [restaurantsData, setRestaurantsData] = useState<DonutChartData>({
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: CHART_COLORS,
        borderColor: '#fff',
        borderRadius: 10,
        offset: 5,
      },
    ],
  })

  useEffect(() => {
    setDietaryAndCuisineDetails()
  }, [dietary, cuisines, type])

  const setDietaryAndCuisineDetails = (): void => {
    const data = type === 'dietary' ? dietary : cuisines
    if (!data) return

    setDietaryAndCuisineDetail(data)
    setPlaceIds(data.placeIds.filter(Boolean))
  }

  const chatValue = useMemo(() => {
    if (!dietaryAndCuisineDetail) return { restaurants: [], users: [] }

    const restaurants = Object.entries(
      dietaryAndCuisineDetail.restaurants[type] || {},
    )
      .map(([key, value]) => ({
        label: key.toLowerCase().trim(),
        value,
      }))
      .filter(
        (item, index, self) =>
          item.label.length > 0 &&
          item.label !== 'not mentioned' &&
          self.findIndex((i) => i.label === item.label) === index,
      )

    const users = Object.entries(dietaryAndCuisineDetail.users[type] || {})
      .map(([key, value]) => ({
        label: key.toLowerCase().trim(),
        value,
      }))
      .filter(
        (item, index, self) =>
          item.label.length > 0 &&
          item.label !== 'not mentioned' &&
          self.findIndex((i) => i.label === item.label) === index,
      )

    return {
      restaurants: restaurants.slice(0, 10),
      users: users.slice(0, 10),
    }
  }, [dietaryAndCuisineDetail, type])

  useEffect(() => {
    if (dietaryAndCuisineDetail) {
      const userLabel = chatValue.users.map((item) => item?.label)
      const restaurantLabel = chatValue.restaurants.map((item) => item?.label)
      const userValue = chatValue.users.map((item) => item?.value) as number[]
      const restaurantValue = chatValue.restaurants.map(
        (item) => item?.value,
      ) as number[]
      setUsersData((prev) => ({
        ...prev,
        labels: userLabel,
        datasets: [
          {
            ...prev.datasets?.[0],
            data: userValue,
          },
        ],
      }))

      setRestaurantsData((prev) => ({
        ...prev,
        labels: restaurantLabel,
        datasets: [
          {
            ...prev.datasets?.[0],
            data: restaurantValue,
          },
        ],
      }))
    }
  }, [chatValue, type])

  return (
    <Box>
      <Grid container spacing={2} p={{ xs: '12px', md: '16px' }}>
        {dietaryAndCuisineDetail &&
          !isLoading &&
          dietaryAndCuisineDetail?.users?.count > 0 && (
            <Fade in={!isLoading} timeout={1000}>
              <Grid
                item
                xs={12}
                md={6}
                lg={
                  isCustomBreakpoint
                    ? 6
                    : dietaryAndCuisineDetail?.restaurants?.count > 0
                    ? 4
                    : 8
                }
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Chart
                  chartData={usersData}
                  labels={chatValue.users.map((item) => item?.label)}
                  colors={CHART_COLORS}
                  rightLabels={false}
                  title="Users"
                  total={dietaryAndCuisineDetail?.users?.count ?? 0}
                />
              </Grid>
            </Fade>
          )}
        {dietaryAndCuisineDetail &&
          !isLoading &&
          dietaryAndCuisineDetail?.restaurants?.count > 0 && (
            <Fade in={!isLoading} timeout={1000}>
              <Grid
                item
                xs={12}
                md={6}
                lg={
                  isCustomBreakpoint
                    ? 6
                    : dietaryAndCuisineDetail?.users?.count > 0
                    ? 4
                    : 8
                }
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Chart
                  chartData={restaurantsData}
                  labels={chatValue.restaurants.map((item) => item?.label)}
                  colors={CHART_COLORS}
                  title="Restaurants"
                  total={dietaryAndCuisineDetail?.restaurants.count ?? 0}
                />
              </Grid>
            </Fade>
          )}

        {!isMediumScreen &&
          !isLoading &&
          dietaryAndCuisineDetail &&
          dietaryAndCuisineDetail.placeIds.length > 0 && (
            <Fade in={!isLoading} timeout={1000}>
              <Grid item xs={12} md={12} lg={isCustomBreakpoint ? 12 : 4}>
                <CompetitorInsightsMap placeIds={placeIds} />
              </Grid>
            </Fade>
          )}

        {!dietaryAndCuisineDetail && !isLoading && (
          <Grid
            item
            xs={12}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <NoDataFound height="250px" />
          </Grid>
        )}
        {isLoading && (
          <Grid item xs={12}>
            <ChartSkeleton />
          </Grid>
        )}
      </Grid>
      <Modal
        open={openMap}
        onClose={() => setOpenMap(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelMainBox>
          <CompetitorInsightsMap placeIds={placeIds} />
        </ModelMainBox>
      </Modal>
    </Box>
  )
}

export default DietaryAndCuisineTab
