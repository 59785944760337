import {
  PayloadAction,
  createAsyncThunk,
  createSlice,
  isAnyOf,
} from '@reduxjs/toolkit'
import okaoAxios from '../helpers/axios'

import { RootState } from './'
import {
  GeneralData,
  DiningFrequencyState,
  SocialLinksState,
  StepsPayloadMap,
  Login,
  Restaurant,
  IRestaurantName,
} from '../types'
import { AlertColor } from '@mui/material'

export interface IOnboardingSlice {
  status: string
  onboardPayload: StepsPayloadMap
  personalizePayload: GeneralData
  role: string
  socialLinks: SocialLinksState
  diningFrequency: DiningFrequencyState
  shouldOpen: boolean
  message: string | undefined
  severity: AlertColor | undefined
  restaurant: Restaurant | undefined
  locateRestaurant: IRestaurantName | undefined
  website: string
  isNewRestaurant: boolean
}

const initialState: IOnboardingSlice = {
  status: 'idle',
  onboardPayload: {},
  personalizePayload: {},
  role: '',
  socialLinks: {
    facebook: '',
    instagram: '',
    x: '',
    tiktok: '',
  },
  diningFrequency: {
    number: 0,
    frequency: 'WEEK',
  },
  shouldOpen: false,
  message: undefined,
  severity: undefined,
  restaurant: undefined,
  website: '',
  locateRestaurant: undefined,
  isNewRestaurant: false,
}

export const fetchEaterProfile = createAsyncThunk(
  'api/fetchEaterProfile',
  async (payload: unknown, { rejectWithValue }) => {
    try {
      const response = await okaoAxios.post(`/eater/onboard-eater`, payload)
      return response?.data
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const updateEaterProfile = createAsyncThunk(
  'api/updateEaterProfile',
  async (payload: unknown, { rejectWithValue }) => {
    try {
      const response = await okaoAxios.post(
        `/eater/update-preferences`,
        payload,
      )
      return response?.data
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    setOnboardPayload: (state, action: PayloadAction<StepsPayloadMap>) => {
      state.onboardPayload = action.payload
    },
    setPersonalizePayload: (state, action: PayloadAction<GeneralData>) => {
      state.personalizePayload = action.payload
    },
    setRole: (state, action: PayloadAction<string>) => {
      state.role = action.payload
    },
    setSocialLinks: (state, action: PayloadAction<SocialLinksState>) => {
      state.socialLinks = action.payload
    },
    setDiningFrequency: (
      state,
      action: PayloadAction<DiningFrequencyState>,
    ) => {
      state.diningFrequency = action.payload
    },
    setOpen: (state, action: PayloadAction<boolean>) => {
      state.shouldOpen = action.payload
    },
    setRestaurant: (state, action: PayloadAction<Restaurant | undefined>) => {
      state.restaurant = action.payload
    },
    setLocateRestaurant: (
      state,
      action: PayloadAction<IRestaurantName | undefined>,
    ) => {
      state.locateRestaurant = action.payload
    },
    setWebsiteUrl: (state, action: PayloadAction<string>) => {
      state.website = action.payload
    },
    setIsNewRestaurant: (state, action: PayloadAction<boolean>) => {
      state.isNewRestaurant = action.payload
    },
    clearData: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        isAnyOf(fetchEaterProfile.fulfilled, updateEaterProfile.fulfilled),
        (state, action) => {
          state.status = 'idle'
          state.shouldOpen = true
          state.message = 'Your information has been saved successfully.'
          state.severity = 'success'
          const localStorageData: Login = JSON.parse(
            window.localStorage.getItem('login') ?? '{}',
          )
          const setLocalData = {
            ...localStorageData,
            eaterDTO: action.payload.eaterDTO,
          }
          localStorage.setItem('login', JSON.stringify(setLocalData))
        },
      )
      .addMatcher(
        isAnyOf(fetchEaterProfile.pending, updateEaterProfile.pending),
        (state, action) => {
          state.status = 'loading'
        },
      )
      .addMatcher(
        isAnyOf(fetchEaterProfile.rejected, updateEaterProfile.rejected),
        (state, action) => {
          state.status = 'error'
          state.shouldOpen = true
          state.message = 'Sorry, an error occurred. Please try again.'
          state.severity = 'error'
        },
      )
  },
})

export const selectIsLoading = (state: RootState): boolean =>
  state.onboarding?.status === 'loading'
export const selectOnboardPayload = (state: RootState): StepsPayloadMap =>
  state.onboarding?.onboardPayload
export const selectPersonalizePayload = (state: RootState): GeneralData =>
  state.onboarding?.personalizePayload
export const selectRole = (state: RootState): string => state.onboarding?.role
export const selectSocialLinks = (state: RootState): SocialLinksState =>
  state.onboarding?.socialLinks
export const selectDiningFrequency = (state: RootState): DiningFrequencyState =>
  state.onboarding?.diningFrequency
export const selectShouldOpen = (state: RootState): boolean =>
  state.onboarding?.shouldOpen
export const selectMessage = (state: RootState): string | undefined =>
  state.onboarding?.message
export const selectSeverity = (state: RootState): AlertColor | undefined =>
  state.onboarding?.severity
export const selectRestaurant = (state: RootState): Restaurant | undefined =>
  state.onboarding?.restaurant
export const selectWebsite = (state: RootState): string | undefined =>
  state.onboarding?.website
export const selectIsNewRestaurant = (state: RootState): boolean =>
  state.onboarding?.isNewRestaurant
export const selectLocateRestaurant = (
  state: RootState,
): IRestaurantName | undefined => state.onboarding?.locateRestaurant

export const {
  setOnboardPayload,
  setPersonalizePayload,
  setRole,
  setSocialLinks,
  setDiningFrequency,
  setOpen,
  setRestaurant,
  setWebsiteUrl,
  setLocateRestaurant,
  clearData,
  setIsNewRestaurant
} = onboardingSlice.actions

export default onboardingSlice.reducer
