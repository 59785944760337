import { useState, useEffect } from 'react'

interface WeekDay {
  date: number
  day: string
  active: boolean
}

const useCurrentWeek = (): WeekDay[] => {
  const [week, setWeek] = useState<WeekDay[]>([])

  useEffect(() => {
    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    const currentDate = new Date()
    const firstDayOfWeek = currentDate.getDate() - currentDate.getDay()
    const today = currentDate.getDate()

    const weekData: WeekDay[] = Array.from({ length: 7 }).map((_, index) => {
      const date = new Date(currentDate.setDate(firstDayOfWeek + index))
      return {
        date: date.getDate(),
        day: daysOfWeek[date.getDay()],
        active: date.getDate() === today,
      }
    })

    setWeek(weekData)
  }, [])

  return week
}

export default useCurrentWeek
