import { MenuItem } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { FacebookShareButton, TwitterShareButton } from 'react-share'
import RoundedButton from '../../../../components/ui/RoundedButton'
import useAppUtilities from '../../../../hooks/useAppUtilities'
import {
  RestaurantShareFacebookIcon,
  RestaurantShareIconShare,
  RestaurantShareMenu,
  RestaurantShareXIcon,
} from '../styled/RestaurantShare.styled'

interface RestaurantShareProps {
  imgUrl: string
  description: string
  title: string
  cuisine: string
  city: string
}

const RestaurantShare: React.FC<RestaurantShareProps> = ({
  imgUrl,
  description,
  title,
  cuisine,
  city,
}) => {
  const { theme, location } = useAppUtilities()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [pageTitle, setPageTitle] = useState('')
  const open = Boolean(anchorEl)
  const shareUrl = `https://www.resactly.com${location.pathname}`
  const image = imgUrl

  useEffect(() => {
    setPageTitle(`${title} - Authentic ${cuisine} Dining Experience in ${city}`)
  }, [title, cuisine, city])

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (): void => {
    setAnchorEl(null)
  }

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
        <meta property="og:url" content={shareUrl} />
        <meta name="x:card" content="summary_large_image" />
        <meta name="x:title" content={pageTitle} />
        <meta name="x:description" content={description} />
        <meta name="x:image" content={image} />
      </Helmet>
      <RoundedButton
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        borderColor={theme.palette.grey[300]}
        diameter={37}
      >
        <RestaurantShareIconShare />
      </RoundedButton>
      <RestaurantShareMenu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem className="flex-center">
          <FacebookShareButton
            url={shareUrl}
            title={title}
            className="flex-center"
          >
            <RestaurantShareFacebookIcon />
          </FacebookShareButton>
        </MenuItem>
        <MenuItem>
          <TwitterShareButton
            className="flex-center"
            url={shareUrl}
            title={title}
          >
            <RestaurantShareXIcon />
          </TwitterShareButton>
        </MenuItem>
      </RestaurantShareMenu>
    </>
  )
}

export default RestaurantShare
