import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useAppSelector } from '../store'
import { selectLogin } from '../store/authSlice'
import { UserStatus } from '../types'



type UserType = 'EATER' | 'RESTAURANT' | undefined

interface RoleGuardProps {
  allowedRoles: UserType[]
  allowedStatuses: UserStatus[]
  redirectPath: string
}

const RoleGuard: React.FC<RoleGuardProps> = ({
  allowedRoles,
  allowedStatuses,
  redirectPath,
}) => {
  const loginResponse = useAppSelector(selectLogin)
  const userStatus = loginResponse?.status as UserStatus

  if (
    !allowedRoles.includes(loginResponse?.type as UserType) ||
    !allowedStatuses.includes(userStatus)
  ) {
    return <Navigate to={redirectPath} />
  }

  return <Outlet />
}

export default RoleGuard
