import { Box, CircularProgress } from '@mui/material'
import Modal from '@mui/material/Modal'
import React, { useCallback } from 'react'
import useAppUtilities from '../../hooks/useAppUtilities'
import {
  BasicModalDrawer,
  BasicModalFooterMainContainer,
  BasicModalHeaderIconX,
  BasicModalHeaderMainContainer,
  BasicModalHeaderTypography,
  BasicModalWrapper,
} from '../styled/modal/BasicModal.styled'
import CustomButton from '../ui/CustomButton'

interface BasicModalProps {
  open: boolean
  handleClose: () => void
  children: React.ReactNode
  title?: string
  buttonName: string
  cancelButtonName?: string
  deleteModal?: boolean
  onCancel: () => void
  onConfirm: () => void
  cancelIcon?: boolean
  modelWidth?: string
  isLoader?: boolean
  saveBtnDisabled?: boolean
  modelOpenType?: 'popup' | 'drawer'
}

const BasicModal: React.FC<BasicModalProps> = ({
  open,
  handleClose,
  children,
  title,
  buttonName,
  deleteModal,
  onCancel,
  onConfirm,
  cancelButtonName = 'Cancel',
  cancelIcon = true,
  modelWidth = '400px',
  isLoader = false,
  saveBtnDisabled = false,
  modelOpenType = 'popup',
}) => {
  const { theme, isSmallScreen } = useAppUtilities()

  const FallbackHeaderModal = useCallback(
    () => (
      <BasicModalHeaderMainContainer>
        <BasicModalHeaderTypography>{title}</BasicModalHeaderTypography>
        {cancelIcon && <BasicModalHeaderIconX onClick={handleClose} />}
      </BasicModalHeaderMainContainer>
    ),
    [
      title,
      cancelIcon,
      handleClose,
    ],
  )
  const FallbackFooterModal = useCallback(
    () => (
      <BasicModalFooterMainContainer>
        <CustomButton
          title={cancelButtonName}
          onClick={onCancel}
          fullWidth
          disabled={isLoader}
          sx={{
            backgroundColor: 'white',
            borderColor: theme.palette.primary.light,
            color: theme.palette.primary.light,
            fontSize: '14px !important',
            paddingY: '8px',
          }}
        />
        <CustomButton
          title={buttonName}
          icon={
            isLoader ? <CircularProgress color="inherit" size={16} /> : null
          }
          disabled={isLoader || saveBtnDisabled}
          disabledTextColor="white"
          fullWidth
          backgroundColor={
            buttonName === 'Delete'
              ? theme.palette.error.main
              : theme.palette.primary.light
          }
          onClick={onConfirm}
          sx={{
            backgroundColor: deleteModal
              ? theme.palette.error.main
              : buttonName === 'Delete'
              ? theme.palette.error.main
              : theme.palette.primary.light,
            color: 'white',
            border: 'none',
            fontSize: '14px !important',
            paddingY: '9px',
          }}
          type="submit"
        />
      </BasicModalFooterMainContainer>
    ),
    [
      cancelButtonName,
      buttonName,
      deleteModal,
      isLoader,
      saveBtnDisabled,
      onCancel,
      onConfirm,
    ],
  )

  return (
    <>
      {modelOpenType === 'drawer' && isSmallScreen ? (
        <BasicModalDrawer open={open} onClose={handleClose}>
          <FallbackHeaderModal />
          <Box>{children}</Box>
          <FallbackFooterModal />
        </BasicModalDrawer>
      ) : (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <BasicModalWrapper modelWidth={modelWidth}>
            <FallbackHeaderModal />
            <Box>{children}</Box>
            <FallbackFooterModal />
          </BasicModalWrapper>
        </Modal>
      )}
    </>
  )
}

export default BasicModal
