import React from 'react'
import {
  EditModeButtonsMainBox,
  EditModeCancelButton,
  EditModeUpdateButton,
} from '../styled/EditModeButtons.styled'
import { selectCurrentTab } from '../../../../store/restaurant/setting'
import { useAppSelector } from '../../../../store'

interface IEditModeButtonsProps {
  onCancel: () => void
  onUpdate: () => void
}

const EditModeButtons: React.FC<IEditModeButtonsProps> = ({
  onCancel,
  onUpdate,
}) => {
    const currentTab = useAppSelector(selectCurrentTab)
    
  return (
    <EditModeButtonsMainBox>
      {currentTab !== 'image' &&<EditModeCancelButton onClick={onCancel} />}
      <EditModeUpdateButton onClick={onUpdate} />
    </EditModeButtonsMainBox>
  )
}

export default EditModeButtons
