import React, { useEffect, useImperativeHandle, useState } from 'react'
import { listData } from '../../../helpers/onboard-helper'
import { OnboardSelectionMethods } from '../../../types'
import OnboardPreferenceOptions from './OnboardPreferenceOptions'
import OnboardTitleTypography from './OnboardTitleTypography'
import {
  OnboardPreferencesDisLikeContainer,
  OnboardPreferencesLikeContainer,
} from './styled/OnboardPreferences.styled'

interface OnboardSelectionProps {
  key?: number
  title?: string
  subTitle?: string
  secondSubTitle?: string
  listName: string
  liked?: string[]
  dislike?: string[]
  indexKey: string
  isEditPreference?: boolean
  alignItems?: 'center' | 'right' | 'left'
}

const OnboardPreferences = React.forwardRef<
  OnboardSelectionMethods,
  OnboardSelectionProps
>(function OnboardSelection(
  {
    title,
    subTitle,
    secondSubTitle,
    listName,
    liked,
    dislike,
    indexKey,
    isEditPreference = false,
    alignItems = 'center',
  }: OnboardSelectionProps,
  ref,
): JSX.Element {
  const [itemList, setItemList] = useState<string[]>([])
  const [likedItems, setLikedItems] = useState<string[]>(liked ?? [])
  const [dislikedItems, setDislikedItems] = useState<string[]>(dislike ?? [])

  const firstTestId = `1`
  const secondTestId = `2`

  useEffect(() => {
    setItemList(listData[listName] || [])
  }, [listName])

  useEffect(() => {
    if (liked && liked.length > 0) {
      setLikedItems(liked)
    }
  }, [liked])

  useEffect(() => {
    if (dislike && dislike.length > 0) {
      setDislikedItems(dislike)
    }
  }, [dislike])

  useImperativeHandle(ref, () => ({
    getPayload,
  }))

  const getPayload = (): { likedItems: string[]; dislikedItems: string[] } => ({
    likedItems,
    dislikedItems,
  })

  return (
    <>
      {title && (
        <OnboardTitleTypography
          title={title}
          subTitle="Select all that apply or search to add new"
          isEditPreference={isEditPreference}
          alignItems={alignItems}
        />
      )}
      <OnboardPreferencesLikeContainer isProfile={isEditPreference}>
        <OnboardPreferenceOptions
          title={subTitle}
          chipType="green"
          itemList={itemList}
          selectedItems={likedItems}
          setSelectedItems={setLikedItems}
          oppositeItems={dislikedItems}
          searchIndexKey={indexKey}
          isPreferenceEdit={isEditPreference}
          testId={firstTestId}
          alignItems={alignItems}
        />
      </OnboardPreferencesLikeContainer>
      {secondSubTitle && secondSubTitle.length > 0 && (
        <OnboardPreferencesDisLikeContainer>
          <OnboardPreferenceOptions
            title={secondSubTitle}
            chipType="red"
            itemList={itemList}
            selectedItems={dislikedItems}
            setSelectedItems={setDislikedItems}
            oppositeItems={likedItems}
            searchIndexKey={indexKey}
            isPreferenceEdit={isEditPreference}
            testId={secondTestId}
            alignItems={alignItems}
          />
        </OnboardPreferencesDisLikeContainer>
      )}
    </>
  )
})

export default OnboardPreferences
