import { Box, Tooltip, Typography } from '@mui/material'
import { IconChevronRight } from '@tabler/icons-react'
import React, { useCallback } from 'react'
import InfoCircleTooltip from '../../../../../components/dashboard/InfoCircleTooltip'
import {
  TableBodyCell,
  TableBodyRow,
} from '../../../../../components/styled/Table.styled'
import AvatarTableCell from '../../../../../components/table/AvatarTableCell'
import RestaurantTable from '../../../../../components/table/RestaurantTable'
import TextTableCell from '../../../../../components/table/TextTableCell'
import useAppUtilities from '../../../../../hooks/useAppUtilities'
import { useAppSelector } from '../../../../../store'
import {
  selectIsLoading,
  selectSuggestedIngredients,
} from '../../../../../store/restaurant/dashboard'
import { SubOneTypography } from '../../styled/competitorInsights/PricingTabTable.styled'

interface TableColumn {
  id: string
  label: string
  minWidth?: string
  maxWidth?: string
  width?: string
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify'
}

interface PercentageChange {
  percentage: number
  type: 'increase' | 'decrease'
  symbol: string
}

const IngredientTab: React.FC = () => {
  const { theme } = useAppUtilities()
  const suggestedIngredients = useAppSelector(selectSuggestedIngredients)
  const isLoading = useAppSelector(selectIsLoading)

  const calculatePercentageChange = (
    previousValue: number,
    currentValue: number,
  ): PercentageChange => {
    const formattedPercentage = previousValue
      ? Number(
          (
            (Math.abs(currentValue - previousValue) / previousValue) *
            100
          ).toFixed(2),
        )
      : 0
    const changeType = formattedPercentage > 0 ? 'increase' : 'decrease'
    const symbol = changeType === 'increase' ? '▲' : '▼'

    return {
      percentage: Number(formattedPercentage),
      type: changeType,
      symbol,
    }
  }

  const renderChangeIndicator = useCallback(
    (previousCount: number, currentCount: number) => (
      <Box display="flex" alignItems="center">
        <Typography variant="subtitle1" color={theme.palette.grey[800]}>
          {previousCount ?? 0}
        </Typography>
        <IconChevronRight
          stroke={2}
          color={theme.palette.grey[300]}
          size="18px"
        />
        <Typography variant="subtitle1" color={theme.palette.grey[800]}>
          {currentCount ?? 0}
        </Typography>
      </Box>
    ),
    [theme],
  )

  return (
    <Box p={{ xs: '12px', md: '16px' }}>
      {/* TODO: Implement Ingredient suggestions Filter Chip */}
      {/* <Box pb={1}>
        <PopularDishesFilterChip />
      </Box> */}
      <Typography
        variant="subtitle2"
        color={theme.palette.grey[500]}
        pb={1}
        fontSize={{
          xs: '10px',
          md: '12px',
        }}
      >
        Discover suggested ingredients to increase your dish popularity
      </Typography>

      <RestaurantTable
        columns={columns}
        data={suggestedIngredients ?? []}
        isLoading={isLoading}
        maxHeight={346}
        renderRow={(row, index) => {
          const { percentage, type, symbol } = calculatePercentageChange(
            row.popularityRise,
            row.currentPopularity,
          )
          return (
            <TableBodyRow key={index}>
              <AvatarTableCell
                imageUrl={row?.dish?.imageurl}
                primaryText={row?.dish?.name}
                secondaryText={row?.dish?.description}
                maxWidth={columns?.[0].maxWidth}
                minWidth={columns?.[0].minWidth}
              />

              <TextTableCell text={row.suggested} />

              <TableBodyCell align="left">
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box display="flex" alignItems="center">
                    <SubOneTypography>{row.currentPopularity}</SubOneTypography>
                    <Typography
                      variant="subtitle1"
                      color={
                        type === 'increase'
                          ? theme.palette.success.main
                          : theme.palette.error.main
                      }
                      pl={1}
                      fontWeight={600}
                    >
                      {percentage}%
                    </Typography>
                    <Tooltip
                      title={renderChangeIndicator(
                        row.popularityRise,
                        row.currentPopularity,
                      )}
                      arrow
                      placement="bottom"
                    >
                      <Typography
                        color={
                          type === 'increase'
                            ? theme.palette.success.main
                            : theme.palette.error.main
                        }
                        fontSize="10px"
                        fontWeight={500}
                        pl="4px"
                        sx={{ cursor: 'pointer' }}
                      >
                        {symbol}
                      </Typography>
                    </Tooltip>
                  </Box>
                  <InfoCircleTooltip
                    data={renderChangeIndicator(
                      row?.dish?.price,
                      row?.compared?.price,
                    )}
                  />
                </Box>
              </TableBodyCell>
            </TableBodyRow>
          )
        }}
      />
    </Box>
  )
}

export default IngredientTab

const columns: readonly TableColumn[] = [
  { id: '2', label: 'Your dishes', width: '300px' },
  {
    id: '3',
    label: 'Suggested Ingredient',
    minWidth: '250px',
    maxWidth: '450px',
  },
  {
    id: '4',
    label: 'Potential rise in popularity',
    minWidth: '180px',
    width: '220px',
  },
]
