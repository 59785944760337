import React, { useState } from 'react'
import BasicModal from '../../../../components/modal/BasicModal'
import InputComponent from '../../../../components/ui/InputComponent'
import { Box } from '@mui/material'

interface IReviewRespondDialogProps {
  open: boolean
  onCancel: () => void
  onConfirm: () => void
  title: string
  handleClose: () => void
}

const ReviewRespondDialog: React.FC<IReviewRespondDialogProps> = ({
  handleClose,
  onCancel,
  onConfirm,
  open,
  title,
}) => {
  const [reviewRespond, setReviewRespond] = useState<string>('')

  return (
    <BasicModal
      open={open}
      onCancel={onCancel}
      onConfirm={onConfirm}
      title={title}
      buttonName="Send"
      handleClose={handleClose}
    >
      <Box my={2}>
        <InputComponent
          id="review-respond"
          value={reviewRespond}
          setValue={setReviewRespond}
          placeholder="Type here..."
          rows={6}
          multiline
        />
      </Box>
    </BasicModal>
  )
}

export default ReviewRespondDialog
