import styled from 'styled-components'
import React from 'react'
import { Avatar, useTheme } from '@mui/material'

interface SquareAvatarProps {
  name: string
  size: string
}

export const SquareAvatar = styled(({ name, size, ...rest }) => (
  <Avatar {...rest} />
)).attrs<SquareAvatarProps>(({ name, size }) => {
  const theme = useTheme()
  return {
    variant: 'square',
    sx: {
      border: `1px solid ${theme.palette.grey[200]}`,
      borderRadius: '8px',
      height: size,
      width: size,
    },
    alt: name,
  }
})<SquareAvatarProps>(() => ({}))
