import { Box, Typography, useTheme } from '@mui/material'
import styled from 'styled-components'
import React from 'react'

interface ImagePlaceholderBoxProps {
  isMediumScreen: boolean
  isEditMode: boolean
}

export const MainBox = styled(Box).attrs(() => {
  const theme = useTheme()
  return {
    sx: {
      padding: 2,
      border: 1.5,
      borderRadius: '12px',
      borderColor: theme.palette.grey[200],
      backgroundColor: theme.palette.background.paper,
      display: 'flex',
      flexDirection: {
        xs: 'row',
        md: 'column',
      },
      alignItems: {
        xs: 'center',
        md: 'start',
      },
      height: '100%',
    },
  }
})(() => ({}))

export const ImageUploadBox = styled(Box).attrs(() => ({
  sx: {
    width: {
      xs: 'auto',
      md: '100%',
    },
    position: 'relative',
  },
}))(() => ({}))

export const ImagePlaceholderBox = styled(
  ({ isMediumScreen, isEditMode, ...rest }) => <Box {...rest} />,
).attrs<ImagePlaceholderBoxProps>(({ isMediumScreen, isEditMode }) => {
  const theme = useTheme()
  return {
    sx: {
      width: isMediumScreen ? '114px' : '100%',
      height: '114px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: `1px dashed ${theme.palette.grey[300]}`,
      borderRadius: '8px',
      cursor: isEditMode ? 'pointer' : 'default',
    },
  }
})<ImagePlaceholderBoxProps>(() => ({}))

export const IconActionDeleteButton = styled(Box).attrs(() => ({
  sx: {
    position: 'absolute',
    backgroundColor: '#00000080',
    borderRadius: '50%',
    height: '40px',
    width: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: '1',
    cursor: 'pointer',
    top: '8px',
    right: '8px',
  },
}))(() => ({}))
export const IconActionRotateButton = styled(Box).attrs(() => ({
  sx: {
    position: 'absolute',
    backgroundColor: '#00000080',
    borderRadius: '50%',
    height: '40px',
    width: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: '1',
    cursor: 'pointer',
    top: '56px',
    right: '8px',
  },
}))(() => ({}))

export const InfoBox = styled(Box).attrs(() => ({
  sx: {
    marginTop: {
      xs: '0px',
      md: '16px',
    },
    marginLeft: {
      xs: '16px',
      md: '0px',
    },
    width: '100%',
  },
}))(() => ({}))

export const LabelTypography = styled(Typography).attrs(() => {
  const theme = useTheme()
  return {
    variant: 'subtitle1',
    sx: {
      color: theme.palette.grey[500],
      marginBottom: '6px',
    },
  }
})(() => ({}))

export const ContentTypography = styled(Typography).attrs(() => {
  const theme = useTheme()
  return {
    variant: 'subtitle1',
    sx: {
      color: theme.palette.grey[800],
    },
  }
})(() => ({}))
