import { useAuth0 } from '@auth0/auth0-react'
import { alpha, Avatar, Box, Button, Popover, Typography } from '@mui/material'
import { IconChevronDown, IconLogout } from '@tabler/icons-react'
import React, { MouseEvent, useState } from 'react'
import { ROUTES } from '../../helpers/routes-helper'
import { WEB_NAV_HEIGHT } from '../../helpers/static-constants'
import useAppUtilities from '../../hooks/useAppUtilities'
import { useAuthHelper } from '../../hooks/useAuthHelper'
import { useAppSelector } from '../../store'
import { selectUserInfo } from '../../store/authSlice'
import { selectEater } from '../../store/profileSlice'
import useRudderStackAnalytics from '../../useRudderAnalytics'

const LoginButton: React.FC = (): JSX.Element => {
  const { theme, navigate } = useAppUtilities()
  const { isAuthenticated, isLoading } = useAuth0()
  const { loginAuthUser, logoutAuthUser } = useAuthHelper()
  const eater = useAppSelector(selectEater)
  const userInfo = useAppSelector(selectUserInfo)
  const [analytics, isAnalyticsReady] = useRudderStackAnalytics()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const openPop = Boolean(anchorEl)
  const id = openPop ? 'simple-popover' : undefined

  const handleClose = (): void => {
    setAnchorEl(null)
  }

  const handleClick = (event: MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const onChangeRoute = (name: string): void => {
    if (name === 'profile') {
      navigate(ROUTES.PROFILE)
    } else {
      if (analytics && isAnalyticsReady) {
        analytics?.identify('', { isLoggedIn: false })
        analytics?.reset()
      }
      logoutAuthUser()
    }
    setAnchorEl(null)
  }

  return (
    <>
      {!isLoading && !isAuthenticated && (
        <Box
          sx={{
            paddingX: '32px',
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            '&:hover': {
              backgroundColor: `${alpha(theme.palette.primary.main, 0.9)}`,
            },
          }}
          bgcolor={theme.palette.primary.main}
          onClick={() => loginAuthUser()}
        >
          <Typography
            noWrap
            component="p"
            variant="subtitle1"
            fontWeight={500}
            color={'white'}
          >
            Login
          </Typography>
        </Box>
      )}
      {isAuthenticated && (
        <>
          <Button
            aria-describedby={id}
            sx={{
              paddingX: '16px',
              height: '100%',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: 'white',
              },
              borderLeft: `1px solid ${theme.palette.grey[300]}`,
              borderRadius: '0px',
            }}
            disableElevation
            disableRipple
            disableFocusRipple
            onClick={handleClick}
          >
            <Avatar
              src={
                eater?.imageUrl ??
                userInfo?.picture ??
                'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'
              }
              alt="Profile Image"
              sx={{ width: 32, height: 32, mr: '8px' }}
            />

            <IconChevronDown
              color={theme.palette.grey[400]}
              stroke={2}
              size={16}
            />
          </Button>

          <Popover
            id={id}
            open={openPop}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            sx={{
              '& .MuiPaper-root': {
                borderRadius: '8px',
                top: `${4 + WEB_NAV_HEIGHT}px !important`,
              },
            }}
          >
            <div
              style={{
                padding: '8px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'start',
                  alignItems: 'center',
                  padding: '8px',
                  '&:hover': {
                    color: theme.palette.error.dark,
                  },
                  color: theme.palette.grey[800],
                  cursor: 'pointer',
                }}
                onClick={() => onChangeRoute('profile')}
              >
                <Typography noWrap component="p" variant="subtitle2">
                  Profile
                </Typography>
              </Box>

              <Box
                onClick={() => onChangeRoute('logout')}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                  padding: '8px',
                  cursor: 'pointer',
                  '&:hover': {
                    color: 'red',
                  },
                }}
              >
                <Typography
                  noWrap
                  component="span"
                  variant="subtitle2"
                  sx={{
                    paddingRight: '16px',
                  }}
                >
                  Logout
                </Typography>
                <IconLogout size={16} stroke={2} />
              </Box>
            </div>
          </Popover>
        </>
      )}
    </>
  )
}

export default LoginButton
