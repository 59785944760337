import { Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { SocialLinksState } from '../../../../types'
import { useAppSelector } from '../../../../store'
import {
  selectIsSettingEdit,
  selectIsUpdateData,
  selectRestaurant,
  setIsEditCancelledData,
  setIsSettingEdit,
  setIsUpdateData,
  setIsValidData,
  updateRestaurant,
} from '../../../../store/restaurant/setting'
import useAppUtilities from '../../../../hooks/useAppUtilities'
import InputComponent from '../../../../components/ui/InputComponent'

const SocialTab: React.FC = () => {
  const restaurant = useAppSelector(selectRestaurant)
  const isEditMode = useAppSelector(selectIsSettingEdit)
  const isUpdateMode = useAppSelector(selectIsUpdateData)
  const { dispatch } = useAppUtilities()
  const [socialLinksData, setSocialLinksData] = useState<SocialLinksState>({
    facebook: '',
    instagram: '',
    x: '',
    tiktok: '',
  })

  useEffect(() => {
    setSocialLinksData((preData) => {
      return {
        facebook: restaurant?.facebook ?? '',
        instagram: restaurant?.instagram ?? '',
        tiktok: restaurant?.tiktok ?? '',
        x: restaurant?.x ?? '',
      }
    })
  }, [restaurant])
  useEffect(() => {
    if (isUpdateMode) {
      void updateSocial()
    }
  }, [isEditMode, isUpdateMode])

  const updateSocial = async (): Promise<void> => {
    if (
      restaurant &&
      (restaurant.instagram !== socialLinksData.instagram ||
        restaurant?.facebook !== socialLinksData.facebook ||
        restaurant?.tiktok !== socialLinksData.tiktok ||
        restaurant?.x !== socialLinksData.x)
    ) {
      await dispatch(
        updateRestaurant({
          ...restaurant,
          facebook: socialLinksData.facebook,
          instagram: socialLinksData.instagram,
          tiktok: socialLinksData.tiktok,
          x: socialLinksData.x,
        }),
      )
    }
    dispatch(setIsSettingEdit(false))
    dispatch(setIsUpdateData(false))
    dispatch(setIsValidData(false))
    dispatch(setIsEditCancelledData(false))
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12} lg={6}>
        <InputComponent
          disabled={!isEditMode}
          id="facebook"
          label="Facebook"
          placeholder="Enter Your Facebook Url"
          labelShrink
          labelFontSize="16px"
          labelWeight={500}
          isEditBackgroundMode
          value={socialLinksData.facebook}
          setValue={(value) =>
            setSocialLinksData((prev) => ({ ...prev, facebook: value }))
          }
        />
      </Grid>
      <Grid item xs={12} md={12} lg={6}>
        <InputComponent
          disabled={!isEditMode}
          id="instagram"
          label="Instagram"
          placeholder="Enter Your Instagram Url"
          labelShrink
          labelFontSize="16px"
          labelWeight={500}
          isEditBackgroundMode
          value={socialLinksData.instagram}
          setValue={(value) =>
            setSocialLinksData((prev) => ({ ...prev, instagram: value }))
          }
        />
      </Grid>
      <Grid item xs={12} md={12} lg={6}>
        <InputComponent
          disabled={!isEditMode}
          id="x"
          label="X (formerly Twitter)"
          placeholder="Enter Your X (formerly Twitter) Url"
          labelShrink
          labelFontSize="16px"
          labelWeight={500}
          isEditBackgroundMode
          value={socialLinksData.x}
          setValue={(value) =>
            setSocialLinksData((prev) => ({ ...prev, x: value }))
          }
        />
      </Grid>
      <Grid item xs={12} md={12} lg={6}>
        <InputComponent
          disabled={!isEditMode}
          id="tiktok"
          label="Tiktok"
          placeholder="Enter Your Tiktok Url"
          labelShrink
          labelFontSize="16px"
          labelWeight={500}
          isEditBackgroundMode
          value={socialLinksData.tiktok}
          setValue={(value) =>
            setSocialLinksData((prev) => ({ ...prev, tiktok: value }))
          }
        />
      </Grid>
    </Grid>
  )
}

export default SocialTab
