import { Box, Modal, SelectChangeEvent, Typography } from '@mui/material'
import { IconAdjustments, IconMapSearch } from '@tabler/icons-react'
import React, { useState } from 'react'
import ResactlyTab from '../../../../components/ResactlyTab'
import { ModelMainBox } from '../../../../components/styled/ModalInnerBox.styled'
import SelectBox from '../../../../components/ui/SelectBox'
import useAppUtilities from '../../../../hooks/useAppUtilities'
import { useAppSelector } from '../../../../store'
import {
  selectRegionInsightSearch,
  setRestaurantMiles,
} from '../../../../store/restaurant/dashboard'
import {
  CompetitorInsightsButton,
  CompetitorInsightsMainBox,
  CompetitorInsightsTitleBox,
} from '../styled/CompetitorInsights.styled'
import DietaryAndCuisineTab from './competitorInsights/DietaryAndCuisineTab'
import IngredientTab from './competitorInsights/IngredientTab'
import PopularDishesTab from './competitorInsights/PopularDishesTab'
import PricingTab from './competitorInsights/PricingTab'
import RegionalInsightsTab from './competitorInsights/RegionalInsightsTab'
import RangAndFilterDialog from './competitorInsights/dish/RangAndFilterDialog'
import RestaurantSearchPopover from './restaurantSearch/RestaurantSearchPopover'

const CompetitorInsights: React.FC = () => {
  const { theme, dispatch, isMediumScreen } = useAppUtilities()
  const searchName = useAppSelector(selectRegionInsightSearch)
  const [changeTab] = useState(0)
  const [openMap, setOpenMap] = useState(false)
  const [openFilter, setOpenFilter] = useState(false)
  const [selectedValue, setSelectedValue] = useState<string>('5')
  const [selectedTabValue, setSelectedTabValue] =
    useState<string>('popularDishes')
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)
  const open = Boolean(anchorEl)

  const handleChange = (event: SelectChangeEvent<unknown>): void => {
    setSelectedValue(event.target.value as string)
    dispatch(setRestaurantMiles(Number(event.target.value as string)))
  }

  const onChangeTab = (item: any): void => {
    setSelectedTabValue(item.value)
  }

  const handleChangeRegionalInsight = (
    event: React.MouseEvent<HTMLElement>,
  ): void => {
    setAnchorEl(event.currentTarget)
  }

  const tabComponentList = [
    <PopularDishesTab
      key="popularDishes"
      openMap={openMap}
      setOpenMap={setOpenMap}
    />,
    <PricingTab key="pricing" />,
    <DietaryAndCuisineTab
      key="dietary"
      openMap={openMap}
      setOpenMap={setOpenMap}
      type="dietary"
    />,
    <DietaryAndCuisineTab
      key="cuisine"
      openMap={openMap}
      setOpenMap={setOpenMap}
      type="cuisines"
    />,
    <IngredientTab key="ingredientSuggestions" />,
    <RegionalInsightsTab key="knowYourNeighbourhood" />,
  ]

  return (
    <>
      <CompetitorInsightsMainBox>
        <CompetitorInsightsTitleBox>
          <Typography
            variant="body2"
            color={theme.palette.grey[800]}
            fontWeight={700}
            py="7px"
          >
            Competitor Insights
          </Typography>
          {!isMediumScreen && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              width={
                selectedTabValue === 'knowYourNeighbourhood' ? '200px' : '300px'
              }
            >
              {selectedTabValue !== 'knowYourNeighbourhood' && (
                <Typography
                  noWrap
                  variant="subtitle1"
                  color={theme.palette.grey[500]}
                  fontWeight={500}
                  minWidth="78px"
                >
                  Restaurant
                </Typography>
              )}

              {selectedTabValue !== 'knowYourNeighbourhood' ? (
                <SelectBox
                  id="miles"
                  value={selectedValue}
                  onChange={handleChange}
                  options={[
                    { value: '5', label: 'Under 5 miles' },
                    { value: '10', label: 'Under 10 miles' },
                    { value: '15', label: 'Under 15 miles' },
                    { value: '20', label: 'Under 20 miles' },
                    { value: '25', label: 'Under 25 miles' },
                  ]}
                />
              ) : (
                <Box
                  aria-describedby={open ? 'simple-popover' : undefined}
                  onClick={handleChangeRegionalInsight}
                  sx={{
                    cursor: 'pointer',
                    border: '1px solid',
                    borderColor: theme.palette.grey[300],
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    borderRadius: '8px',
                    pl: '8px',
                    pr: '8px',
                  }}
                >
                  <Typography variant="subtitle1" noWrap>
                    {searchName.length > 0 ? searchName : 'Search Location'}
                  </Typography>
                </Box>
              )}
            </Box>
          )}

          {selectedTabValue !== 'pricing' && isMediumScreen && (
            <Box display="flex" alignItems="center" gap="8px">
              {selectedTabValue !== 'ingredientSuggestions' &&
                selectedTabValue !== 'knowYourNeighbourhood' && (
                  <CompetitorInsightsButton
                    icon={<IconMapSearch stroke={2} size={18} />}
                    action={() => setOpenMap(true)}
                  />
                )}
              <CompetitorInsightsButton
                icon={<IconAdjustments stroke={2} size={18} />}
                action={() => setOpenFilter(true)}
              />
            </Box>
          )}
        </CompetitorInsightsTitleBox>
        <Box>
          <ResactlyTab
            list={tabList}
            tabComponent={tabComponentList}
            align="space-between"
            changeTab={changeTab}
            onChangeTab={onChangeTab}
          />
        </Box>
        <Modal
          open={openFilter}
          onClose={() => setOpenFilter(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <ModelMainBox>
            <RangAndFilterDialog selectedTabName={selectedTabValue} />
          </ModelMainBox>
        </Modal>
      </CompetitorInsightsMainBox>
      <RestaurantSearchPopover
        open={open}
        anchorEl={anchorEl}
        handleClose={() => setAnchorEl(null)}
      />
    </>
  )
}

export default CompetitorInsights

const tabList = [
  {
    label: 'Popular Dishes',
    value: 'popularDishes',
  },
  {
    label: 'Pricing',
    value: 'pricing',
  },
  {
    label: 'Dietary',
    value: 'dietary',
  },
  {
    label: 'Cuisine',
    value: 'cuisine',
  },
  {
    label: 'Ingredient suggestions',
    value: 'ingredientSuggestions',
  },
  {
    label: 'Know Your Neighbourhood',
    value: 'knowYourNeighbourhood',
  },
]
