import { useTheme, useMediaQuery, Theme } from '@mui/material'
import { useAppDispatch } from '../store'
import { useLocation, useNavigate } from 'react-router'

interface CustomHookReturn {
  theme: Theme
  isSmallScreen: boolean
  isMediumScreen: boolean
  dispatch: ReturnType<typeof useAppDispatch>
  navigate: ReturnType<typeof useNavigate>
  location: ReturnType<typeof useLocation>
}

const useAppUtilities = (): CustomHookReturn => {
  const theme = useTheme()
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useAppDispatch()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'))

  return { theme, dispatch, location, navigate, isSmallScreen, isMediumScreen }
}

export default useAppUtilities
