import { Box, Typography, useTheme } from '@mui/material'
import styled from 'styled-components'
import CustomButton from '../../../../components/ui/CustomButton'

export const CompetitorInsightsMainBox = styled(Box).attrs(() => {
  const theme = useTheme()
  return {
    sx: {
      bgcolor: theme.palette.common.white,
      borderRadius: '12px',
      border: `2px solid ${theme.palette.grey[200]}`,
    },
  }
})(() => ({}))

export const CompetitorInsightsTitleBox = styled(Box).attrs(() => {
  const theme = useTheme()
  return {
    p: { xs: '12px 14px', md: '16px' },
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    display: 'flex',
    justifyContent: 'space-between',
  }
})(() => ({}))

export const CompetitorInsightsSelectOption = styled(Typography).attrs(() => {
  const theme = useTheme()
  return {
    noWrap: true,
    component: 'p',
    variant: 'subtitle1',
    color: theme.palette.grey[800],
  }
})(() => ({}))

export const CompetitorInsightsButton = styled(CustomButton).attrs(() => {
  const theme = useTheme()
  return {
    textColor: theme.palette.grey[800],
    backgroundColor: theme.palette.grey[50],
    hoverColor: theme.palette.grey[400],
    sx: {
      borderColor: theme.palette.grey[300],
      px: 0,
      minWidth: '32px',
      height: '32px',
      borderRadius: '6px',
    },
  }
})(() => ({}))
