import { Box, Typography, useTheme } from '@mui/material'
import { IconChevronLeft } from '@tabler/icons-react'
import styled from 'styled-components'
import ImgIcon from '../../../../../../components/ui/ImgIcon'
import React from 'react'

export const DashboardSimilarDishDetailMainBox = styled(Box).attrs(() => {
  return {
    className: 'hide-scrollbar',
    sx: {
      position: 'relative',
      paddingY: '5px',
      paddingTop: '10px',
      overflow: 'scroll',
      maxHeight: { xs: '469px', md: 350 },
    },
  }
})(() => ({}))

export const BackButton = styled(ImgIcon).attrs(() => {
  const theme = useTheme()
  return {
    iconElement: (
      <IconChevronLeft
        stroke={2}
        color={theme.palette.common.white}
        size={18}
      />
    ),
    alt: 'back-navigation',
    wrapperStyle: {
      backgroundColor: '#0000004D',
      height: '22px',
      width: '22px',
      marginTop: '10px',
      marginBottom: '20px',
    },
  }
})(() => ({}))

export const DashboardSimilarDishDetailTypography = styled(Typography).attrs(
  () => {
    const theme = useTheme()
    return {
      variant: 'subtitle1',
      fontWeight: 400,
      color: theme.palette.grey[600],
      sx: { marginTop: '2px' },
    }
  },
)(() => ({}))
