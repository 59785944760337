import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Popover,
  Rating,
  Typography,
} from '@mui/material'
import { IconDotsVertical, IconStarFilled } from '@tabler/icons-react'
import moment from 'moment'
import React, { useState } from 'react'
import { ROUTES } from '../../helpers/routes-helper'
import useAppUtilities from '../../hooks/useAppUtilities'
import {
  setMessage,
  setOpen,
  setRestaurantReviews,
  setSeverity,
} from '../../store/profileSlice'
import { deleteRestaurantReview } from '../../store/restaurantSlice'
import { IRestaurantReview } from '../../types'

interface IReviewProps {
  review: IRestaurantReview
}

const ProfileReview: React.FC<IReviewProps> = ({ review }) => {
  const { theme, navigate, dispatch } = useAppUtilities()
  const [loading, setLoading] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [value, setValue] = useState<number | null>(review?.starCount ?? 0)

  const openPop = Boolean(anchorEl)
  const id = openPop ? 'simple-popover' : undefined

  const handleClose = (): void => {
    setAnchorEl(null)
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const onDeleteRestaurantReview = async (): Promise<void> => {
    setLoading(true)
    const result = await dispatch(
      deleteRestaurantReview(review.restaurantReviewId),
    )
    if (deleteRestaurantReview.fulfilled.match(result)) {
      dispatch(setRestaurantReviews(result.payload.restaurantReviews))
      dispatch(setMessage(`Restaurant review has been delete successfully.`))
      dispatch(setOpen(true))
    }
    if (deleteRestaurantReview.rejected.match(result)) {
      dispatch(
        setMessage(
          `An error occurred while delete the restaurant review. Please try again later.`,
        ),
      )
      dispatch(setSeverity('error'))
      dispatch(setOpen(true))
    }
    setLoading(false)
  }

  return (
    <Box
      position="relative"
      sx={{
        width: '100%',
        height: '100%',
      }}
    >
      <Box
        sx={{
          padding: '16px',
          border: `1px solid ${theme.palette.grey[300]}`,
          borderRadius: '16px',
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'start',
          flexDirection: 'column',
          filter: loading ? 'blur(2px)' : 'none',
        }}
      >
        <div
          style={{
            width: '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'start',
              position: 'relative',
              width: '100%',
            }}
          >
            <Avatar
              alt="Profile Image"
              sx={{
                width: { xs: 60, md: 80 },
                height: { xs: 60, md: 80 },
                borderRadius: '8px',
                boxShadow: '0px 0px 2px 0px #1A1A1A40',
              }}
              src={review?.imageUrl ?? '/logoLetter-resize.svg'}
              variant="square"
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'self-start',
                flexDirection: 'column',
                paddingLeft: '16px',
              }}
            >
              <div>
                <Typography
                  component="p"
                  variant="body2"
                  color={theme.palette.grey[800]}
                  fontWeight={{
                    xs: 500,
                    md: 600,
                  }}
                >
                  {review?.restaurantName ?? ''}
                </Typography>
                <Typography
                  component="p"
                  variant="subtitle2"
                  color={theme.palette.grey[400]}
                >
                  {moment(review?.created).format('DD MMM, YYYY')}
                </Typography>
              </div>
              <Rating
                name="simple-controlled"
                value={value}
                readOnly
                size="small"
                color={theme.palette.warning[400]}
                onChange={(event, newValue) => {
                  setValue(newValue)
                }}
                sx={{
                  '& .MuiRating-icon': {
                    margin: '0 1px',
                  },
                  '& .MuiRating-iconFilled': {
                    color: theme.palette.warning.light,
                  },
                  '& .MuiRating-iconHover': {
                    color: theme.palette.warning[300],
                  },
                }}
                icon={<IconStarFilled size={14} />}
                emptyIcon={
                  <IconStarFilled size={14} color={theme.palette.grey[200]} />
                }
              />
            </div>

            <Button
              aria-describedby={id}
              sx={{
                p: '0px',
                minWidth: '16px',
                position: 'absolute',
                right: '0px',
                top: '0px',
                cursor: 'pointer',
                color: theme.palette.grey[400],
                fontSize: '1.5rem',
              }}
              onClick={handleClick}
              variant="text"
            >
              <IconDotsVertical size={16} stroke={2} />
            </Button>
            <Popover
              id={id}
              open={openPop}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              sx={{
                '& .MuiPaper-root': {
                  borderRadius: '8px',
                },
              }}
            >
              <div
                style={{
                  padding: '8px',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'start',
                    alignItems: 'center',
                    padding: '8px',
                    color: theme.palette.grey[800],
                    cursor: 'pointer',
                  }}
                  onClick={() =>
                    navigate(
                      `${ROUTES.RESTAURANT_DETAILS}/${review.restaurantAlias}/details?name=review&mode_type=edit`,
                    )
                  }
                >
                  <Typography noWrap component="p" variant="subtitle2">
                    Edit
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'start',
                    alignItems: 'center',
                    padding: '8px',
                    color: theme.palette.error.dark,
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    void onDeleteRestaurantReview()
                  }}
                >
                  <Typography noWrap component="p" variant="subtitle2">
                    Delete
                  </Typography>
                </Box>
              </div>
            </Popover>
          </div>
          <div>
            <Typography
              component="p"
              variant="subtitle1"
              sx={{
                py: '16px',
              }}
              color={theme.palette.grey[700]}
            >
              {review?.review}
            </Typography>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
            gap: '5px',
            width: '100%',
          }}
        >
          {review?.images?.slice(0, 3).map((item, index) => (
            <Box
              key={index}
              position="relative"
              sx={{
                width: { xs: 60, md: 80 },
                height: { xs: 60, md: 80 },
                borderRadius: '8px',
                overflow: 'hidden',
              }}
            >
              <Avatar
                alt="Profile review"
                sx={{
                  width: '100%',
                  height: '100%',
                  borderRadius: '8px',
                  filter: index === 2 ? 'blur(2px)' : 'none',
                }}
                src={item.url ?? '/logoLetter-resize.svg'}
                variant="square"
              />
              {index === 2 && review?.images && (
                <Box
                  position="absolute"
                  top={0}
                  left={0}
                  width="100%"
                  height="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  bgcolor="rgba(0, 0, 0, 0.5)"
                >
                  <Typography
                    variant="subtitle1"
                    color="white"
                    sx={{
                      textAlign: 'center',
                      fontSize: '12px',
                      fontWeight: 'bold',
                    }}
                  >
                    {review?.images?.length - 2} More
                  </Typography>
                </Box>
              )}
            </Box>
          ))}
        </div>
      </Box>

      {loading && (
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      )}
    </Box>
  )
}

export default ProfileReview
