import React from 'react'
import useAppUtilities from '../../../../hooks/useAppUtilities'
import {
  DownloadAppBoxContainer,
  DownloadAppContainer,
  DownloadAppInnerContainer,
  DownloadAppInnerGrid,
  DownloadAppMainContainer,
  DownloadAppMainGrid,
  DownloadAppSideImg,
  DownloadAppTypographySubTitle,
  DownloadAppTypographyTitle,
} from '../styled/DownloadApp.styled'
import DownloadAddStoreButton from './DownloadAddStoreButton'

const DownloadApp: React.FC = () => {
  const { isSmallScreen, isMediumScreen } = useAppUtilities()

  return (
    <DownloadAppMainContainer>
      <DownloadAppBoxContainer>
        <DownloadAppContainer>
          <DownloadAppInnerContainer>
            <div>
              <DownloadAppMainGrid>
                <DownloadAppInnerGrid>
                  <DownloadAppTypographyTitle variant="h2">
                    Download our app for the <br />
                    best dining experience
                  </DownloadAppTypographyTitle>
                  <DownloadAppTypographySubTitle variant="subtitle1">
                    Browse menus, get recommendations and directions all in one
                    convenient place.
                    <br />
                    Download our app today for an elevated <br /> dining
                    adventure!
                  </DownloadAppTypographySubTitle>
                  <DownloadAddStoreButton
                    height={isSmallScreen ? '24px' : '40px'}
                    width={isSmallScreen ? '82px' : '139px'}
                  />
                </DownloadAppInnerGrid>
                <DownloadAppInnerGrid>
                  <DownloadAppSideImg
                    src="/new/images/downloadApp.png"
                    alt="downloadApp"
                    isMediumScreen={isMediumScreen}
                    isSmallScreen={isSmallScreen}
                  />
                </DownloadAppInnerGrid>
              </DownloadAppMainGrid>
            </div>
          </DownloadAppInnerContainer>
        </DownloadAppContainer>
      </DownloadAppBoxContainer>
    </DownloadAppMainContainer>
  )
}

export default DownloadApp
