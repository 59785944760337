import { Box, Popover } from '@mui/material'
import React from 'react'
import RestaurantSearch from './RestaurantSearch'

interface RestaurantSearchPopoverProps {
  open: boolean
  anchorEl: Element | null
  handleClose: () => void
}

const RestaurantSearchPopover: React.FC<RestaurantSearchPopoverProps> = ({
  anchorEl,
  handleClose,
  open,
}) => {
  return (
    <Popover
      id={open ? 'simple-popover' : undefined}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      sx={{
        '& .MuiPaper-root': {
          marginTop: 1,
          width: 285,
          borderRadius: '8px',
        },
      }}
    >
      <Box sx={{ p: 1 }}>
        <RestaurantSearch onClose={handleClose} />
      </Box>
    </Popover>
  )
}

export default RestaurantSearchPopover
