import { Box, Typography } from '@mui/material'
import React from 'react'

const MessageDisplay: React.FC<{ text: string }> = ({ text }) => {
  const formatText = (text: string): JSX.Element[] => {
    return text.split('\n').map((line, index) => (
      <Typography
        key={index}
        variant="subtitle2"
        lineHeight={1.5}
        letterSpacing={0.4}
        sx={{ display: 'block', mt: 1 }}
      >
        {formatBoldText(line)}
      </Typography>
    ))
  }

  const formatBoldText = (line: string): JSX.Element[] => {
    const parts = line.split(/(\*\*.*?\*\*)/)

    return parts.map((part, idx) => {
      if (part.match(/\*\*(.*?)\*\*/)) {
        return <strong key={idx}>{part.replace(/\*\*/g, '')}</strong>
      }
      return <span key={idx}>{part}</span>
    })
  }

  return <Box sx={{ typography: 'subtitle2' }}>{formatText(text)}</Box>
}

export default MessageDisplay
