import { Restaurant } from '../types'

export const milesToKilometers = (miles: any): any => {
  const conversionFactor = 1.60934

  // Convert miles to kilometers
  const kilometers = miles * conversionFactor
  return kilometers
}
export const restaurantTimezone = (restaurant: Restaurant | undefined): any => {
  const res = restaurant?.weekdayText?.map((entry) => {
    const [day, hours] = entry.split(': ')
    const timeRanges = hours.split(' – ')
    return {
      day,
      time:
        hours === 'Closed' ? [hours] : [`${timeRanges[0]} - ${timeRanges[1]}`],
    }
  })

  return res
}

export const calculateDistance = (
  currentLat: number,
  currentLong: number,
  latitude: number,
  longitude: number,
): string => {
  const toRad = (value: number): number => (value * Math.PI) / 180
  const R = 3958.8 // Radius of Earth in miles
  const dLat = toRad(latitude - currentLat)
  const dLon = toRad(longitude - currentLong)
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRad(currentLat)) *
      Math.cos(toRad(latitude)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  return `${(R * c).toFixed(2)} mi`
}
