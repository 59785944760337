import { Box } from '@mui/material'
import styled from 'styled-components'

export const ModelMainBox = styled(Box).attrs(() => {
  return {
    sx: {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      bgcolor: 'background.paper',
      boxShadow: 24,
      borderRadius: '16px',
      width: 'calc(100% - 20px)',
    },
  }
})(() => ({}))
