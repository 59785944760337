import {
  Avatar,
  AvatarGroup,
  Box,
  CircularProgress,
  MenuItem,
} from '@mui/material'
import { IconDotsVertical, IconUsers } from '@tabler/icons-react'
import React, { useState } from 'react'
import BasicModal from '../../../../components/modal/BasicModal'
import { ROUTES } from '../../../../helpers/routes-helper'
import useAppUtilities from '../../../../hooks/useAppUtilities'
import {
  acceptInvitation,
  declineInvitation,
  deleteGroup,
  setGroup,
} from '../../../../store/socialSlice'
import { Group, GroupRequest } from '../../../../types/groupTypes'
import {
  SocialGroupBadge,
  SocialGroupBasicModalTitleTypography,
  SocialGroupChip,
  SocialGroupCustomButton,
  SocialGroupDivider,
  SocialGroupGroupRequestsContainer,
  SocialGroupGroupRequestsRightContainer,
  SocialGroupIconPointFilled,
  SocialGroupInnerContainer,
  SocialGroupLeftContainer,
  SocialGroupMemberListTypography,
  SocialGroupMenu,
  SocialGroupMenuItemTypography,
  SocialGroupsIconDotsVerticalButton,
  SocialGroupSkeleton,
  SocialGroupsRightContainer,
  SocialGroupsRightInnerContainer,
  SocialGroupTitleContainer,
  SocialGroupTitleTypography,
  SocialGroupUserCountTypography,
  SocialGroupUserTitle,
} from '../styled/SocialGroup.styled'
import GroupAddEditDialog from './GroupAddEditDialog'

interface ISocialGroupProp {
  groups: Group[]
  groupRequests: GroupRequest[]
  isLoader: boolean
  page: number
}

const SocialGroup: React.FC<ISocialGroupProp> = ({
  groups,
  groupRequests,
  isLoader = false,
  page,
}) => {
  const { theme, navigate, dispatch, isSmallScreen } = useAppUtilities()
  const [modalOpen, setModalOpen] = useState(false)
  const [loadingIndex, setLoadingIndex] = useState(0)
  const [staticLoader, setStaticLoader] = useState(false)
  const [action, setAction] = useState<string | null>(null)
  const [isGroupDelete, setIsGroupDelete] = useState(false)
  const [isOwner, setIsOwner] = React.useState<boolean>(false)
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [selectedGroup, setSelectedGroup] = useState<Group | null>(null)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [loadingIndexName, setLoadingIndexName] = useState<
    'accept' | 'decline'
  >('accept')
  const open = Boolean(anchorEl)

  const onPopupOpen = (
    event: React.MouseEvent<HTMLButtonElement>,
    group: Group,
  ): void => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
    setSelectedGroup(group)
    const owner = group.members?.find((member) => member.owner && member.me)
    if (owner) setIsOwner(true)
    else setIsOwner(false)
  }

  const handleModelOpen = (name: string): void => {
    setAnchorEl(null)
    setAction(name)
    if (name === 'delete') {
      setIsGroupDelete(true)
    } else {
      setModalOpen(true)
    }
  }

  const copyCurrentUrl = async (groupId: string): Promise<void> => {
    const domain = process.env.REACT_APP_DOMAIN // Get the domain from the environment variable
    const currentPath = window.location.pathname // Get the current path dynamically

    if (!domain) {
      console.error(
        'REACT_APP_DOMAIN is not defined in the environment variables.',
      )
      return
    }

    const urlToCopy = `${domain}${currentPath}/${groupId}`
    await navigator.clipboard.writeText(urlToCopy)
    setAnchorEl(null)
    setModalOpen(false)
  }

  const onDelete = async (): Promise<void> => {
    if (action === 'delete' && selectedGroup?.id) {
      setStaticLoader(true)
      const result = await dispatch(deleteGroup(selectedGroup.id))

      if (deleteGroup.fulfilled.match(result)) {
        dispatch(
          setGroup(groups.filter((group) => group.id !== selectedGroup.id)),
        )
      }
      setStaticLoader(false)
      setIsGroupDelete(false)
    }
  }

  const onHandleModalClose = (): void => setIsGroupDelete(false)

  const onAccept = async (groupId: string, index: number): Promise<void> => {
    setIsLoading(true)
    setLoadingIndex(index)
    setLoadingIndexName('accept')
    await dispatch(acceptInvitation({ groupId }))
    setIsLoading(false)
  }
  const onDecline = async (groupId: string, index: number): Promise<void> => {
    setIsLoading(true)
    setLoadingIndex(index)
    setLoadingIndexName('decline')
    await dispatch(declineInvitation({ groupId }))
    setIsLoading(false)
  }

  return (
    <>
      <Box>
        {groupRequests?.map((group, index) => (
          <SocialGroupGroupRequestsContainer key={index}>
            <SocialGroupInnerContainer>
              <SocialGroupLeftContainer>
                <SocialGroupTitleContainer>
                  <SocialGroupTitleTypography>
                    {group.groupName}
                  </SocialGroupTitleTypography>
                  <SocialGroupChip key={index} label="New Invite" />
                </SocialGroupTitleContainer>
                <SocialGroupUserTitle>
                  <IconUsers size={18} stroke={2} />
                  <SocialGroupUserCountTypography>
                    {group?.memberCount}
                  </SocialGroupUserCountTypography>
                </SocialGroupUserTitle>
              </SocialGroupLeftContainer>
              <SocialGroupGroupRequestsRightContainer>
                <SocialGroupCustomButton
                  title="Accept invitation"
                  onClick={() => {
                    void onAccept(group.groupId, index)
                  }}
                  disabled={isLoading && index === loadingIndex}
                  icon={
                    isLoading &&
                    index === loadingIndex &&
                    loadingIndexName === 'accept' ? (
                      <CircularProgress color="inherit" size={16} />
                    ) : null
                  }
                />
                <SocialGroupCustomButton
                  title="Decline"
                  onClick={() => {
                    void onDecline(group.groupId, index)
                  }}
                  disabled={isLoading && index === loadingIndex}
                  icon={
                    isLoading &&
                    index === loadingIndex &&
                    loadingIndexName === 'decline' ? (
                      <CircularProgress color="inherit" size={16} />
                    ) : null
                  }
                />
              </SocialGroupGroupRequestsRightContainer>
            </SocialGroupInnerContainer>
            <SocialGroupDivider />
          </SocialGroupGroupRequestsContainer>
        ))}
        {groups?.map((group) => (
          <SocialGroupGroupRequestsContainer
            key={group.id}
            onClick={() => navigate(`${ROUTES.SOCIAL}/${group.id as string}`)}
          >
            <SocialGroupInnerContainer>
              <SocialGroupLeftContainer>
                <SocialGroupTitleContainer>
                  <SocialGroupTitleTypography>
                    {group.name}
                  </SocialGroupTitleTypography>
                  <SocialGroupBadge badgeContent={group.unreadMessageCount} />
                </SocialGroupTitleContainer>
                <SocialGroupUserTitle>
                  <IconUsers size={18} stroke={2} />
                  <SocialGroupUserCountTypography>
                    {group?.members?.length}
                  </SocialGroupUserCountTypography>
                  <SocialGroupIconPointFilled />
                  <SocialGroupMemberListTypography>
                    {group?.members
                      ?.slice(0, isSmallScreen ? 2 : 5)
                      ?.map((member) => member.name)
                      .join(', ')}
                  </SocialGroupMemberListTypography>
                </SocialGroupUserTitle>
              </SocialGroupLeftContainer>
              <SocialGroupsRightContainer>
                {Array(group.members?.length)}
                {!isSmallScreen && (
                  <AvatarGroup max={5} spacing={13}>
                    {group?.members?.map((_, index) => (
                      <Avatar
                        key={index}
                        alt={`group-profile-${index}`}
                        src={
                          'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQmJUeQCIV5gK-gudX5l3OIhRcmgnbtGDhExw&s'
                        }
                      />
                    ))}
                  </AvatarGroup>
                )}
                <SocialGroupsRightInnerContainer>
                  <SocialGroupsIconDotsVerticalButton
                    onClick={(e) => onPopupOpen(e, group)}
                  >
                    <IconDotsVertical stroke={2} />
                  </SocialGroupsIconDotsVerticalButton>
                </SocialGroupsRightInnerContainer>
              </SocialGroupsRightContainer>
            </SocialGroupInnerContainer>
            <SocialGroupDivider />
          </SocialGroupGroupRequestsContainer>
        ))}
        {isLoader && <SocialGroupSkeleton />}
        <SocialGroupMenu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={() => setAnchorEl(null)}
        >
          <MenuItem onClick={() => selectedGroup && handleModelOpen('edit')}>
            <SocialGroupMenuItemTypography>
              Edit group
            </SocialGroupMenuItemTypography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              void copyCurrentUrl(selectedGroup?.id?.toString() ?? '')
            }}
          >
            <SocialGroupMenuItemTypography>
              Copy group link
            </SocialGroupMenuItemTypography>
          </MenuItem>
          {isOwner && (
            <MenuItem
              onClick={() => selectedGroup && handleModelOpen('delete')}
            >
              <SocialGroupMenuItemTypography color={theme.palette.error.dark}>
                Delete group
              </SocialGroupMenuItemTypography>
            </MenuItem>
          )}
        </SocialGroupMenu>
      </Box>
      <BasicModal
        open={isGroupDelete}
        onCancel={() => setIsGroupDelete(false)}
        onConfirm={() => {
          void onDelete()
        }}
        title={'Delete Group?'}
        cancelButtonName="Cancel"
        buttonName={'Delete'}
        handleClose={onHandleModalClose}
        cancelIcon={true}
        modelWidth="444px"
        isLoader={staticLoader}
      >
        <div>
          <SocialGroupBasicModalTitleTypography>
            Are you sure you want to delete the group? This action cannot be
            undone.
          </SocialGroupBasicModalTitleTypography>
        </div>
      </BasicModal>
      <GroupAddEditDialog
        groupAction="edit"
        setIsModalOpen={setModalOpen}
        openModel={modalOpen}
        selectedGroup={selectedGroup}
      ></GroupAddEditDialog>
    </>
  )
}

export default SocialGroup
