import { Box, Drawer, Typography, useTheme } from '@mui/material'
import styled from 'styled-components'

export const ChipMainBox = styled(Box).attrs(() => {
  return {
    display: 'flex',
    alignItems: 'center',
    p: { xs: '12px', md: '16px' },
  }
})(() => ({}))
export const FilterTypography = styled(Typography).attrs(() => {
  const theme = useTheme()
  return {
    variant: 'subtitle1',
    color: theme.palette.grey[500],
    fontWeight: 400,
    pr: 1,
  }
})(() => ({}))
export const PopularDishesDrawer = styled(Drawer).attrs(() => {
  return {
    anchor: 'bottom',
    PaperProps: {
      style: {
        borderTopLeftRadius: '12px',
        borderTopRightRadius: '12px',
        paddingBottom: '5px',
      },
    },
  }
})(() => ({}))
