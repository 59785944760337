import { Box, Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import {
  ImageTabImages,
  ImageTabTitleTypography,
  ImageTagBottomBox,
  ImageTagFirstTitleBox,
  ImageTagSecondTitleBox,
  StyledIconPointFilled,
  StyledTypography,
} from '../styled/ImagesTab.styled'
import {
  addRestaurantImageByIndex,
  addRestaurantMainImage,
  deleteRestaurantImageByIndex,
  deleteRestaurantMainImage,
  selectRestaurant,
} from '../../../../store/restaurant/setting'
import { useAppSelector } from '../../../../store'
import useAppUtilities from '../../../../hooks/useAppUtilities'
import { IRestaurantAddMainImageRequest } from '../../../../types/restaurantSettingType'

interface imgListType {
  id: number
  url: string | null
}

const ImagesTab: React.FC = (): JSX.Element => {
  const { dispatch } = useAppUtilities()
  const restaurant = useAppSelector(selectRestaurant)
  const [mainImage, setMainImage] = useState<imgListType>({ id: 4, url: null })
  const [images, setImages] = useState<imgListType[]>([
    { id: 0, url: null },
    { id: 1, url: null },
    { id: 2, url: null },
    { id: 3, url: null },
  ])

  useEffect(() => {
    if (restaurant?.restaurantImages) {
      setImages((prevImages) =>
        prevImages.map((image, index) => {
          const matchingImage = restaurant?.restaurantImages?.find(
            (img) => Number(img?.order) === image?.id,
          )
          return {
            ...image,
            url: matchingImage?.url ?? null,
          }
        }),
      )
     
    }
    setMainImage((preImage)=>{
      return {
        ...preImage,
        url: restaurant?.imageurl ?? null
      }
    })
  }, [restaurant])

  const uploadImage = async (
    index: number,
    file: File | null,
  ): Promise<void> => {
    if (file) {
      await dispatch(
        addRestaurantImageByIndex({
          id: restaurant?.restaurantID ?? '',
          file,
          index: index.toString(),
        }),
      )
    } else {
      await dispatch(
        deleteRestaurantImageByIndex({
          id: restaurant?.restaurantID ?? '',
          index: index.toString(),
        }),
      )
    }
  }

  const uploadImageMain = async (
    index: number,
    file: File | null,
  ): Promise<void> => {
    if (file) {
      const payload: IRestaurantAddMainImageRequest = {
        alias: restaurant?.alias ?? '',
        file,
      }
      await dispatch(addRestaurantMainImage(payload))
    } else {
      await dispatch(
        deleteRestaurantMainImage({
          alias: restaurant?.alias ?? '',
        }),
      )
    }
  }

  return (
    <Box>
      <ImageTabTitleTypography>Restaurant Images</ImageTabTitleTypography>
      <Grid container spacing={1} minHeight={{ xs: '75vw', md: '20vw' }}>
        <Grid item xs={12} md={6}>
          <ImageTabImages
            id={mainImage.id}
            index={0}
            uploadImage={(inx, file) => {
              void uploadImageMain(inx, file)
            }}
            imgUrl={mainImage.url}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={1} sx={{ height: 'calc(100% + 8px)' }}>
            {images.map((image, index) => (
              <Grid item xs={6} key={image?.id} height={'50%'}>
                <ImageTabImages
                  id={image?.id}
                  index={index}
                  uploadImage={(inx, file) => {
                    void uploadImage(inx, file)
                  }}
                  imgUrl={image?.url}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <ImageTagBottomBox>
        <ImageTagFirstTitleBox>
          <StyledIconPointFilled />
          <StyledTypography>
            First image will also be the cover of the restaurant
          </StyledTypography>
        </ImageTagFirstTitleBox>
        <ImageTagSecondTitleBox>
          <StyledIconPointFilled />
          <StyledTypography>Drag images to move position</StyledTypography>
        </ImageTagSecondTitleBox>
      </ImageTagBottomBox>
    </Box>
  )
}

export default ImagesTab
