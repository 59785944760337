import { Box, Skeleton } from '@mui/material'
import { Inbox, useSession } from '@talkjs/react'
import React, { useEffect, useState } from 'react'
import { ThemeOptions } from 'talkjs/all'
import EmptyScreen from '../../../../components/EmptyScreen'

const RestaurantMessageTab: React.FC = () => {
  const session = useSession()
  const [isSession, setIsSession] = useState(false)

  useEffect(() => {
    if (!session?.isAlive) {
      setIsSession(true)
    }
  }, [session?.isAlive])
  const customTheme: string | ThemeOptions = {
    custom: {
      mailIcon:
        'data:image/svg+xml,%3Csvg%20%20xmlns=%22http://www.w3.org/2000/svg%22%20%20width=%2224%22%20%20height=%2224%22%20%20viewBox=%220%200%2024%2024%22%20%20fill=%22none%22%20%20stroke=%22grey%22%20%20stroke-width=%222%22%20%20stroke-linecap=%22round%22%20%20stroke-linejoin=%22round%22%20%20class=%22icon%20icon-tabler%20icons-tabler-outline%20icon-tabler-mail%22%3E%3Cpath%20stroke=%22none%22%20d=%22M0%200h24v24H0z%22%20fill=%22none%22/%3E%3Cpath%20d=%22M3%207a2%202%200%200%201%202%20-2h14a2%202%200%200%201%202%202v10a2%202%200%200%201%20-2%202h-14a2%202%200%200%201%20-2%20-2v-10z%22%20/%3E%3Cpath%20d=%22M3%207l9%206l9%20-6%22%20/%3E%3C/svg%3E',
    },
  }
  return (
    <Box
      sx={{
        width: '100%',
        height: 'calc(100% - 5px)',
        pt: 3,
      }}
    >
      {!isSession ? (
        <Inbox
          style={{ height: '100%', width: '100%' }}
          showFeedHeader={false}
          theme={customTheme}
          loadingComponent={
            <>
              <Skeleton
                variant="rounded"
                sx={{
                  borderRadius: '12px',
                }}
                width="100%"
                height="100%"
              />
            </>
          }
        />
      ) : (
        <EmptyScreen
          mainText="Please wait while we initialize your chat session..."
          secondaryText="Session is loading..."
          imageUrl="/images/empty-screen.svg"
          containerHeight="400px"
        />
      )}
    </Box>
  )
}

export default RestaurantMessageTab
