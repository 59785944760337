import { useTheme } from '@mui/material'
import styled from 'styled-components'
import CustomButton from '../../../../components/ui/CustomButton'
import { IconPencil } from '@tabler/icons-react'
import React from 'react'

export const ViewModeStyleButton = styled(CustomButton).attrs(() => {
  const theme = useTheme()
  return {
    startIcon: <IconPencil size={18} stroke={2} />,
    title: 'Edit',
    sx: {
      backgroundColor: 'transparent',
      borderColor: theme.palette.primary.light,
      color: theme.palette.primary.light,
      py: '8px',
      '& .MuiButton-icon': {
        marginRight: '0px !important',
      },
    },
  }
})(() => ({}))
