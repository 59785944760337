import React, { useEffect, useImperativeHandle, useState } from 'react'
import { listData } from '../../../helpers/onboard-helper'
import { PersonalFavoriteMethods } from '../../../types'
import OnboardPreferenceOptions from './OnboardPreferenceOptions'
import OnboardTitleTypography from './OnboardTitleTypography'
import {
  OnboardPreferencesDisLikeContainer,
  OnboardPreferencesLikeContainer,
} from './styled/OnboardPreferences.styled'

interface OnboardPersonalFavoritesProps {
  key?: number
  title?: string
  subTitle?: string
  secondSubTitle?: string
  firstListName: string
  secondListName: string
  favoritesRestaurant?: string[]
  foodReviewers?: string[]
  firstIndexKey: string
  secondIndexKey: string
  isEditPreference?: boolean
  alignItems?: 'center' | 'right' | 'left'
}

const OnboardPersonalFavorites = React.forwardRef<
  PersonalFavoriteMethods,
  OnboardPersonalFavoritesProps
>(function OnboardSelection(
  {
    title,
    subTitle,
    secondSubTitle,
    firstListName,
    secondListName,
    favoritesRestaurant,
    foodReviewers,
    firstIndexKey,
    secondIndexKey,
    isEditPreference = false,
    alignItems = 'center',
  },
  ref,
): JSX.Element {
  const [firstItemList, setFirstItemList] = useState<string[]>([])
  const [secondItemList, setSecondItemList] = useState<string[]>([])
  const [favoritesRestaurantItems, setFavoritesRestaurantItems] = useState<
    string[]
  >(favoritesRestaurant ?? [])
  const [foodReviewerItems, setFoodReviewerItems] = useState<string[]>(
    foodReviewers ?? [],
  )

  useEffect(() => {
    setFirstItemList(listData[firstListName] || [])
    setSecondItemList(listData[secondListName] || [])
  }, [firstListName, secondListName])

  useImperativeHandle(ref, () => ({
    getPayload,
  }))

  const getPayload = (): {
    favoritesRestaurantItems: string[]
    foodReviewerItems: string[]
  } => ({
    favoritesRestaurantItems,
    foodReviewerItems,
  })

  return (
    <>
      {title && (
        <OnboardTitleTypography
          title={title}
          subTitle="Select all that apply or search to add new"
          isEditPreference={isEditPreference}
          alignItems={alignItems}
        />
      )}
      <OnboardPreferencesLikeContainer>
        <OnboardPreferenceOptions
          title={subTitle}
          chipType="green"
          itemList={firstItemList}
          selectedItems={favoritesRestaurantItems}
          setSelectedItems={setFavoritesRestaurantItems}
          oppositeItems={[]}
          searchIndexKey={firstIndexKey}
          isPreferenceEdit={isEditPreference}
          searchField="name"
          alignItems={alignItems}
        />
      </OnboardPreferencesLikeContainer>
      {secondSubTitle && secondSubTitle?.length > 0 && (
        <OnboardPreferencesDisLikeContainer>
          <OnboardPreferenceOptions
            title={secondSubTitle}
            chipType="green"
            itemList={secondItemList}
            selectedItems={foodReviewerItems}
            setSelectedItems={setFoodReviewerItems}
            oppositeItems={[]}
            searchIndexKey={secondIndexKey}
            isPreferenceEdit={isEditPreference}
            alignItems={alignItems}
          />
        </OnboardPreferencesDisLikeContainer>
      )}
    </>
  )
})

export default OnboardPersonalFavorites
