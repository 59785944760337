import { Avatar, Box, Typography } from '@mui/material'
import { IconRefresh, IconTrash, IconUpload } from '@tabler/icons-react'
import React, {
  ChangeEvent,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import ImgIcon from '../../../../components/ui/ImgIcon'
import useAppUtilities from '../../../../hooks/useAppUtilities'
import {
  Dish,
  IDishAddImage,
  IDishAddRequest,
  IMenuStatus,
  Menu,
} from '../../../../types/menuTypes'
import { useParams } from 'react-router'
import {
  addDishImage,
  addMenuDish,
  deleteDishImage,
  fetchMenuById,
  publishMenuById,
  selectedMenu,
  setOpenStaticMessage,
} from '../../../../store/restaurant/menu'
import { RootState, useAppSelector } from '../../../../store'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import InputComponent from '../../../../components/ui/InputComponent'
import DietaryTagInput from './DietaryTagInput'
import {
  dietaryPreferences,
  listData,
} from '../../../../helpers/onboard-helper'

interface IAddEditDishDialogProps {
  dish?: Dish
  setLoading: (load: boolean) => void
  setIsValid: (valid: boolean) => void
}

export interface dishDialogMethods {
  getPayload: () => Promise<void>
}

const formSchema = z.object({
  name: z.string().min(1, { message: 'Name is required' }),
  price: z.number().positive({ message: 'Price is required' }),
  description: z.string().optional(),
  ingredients: z
    .array(z.string())
    .min(1, { message: 'At least one ingredient required' }),
  suitableDietary: z.array(z.string()).optional(),
  unsuitableDietary: z.array(z.string()).optional(),
  preparation: z
    .array(z.string())
    .min(1, { message: 'At least one preparation step required' }),
})

const AddEditDishDialog = forwardRef<
  dishDialogMethods,
  IAddEditDishDialogProps
>(function AddEditDishDialog({ dish, setLoading, setIsValid }, ref) {
  const { id } = useParams()
  const { theme, dispatch } = useAppUtilities()
  const menu = useAppSelector((state: RootState) => selectedMenu(state))
  const [image, setImage] = useState<string>(dish?.imageurl ?? '')
  const [imageFile, setImageFile] = useState<Blob | undefined>()
  const [isDishLoading, setDishLoading] = useState(false)

  const fileInputRef = useRef<HTMLInputElement>(null)

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    trigger,
    formState: { errors, isValid },
  } = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    defaultValues: {
      name: '',
      price: undefined,
      description: '',
      ingredients: [],
      suitableDietary: [],
      unsuitableDietary: [],
      preparation: [],
    },
  })

  useEffect(() => {
    setIsValid(isValid)
  }, [isValid])

  useEffect(() => {
    if (dish) {
      const ingredients = dish.ingredients.length
        ? dish.ingredients.split(',').map((item) => item.trim())
        : []
      const suitableDietary = dish.dietary.length
        ? dish.dietary.split(',').map((item) => item.trim())
        : []
      const unsuitableDietary = dish.unsuitabledietary.length
        ? dish.unsuitabledietary.split(',').map((item) => item.trim())
        : []

      const preparation = dish.preparation.length
        ? dish.preparation
            .split(',')
            .map((item) => item.trim())
            ?.filter((item) => item !== 'Not Specified')
        : []

      setImage(dish.imageurl ?? '')

      setValue('name', dish.name ?? '')
      setValue('price', dish.price ?? undefined)
      setValue('description', dish.description ?? '')
      setValue('ingredients', ingredients)
      setValue('suitableDietary', suitableDietary)
      setValue('unsuitableDietary', unsuitableDietary)
      setValue('preparation', preparation)

      void trigger().then((isValid) => {
        setIsValid(isValid)
      })
    }
  }, [dish, setValue, trigger])

  const selectedIngredients = watch('ingredients', [])
  const selectedSuitableDietary = watch('suitableDietary', [])
  const selectedUnsuitableDietary = watch('unsuitableDietary', [])
  const selectedPreparation = watch('preparation', [])

  useEffect(() => {
    setLoading(isDishLoading)
  }, [isDishLoading])

  const handleClick = (): void => {
    fileInputRef.current?.click()
  }

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const file = event.target.files?.[0]

    if (!file) return

    const maxSize = 3 * 1024 * 1024 // 3MB
    const allowedTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp']

    if (!allowedTypes.includes(file.type)) {
      alert('Only image files (JPEG, PNG, GIF, WEBP) are allowed.')
      return
    }

    if (file.size > maxSize) {
      alert('File size must be less than 3MB.')
      return
    }

    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0]
      const imageUrl = URL.createObjectURL(file)
      setImageFile(event.target.files[0])
      setImage(imageUrl)
    }
  }

  const handleResetImage = (): void => {
    if (!isDishLoading) {
      setImage('')
      setImageFile(undefined)
      if (fileInputRef.current) {
        fileInputRef.current.value = ''
      }
    }
  }

  const findNewDish = (oldMenu: Menu, newMenu: Menu): Dish | null => {
    const oldDishIDs = new Set(oldMenu.dishes.map((dish) => dish.dishID))

    return newMenu.dishes.find((dish) => !oldDishIDs.has(dish.dishID)) ?? null
  }

  const getPayload = async (): Promise<void> => {
    if (id) {
      await handleSubmit(
        async (data: z.infer<typeof formSchema>): Promise<void> => {
          setDishLoading(true)
          if (dish?.dishID && dish?.dishID.length > 0) {
            const payload: IDishAddRequest = {
              menuId: id,
              description: data?.description ?? '',
              name: data.name,
              dishId: dish.dishID,
              ...(data.price && { price: data.price }),
              chefId: '',
              ...(data?.suitableDietary && {
                dietary: data?.suitableDietary.join(','),
              }),
              ingredients: data?.ingredients.join(','),
              ...(data?.unsuitableDietary && {
                unsuitabledietary: data?.unsuitableDietary.join(','),
              }),
              preparation: data?.preparation.join(','),
              imageurl: dish.imageurl,
            }

            const editDishRes = await dispatch(addMenuDish(payload))
            if (addMenuDish.rejected.match(editDishRes)) {
              return
            }

            const shouldDeleteExistingImage =
              image.length <= 0 && !imageFile && dish?.imageurl?.length > 0
            const shouldReplaceExistingImage =
              dish?.imageurl?.length > 0 && imageFile

            if (shouldDeleteExistingImage || shouldReplaceExistingImage) {
              await dispatch(deleteDishImage({ id: dish.dishID }))
            }

            if (imageFile) {
              const imagePayload: IDishAddImage = {
                dishId: dish.dishID,
                file: imageFile,
              }
              await dispatch(addDishImage(imagePayload))
            }

            const res = await dispatch(fetchMenuById({ id: id ?? '' }))
            if (fetchMenuById.fulfilled.match(res)) {
              dispatch(
                setOpenStaticMessage({
                  message: `${data?.name ?? ''} dish updated successfully.`,
                  severity: 'success',
                  shouldOpen: true,
                  status: 'idle',
                }),
              )
            }
          } else {
            const payload: IDishAddRequest = {
              menuId: id,
              description: data.description,
              name: data.name,
              dishId: '',
              chefId: '',
              ...(data.price && { price: data.price }),
              ...(data?.suitableDietary && {
                dietary: data?.suitableDietary.join(','),
              }),
              ingredients: data?.ingredients.join(','),
              ...(data?.unsuitableDietary && {
                unsuitabledietary: data?.unsuitableDietary.join(','),
              }),
              preparation: data?.preparation.join(','),
            }
            const oldMenu: Menu | undefined = menu

            const addMenuRes = await dispatch(addMenuDish(payload))

            if (addMenuDish.fulfilled.match(addMenuRes)) {
              if (menu?.status === IMenuStatus.NEW) {
                await dispatch(publishMenuById({ menuId: menu.menuID }))
              }

              const res = await dispatch(fetchMenuById({ id: id ?? '' }))
              if (fetchMenuById.fulfilled.match(res) && oldMenu && imageFile) {
                const newAddedDish = findNewDish(oldMenu, res.payload)
                if (newAddedDish) {
                  const imagePayload: IDishAddImage = {
                    dishId: newAddedDish.dishID,
                    file: imageFile,
                  }
                  const imageUrl = await dispatch(addDishImage(imagePayload))
                  if (addDishImage.fulfilled.match(imageUrl)) {
                    setImage(imageUrl.payload.imageUrl)
                    payload.imageurl = imageUrl.payload.imageUrl
                    await dispatch(fetchMenuById({ id: id ?? '' }))
                  }
                }
                dispatch(
                  setOpenStaticMessage({
                    message: `${
                      newAddedDish?.name ?? ''
                    } dish created successfully.`,
                    severity: 'success',
                    shouldOpen: true,
                    status: 'idle',
                  }),
                )
              }
            }
          }
          setDishLoading(false)
        },
      )()
    }
  }

  useImperativeHandle(ref, () => ({ getPayload }), [getPayload])

  return (
    <Box
      className="hide-scrollbar"
      sx={{
        maxHeight: { xs: '60vh' },
        overflow: 'scroll',
        my: 1,
      }}
    >
      <Box my={2}>
        <Box>
          {image ? (
            <Box
              display="flex"
              alignItems="stretch"
              mt={2}
              height="100%"
              gap={2}
            >
              <Avatar
                variant="square"
                src={image}
                sx={{ height: '78px', width: '78px', borderRadius: '8px' }}
              />
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="space-evenly"
              >
                <ImgIcon
                  iconElement={
                    <IconRefresh
                      stroke={2}
                      color={theme.palette.grey[800]}
                      size={16}
                    />
                  }
                  alt="refresh-image"
                  imgSize={18}
                  wrapperStyle={{
                    backgroundColor: theme.palette.grey[50],
                    height: '28px',
                    width: '28px',
                    outline: `1px solid ${theme.palette.grey[300]}`,
                    cursor: 'pointer',
                  }}
                />
                <ImgIcon
                  iconElement={
                    <IconTrash
                      stroke={2}
                      color={theme.palette.grey[800]}
                      size={16}
                    />
                  }
                  alt="delete-image"
                  imgSize={18}
                  wrapperStyle={{
                    backgroundColor: theme.palette.grey[50],
                    height: '28px',
                    width: '28px',
                    outline: `1px solid ${theme.palette.grey[300]}`,
                    cursor: 'pointer',
                  }}
                  onClick={handleResetImage}
                />
              </Box>
            </Box>
          ) : (
            <Box
              textAlign="center"
              sx={{
                border: `1px dashed ${theme.palette.grey[500]}`,
                borderRadius: '12px',
                cursor: 'pointer',
              }}
              py={2}
              mt={2}
              onClick={handleClick}
            >
              <IconUpload
                stroke={2}
                size={18}
                style={{
                  color: theme.palette.grey[500],
                }}
              />
              <Typography
                variant="subtitle1"
                color={theme.palette.grey[500]}
                pt="2.4px"
              >
                Upload Dish Image
              </Typography>
            </Box>
          )}
          <input
            ref={fileInputRef}
            type="file"
            accept="image/*"
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />
        </Box>
      </Box>
      <Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap={2}
          sx={{
            width: '100%',
          }}
        >
          <div
            style={{
              width: '100%',
            }}
          >
            <InputComponent
              id="dish-name-input"
              label="Dish Name"
              disabled={isDishLoading}
              {...register('name')}
              error={!!errors.name}
              helperText={errors.name ? errors.name.message : ' '}
              isFieldRequired
              fullWidth
              placeholder="Type to add or search existing"
              sx={{
                mt: '6px',
              }}
            />
          </div>
          <div
            style={{
              width: '100%',
            }}
          >
            <InputComponent
              id="price-input"
              label="Price"
              disabled={isDishLoading}
              {...register('price', { valueAsNumber: true })}
              error={!!errors.price}
              helperText={errors.price ? 'Price is required' : ' '}
              isFieldRequired
              fullWidth
              placeholder="Eg: 250"
              type="number"
              sx={{
                mt: '6px',
              }}
            />
          </div>
        </Box>
        <Box my={1}>
          <InputComponent
            label="Description"
            id="description-input"
            disabled={isDishLoading}
            placeholder="Eg: Fragrant rice dish layered with spiced meat or vegetables"
            {...register('description')}
            error={!!errors.description}
            helperText={errors.description ? errors.description.message : ' '}
            sx={{
              mt: '6px',
              '& .MuiOutlinedInput-root': {
                backgroundColor: theme.palette.common.white,
              },
            }}
          />
        </Box>
        <Box>
          <DietaryTagInput
            id="ingredients"
            disabled={isDishLoading}
            options={listData.ingredients}
            value={selectedIngredients}
            onChange={(_, value) =>
              setValue('ingredients', [...value], { shouldValidate: true })
            }
            renderInput={(params) => (
              <InputComponent
                {...params}
                label="Ingredients"
                id="ingredients"
                placeholder="Select option"
                error={!!errors.ingredients}
                helperText={
                  errors.ingredients ? errors.ingredients.message : ' '
                }
                isFieldRequired
                sx={{
                  mt: '6px',
                }}
                fullWidth
              />
            )}
          />
        </Box>
        <Box my={1}>
          <DietaryTagInput
            id="preparation-input-1"
            disabled={isDishLoading}
            options={listData.foodPreparations}
            value={selectedPreparation}
            onChange={(_, value) =>
              setValue('preparation', [...value], { shouldValidate: true })
            }
            renderInput={(params) => (
              <InputComponent
                {...params}
                placeholder="Select option"
                id="preparation-input-1"
                label="Preparation"
                error={!!errors.preparation}
                helperText={
                  errors.preparation ? errors.preparation.message : ' '
                }
                isFieldRequired
                sx={{
                  mt: '6px',
                }}
                fullWidth
              />
            )}
          />
        </Box>
        <Box>
          <DietaryTagInput
            id="suitableDietary"
            disabled={isDishLoading}
            options={dietaryPreferences}
            value={selectedSuitableDietary}
            getOptionDisabled={(option) =>
              (selectedUnsuitableDietary ?? []).includes(option)
            }
            onChange={(_, value) => setValue('suitableDietary', value)}
            renderInput={(params) => (
              <InputComponent
                {...params}
                label="Suitable Dietary"
                id="suitableDietary"
                placeholder="Select option"
                error={!!errors.suitableDietary}
                helperText={
                  errors.suitableDietary ? errors.suitableDietary.message : ' '
                }
                sx={{
                  mt: '6px',
                }}
                fullWidth
              />
            )}
          />
          <DietaryTagInput
            id="unsuitableDietary"
            disabled={isDishLoading}
            options={dietaryPreferences}
            value={selectedUnsuitableDietary}
            getOptionDisabled={(option) =>
              (selectedSuitableDietary ?? []).includes(option)
            }
            onChange={(_, value) => setValue('unsuitableDietary', value)}
            renderInput={(params) => (
              <InputComponent
                {...params}
                label="Unsuitable Dietary"
                id="unsuitableDietary"
                placeholder="Select option"
                error={!!errors.unsuitableDietary}
                helperText={
                  errors.unsuitableDietary
                    ? errors.unsuitableDietary.message
                    : ' '
                }
                sx={{
                  mt: '6px',
                }}
                fullWidth
              />
            )}
          />
        </Box>
      </Box>
    </Box>
  )
})

export default AddEditDishDialog
