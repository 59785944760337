import {  AutocompleteProps, Box } from '@mui/material'
import React, { forwardRef } from 'react'
import { AddEditDialogAutoCompleteChip } from '../styled/AddEditDishDialog.styled'
import { DietaryTagInputAutocomplete } from '../styled/DietaryTagInput.styled'

interface IDietaryTagInputProps
  extends AutocompleteProps<string, true, false, false> {
  id: string
}
const DietaryTagInput = forwardRef<HTMLInputElement, IDietaryTagInputProps>(({
  id,
  ...rest
},
ref
) => {
  return (
    <Box my={1}>
      <DietaryTagInputAutocomplete
        id={id}
        size="small"
        {...rest}
        renderTags={(value: readonly string[], getTagProps) =>
          value.map((option: string, index: number) => {
            const { key, ...tagProps } = getTagProps({ index })
            return (
              <AddEditDialogAutoCompleteChip
                key={key}
                label={option}
                {...tagProps}
              />
            )
          })
        }
      />
    </Box>
  )
})
DietaryTagInput.displayName ='DietaryTagInput'
export default DietaryTagInput
