import { styled, Typography, useTheme } from '@mui/material'

export const AboutContainer = styled('div')({
  backgroundSize: '60%,40%',
  backgroundPosition: 'top 15px left 0px, top 0px right 0px',
  backgroundImage: `url('/images/about/background_1.png'), url('/images/about/background_2.png')`,
  backgroundRepeat: 'no-repeat',
})

export const AboutInnerContainer = styled('div')(() => {
  const theme = useTheme()
  return {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
      paddingBottom: '56px',
      paddingTop: '56px',
    },
    [theme.breakpoints.up('sm')]: {
      paddingBottom: '80px',
      paddingTop: '80px',
    },
    [theme.breakpoints.up('md')]: {
      paddingBottom: '112px',
      paddingTop: '112px',
    },
  }
})

export const AboutTitleContainer = styled('div')({
  maxWidth: '835px',
  textAlign: 'center',
})

export const AboutTitleContainerTypography = styled(Typography)(() => {
  const theme = useTheme()
  return {
    color: theme.palette.grey[800],
    [theme.breakpoints.down('sm')]: {
      lineHeight: '36px',
      fontSize: '30px',
    },
    [theme.breakpoints.up('sm')]: {
      lineHeight: '45px',
    },
    [theme.breakpoints.up('md')]: {
      lineHeight: '58px',
    },
  }
})

export const AboutDescriptionContainerTypography = styled(Typography)(() => {
  const theme = useTheme()
  return {
    color: theme.palette.grey[800],
    paddingTop: '20px',
    fontWeight: 500,
  }
})
