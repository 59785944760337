import { KeyboardArrowUp } from '@mui/icons-material'
import { Fab } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useWebLayoutContext } from '../context/WebLayoutContext'
import { MOBILE_APP_HEIGHT } from '../helpers/static-constants'
import { useDevice } from '../hooks/useDevice'

interface ScrollToTopProps {
  scrollableContainerRef: React.RefObject<HTMLDivElement>
}

const ScrollToTop: React.FC<ScrollToTopProps> = ({
  scrollableContainerRef,
}) => {
  const { pathname } = useLocation()
  const [showButton, setShowButton] = useState(false)
  const { setIsScrollBottom } = useWebLayoutContext()
  const { isHybrid } = useDevice()

  useEffect(() => {
    const container = scrollableContainerRef.current

    if (container) {
      container.addEventListener('scroll', handleScroll)
    }
    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll)
      }
    }
  }, [scrollableContainerRef])

  useEffect(() => {
    scrollableContainerRef.current?.scrollTo(0, 0)
  }, [pathname, scrollableContainerRef])

  const handleScroll = (): void => {
    const container = scrollableContainerRef.current
    let bottom = false
    if (container)
      bottom =
        Math.round(container.scrollHeight - container.scrollTop - 10) >=
        container.clientHeight

    setIsScrollBottom(bottom)

    if (container && container.scrollTop > 200) {
      setShowButton(true)
    } else {
      setShowButton(false)
    }
  }

  const scrollToTop = (): void => {
    scrollableContainerRef.current?.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  return (
    <>
      {showButton && (
        <Fab
          color="primary"
          aria-label="scroll back to top"
          onClick={scrollToTop}
          size="small"
          sx={{
            position: 'fixed',
            bottom: isHybrid
              ? `calc(${
                  (MOBILE_APP_HEIGHT as number) + 10
                }px + env(safe-area-inset-bottom))`
              : 16,
            right: 16,
          }}
        >
          <KeyboardArrowUp />
        </Fab>
      )}
    </>
  )
}

export default ScrollToTop
