import { Box, Grid, MenuItem, Typography } from '@mui/material'
import { IconX } from '@tabler/icons-react'
import React, { useEffect, useState } from 'react'
import EmptyScreen from '../../../components/EmptyScreen'
import RestaurantsPageLayout from '../../../components/restaurants/RestaurantsPageLayout'
import useAppUtilities from '../../../hooks/useAppUtilities'
import { useAppSelector } from '../../../store'
import {
  selectMenus,
  selectMessage,
  selectSeverity,
  selectShouldOpen,
  setOpen,
  setSelectedDish,
  setSelectedMenu,
} from '../../../store/restaurant/menu'
import { IMenuStatus, Menu as menuType } from '../../../types/menuTypes'
import AddEditMenuDialog from './components/AddEditMenuDialog'
import AddMenuCard from './components/AddMenuCard'
import MenuCard from './components/MenuCard'
import {
  AddMenuButton,
  HeaderRightBox,
  LoadMenuButton,
  MenuAddMenuButton,
  ShowToasterBox,
  StyledMenuPopup,
} from './styled/Menu.styled'
import { MuiAlert, MuiSnackbar } from '../../eater/styled/global.styled'
import LoadMenuDialog from './components/LoadMenuDialog'

const Menu: React.FC = () => {
  const { theme, isMediumScreen, dispatch } = useAppUtilities()
  const menus = useAppSelector(selectMenus)
  const openServer = useAppSelector(selectShouldOpen)
  const message = useAppSelector(selectMessage)
  const severity = useAppSelector(selectSeverity)
  const [modalOpenEdit, setModalOpenEdit] = useState(false)
  const [openLoadMenuDialog, setOpenLoadMenuDialog] = useState(false)
  const [ShowToaster, setShowToaster] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const isMenuOpen = Boolean(anchorEl)

  useEffect(() => {
    dispatch(setSelectedDish(undefined))
    dispatch(setSelectedMenu(undefined))
  }, [])
  useEffect(() => {
    const findLoadMenu =
      menus?.filter((item) => item.status === IMenuStatus.REQUESTED).length > 0
    if (findLoadMenu) {
      setShowToaster(true)
    }
  }, [menus])

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = (): void => {
    setAnchorEl(null)
  }

  const loadMenu = async (): Promise<void> => {
    handleCloseMenu()
    setOpenLoadMenuDialog(true)
  }

  const addMenu = (): void => {
    handleCloseMenu()
    setModalOpenEdit(true)
  }

  const setOpenServer = (): void => {
    dispatch(setOpen(false))
  }

  return (
    <RestaurantsPageLayout
      title={'Menu'}
      HeaderRight={
        <HeaderRightBox>
          <LoadMenuButton
            onClick={() => {
              void loadMenu()
            }}
          />
          <AddMenuButton onClick={addMenu} />
        </HeaderRightBox>
      }
      body={
        <Box>
          {isMediumScreen && (
            <>
              <MenuAddMenuButton onClick={handleOpenMenu} />
              <StyledMenuPopup
                anchorEl={anchorEl}
                open={isMenuOpen}
                onClose={handleCloseMenu}
              >
                <MenuItem onClick={addMenu}>
                  <Typography
                    variant="subtitle1"
                    sx={{ color: theme.palette.grey[800] }}
                  >
                    Add Menu
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    void loadMenu()
                  }}
                >
                  <Box>
                    <Typography
                      variant="subtitle1"
                      sx={{ color: theme.palette.grey[800] }}
                    >
                      Load Menu
                    </Typography>
                  </Box>
                </MenuItem>
              </StyledMenuPopup>
            </>
          )}
          {ShowToaster && (
            <ShowToasterBox>
              <Typography
                variant="subtitle1"
                color={theme.palette.primary[900]}
              >
                Load menu request has been sent, This might take few days to
                update.
              </Typography>
              <IconX
                stroke={2}
                color={theme.palette.primary[900]}
                style={{ cursor: 'pointer' }}
                onClick={() => setShowToaster(false)}
              />
            </ShowToasterBox>
          )}
          {menus.length > 0 ? (
            <Grid container spacing={[1, 1.5, 2.5]}>
              {menus
                ?.filter(
                  (item) =>
                    item.status === IMenuStatus.NEW ||
                    item.status === IMenuStatus.COMPLETED,
                )
                .map((item: menuType, index: number) => (
                  <Grid item xs={6} sm={4} md={2.4} lg={2} key={index}>
                    <MenuCard menu={item} />
                  </Grid>
                ))}
              <Grid item xs={6} sm={4} md={2.4} lg={2}>
                <AddMenuCard />
              </Grid>
            </Grid>
          ) : (
            <EmptyScreen
              imageUrl="/new/images/empty-menu-img.svg"
              containerHeight={ShowToaster ? '70vh' : '80vh'}
              secondaryText="No menus to display! Click below to create your first one."
            >
              <HeaderRightBox>
                <LoadMenuButton
                  onClick={() => {
                    void loadMenu()
                  }}
                />
                <AddMenuButton onClick={addMenu} />
              </HeaderRightBox>
            </EmptyScreen>
          )}

          <AddEditMenuDialog
            openEditModel={modalOpenEdit}
            setOpenEditModel={setModalOpenEdit}
          />
          <LoadMenuDialog
            openEditModel={openLoadMenuDialog}
            setOpenEditModel={setOpenLoadMenuDialog}
            setShowToaster={setShowToaster}
          />
          <MuiSnackbar open={openServer} onClose={setOpenServer}>
            <MuiAlert onClose={setOpenServer} severity={severity ?? 'success'}>
              {message}
            </MuiAlert>
          </MuiSnackbar>
        </Box>
      }
    />
  )
}

export default Menu
