import { Box, Dialog, Fade, Grid, Skeleton } from '@mui/material'
import { IconEye, IconStar, IconUsers } from '@tabler/icons-react'
import React, { useCallback, useEffect, useState } from 'react'
import useAppUtilities from '../../../hooks/useAppUtilities'
import { useAppSelector } from '../../../store'
import {
  selectRestaurantAlias,
  selectRestaurantLocation,
} from '../../../store/authSlice'
import {
  fetchCompetitorCuisine,
  fetchCompetitorDietary,
  fetchCompetitorPopularDishes,
  fetchCompetitorPricing,
  fetchRestaurantDashboard,
  fetchSuggestedIngredients,
  fetchVisitors,
  selectDashboardDetail,
  selectEndDate,
  selectMessage,
  selectRestaurantFIPS,
  selectRestaurantMiles,
  selectSeverity,
  selectShouldOpen,
  selectStartDate,
  setOpen,
  setRestaurantFIPS,
} from '../../../store/restaurant/dashboard'
import {
  fetchRestaurantAdmin,
  selectRestaurant,
} from '../../../store/restaurant/setting'
import { IDashboardCard } from '../../../types/dashboard'
import { MuiAlert, MuiSnackbar } from '../../eater/styled/global.styled'
import CompetitorInsights from './components/CompetitorInsights'
import DashboardHeader from './components/DashboardHeader'
import PopularDishesTable from './components/PopularDishesTable'
import TopCard from './components/TopCard'
import VisitorsModal from './components/VisitorsModal'
import Rating from './components/rating/Rating'
import { DashboardMainGrid } from './styled/Dashboard.styled'
import {
  findFipsByCountyState,
  getCountyAndStateByPlaceId,
} from '../../../helpers/fips-code-helper'

const Dashboard: React.FC = () => {
  const { theme, dispatch } = useAppUtilities()
  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY
  const openServer = useAppSelector(selectShouldOpen)
  const message = useAppSelector(selectMessage)
  const severity = useAppSelector(selectSeverity)
  const restaurantLocation = useAppSelector(selectRestaurantLocation)
  const restaurantAlias = useAppSelector(selectRestaurantAlias)
  const restaurant = useAppSelector(selectRestaurant)
  const dashboardDetail = useAppSelector(selectDashboardDetail)
  const restaurantMiles = useAppSelector(selectRestaurantMiles)
  const fromDate = useAppSelector(selectStartDate)
  const adminFIPS = useAppSelector(selectRestaurantFIPS)
  const toDate = useAppSelector(selectEndDate)
  const [loading, setLoading] = useState<boolean>(true)
  const [cardModal, setCardModal] = useState<boolean>(false)
  const [isMapLoad, setIsMapLoad] = useState<boolean>(false)

  useEffect(() => {
    void init()
  }, [fromDate, toDate])

  useEffect(() => {
    void onCompetitorPricing()
    if (!window.googleMapsLoaded) {
      const script = document.createElement('script')
      script.src = `https://maps.googleapis.com/maps/api/js?key=${googleMapsApiKey}&libraries=places`
      script.async = true
      script.onload = () => {
        window.googleMapsLoaded = true
        setIsMapLoad(true)
      }
      document.body.appendChild(script)

      return () => {
        document.body.removeChild(script)
      }
    }
  }, [])

  useEffect(() => {
    void onCompetitorPopularDishes()
  }, [fromDate, toDate, restaurantMiles])

  useEffect(() => {
    void onCompetitorPricing()
    void onCompetitorCuisine()
    void onCompetitorDietary()
    void onCompetitorSuggestedIngredients()
  }, [restaurantMiles])

  useEffect(() => {
    void findFipsCode()
  }, [isMapLoad, window.googleMapsLoaded, restaurant?.place_id])

  const onCompetitorPricing = async (): Promise<void> => {
    await dispatch(
      fetchCompetitorPricing({
        longitude: restaurantLocation?.lng ?? -73.935242,
        latitude: restaurantLocation?.lat ?? 40.73061,
        radius: restaurantMiles ?? 5,
      }),
    )
  }
  const onCompetitorPopularDishes = async (): Promise<void> => {
    await dispatch(
      fetchCompetitorPopularDishes({
        longitude: restaurantLocation?.lng ?? -73.935242,
        latitude: restaurantLocation?.lat ?? 40.73061,
        radius: restaurantMiles ?? 5,
        fromDate,
        toDate,
      }),
    )
  }
  const onCompetitorDietary = async (): Promise<void> => {
    await dispatch(
      fetchCompetitorDietary({
        longitude: restaurantLocation?.lng ?? -73.935242,
        latitude: restaurantLocation?.lat ?? 40.73061,
        radius: restaurantMiles ?? 5,
      }),
    )
  }
  const onCompetitorCuisine = async (): Promise<void> => {
    await dispatch(
      fetchCompetitorCuisine({
        longitude: restaurantLocation?.lng ?? -73.935242,
        latitude: restaurantLocation?.lat ?? 40.73061,
        radius: restaurantMiles ?? 5,
      }),
    )
  }
  const onCompetitorSuggestedIngredients = async (): Promise<void> => {
    await dispatch(
      fetchSuggestedIngredients({
        longitude: restaurantLocation?.lng ?? -73.935242,
        latitude: restaurantLocation?.lat ?? 40.73061,
        radius: restaurantMiles ?? 5,
        fromDate,
        toDate,
      }),
    )
  }
  const onVisitors = async (): Promise<void> => {
    await dispatch(
      fetchVisitors({
        fromDate,
        toDate,
      }),
    )
  }

  const getRestaurant = async (): Promise<void> => {
    await dispatch(fetchRestaurantAdmin({ alias: restaurantAlias ?? '' }))
  }

  const findFipsCode = async (): Promise<void> => {
    if (!adminFIPS && (window.googleMapsLoaded === true || isMapLoad)) {
      if (!restaurant?.place_id) {
        dispatch(setRestaurantFIPS(null))
        return
      }

      const countyName = await getCountyAndStateByPlaceId(restaurant.place_id)

      if (countyName) {
        const fipsCode = await findFipsByCountyState(
          countyName.county,
          countyName.countyState,
        )
        dispatch(setRestaurantFIPS(fipsCode))
      } else {
        dispatch(setRestaurantFIPS(null))
      }
    }
  }

  const init = async (): Promise<void> => {
    setLoading(true)

    await Promise.all([
      getRestaurant(),
      dispatch(fetchRestaurantDashboard({ fromDate, toDate })),
      onVisitors(),
    ])

    setLoading(false)
  }

  const onTopCardDetail = useCallback((): IDashboardCard[] => {
    return [
      {
        title: 'Total Views',
        value: dashboardDetail?.totalViews?.value ?? 0,
        percentageChange: dashboardDetail?.totalViews?.change ?? 0,
        icon: (
          <IconUsers
            stroke={1.5}
            style={{ color: theme.palette.secondary.main, fontSize: 28 }}
          />
        ),
        isInfo: false,
        isModelShow: true,
      },
      {
        title: 'Average Rating',
        value: Number(dashboardDetail?.averageRating?.value?.toFixed(1)) ?? 0,
        percentageChange: dashboardDetail?.averageRating?.change ?? 0,
        icon: (
          <IconStar
            stroke={1.5}
            style={{ color: theme.palette.secondary.main, fontSize: 28 }}
          />
        ),
        isInfo: false,
      },
      {
        title: 'Recommended to Users',
        value: dashboardDetail?.recommendedToUsers?.value ?? 0,
        percentageChange: dashboardDetail?.recommendedToUsers?.change ?? 0,
        icon: (
          <IconEye
            stroke={1.5}
            style={{ color: theme.palette.secondary.main, fontSize: 28 }}
          />
        ),
        isInfo: true,
      },
      {
        title: 'Reviews',
        value: dashboardDetail?.reviews?.value ?? 0,
        percentageChange: dashboardDetail?.reviews?.change ?? 0,
        icon: (
          <IconStar
            stroke={1.5}
            style={{ color: theme.palette.secondary.main, fontSize: 28 }}
          />
        ),
        isInfo: false,
      },
    ]
  }, [dashboardDetail, theme])

  const setOpenServer = (): void => {
    dispatch(setOpen(false))
  }

  return (
    <DashboardHeader
      title="Dashboard"
      body={
        <>
          {!loading ? (
            <Fade in={!loading} timeout={1000}>
              <Box>
                <DashboardMainGrid>
                  {onTopCardDetail().map((item, index) => (
                    <Grid item key={index} xs={6} lg={3}>
                      <TopCard
                        card={item}
                        onCardClick={() => setCardModal(true)}
                      />
                    </Grid>
                  ))}
                </DashboardMainGrid>
                <DashboardMainGrid py={{ xs: '6px', sm: 1, md: 2 }}>
                  <Grid item xs={12} md={6}>
                    <Rating />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <PopularDishesTable />
                  </Grid>
                </DashboardMainGrid>
                <CompetitorInsights />
              </Box>
            </Fade>
          ) : (
            <Box>
              <DashboardMainGrid>
                {Array.from({ length: 4 }).map((_, index) => (
                  <Grid item key={index} xs={6} lg={3}>
                    <Skeleton
                      variant="rectangular"
                      width="100%"
                      sx={{
                        height: { xs: '124px', md: '144px' },
                        borderRadius: { xs: '12px', md: '16px' },
                      }}
                    />
                  </Grid>
                ))}
              </DashboardMainGrid>
              <DashboardMainGrid py={{ xs: '6px', sm: 1, md: 2 }}>
                <Grid item xs={12} md={6}>
                  <Skeleton
                    variant="rectangular"
                    width="100%"
                    height={270}
                    sx={{
                      borderRadius: { xs: '12px', md: '16px' },
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Skeleton
                    variant="rectangular"
                    width="100%"
                    height={270}
                    sx={{
                      borderRadius: { xs: '12px', md: '16px' },
                    }}
                  />
                </Grid>
              </DashboardMainGrid>
              <Skeleton
                variant="rectangular"
                width="100%"
                height={500}
                sx={{
                  borderRadius: { xs: '12px', md: '16px' },
                }}
              />
            </Box>
          )}

          <Dialog
            onClose={setCardModal}
            open={Boolean(cardModal)}
            PaperProps={{
              sx: {
                bgcolor: 'background.paper',
                borderRadius: '16px',
                p: { xs: '16px', md: '24px' },
                width: { xs: 'calc(100% - 20px)', md: '500px' },
              },
            }}
          >
            <VisitorsModal handleClose={() => setCardModal(false)} />
          </Dialog>

          <MuiSnackbar open={openServer} onClose={setOpenServer}>
            <MuiAlert onClose={setOpenServer} severity={severity ?? 'success'}>
              {message}
            </MuiAlert>
          </MuiSnackbar>
        </>
      }
    />
  )
}

export default Dashboard
