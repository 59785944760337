import {
  Paper,
  SxProps,
  Table,
  TableCell,
  TableRow,
  Theme,
  useTheme,
} from '@mui/material'
import styled from 'styled-components'
import React from 'react'

interface TableRowTableCellProps {
  minWidth: string | number | undefined
  maxWidth?: string | number | undefined
}

interface TableBodyRowProps {
  sx?: SxProps<Theme>
}

export const TableMainPaper = styled(Paper).attrs<TableBodyRowProps>(
  ({ sx }) => {
    const theme = useTheme()
    return {
      sx: {
        width: '100%',
        overflow: 'hidden',
        boxShadow: 'none',
        border: `2px solid ${theme.palette.grey[200]}`,
        borderRadius: '12px',
        ...sx,
      },
    }
  },
)``

export const StickyTable = styled(Table).attrs(() => {
  return {
    stickyHeader: true,
    'aria-label': 'sticky table',
  }
})(() => ({}))

export const TableRowTableCell = styled(
  ({ minWidth, maxWidth, width, ...rest }) => <TableCell {...rest} />,
).attrs<TableRowTableCellProps>(({ minWidth, maxWidth, width }) => {
  const theme = useTheme()
  return {
    sx: {
      minWidth,
      maxWidth,
      width,
      textTransform: 'uppercase',
      fontSize: '10px !important',
      fontWeight: 600,
      color: theme.palette.grey[600],
      backgroundColor: theme.palette.grey[50],
      py: '8px',
      px: { xs: '6px', md: '16px' },
    },
  }
})<TableRowTableCellProps>(() => ({}))

export const TableBodyRow = styled(TableRow).attrs<TableBodyRowProps>(
  ({ sx }) => ({
    sx: {
      '&:last-child td, &:last-child th': { border: 0 },
      ...sx,
    },
  }),
)``

export const TableBodyCell = styled(TableCell).attrs<TableBodyRowProps>(
  ({ sx }) => ({
    sx: {
      py: '8px',
      px: { xs: '12px', md: '16px' },
      ...sx,
    },
  }),
)``
