import { Box, Typography, useTheme } from '@mui/material'
import styled from 'styled-components'

export const DashboardDishCardMainBox = styled(Box).attrs(() => {
  return {
    sx: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'start',
      cursor: 'pointer',
    },
  }
})(() => ({}))

export const DashboardDishCardInnerBox = styled(Box).attrs(() => {
  return {
    sx: {
      marginLeft: '16px',
    },
  }
})(() => ({}))

export const DashboardDishCardTypography = styled(Typography).attrs(() => {
  const theme = useTheme()
  return {
    variant: 'subtitle1',
    fontWeight: 600,
    color: theme.palette.grey[800],
  }
})(() => ({}))

export const DashboardDishCardInfoMainBox = styled(Box).attrs(() => {
  return {
    sx: {
      marginTop: '4px',
      padding: '4px 0px',
      display: 'flex',
      alignItems: 'start',
      justifyContent: 'start',
    },
  }
})(() => ({}))

export const DashboardDishCardInfoTypography = styled(Typography).attrs(() => {
  const theme = useTheme()
  return {
    variant: 'caption',
    fontWeight: 600,
    color: theme.palette.grey[500],
    sx: { marginLeft: '4px' },
  }
})(() => ({}))
