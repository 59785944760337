import { Box, Typography, useTheme } from '@mui/material'
import { IconStarFilled } from '@tabler/icons-react'
import styled from 'styled-components'
import React from 'react'

export const AllReviewsHeaderMainContainer = styled(Box).attrs(() => {
  return {
    sx: {
      display: 'flex',
      justifyContent: 'space-between',
      justifyItems: 'center',
      alignItems: 'center',
      marginBottom: '16px',
    },
  }
})(({ theme }) => ({}))

export const AllReviewsHeaderRatingMainContainer = styled(Box).attrs(() => {
  return {
    sx: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }
})(({ theme }) => ({}))

export const AllReviewsHeaderIconStarFilled = styled(IconStarFilled).attrs(
  () => {
    const theme = useTheme()
    return {
      color: theme.palette.secondary[300],
      size: 18,
    }
  },
)(({ theme }) => ({}))

export const AllReviewsHeaderTitleTypography = styled(Typography).attrs(() => {
  const theme = useTheme()
  return {
    variant: 'body2',
    fontWeight: 700,
    sx: {
      color: theme.palette.grey[800],
    },
  }
})(({ theme }) => ({}))

export const AllReviewsHeaderRatingTypography = styled(Typography).attrs(() => {
  const theme = useTheme()
  return {
    variant: 'body2',
    fontWeight: 700,
    sx: {
      ml: '6px',
      color: theme.palette.grey[800],
    },
  }
})(({ theme }) => ({}))

export const AllReviewsContentMainContainer = styled(Box).attrs(() => ({
  className: 'hide-scrollbar',
  sx: {
    overflowY: { xs: 'auto', md: 'auto' },
    maxHeight: { xs: '300px', md: 'none' },
    flexGrow: 1,
  },
}))``

export const AllReviewsContentWrapper = styled(Box).attrs(() => {
  const theme = useTheme()
  return {
    sx: {
      marginTop: '8px',
      padding: '10px',
      border: 1.5,
      borderRadius: '12px',
      borderColor: theme.palette.grey[200],
      backgroundColor: theme.palette.background.paper,
      display: 'flex',
      justifyContent: 'start',
      alignItems: 'center',
    },
  }
})(({ theme }) => ({}))

interface AllReviewsContentInnerWrapperProps {
  type: string
}

export const AllReviewsContentInnerWrapper = styled(({ type, ...rest }) => (
  <Box {...rest} />
)).attrs<AllReviewsContentInnerWrapperProps>(({ type }) => {
  const theme = useTheme()
  return {
    sx: {
      backgroundColor:
        type === 'positive'
          ? theme.palette.success[50]
          : theme.palette.error[50],
      color:
        type === 'positive'
          ? theme.palette.success[600]
          : theme.palette.error[600],
      marginRight: '10px',
      borderRadius: '8px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: { xs: '4px', md: '10px' },
      alignSelf: 'start',
    },
  }
})<AllReviewsContentInnerWrapperProps>(() => ({}))
