import React from 'react'
import { Route, Routes } from 'react-router-dom'
import AuthGuard from './guard/AuthGuard'
import RoleGuard from './guard/RoleGuard'
import { ROUTES } from './helpers/routes-helper'
import BaseLayout from './layouts/BaseLayout'
import OnboardLayout from './layouts/OnboardLayout'
import RestaurantLayout from './layouts/restaurant/RestaurantLayout'
import WebMobileLayout from './layouts/WebMobileLayout'
import About from './pages/eater/about/About'
import Error from './pages/eater/error/Error'
import GroupPage from './pages/eater/group/GroupPage'
import Home from './pages/eater/home/Home'
import Onboard from './pages/eater/onboardPages/Onboard'
import Profile from './pages/eater/profile/ProfilePage'
import RecoverAccount from './pages/eater/recoverAccount/RecoverAccount'
import RecoverBlockedAccount from './pages/eater/recoverAccount/RecoverBlockedAccount'
import RestaurantDetail from './pages/eater/restaurant/RestaurantDetail'
import Restaurants from './pages/eater/restaurant/Restaurants'
import SearchResults from './pages/eater/restaurant/SearchResults'
import Social from './pages/eater/social/Social'
import Dashboard from './pages/restaurant/dashboard/Dashboard'
import Dish from './pages/restaurant/dish/Dish'
import Menu from './pages/restaurant/menu/Menu'
import Notifications from './pages/restaurant/notifications/Notifications'
import Recommendations from './pages/restaurant/recommendations/Recommendations'
import RestaurantPending from './pages/restaurant/RestaurantPending'
import Reviews from './pages/restaurant/reviews/Reviews'
import Settings from './pages/restaurant/settings/Settings'
import Sitemap from './sitemap-generator'
import Messages from './pages/restaurant/messages/Messages'

const RouterApp: React.FC = () => {
  return (
    <Routes>
      <Route element={<BaseLayout />}>
        <Route element={<WebMobileLayout />}>
          <Route path="*" element={<Error type="404" />} />
          <Route
            path={ROUTES.UNAUTHORIZED}
            element={<Error type="unauthorized" />}
          />
          {/* Public routes */}
          <Route path={ROUTES.HOME} element={<Home />} />
          <Route path={ROUTES.ABOUT} element={<About />} />
          <Route path={ROUTES.RESTAURANTS} element={<Restaurants />} />
          <Route path={ROUTES.SEARCH} element={<SearchResults />} />
          <Route
            path={`${ROUTES.RESTAURANT_DETAILS}/:alias/details`}
            element={<RestaurantDetail />}
          />
        </Route>

        {/* Authenticated routes */}
        <Route element={<AuthGuard />}>
          {/* Eater routes */}
          <Route
            element={
              <RoleGuard
                allowedRoles={['EATER']}
                allowedStatuses={['ACTIVE']}
                redirectPath={ROUTES.HOME}
              />
            }
          >
            <Route element={<WebMobileLayout />}>
              <Route path={ROUTES.SOCIAL} element={<Social />} />
              <Route path={`${ROUTES.SOCIAL}/:id`} element={<GroupPage />} />
              <Route path={ROUTES.PROFILE} element={<Profile />} />
            </Route>
          </Route>

          {/* Onboard routes (for status PENDING) */}
          <Route
            element={
              <RoleGuard
                allowedRoles={[undefined, 'EATER', 'RESTAURANT']}
                allowedStatuses={['PENDING', 'ACTIVE', 'NEW']}
                redirectPath={ROUTES.HOME}
              />
            }
          >
            <Route element={<OnboardLayout />}>
              <Route
                path={`${ROUTES.ONBOARD}/:stepName`}
                element={<Onboard />}
              />
            </Route>
          </Route>

          {/* Recovery routes */}
          <Route
            element={
              <RoleGuard
                allowedRoles={['EATER', 'RESTAURANT']}
                allowedStatuses={['DELETED']}
                redirectPath={ROUTES.HOME}
              />
            }
          >
            <Route path={ROUTES.RECOVER_ACCOUNT} element={<RecoverAccount />} />
          </Route>
          <Route
            element={
              <RoleGuard
                allowedRoles={['EATER', 'RESTAURANT']}
                allowedStatuses={['BLOCKED']}
                redirectPath={ROUTES.HOME}
              />
            }
          >
            <Route
              path={ROUTES.RECOVER_BLOCKED_ACCOUNT}
              element={<RecoverBlockedAccount />}
            />
          </Route>

          {/* Restaurant routes  */}
          <Route
            element={
              <RoleGuard
                allowedRoles={['RESTAURANT']}
                allowedStatuses={['ACTIVE']}
                redirectPath={ROUTES.NOTFOUND}
              />
            }
          >
            <Route element={<RestaurantLayout />}>
              <Route
                path={ROUTES.RESTAURANT_ADMIN_DASHBOARD}
                element={<Dashboard />}
              />
              <Route path={ROUTES.RESTAURANT_MENUS} element={<Menu />} />
              <Route
                path={`${ROUTES.RESTAURANT_MENUS}/:id`}
                element={<Dish />}
              />
              <Route path={ROUTES.RESTAURANT_REVIEWS} element={<Reviews />} />
              <Route
                path={ROUTES.RESTAURANT_RECOMMENDATIONS}
                element={<Recommendations />}
              />
              <Route
                path={ROUTES.RESTAURANT_NOTIFICATIONS}
                element={<Notifications />}
              />
              <Route path={ROUTES.RESTAURANT_MESSAGES} element={<Messages />} />
              <Route path={ROUTES.RESTAURANT_SETTINGS} element={<Settings />} />
            </Route>
          </Route>

          {/* Pending restaurant route */}
          <Route
            element={
              <RoleGuard
                allowedRoles={['RESTAURANT']}
                allowedStatuses={['PENDING']}
                redirectPath={ROUTES.HOME}
              />
            }
          >
            <Route element={<RestaurantLayout />}>
              <Route
                path={ROUTES.RESTAURANT_ADMIN_PENDING}
                element={<RestaurantPending />}
              />
            </Route>
          </Route>
        </Route>
      </Route>

      <Route path={ROUTES.SITEMAP} Component={Sitemap} />
    </Routes>
  )
}

export default RouterApp
