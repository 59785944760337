import { useAuth0 } from '@auth0/auth0-react'
import { Box, CircularProgress } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import EmptyScreen from '../../../components/EmptyScreen'
import Filter from '../../../components/filter/Filter'
import RoundedButton from '../../../components/ui/RoundedButton'
import { useWebLayoutContext } from '../../../context/WebLayoutContext'
import { generateEater } from '../../../helpers/converters'
import { getLocation } from '../../../helpers/location-helper'
import { ROUTES } from '../../../helpers/routes-helper'
import { headingName, price } from '../../../helpers/static-constants'
import useAppUtilities from '../../../hooks/useAppUtilities'
import { useAppSelector } from '../../../store'
import { selectLocation } from '../../../store/authSlice'
import {
  getLandingPageCuisineDetails,
  getRestaurants,
  selectIsLoading,
  selectPage,
  selectRestaurants,
  selectTotalPages,
  setRestaurants,
} from '../../../store/landingSlice'
import { selectEater } from '../../../store/profileSlice'
import { selectRadius } from '../../../store/restaurantSlice'
import {
  IFilterResponseItem,
  IFilterTabList,
  ILandingDetailsRequest,
  IRestaurantsCuisineRequest,
} from '../../../types'
import useRudderStackAnalytics from '../../../useRudderAnalytics'
import { MuiBackdrop } from '../styled/global.styled'
import RestaurantList from './components/RestaurantList'
import {
  RestaurantsContainer,
  RestaurantsContentContainer,
  RestaurantsHeaderBackRoundedButton,
  RestaurantsHeaderContainer,
  RestaurantsHeaderFilterButton,
  RestaurantsHeaderFilterButtonContainer,
  RestaurantsHeaderInnerContainer,
  RestaurantsHeaderTitleContainer,
  RestaurantsHeaderTitleTypography,
  RestaurantsIconAdjustments,
  RestaurantsIconAdjustmentsSecond,
  RestaurantsIconChevronLeft,
  RestaurantsMainContainer,
} from './styled/Restaurants.styled'

const RestaurantPage: React.FC = () => {
  const { theme, navigate, location, dispatch, isMediumScreen } =
    useAppUtilities()
  const { isAuthenticated } = useAuth0()
  const [analytics, isAnalyticsReady] = useRudderStackAnalytics()
  const queryParams = new URLSearchParams(location.search)
  const name = queryParams.get('name')
  const value = queryParams.get('value')
  const queryType = queryParams.get('type')
  const page = useAppSelector(selectPage)
  const eater = useAppSelector(selectEater)
  const radius = useAppSelector(selectRadius)
  const isLoading = useAppSelector(selectIsLoading)
  const totalPages = useAppSelector(selectTotalPages)
  const locationData = useAppSelector(selectLocation)
  const restaurants = useAppSelector(selectRestaurants)
  const { isScrollBottom } = useWebLayoutContext()
  const [loading, setLoading] = useState(false)
  const [title, setTitle] = useState('Restaurants')
  const [helmetTitle, setHelmetTitle] = useState('');
  const [filters, setFilters] = useState(restaurants ?? [])
  const [isDefaultLoading, setIsDefaultLoading] = useState(false)
  const [filterModalOpen, setFilterModalOpen] = React.useState(false)
  const [filterTabList, setFilterTabList] = useState<IFilterTabList[]>([])

  useEffect(() => {
    const { fullName, eaterId, email } = generateEater(eater ?? {})
    if (analytics && isAnalyticsReady) {
      analytics?.page('PAGE_OPENED', {
        path: ROUTES.RESTAURANTS,
        title: `Resactly | Explore ${title}`,
        url: `${window.location.origin}${ROUTES.RESTAURANTS}`,
        category: 'Restaurants',
        name: title,
        eaterName: fullName,
        email,
        eaterId,
        startTime: new Date().toISOString(),
      })
    }
  }, [analytics, eater, isAnalyticsReady])

  useEffect(() => {
    if(title) {
      setHelmetTitle(`Resactly | Explore ${title}`)
    }
  }, [title])

  useEffect(() => {
    const { fullName, eaterId, email } = generateEater(eater ?? {})
    return () => {
      analytics?.page('PAGE_CLOSED', {
        path: ROUTES.RESTAURANTS,
        title: `Resactly | Explore ${title}`,
        url: `${window.location.origin}${ROUTES.RESTAURANTS}`,
        category: 'Restaurants',
        name: title,
        eaterName: fullName,
        email,
        eaterId,
        endTime: new Date().toISOString(),
      })
    }
  }, [location, analytics])

  useEffect(() => {
    void fetchData()
  }, [radius])

  useEffect(() => {
    if (restaurants && restaurants.length) {
      const filter = onFilterItems()
      setFilterTabList(filter)
    }
  }, [restaurants])

  const onFilterItems = (): IFilterTabList[] => {
    const filterItems = [
      {
        key: 1,
        name: 'Distance',
        items: [
          { key: 1, name: '0 mi', value: '0' },
          { key: 2, name: '5 mi', value: '5' },
          { key: 3, name: '10 mi', value: '10' },
          { key: 4, name: '15 mi', value: '15' },
          { key: 5, name: '20 mi', value: '20' },
          { key: 6, name: '25 mi', value: '25' },
          { key: 7, name: '30 mi', value: '30' },
        ],
        rangeStep: 5,
        filterType: 'range',
      },
    ].filter(Boolean) as IFilterTabList[]

    if (restaurants && restaurants.length !== 0) {
      const allDietaryPreferences = restaurants.flatMap(
        (item) =>
          item?.restaurantDTO?.dietary?.map((preference) =>
            preference.trim(),
          ) ?? [],
      )
      const uniqueDietaryPreferences = Array.from(
        new Set(
          allDietaryPreferences.filter(
            (preference): preference is string =>
              preference !== undefined && preference.trim() !== '',
          ),
        ),
      )

      const cuisineList: string[] = restaurants
        .flatMap(
          (item) =>
            item?.restaurantDTO?.cuisine
              ?.split(',')
              .map((cuisine) => cuisine.trim()) ?? [],
        )
        .filter((cuisine): cuisine is string => cuisine !== undefined)
        .filter((cuisine, index, self) => self.indexOf(cuisine) === index)

      const priceRanges = restaurants.map(
        (dish) => dish?.restaurantDTO?.priceRange,
      )
      const uniquePriceRanges = Array.from(new Set(priceRanges))

      if (uniquePriceRanges.length > 1) {
        filterItems.push({
          key: 2,
          name: 'Price',

          items: Array.from({ length: 4 }).map((_, index) => {
            return {
              key: index + 1,
              name: price[index],
              value: `${index + 1}`,
            }
          }),
          rangeStep: 1,
          filterType: 'range',
          priceType: 'dollar',
        })
      }
      if (uniqueDietaryPreferences.length !== 0) {
        filterItems.push({
          key: 3,
          name: 'Dietary',
          items: uniqueDietaryPreferences.map((item, index) => {
            return { key: index + 1, name: item, value: item }
          }),
          filterType: 'checkbox',
        })
      }
      if (cuisineList.length !== 0) {
        filterItems.push({
          key: 4,
          name: 'Cuisine',
          items: cuisineList.map((item, index) => {
            return { key: index + 1, name: item, value: item }
          }),
          filterType: 'checkbox',
        })
      }
    }

    return filterItems
  }

  const fetchItems = async (pageNumber: number): Promise<void> => {
    if (loading) return
    setLoading(true)
    let local = locationData
    if (locationData === undefined) {
      local = await getLocation()
    }

    if (name && queryType === 'cuisine') {
      const payload: IRestaurantsCuisineRequest = {
        longitude: local?.longitude ?? 0,
        latitude: local?.latitude ?? 0,
        radius,
        name,
        page: pageNumber,
      }

      const result = await dispatch(getLandingPageCuisineDetails(payload))
      if (getLandingPageCuisineDetails.fulfilled.match(result)) {
        dispatch(setRestaurants({ response: result.payload, isAuthenticated }))
      }
      setTitle(value ? `${value} Cuisine Restaurants` : `Cuisine Restaurants`)
    } else {
      const request: ILandingDetailsRequest = {
        longitude: local?.longitude,
        latitude: local?.latitude,
        radius,
        type: queryType ?? '',
        page: pageNumber,
      }
      const title = headingName.find((item) => item.key === queryType)?.title

      setTitle(title ?? 'Restaurant')
      const result = await dispatch(getRestaurants(request))
      if (getRestaurants.fulfilled.match(result)) {
        dispatch(setRestaurants({ response: result.payload, isAuthenticated }))
      }
    }

    setLoading(false)
  }

  const fetchData = async (): Promise<void> => {
    setIsDefaultLoading(true)
    await fetchItems(0)
    setIsDefaultLoading(false)
  }

  useEffect(() => {
    if (isScrollBottom && totalPages - 1 > page && !loading) {
      fetchItems(page + 1).catch(() => {})
    }
  }, [isScrollBottom])

  const onFilter = (obj: IFilterResponseItem): void => {
    if (restaurants) {
      const data = restaurants.filter((dish) => {
        if (
          obj.price !== undefined &&
          obj.price > 1 &&
          price.indexOf(dish?.restaurantDTO?.priceRange?? '0') > Number(obj.price) - 1
        ) {
          return false
        }
        if (
          obj.distance !== undefined &&
          obj.distance > 0 &&
          Number(dish?.restaurantDTO?.distance ?? 0) > Number(obj.distance)
        ) {
          return false
        }

        if (obj.dietary && obj.dietary.length > 0) {
          const dietaryValues = obj.dietary.map((item) =>
            item.name.trim().toLowerCase(),
          )
          if (
            !dietaryValues.some((dietary) =>
              dish?.restaurantDTO?.dietary
                ?.map((d) => d.trim().toLowerCase())
                .includes(dietary.trim().toLowerCase()),
            )
          ) {
            return false
          }
        }
        if (obj.cuisine && obj.cuisine.length > 0) {
          const cuisineValues = obj.cuisine.map((item) => item.name)
          if (
            !cuisineValues.some(
              (dietary) =>
                dish?.restaurantDTO?.cuisine?.toLocaleLowerCase() ===
                dietary.toLowerCase(),
            )
          ) {
            return false
          }
        }

        return true
      })
      setFilters(data)
    }
  }

  return (
    <RestaurantsMainContainer>
      <Helmet>
        <title>{helmetTitle}</title>
        <meta
          name="description"
          content="Browse a comprehensive list of top restaurants on Resactly. Use our AI-driven search to find the best dining options based on your preferences and location."
        />
      </Helmet>
      {!isDefaultLoading && (
        <RestaurantsContainer>
          <RestaurantsHeaderContainer>
            <RestaurantsHeaderInnerContainer>
              <RestaurantsHeaderTitleContainer>
                <RestaurantsHeaderBackRoundedButton
                  onClick={() => navigate(ROUTES.HOME)}
                >
                  <RestaurantsIconChevronLeft />
                </RestaurantsHeaderBackRoundedButton>
                <RestaurantsHeaderTitleTypography>
                  {title}
                </RestaurantsHeaderTitleTypography>
              </RestaurantsHeaderTitleContainer>
              {restaurants && restaurants.length > 0 && (
                <>
                  <RestaurantsHeaderFilterButtonContainer>
                    {isMediumScreen ? (
                      <RoundedButton
                        component="p"
                        borderColor={theme.palette.grey[800]}
                        diameter={37}
                        onClick={() => setFilterModalOpen(true)}
                      >
                        <RestaurantsIconAdjustments />
                      </RoundedButton>
                    ) : (
                      <>
                        <div>
                          <Filter
                            onFilterData={onFilter}
                            tabList={filterTabList}
                            rowData={restaurants}
                            isFilterModalOpen={filterModalOpen}
                            setFilterModalOpen={setFilterModalOpen}
                          />
                        </div>
                        <RestaurantsHeaderFilterButton
                          startIcon={<RestaurantsIconAdjustmentsSecond />}
                          onClick={() => setFilterModalOpen(true)}
                        >
                          Filter
                        </RestaurantsHeaderFilterButton>
                      </>
                    )}
                  </RestaurantsHeaderFilterButtonContainer>
                </>
              )}
            </RestaurantsHeaderInnerContainer>
            {isMediumScreen && (
              <Box mt="12px">
                <Filter
                  onFilterData={onFilter}
                  tabList={filterTabList}
                  rowData={restaurants}
                  isFilterModalOpen={filterModalOpen}
                  setFilterModalOpen={setFilterModalOpen}
                />
              </Box>
            )}
          </RestaurantsHeaderContainer>

          <RestaurantsContentContainer>
            {restaurants && restaurants.length > 0 ? (
              filters && filters.length > 0 ? (
                <RestaurantList loading={isLoading} restaurants={filters} />
              ) : (
                <EmptyScreen containerHeight={'400px'} />
              )
            ) : (
              <EmptyScreen
                mainText="No data available"
                secondaryText="No restaurants found"
                imageUrl="/images/empty-screen.svg"
                containerHeight={'400px'}
              />
            )}
          </RestaurantsContentContainer>
        </RestaurantsContainer>
      )}

      <MuiBackdrop open={isDefaultLoading}>
        <CircularProgress color="inherit" />
      </MuiBackdrop>
    </RestaurantsMainContainer>
  )
}

export default RestaurantPage
